import NetUtils from "../../utils/NetUtils";

const TimeregabsenceService = {
    findByDate: date => NetUtils.doGet('/api/timeregabsences', {date: date}),
    findById: id => NetUtils.doGet('/api/timeregabsences/' + id),
    findUnnotarized: date => NetUtils.doGet('/api/timeregabsences/unnotarized'),

    update: model => NetUtils.doPost('/api/timeregabsences', model),
    updateList: modelList => NetUtils.doPost('/api/timeregabsences/list', modelList),
    notarizeIdList: modelIdList => NetUtils.doPost('/api/timeregabsences/notarize-id-list', modelIdList),
};
export default TimeregabsenceService;