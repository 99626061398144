import React from "react";
import FormItemNumber from "../../../../components/EditForm/FormItemNumber";
import {FORMROW_KEY_PREFIX} from "../FormanswerEditForm";

export default function FormtypeNumber(props) {
    const model = props.model

    return <FormItemNumber label={model.name}
                           name={FORMROW_KEY_PREFIX + model.id}
                           placeholder={model.example}
                           maxWidth={null}
                           {...props}
    />
}