import React from 'react';
import {i18n} from "../../I18n/I18n";
import Label from "./Label";
import TextInput from "./TextInput";

const FormItemText = props => {
    const {
        name,
        label,
        labelI18n,
        hidden
    } = props

    if (hidden) return undefined
    return <div className="formItem">
        {/*<label htmlFor={'input-' + name}>{labelI18n ? i18n(labelI18n) : label}</label>*/}
        <Label htmlFor={'input-' + name}>{labelI18n ? i18n(labelI18n) : label}</Label>
        <TextInput {...props}/>
    </div>
}
export default FormItemText;