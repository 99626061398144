import React from "react";
import {NBSP} from "../../../App";
import NumberGridInput from "../../../components/Grid/NumberGridInput";
import './getDayHeader.scss'

const getDayEditor = (e, item, i, projectInactive, dayLocked) => {
    let className = undefined

    if (projectInactive) {
        className = 'inactive-project'
        return <div className={className}>{NBSP}</div>
    }

    let quantity = e.rowData.dayList[i].quantity
    if (quantity === 0) quantity = ''

    if (dayLocked) return <div className={className}>{quantity}</div>

    return <NumberGridInput editorEvent={e} integer={true} min={0} max={20} align='center'/>
}
export default getDayEditor