import '@components/EditForm/EditForm.scss'
import React, {useContext, useState} from 'react';
import {Controller, useForm, useWatch} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import {APP_NAME} from "../../../App";
import AppContext from "../../../AppContext";
import {CancelButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemRichText from "../../../components/EditForm/FormItemRichText";
import FormItemText from "../../../components/EditForm/FormItemText";
import Grid from "../../../components/Grid/Grid";
import RemoveColumn from "../../../components/Grid/RemoveColumn";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Spinner from "../../../components/Spinner/Spinner";
import TabPanel from "../../../components/TabView/TabPanel";
import TabView from "../../../components/TabView/TabView";
import XlnzSideBar from "../../../components/XlnzSideBar/XlnzSideBar";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import {GridTemplates} from "../../../GridTemplates";
import useMountEffect from "../../../hooks/useMountEffect";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import logo from '../../../resources/images/logo.png'
import {findById, removeByValue} from "../../../utils/ArrayUtil";
import RouterUtils from "../../../utils/RouterUtils";
import NewsletterService from "../NewsletterService";
import NewsletterStatus from "../NewsletterStatus";
import './NewsletterEditForm.scss'
import {SendNewsletterButton} from "./SendNewsletterButton";

const NewsletterEditForm = () => {
    const context = useContext(AppContext)
    const roles = useRoles()

    const [model, setModel] = useState()

    const location = useLocation()
    const navigate = useNavigate()

    const disabled = !model || !roles.hasRole(Role.CRM_WRITE)

    const defaultValues = {
        name: '',
        status: NewsletterStatus.DRAFT,
        message: '',
        sentlogList: []
    }

    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, getValues, setValue, control} = form

    const watchName = useWatch({control, name: 'name'})
    const watchMessage = useWatch({control, name: 'message'})

    const validate = async data => {
        return true
    }

    const save = async (data) => {
        if (!await validate(data)) {
            hideLoading()
            return undefined
        }

        if (!disabled) {
            data.company = context.currentCompany.id

            console.log("SAVE MODEL:", data)
            const _model = await NewsletterService.update(data)

            if (_model) {
                setModel(_model)
                reset(_model)
            }
            return _model
        } else {
            return model;
        }
    };

    const onSubmit = () => {
        showLoading();
        const data = getValues();
        console.log("data.message", data.message)
        // data.message = data.message.replaceAll('<p><br></p>', '<br>')
        // console.log("data.message", data.message)
        return save(data).then(_model => {
            hideLoading()
            if (_model) RouterUtils.goBack(navigate)
        });
    }

    useMountEffect(async () => {
        showLoading();
        const id = RouterUtils.getId(location)
        if (id > 0) {
            const _model = await NewsletterService.findById(id)
            setModel(_model)
            reset(_model)
        } else {
            setModel(defaultValues)
        }

        hideLoading();
    })


    if (!model) return <Spinner/>
    return <div className='NewsletterEditForm'>
        <div className='EditForm'>
            <div className="headline">
                <h1>{i18n('newsletter')} {model?.number}</h1>
            </div>
            <form>
                <div className="button-row">
                    <div className='left-buttons'>
                        {!disabled &&
                            <SaveButton onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>}
                        <CancelButton onClick={e => RouterUtils.goBack(navigate)}/>
                    </div>
                    <div className='divider'></div>
                    <div className='right-buttons'>
                        <SendNewsletterButton model={model}
                                              handleSubmit={handleSubmit}
                                              save={save}
                                              reset={reset}
                                              recipient={getValues('recipient')}
                        />
                    </div>
                </div>

                <div className='columnContainer'>
                    <div className='column'>
                        <FormItemDropdown name='recipient' labelI18n='recipient'
                                          showSelect={true}
                                          options={[
                                              {id: 'USERS', name: i18n('users')},
                                              {id: 'LEADS', name: i18n('leads')}
                                          ]}
                                          style={{maxWidth: '200px'}}
                                          required {...form} />

                        <FormItemText name='name' labelI18n='name' maxLength={78}
                                      style={{maxWidth: '500px'}}
                                      required {...form}/>

                        <FormItemDropdown name='status' labelI18n='status'
                                          options={NewsletterStatus.LIST}
                                          optionValue='value'
                                          optionLabel='label'
                                          required {...form} />
                    </div>
                    <div className='column'>
                    </div>
                    <div className='column'>
                    </div>
                </div>

                <TabView>
                    <TabPanel key='message' header={i18n('message')}>
                        <FormItemRichText name='message' {...form}/>
                    </TabPanel>
                    <TabPanel key='log' header={i18n('log')}>
                        <Controller name='sentlogList'
                                    control={control}
                                    render={({field: {ref, value, onChange}}) =>
                                        <div style={{minWidth: '700px'}}>
                                            <Grid
                                                inputRef={ref} value={value} disabled={disabled}
                                                xlnzGrid={true}
                                                hideFooter={true}
                                                scrollable={false}
                                                forceDesktop={true}
                                                columns={[
                                                    {
                                                        field: 'ctime',
                                                        headerI18n: 'created',
                                                        body: GridTemplates.time,
                                                        width: 140
                                                    },
                                                    {field: 'toEmail', headerI18n: 'email',},
                                                    {field: 'status', headerI18n: 'status'},
                                                    {
                                                        field: 'lastOpenedTime',
                                                        headerI18n: 'last_opened',
                                                        body: GridTemplates.time,
                                                        width: 140
                                                    },
                                                    RemoveColumn(row => {
                                                        const _sentlogList = getValues('sentlogList')
                                                        const _row = findById(_sentlogList, row.id)
                                                        // console.log("row", row, _sentlogList,_sentlogList.indexOf(row))
                                                        removeByValue(_sentlogList, _row)
                                                        // _sentlogList = _sentlogList.filter(r => r !== row)
                                                        setValue('sentlogList', _sentlogList)
                                                    })
                                                ]}
                                            />
                                        </div>
                                    }/>
                    </TabPanel>
                </TabView>

                <XlnzSideBar faicon={Icon.PREVIEW}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '700px',
                        lineHeight: '20px',
                        padding: '20px'
                    }}>
                        <div style={{
                            // alignSelf: 'center',
                        }}>
                            <img src={logo} alt={APP_NAME} style={{
                                width: '250px'
                            }}/>
                        </div>

                        <div dangerouslySetInnerHTML={{__html: watchName}} style={{
                            // alignSelf: 'center',
                            padding: '40px 0',
                            fontSize: '26px',
                        }}/>
                        <div dangerouslySetInnerHTML={{__html: watchMessage}} style={{
                            fontSize: '13px',
                            listStyle: "outside"
                        }}/>

                    </div>
                </XlnzSideBar>
            </form>
        </div>
    </div>
}

export default NewsletterEditForm;
