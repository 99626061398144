import React from "react";
import './Label.scss'
import {i18n} from "../../I18n/I18n";

export default function Label(props) {
    const {
        labelI18n,
        value,
        valueI18n,
        children,
        htmlFor
    } = props

    if (labelI18n) console.warn("Depricated parameter: 'labelI18n'", labelI18n)

    if (!labelI18n && !value && !valueI18n && !children) return <span className='Label'>&nbsp;</span>

    if (htmlFor) {
        return <label className='Label' htmlFor={htmlFor}>
            {value}
            {valueI18n && i18n(valueI18n)}
            {labelI18n && i18n(labelI18n)}
            {children}
        </label>
    } else {
        return <span className='Label'>
            {value}
            {valueI18n && i18n(valueI18n)}
            {labelI18n && i18n(labelI18n)}
            {children}
        </span>
    }
}