import React from 'react';
import Unit from "../../../../enums/Unit";
import {formatTwoDecimalsIfNeeded} from "../../../../utils/NumberUtils";
import AvatarWithHours from "../shared/AvatarWithHours";
import AllowanceAvatarTooltip from "./AllowanceAvatarTooltip";
import './EmployeeAllowanceInfoGridTemplete.scss'

const EmployeeAllowanceInfoGridTemplete = props => {
    const {
        employeeList,
        timeregallowanceList,
        timeregallowancetypeList,
    } = props

    const hasHours = type => timeregallowanceList.find(tr => tr.type === (!type ? null : type.id))

    return <div className='EmployeeInfo'>
        {
            timeregallowancetypeList
                .filter(type => hasHours(type))
                .map(type => <TypeGroup key={type.id} employeeList={employeeList}
                                        timeregallowanceList={timeregallowanceList}
                                        type={type}/>)
        }
    </div>
}

export default EmployeeAllowanceInfoGridTemplete;

function TypeGroup({employeeList, timeregallowanceList, type}) {
    const getEmployee = employeeId => employeeList.find(employee => employee.id === employeeId)

    return <div className='type'>
        <div className='type-label'>{type.name}</div>
        <div className='employeeList'>
            {
                timeregallowanceList
                    .filter(tr => tr.type === (!type ? null : type.id))
                    .map((tr, index) => {
                        const employee = getEmployee(tr.employee)
                        return <AvatarWithHours
                            key={index}
                            label={employee?.alias || 'ERROR'}
                            leftHours={formatTwoDecimalsIfNeeded(tr.quantity) + ' ' + Unit.findById(type.unit).symbol}
                            notarized={tr.notarized}
                            tooltipContent={<AllowanceAvatarTooltip employee={employee} timeregallowance={tr}/>}
                        />
                    })
            }
        </div>
    </div>;
}