import React from "react";
import usePreload from "../../hooks/usePreload";
import {findByField} from "../../utils/ArrayUtil";
import FormItemDropdown from "./FormItemDropdown";

const FormItemProject = props => {
    const {
        name = 'project',
        labelI18n = 'project',
        options,
        onChange,
        required = false,
        showSelect = true
    } = props

    const preload = usePreload()
    const costcenterList = preload.getCostcenterList()

    options.forEach(item => {
        const filterBy = [
            item.number,
            item.name,
            item.projectLeaderName,
            item.markup
        ]

        if (costcenterList?.length > 0 && item.costcenter > 0) {
            const costcenter = findByField(costcenterList, 'id', item.costcenter)
            filterBy.push(costcenter.name)
        }

        item.filterBy = filterBy.join(';')
    })

    return <FormItemDropdown name={name}
                             labelI18n={labelI18n}
                             options={options}
                             optionLabel='label'
                             onChange={onChange}
                             filterBy='filterBy'
                             required={required}
                             showSelect={showSelect}
                             {...props} />
}
export default FormItemProject