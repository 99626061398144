import Role from "../../enums/Role";
import TendertermEditForm from "./TendertermEditForm";
import TendertermGrid from "./TendertermGrid";

const TendertermIndex = {
    routeProps: {
        path: '/tenderterms',
        exact: true,
        element: <TendertermGrid/>,
    },
    nameKey: 'tender_terms',
    icon: 'pi pi-fw pi-folder',
    subModules: [{
        routeProps: {
            path: '/tenderterms/:id',
            exact: true,
            element: <TendertermEditForm/>,
        }
    }],
    roles: [Role.ADMIN]
};
export default TendertermIndex;