import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {i18n} from "../../I18n/I18n";
import './ContextMenuItem.scss'

const ContextMenuItem = props => {
    const label = props.label
    const labelI18n = props.i18n
    const icon = props.icon
    const command = props.command
    const items = props.items
    const hidden = props.hidden
    const disabled = props.disabled !== undefined ? props.disabled : false

    return {
        label: i18n(labelI18n),
        command: command,
        items: items,
        disabled: disabled,
        template: (item, options, row) => {

            // console.log("ROW222", row)

            return <Template label={label ? label : i18n(labelI18n)}
                             icon={icon}
                             items={items}
                             hidden={hidden}
                             row={row}
                             item={item}
                             options={options}
            />
        }
    }
}

const Template = ({label, icon, items, hidden, row, item, options}) => {
    // console.log("rowrow", row)
    // console.log("ww", label, icon, items, hidden, row, item, options)
    if (hidden && hidden(row)) return undefined

    // console.log("options", options)

    return <div className='ContextMenuItem' target={item?.target}
                onClick={e => {
                    if (options.onClick) options.onClick(e)
                    if (options.originalCommand) options.originalCommand(row)
                }}
    >
        <div className='icon'>
            <FontAwesomeIcon icon={icon}/>
        </div>
        <div className='label'>
            {label}
        </div>
        {
            items &&
            <div className='right-arrow'>
                <FontAwesomeIcon icon={faChevronRight}/>
            </div>
        }
    </div>
}

export default ContextMenuItem