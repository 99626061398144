import React, {useState} from 'react';
import {DebounceInput} from 'react-debounce-input';
import './XlnzAutoComplete.scss';

const XlnzAutoComplete = ({
                              field = 'label',
                              disabled = false,
                              className = '',
                              delay = 600,
                              value = '',
                              suggestions = [],
                              completeMethod,
                              onChange,
                              onBlur
                          }) => {
    const inputValue = value
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

    const handleChange = (e) => {
        if (completeMethod) {
            completeMethod(e.target.value);
        }
    };

    function handleMouseDown(suggestion) {
        if (onChange) onChange({target: {value: suggestion}});
    }

    function handleBlur(e) {
        if (onBlur) onBlur(e);
    }

    function handleKeyDown(e) {
        if (e.key === 'ArrowDown') {
            if (activeSuggestionIndex < suggestions.length - 1) {
                setActiveSuggestionIndex(activeSuggestionIndex + 1);
            }
        } else if (e.key === 'ArrowUp') {
            if (activeSuggestionIndex > 0) {
                setActiveSuggestionIndex(activeSuggestionIndex - 1);
            }
        } else if (e.key === 'Enter' || e.key === 'Tab') {
            if (activeSuggestionIndex >= 0) {
                handleMouseDown(suggestions[activeSuggestionIndex]);
            }
        }
    }

    return <div className={`xlnz-autocomplete ${className}`}>
        <DebounceInput
            minLength={2}
            debounceTimeout={delay}
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            className="xlnz-autocomplete-input"
            disabled={disabled}
        />
        <ul className="xlnz-autocomplete-suggestions">
            {
                suggestions?.length > 0 &&
                suggestions.map((suggestion, index) => (
                    <li
                        key={index}
                        onMouseDown={() => handleMouseDown(suggestion)} // Direkt hantering av klick
                        className={
                            index === activeSuggestionIndex
                                ? 'xlnz-autocomplete-suggestion active'
                                : 'xlnz-autocomplete-suggestion'
                        }
                    >
                        {suggestion[field]}
                    </li>
                ))
            }
        </ul>
    </div>
}

export default XlnzAutoComplete;
