import React from 'react';
import OptionalDatePicker from "../Date/OptionalDatePicker";

const OptionalDateEditor = props => {
    const {
        editorEvent
    } = props

    if (!editorEvent) throw new Error("editorEvent parameter missing")

    const {
        field,
        rowData
    } = editorEvent
    const value = rowData[field]

    return <OptionalDatePicker
        value={value}
        onChange={_value => {
            // console.log("_value", _value)
            editorEvent.editorCallback(_value)
        }}
        showIcon={false}
    />
}
export default OptionalDateEditor;