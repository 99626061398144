import React, {useEffect, useState} from "react";
import {Controller, useWatch} from "react-hook-form";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import TaxReductionType from "../../../enums/TaxReductionType";
import {findByField} from "../../../utils/ArrayUtil";
import HouseworkGrid from "./HouseworkGrid";

export default function InvoiceHousework(props) {
    const {
        disabled,
        form,
        customer,
        vatType,
        rowList
    } = props

    const [houseworkTypeList, setHouseworkTypeList] = useState([])

    const {setValue, control} = form

    const watchTaxReductionType = useWatch({control, name: 'taxReductionType'})
    const watchHouseworkType = useWatch({control, name: 'houseworkType'})


    // useEffect(() => {
    //     console.log("watchTaxReductionType", watchTaxReductionType)
    //     console.log("getValues", getValues('taxReductionType'))
    //     if (findByField(rowList, 'housework', true) !== undefined) {
    //         if (!watchTaxReductionType) {
    //             setValue('taxReductionType', TaxReductionType.rot.id)
    //         }
    //     } else {
    //         if (watchTaxReductionType) {
    //             setValue('taxReductionType', undefined)
    //         }
    //     }
    // }, [rowList]);

    useEffect(() => {
        if (watchTaxReductionType) {
            const taxReductionTypeObj = TaxReductionType.findById(watchTaxReductionType)
            setHouseworkTypeList(taxReductionTypeObj?.houseworkTypeList || [])

            // if (!watchHouseworkType) {
            //     const defaultHouseworkTypeObj = findByField(taxReductionTypeObj.houseworkTypeList, 'default', true)
            //     setValue('houseworkType', defaultHouseworkTypeObj.id)
            //     setValue('houseworkList', [])
            // }
        }
        // else {
        //     setValue('houseworkType', undefined)
        //     setValue('houseworkList', [])
        // }

    }, [watchTaxReductionType, watchHouseworkType, setValue]);

    return <>
        <div style={{display: 'flex', gap: '20px', width: '500px'}}>
            <div style={{width: '100px'}}>
                <FormItemDropdown name='taxReductionType' labelI18n='house_work'
                                  options={TaxReductionType.values()}
                                  showSelect
                                  showSelectValue={null}
                                  onChange={e => {
                                      if (e.target.value) {
                                          const taxReductionTypeObj = TaxReductionType.findById(e.target.value)
                                          setHouseworkTypeList(taxReductionTypeObj.houseworkTypeList)

                                          const defaultHouseworkTypeObj = findByField(taxReductionTypeObj.houseworkTypeList, 'default', true)
                                          setValue('houseworkType', defaultHouseworkTypeObj.id)
                                      } else {
                                          console.log("NULLL")
                                          setHouseworkTypeList([])
                                          setValue('houseworkType', null)
                                      }

                                      setValue('houseworkList', [])

                                      console.log("ONCHANGE", e)
                                  }
                                  }
                                  {...form} />
            </div>
            {
                houseworkTypeList.length > 0 &&
                <div style={{width: '250px'}}>
                    <FormItemDropdown name='houseworkType' labelI18n='type_of_house_work'
                                      options={houseworkTypeList}
                                      {...form} />
                </div>
            }
        </div>

        {
            watchTaxReductionType && watchHouseworkType &&
            <div className="formItem">
                <Controller name='houseworkList'
                            control={control}
                            render={({field: {ref, value, onChange}}) =>
                                <HouseworkGrid inputRef={ref} value={value}
                                               disabled={disabled}
                                               onChange={onChange}
                                               customer={customer}
                                               vatType={vatType}
                                               rowList={rowList}
                                               taxReductionType={watchTaxReductionType}
                                               houseworkType={watchHouseworkType}
                                />
                            }/>
            </div>
        }
    </>
}