import {findByField} from "../utils/ArrayUtil";

export default class TaxReductionType {
    static get rot() {
        return Object.freeze({
            id: 'rot', name: 'ROT', reductionPercentage: 0.3,
            houseworkTypeList: [
                {id: 'CONSTRUCTION', name: 'Bygg', default: true},
                {id: 'ELECTRICITY', name: 'El'},
                {id: 'GLASSMETALWORK', name: 'Glas/plåtarbete'},
                {id: 'GROUNDDRAINAGEWORK', name: 'Mark/dräneringsarbete'},
                {id: 'MASONRY', name: 'Murning'},
                {id: 'PAINTINGWALLPAPERING', name: 'Målning/tapetsering'},
                {id: 'HVAC', name: 'VVS'},
            ]
        });
    }

    static get rut() {
        return Object.freeze({
            id: 'rut', name: 'RUT', reductionPercentage: 0.5,
            houseworkTypeList: [
                {id: 'CLEANING', name: 'Städning'},
                {id: 'TEXTILECLOTHING', name: 'Kläd- och textilvård'},
                {id: 'SNOWPLOWING', name: 'Snöskottning'},
                {id: 'GARDENING', name: 'Trädgårdsarbete', default: true},
                {id: 'BABYSITTING', name: 'Barnpassning'},
                {id: 'OTHERCARE', name: 'Personlig omsorg'},
                {id: 'MOVINGSERVICES', name: 'Flyttjänster'},
                {id: 'ITSERVICES', name: 'IT-tjänster'},
                {id: 'MAJORAPPLIANCEREPAIR', name: 'Reparation av vitvaror'},
                {id: 'FURNISHING', name: 'Möblering'},
                {id: 'HOMEMAINTENANCE', name: 'Tillsyn av bostad'},
                {id: 'TRANSPORTATIONSERVICES', name: 'Transporttjänster'},
                {id: 'WASHINGANDCAREOFCLOTHING', name: 'Tvätt och vård av kläder'},
            ]
        });
    }

    // static get green() {
    //     return Object.freeze({
    //         id: 'green', name: 'Grönt', reductionPercentage: 0.5,
    //         houseworkTypeList: [
    //             {id: 'SOLARCELLS', name: 'Solceller', reductionPercentage: 0.2, default: true},
    //             {id: 'STORAGESELFPRODUCEDELECTRICITY', name: 'Lagring egenproducerad el'},
    //             {id: 'CHARGINGSTATIONELECTRICVEHICLE', name: 'Laddningspunkt elfordon'},
    //         ]
    //     });
    // }

    static values() {
        return [
            this.rot,
            this.rut,
            // this.green
        ];
    }

    static findById(id) {
        return findByField(this.values(), 'id', id)
    }

    static getDefaultHouseworkType(id) {
        const taxReductionTypeObj = findByField(this.values(), 'id', id)
        const defaultHouseworkTypeObj = findByField(taxReductionTypeObj.houseworkTypeList, 'default', true)
        return defaultHouseworkTypeObj
    }
}