import I18n from '@I18n';
import {useContext} from "react";
import {useNavigate} from "react-router-dom";
import AppContext from "../../../AppContext";
import {ExportButton} from "../../../components/Buttons/Button";
import {errorAlert} from "../../../components/Overlay/Alert";
import {confirm} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import RouterUtils from "../../../utils/RouterUtils";
import SupplierinvoiceService from "../SupplierinvoiceService";
import {formatCurrency} from "../../../utils/NumberUtils";

export const ExportSupplierinvoiceToFortnoxButton = props => {
    const context = useContext(AppContext)
    const navigate = useNavigate()
    if (!context.currentCompany.usingFortnox) return

    const {
        handleSubmit,
        save,
        reset,
        validateSupplierAndNumber,
        difference
    } = props

    const validateFortnox = (_model, bookkeep) => {
        const priceRowWithoutAccount = _model.rowList.find(r => r.quantity !== 0 && (!r.account));
        if (priceRowWithoutAccount) {
            errorAlert("Rader där antal är större än 0 måste ha ett konto och en beskrivning.");
            return false;
        }

        if (bookkeep) {
            if (difference !== 0) {
                errorAlert("Leverantörsfakturan balanserar inte. Differensen (" + formatCurrency(difference, 2) + ") måste vara noll.");
                return false;
            }
        }

        return true;
    }

    const onExportToFornox = data => {
        const sendReq = bookkeep => {
            showLoading();
            save(data).then(_model => {
                if (_model && validateFortnox(_model, bookkeep)) {
                    SupplierinvoiceService.exportToFortnox(_model.id, bookkeep).then(_model => {
                        if (_model) {
                            reset(_model);
                            RouterUtils.goBack(navigate)
                        }
                        hideLoading();
                    });
                } else {
                    hideLoading();
                }
            })
        }

        validateSupplierAndNumber(data, () => {
            confirm({
                header: 'Fråga',
                message: 'Hur ska fakturan överföras?',
                options: [
                    {
                        label: 'Bokförd',
                        onClick: () => sendReq(true)
                    }, {
                        label: 'Ej bokförd',
                        onClick: () => sendReq(false)
                    }
                ]
            })
        })
    }

    return <ExportButton
        label={I18n.t('export_to_fortnox') + '...'}
        onClick={handleSubmit(onExportToFornox)}
    />
}
