import I18n from '@I18n';
import {useContext} from "react";
import AppContext from "../../../AppContext";
import {ExportButton} from "../../../components/Buttons/Button";
import {errorAlert} from "../../../components/Overlay/Alert";
import {confirm} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import InvoiceService from "../InvoiceService";

export const ExportToFortnoxButton = props => {
    const context = useContext(AppContext)
    if (!context.currentCompany.usingFortnox) return

    const handleSubmit = props.handleSubmit
    const save = props.save
    const reset = props.reset

    const validateFortnox = _model => {
        const foo = _model.rowList.find(r => r.quantity !== 0 && (r.name.length === 0 || !r.account));
        if (foo) {
            errorAlert("Rader där antal är större än 0 måste ha ett konto och en beskrivning.");
            return false;
        }
        return true;
    }

    const onExportToFornox = data => {
        const sendReq = bookkeep => {
            showLoading();
            save(data).then(_model => {
                if (_model && validateFortnox(_model)) {
                    InvoiceService.exportToFortnox(_model.id, bookkeep).then(async _exportedModel => {
                        console.log("_exportedModel", _exportedModel)

                        if (_exportedModel) {
                            reset(_exportedModel);
                        } else {
                            reset(await InvoiceService.findById(_model.id))
                        }
                        hideLoading();
                    }, (err) => {
                        console.log("ERRORRR", err)
                    });
                } else {
                    hideLoading();
                }
            })
        }

        confirm({
            header: 'Fråga',
            message: 'Hur ska fakturan överföras?',
            options: [
                {
                    label: 'Bokförd',
                    onClick: () => sendReq(true)
                }, {
                    label: 'Ej bokförd',
                    onClick: () => sendReq(false)
                }
            ]
        })
    }

    return <ExportButton
        label={I18n.t('export_to_fortnox') + '...'}
        onClick={handleSubmit(onExportToFornox)}
    />
}
