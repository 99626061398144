import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import AppContext from "../../../AppContext";
import {EmailButton} from "../../../components/Buttons/Button";
import SendByEmailDialog from "../../../components/Dialog/SendByEmailDialog";
import FormItemCheckbox from "../../../components/EditForm/FormItemCheckbox";
import {useFilter} from "../../../components/Grid/filter";
import {errorAlert} from "../../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import usePreload from "../../../hooks/usePreload";
import {i18n} from "../../../I18n/I18n";
import {findById} from "../../../utils/ArrayUtil";
import RouterUtils from "../../../utils/RouterUtils";
import ValidateUtils from "../../../utils/ValidateUtils";
import I18nService from "../../I18ns/I18nService";
import TenderService from "../TenderService";

export const SendTenderButton = props => {
    const context = useContext(AppContext)
    const {
        changeOrder = false,
        customerId,
        dialog,
        email,
        ourReference,
        handleSubmit,
        save,
        reset,
    } = props

    const filter = useFilter('SendTenderButton', [
        {name: 'attachPdf', defaultValue: false},
    ])

    const navigate = useNavigate();
    const preload = usePreload()

    const onSendByEmail = data => {
        showLoading();
        save(data).then(async _model => {
            if (_model) {
                reset(_model);
                hideLoading();

                const customer = findById(preload.getCustomerList(), customerId)

                let message
                if (context.user.language !== customer.language) {
                    const i18n = await I18nService.findById(customer.language + '_' + (changeOrder ? 'send_change_order_message' : 'send_tender_message'))
                    message = i18n.value
                } else {
                    message = i18n(changeOrder ? 'send_change_order_message' : 'send_tender_message')
                }

                message = message.replaceAll(':OUR_REFERENCE', ourReference?.name)
                message = message.replaceAll(':COMPANY_NAME', context.currentCompany.legalName)

                dialog.show(<SendByEmailDialog
                    email={email}
                    message={message}

                    customFields={form => {
                        form.setValue('attachPdf', filter.attachPdf)
                        return <FormItemCheckbox name='attachPdf' labelI18n='attach_pdf' {...form}/>
                    }}

                    onSend={(toEmail, message, data) => {
                        const attachPdf = data.attachPdf
                        filter.update('attachPdf', attachPdf)

                        showLoading()
                        TenderService.sendByEmail(changeOrder ? _model.tender : _model.id, toEmail, message, attachPdf).then(() => {
                            hideLoading()
                            RouterUtils.goBack(navigate)
                        });
                    }}/>)
            } else {
                hideLoading();
            }
        })
    }

    return <EmailButton onClick={e => {
        if (!ourReference || !ValidateUtils.isEmailValid(ourReference.email)) {
            errorAlert(i18n('error_invalid_our_reference_email'))
        } else {
            handleSubmit(onSendByEmail)(e)
        }
    }}
    />
}
