import {faExclamationCircle, faQuoteRight, faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useContext, useEffect, useState} from "react";
import {useWatch} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import AppContext from "../../../AppContext";
import Button, {MainButton} from "../../../components/Buttons/Button";
import FormItemTextArea from "../../../components/EditForm/FormItemTextArea";
import {errorAlert} from "../../../components/Overlay/Alert";
import {confirmYes} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import useEmployees from "../../../hooks/useEmployees";
import {i18n} from "../../../I18n/I18n";
import RouterUtils from "../../../utils/RouterUtils";
import SupplierinvoiceService from "../SupplierinvoiceService";
import './SupplierinvoiceAttestation.scss'

const SupplierinvoiceAttestation = props => {
    const {
        save,
        form,
        form: {control}
    } = props

    const context = useContext(AppContext)
    const navigate = useNavigate()

    const employees = useEmployees()

    const [attestEmployee, setAttestEmployee] = useState()

    const watchNote = useWatch({control, name: 'note'})
    const watchAttestList = useWatch({control, name: 'attestList'})

    useEffect(() => {
        const attest = watchAttestList.find(attest => !attest.answerTime)
        if (attest) {
            const employee = employees.findById(attest.employee)
            if (context.user.email === employee.email) {
                setAttestEmployee(employee)
            }
        } else {
            setAttestEmployee(undefined)
        }

    }, [context.user.email, employees, watchAttestList])


    function onApproveClick() {
        confirmYes(async () => {
            showLoading()
            const data = form.getValues()

            const _model = await save(data)
            form.reset(_model);

            const comment = data.attestComment || ''
            await SupplierinvoiceService.attest(_model.id, attestEmployee.id, true, comment)
            RouterUtils.goBack(navigate)
            hideLoading()
        })
    }

    function onNotApproveClick() {
        const data = form.getValues()
        if (!data.attestComment || data.attestComment.trim().length === 0) {
            errorAlert(i18n('enter_a_comment'))
            return
        }

        confirmYes(async () => {
            showLoading()

            const _model = await save(data)
            form.reset(_model);

            const comment = data.attestComment
            await SupplierinvoiceService.attest(_model.id, attestEmployee.id, false, comment)
            RouterUtils.goBack(navigate)
            hideLoading()
        })
    }

    if (!attestEmployee) return
    return <div className='SupplierinvoiceAttestation'>
        <h2>{i18n('attestation')}</h2>
        {
            watchNote?.length > 0 &&
            <div className='attest-comment' style={{marginBottom: '20px'}}>
                <FontAwesomeIcon icon={faExclamationCircle}
                                 style={{fontSize: '18px', color: '888', paddingRight: '5px', width: '20px'}}/>
                {watchNote}
            </div>
        }
        {
            watchAttestList
                .filter(attest => attest.comment)
                .map(attest => <div key={attest.id} className='attest-comment'>
                    <div>
                        <FontAwesomeIcon icon={faQuoteRight}
                                         style={{fontSize: '18px', color: '888', paddingRight: '5px', width: '20px'}}/>
                        {attest.comment}
                    </div>
                    <div className='undersigned'>
                        - {employees.findById(attest.employee)?.name}
                    </div>
                </div>)
        }

        <FormItemTextArea name='attestComment' labelI18n='comment' maxLength={1000} {...form} />

        <div className='button-row'>
            <MainButton faicon={faThumbsUp} labelI18n='approve' onClick={onApproveClick}/>
            <Button faicon={faThumbsDown} labelI18n='deny' onClick={onNotApproveClick}/>
        </div>
    </div>
}
export default SupplierinvoiceAttestation