import Role from "../../enums/Role";
import UserEditForm from "./UserEditForm";
import UserGrid from "./UserGrid";

const UserIndex = {
    routeProps: {
        path: '/users',
        exact: true,
        element: <UserGrid/>,
    },
    nameKey: 'users',
    icon: 'pi pi-fw pi-user',
    subModules: [{
        routeProps: {
            path: '/users/:id',
            exact: true,
            element: <UserEditForm/>
        }
    }],
    roles: [Role.USER_READ]
};
export default UserIndex