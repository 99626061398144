import React from 'react';
import DateEditor from "../../../components/Grid/DateEditor";
import {addFreeRows} from "../../../components/Grid/freeRows";
import Grid from "../../../components/Grid/Grid";
import NumberGridInput from "../../../components/Grid/NumberGridInput";
import onRowChange from "../../../components/Grid/onRowChange";
import RemoveColumn from "../../../components/Grid/RemoveColumn";
import {GridTemplates} from "../../../GridTemplates";
import {todayDate} from "../../../utils/DateUtils";

const NUM_OF_FREE_ROWS = 1

const EmployeesalaryGrid = props => {
    const {
        value,
        disabled,
        hired
    } = props

    const rowList = addFreeRows(value, NUM_OF_FREE_ROWS, {
        id: 0, startDate: todayDate(), salary: 0
    }, item => {
        return item.salary > 0
    })

    const fireChange = (list) => {
        props.onChange(list);
    }

    const columns = [
        {
            field: 'startDate', headerI18n: 'start_date',
            editor: DateEditor,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 100
        },
        {
            field: 'salary', headerI18n: hired ? 'cost' : 'salary',
            body: GridTemplates.currencyPerHoursTwoDecimals,
            editor: e => <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 100,
            align: 'right'
        },
        RemoveColumn(row => fireChange(rowList.filter(r => r !== row)))
    ]

    return <div style={{width: '400px'}}>
        <Grid
            xlnzGrid={true}
            columns={columns}
            value={rowList}
            disabled={disabled}
            footerColumnGroup={<div/>}
        />
    </div>
}
export default EmployeesalaryGrid;
