import React, {useContext} from 'react';
import AppContext from "../../../../AppContext";
import Grid from "../../../../components/Grid/Grid";
import NumberGridInput from "../../../../components/Grid/NumberGridInput";
import {hideLoading, showLoading} from "../../../../components/Overlay/Loading";
import Unit from "../../../../enums/Unit";
import {GridTemplates} from "../../../../GridTemplates";
import usePreload, {PreloadType} from "../../../../hooks/usePreload";
import {i18n} from "../../../../I18n/I18n";
import {addDays} from "../../../../utils/DateUtils";
import {formatTwoDecimalsIfNeeded} from "../../../../utils/NumberUtils";
import {deepCopy} from "../../../../utils/ObjectUtils";
import TimeregallowanceService from "../../TimeregallowanceService";
import {MONTHOVERVIEW_VIEW_DAY_WIDTH} from "../MonthoverviewView";
import getDayStyle from "../workedtime/getDayStyle";
import filterTimeregallowancetypes from "./filterTimeregallowancetypes";
import './MonthoverviewAllowanceGrid.scss'

const MonthoverviewAllowanceGrid = props => {
    const context = useContext(AppContext);
    const preload = usePreload()

    const disabled = props.disabled
    const employeeId = props.employeeId
    const startDate = props.startDate
    const numOfDays = props.numOfDays
    const isDayLocked = props.isDayLocked
    const timeregallowanceList = props.timeregallowanceList
    const timeregallowancetypeList = deepCopy(filterTimeregallowancetypes(
        preload.get(PreloadType.TIMEREGALLOWANCETYPE_LIST), timeregallowanceList, disabled))
    const onChange = props.onChange

    timeregallowancetypeList.forEach(type => {
            for (let i = 0; i < numOfDays; i++) {
                const date = addDays(startDate, i)
                const value = timeregallowanceList
                    .filter(ta => ta.date === date)
                    .filter(ta => ta.type === type.id)
                    .map(ta => ta.quantity)
                    .reduce((sum, hours) => sum + hours, 0)

                type[date] = value
            }

            type.totalQuantity = timeregallowanceList
                .filter(ta => ta.type === type.id)
                .map(ta => ta.quantity)
                .reduce((sum, quantity) => sum + quantity, 0)
        }
    )

    const onCellEditComplete = async e => {
        const type = e.rowData
        const date = e.field
        const timeregallowance = timeregallowanceList.find(ta => ta.date === date && ta.type === type.id)

        const oldValue = timeregallowance?.quantity || 0
        const newValue = e.newValue || 0

        if (newValue !== oldValue) {
            showLoading()
            if (timeregallowance) {
                if (!timeregallowance.id && timeregallowance.quantity === 0) {
                    // No need to update.
                } else {
                    timeregallowance.quantity = newValue
                    timeregallowance.notarized = true
                    const savedTimeregallowance = await TimeregallowanceService.update(timeregallowance)
                    Object.assign(timeregallowance, savedTimeregallowance)
                    onChange([...timeregallowanceList])
                }
            } else {
                const newTimeregallowance = {
                    company: context.currentCompany.id,
                    type: type.id,
                    employee: employeeId,
                    date: date,
                    quantity: newValue,
                    notarized: true
                }
                const savedTimeregallowance = await TimeregallowanceService.update(newTimeregallowance)

                const _timeregallowanceList = [...timeregallowanceList]
                _timeregallowanceList.push(savedTimeregallowance)
                onChange(_timeregallowanceList)
            }
            hideLoading()
        }
    }

    const columns = [{
        field: 'nameWithUnit',
        header: i18n('allowances'),
        body: (rowData) => rowData.name + ' (' + Unit.findById(rowData.unit).symbol + ')'
    }]

    for (let i = 0; i < numOfDays; i++) {
        const date = addDays(startDate, i)
        const dayLocked = isDayLocked(date)

        columns.push({
            field: date,
            body: GridTemplates.numberTwoDecimalsIfNeeded,
            editor: e => dayLocked
                ? formatTwoDecimalsIfNeeded(e.value)
                : <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: onCellEditComplete,
            width: MONTHOVERVIEW_VIEW_DAY_WIDTH,
            align: 'center',
            className: 'dayColumn',
            style: getDayStyle(date),
        })
    }

    columns.push({
        field: 'totalQuantity',
        body: GridTemplates.numberTwoDecimalsIfNeeded,
        width: MONTHOVERVIEW_VIEW_DAY_WIDTH,
        align: 'center',
        style: {fontWeight: 'bold'},
    })

    return <div className='MonthoverviewAllowanceGrid'>
        <Grid xlnzGrid={true}
              forceDesktop={true}
              scrollable={false}
              columns={columns}
              value={timeregallowancetypeList}
              hideFooter={true}
              sortable={false}
              onRowSelect={() => {
              }}
        >
        </Grid>
    </div>
}

export default MonthoverviewAllowanceGrid;
