import React, {useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Role from "../../enums/Role";
import {matchActive, matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useMountEffect from "../../hooks/useMountEffect";
import useRoles from "../../hooks/useRoles";
import IncrementService from "./IncrementService";

const IncrementGrid = () => {
    const roles = useRoles()

    const filter = useFilter('IncrementGrid', [
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);
    const filteredList = list.filter(c => matchActive(filter, c) && matchSearch(filter, c))

    useMountEffect(async () => {
        showLoading()
        setList(await IncrementService.findAll())
        hideLoading()
    }, [])

    const leftFilters = [
        roles.hasRole(Role.SUPERADMIN) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
    ]

    const columns = [
        {field: 'type', headerI18n: 'type', mobile: true},
        {field: 'prefix', header: 'Prefix'},
        {field: 'value', headerI18n: 'value', body: GridTemplates.numberTwoDecimalsIfNeeded},
    ];

    return <Grid
        xlnzGrid={true}
        label='Increments'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
    />
}

export default IncrementGrid;
