import NetUtils from "../../utils/NetUtils";

const AgreementService = {
    findAll: params => NetUtils.doGet('/api/agreements', params),
    findUsersWithoutAgreement: () => NetUtils.doGet('/api/agreements/users-without-agreement'),
    listInvoiceOverview: (year, month) => NetUtils.doGet('/api/agreements/invoice-overview', {
        year: year,
        month: month
    }),
    findById: id => NetUtils.doGet('/api/agreements/' + id),
    update: model => NetUtils.doPost('/api/agreements', model),

    findRowsByCustomer: (customerId, year, month) => NetUtils.doGet('/api/agreementrows', {
        customer: customerId,
        year: year,
        month: month
    }),
}
export default AgreementService;