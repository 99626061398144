import Role from "../../enums/Role";
import PricelistEditForm from "./edit/PricelistEditForm";
import PricelistGrid from "./PricelistGrid";

const PricelistsIndex = {
    routeProps: {
        path: '/pricelists',
        exact: true,
        element: <PricelistGrid/>,
    },
    nameKey: 'pricelists',
    icon: 'pi pi-fw pi-folder',
    subModules: [{
        routeProps: {
            path: '/pricelists/:id',
            exact: true,
            element: <PricelistEditForm/>,
        }
    }],
    roles: [Role.ADMIN]
};
export default PricelistsIndex;