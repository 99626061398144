import AppContext from "@AppContext";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {format} from "date-fns";
import {sv} from "date-fns/locale";
import React, {useContext} from 'react';
import ReactDatePicker from "react-datepicker";
import {i18n} from "../../I18n/I18n";
import {addMonths, formatDate, getYear, todayYear} from "../../utils/DateUtils";
import './MonthStepper.scss';
import {capitalizeFirstLetter} from "../../utils/StringUtil";

const MonthStepper = props => {
    const context = useContext(AppContext);

    const customLocale = {
        ...sv,
        localize: {
            ...sv.localize,
            month: (n) => [
                i18n('jan'),
                i18n('feb'),
                i18n('mar'),
                i18n('apr'),
                i18n('may'),
                i18n('jun'),
                i18n('jul'),
                i18n('aug'),
                i18n('sep'),
                i18n('oct'),
                i18n('nov'),
                i18n('dec')
            ][n]
        }
    };

    const value = new Date()
    value.setTime(Date.parse(props.value))

    // const name = props.name || 'MonthStepper'

    const startYear = getYear(context.currentCompany.ctime)
    const endYear = todayYear() + 1

    const onDateChange = date => {
        props.onChange(formatDate(date))
    }

    const onStep = numOfMonths => {
        props.onChange(addMonths(value, numOfMonths))
    }

    return <div className='MonthStepper'>
        <div className='stepper-left' onClick={e => {
            e.preventDefault()
            onStep(-1)
        }}>
            <FontAwesomeIcon icon={faChevronLeft} className='faIcon'/>
        </div>
        <ReactDatePicker
            selected={value}
            onChange={(date) => onDateChange(date)}
            dateFormat="MMMM yyy"
            // locale="el"
            locale={customLocale}
            showMonthYearPicker
            minDate={new Date(startYear, 0)}
            maxDate={new Date(endYear, 11)}
            customInput={<CustomInput foo={value}/>}
        />
        <div className='stepper-right' onClick={e => onStep(1)}>
            <FontAwesomeIcon icon={faChevronRight} className='faIcon'/>
        </div>
    </div>
}
export default MonthStepper;


const CustomInput = React.forwardRef(({foo, value, onClick}, ref) => {
    const _value = capitalizeFirstLetter(format(foo, "MMMM yyyy", {locale: sv}))
    return <input
        readOnly
        ref={ref}
        value={_value}
        onClick={onClick}
        style={{width: '120px', textAlign: 'center'}}
    />;
});