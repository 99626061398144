import Country from "./Country";

export default class Vat {
    static get VAT0() {
        return Object.freeze({id: 0, name: '0 %'});
    }

    static get VAT6() {
        return Object.freeze({id: 6, name: '6 %'});
    }

    static get VAT7() {
        return Object.freeze({id: 7, name: '7 %'});
    }

    static get VAT12() {
        return Object.freeze({id: 12, name: '12 %'});
    }

    static get VAT25() {
        return Object.freeze({id: 25, name: '25 %'});
    }


    static findByCountry(country) {
        if (country === Country.ES.id) {
            return [
                {id: this.VAT0.id, name: this.VAT0.name},
                {id: this.VAT7.id, name: this.VAT7.name}
            ]
        } else {
            return [
                {id: this.VAT0.id, name: this.VAT0.name},
                {id: this.VAT6.id, name: this.VAT6.name},
                {id: this.VAT12.id, name: this.VAT12.name},
                {id: this.VAT25.id, name: this.VAT25.name}
            ]
        }
    }
}