import Role from "../../enums/Role";
import IncrementEditForm from "./IncrementEditForm";
import IncrementGrid from "./IncrementGrid";

const IncrementsIndex = {
    routeProps: {
        path: '/increments',
        exact: true,
        element: <IncrementGrid/>,
    },
    name: 'Increments',
    icon: 'pi pi-fw pi-folder',
    subModules: [{
        routeProps: {
            path: '/increments/:id',
            exact: true,
            element: <IncrementEditForm/>,
        }
    }],
    roles: [Role.SUPERADMIN]
};
export default IncrementsIndex;