import React, {useCallback, useEffect, useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import MonthDropdown from "../../components/Date/MonthDropdown";
import XlnzDropdown from "../../components/XlnzDropdown/XlnzDropdown";
import YearFilter from "../../components/filters/YearFilter";
import {useFilter} from "../../components/Grid/filter";
import {FooterAggregate} from "../../components/Grid/getFooterGroup";
import Grid from "../../components/Grid/Grid";
import PdfColumn from "../../components/Grid/PdfColumn";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import {GridTemplates} from "../../GridTemplates";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import {todayYear} from "../../utils/DateUtils";
import TenderService from "./TenderService";
import TenderStatus from "./TenderStatus";

const TenderGrid = () => {
    const roles = useRoles()

    const filter = useFilter('TenderGrid', [
        {name: 'status', defaultValue: TenderStatus.DRAFT},
        {name: 'year', defaultValue: todayYear()},
        {name: 'month', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);

    const hasTimefilter = useCallback(() => [TenderStatus.ACCEPTED,
                TenderStatus.NOT_ACCEPTED,
                TenderStatus.CANCELLED].indexOf(filter.status) !== -1
            || !filter.status
        , [filter.status])

    useEffect(() => {
        showLoading()
        const params = {
            status: filter.status,
            year: hasTimefilter() ? filter.year : undefined,
            month: hasTimefilter() ? filter.month : undefined,
            search: filter.search
        }
        TenderService.findAll(params).then(_list => {
            setList(_list);
            hideLoading()
        });
    }, [hasTimefilter, filter.status, filter.year, filter.month, filter.search])

    const columns = [
        PdfColumn(() => true,
            rowData => rowData.number + ' - ' + rowData.name,
            rowData => '/api/tenders/' + rowData.id + '/pdf'
        ),
        {field: 'name', headerI18n: 'name'},
        {field: 'number', headerI18n: 'number', width: 100, mobile: true},
        {field: 'ourReferenceName', headerI18n: 'our_reference'},
        {field: 'customerName', headerI18n: 'customer', mobile: true},
        {
            field: 'price',
            headerI18n: 'price',
            body: GridTemplates.currency,
            align: 'right',
            width: 140,
            footer: FooterAggregate.SUM_CURRENCY,
            mobile: true
        },
        {field: 'note', headerI18n: 'note', body: GridTemplates.stringLimit100},
        {
            field: 'status',
            headerI18n: 'status',
            body: row => TenderStatus.getName(row.status),
            width: 100,
        },
    ];

    const leftFilters = [
        roles.hasRole(Role.TENDER_WRITE) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <label className="label">{i18n('status')}</label>
            <XlnzDropdown
                value={filter.status}
                options={TenderStatus.LIST}
                optionValue='value'
                optionLabel='labelMultiple'
                showClear={true}
                placeholder={i18n('select') + '...'}
                onChange={e => filter.update('status', e.value)}
                style={{width: '160px'}}/>
        </div>
    ]

    if (hasTimefilter()) {
        leftFilters.push(<div>
            <label className="label">{i18n('year')}</label>
            <YearFilter value={filter.year} onChange={e => filter.update('year', e.value)}/>
        </div>)
        leftFilters.push(<div>
            <label className="label">{i18n('month')}</label>
            <MonthDropdown value={filter.month} onChange={e => {
                filter.update('month', e.value)
            }}/>
        </div>)
    }

    return <Grid
        xlnzGrid={true}
        id='TenderGrid'
        labelI18n='all_tenders'
        icon={Icon.TENDER}
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={list}
    />
}

export default TenderGrid;
