import React from 'react';
import FormItemText from "./FormItemText";

const FormItemInteger = props => {
    const {
        maxWidth = 160
    } = props

    return <FormItemText maxWidth={maxWidth} integer {...props}/>
}
export default FormItemInteger;