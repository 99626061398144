import {faCheck, faCircleQuestion} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useRef} from 'react';
import {i18n} from "../../I18n/I18n";
import {CancelButton, MainButton} from "../Buttons/Button";
import './Confirm.scss';
import renderOverlay from "./renderOverlay";

const Dialog = props => {
    const {
        onHide,
        header,
        message,
        options,
    } = props

    const ref = useRef()

    useEffect(() => {
        ref.current.focus()
    }, [])


    return <div
        ref={ref}
        className='Confirm'
        tabIndex={-1}
        onKeyDown={e => {
            switch (e.key) {
                case 'Escape':
                    onHide()
                    break
                case 'Enter':
                    if (options[0].label === i18n('yes')) {
                        options[0].onClick()
                        onHide()
                    }
                    break;
                default:
                    break;
            }
        }
        }>
        <h1 className='header'>
            {<FontAwesomeIcon icon={faCircleQuestion} className='faIcon'/>}
            <span>{header}</span>
        </h1>

        <div className='message'>
            {
                message.split('\n').map((item, key) => {
                    return <span key={key}>{item}<br/></span>
                })
            }
        </div>
        <div className='footer'>
            {
                options &&
                options.map((o, index) => <MainButton key={index} label={o.label} faicon={faCheck}
                                                      onClick={() => {
                                                          o.onClick();
                                                          onHide()
                                                      }}/>)
            }
            <CancelButton onClick={onHide}/>
        </div>
    </div>
}

/*
EXAMPLE:
    confirm({
        header: 'Fråga',
        message: 'Hur ska fakturan överföras?',
        options: [
            {
                label: 'Bokförd',
                onClick: () => sendReq(true)
            }, {
                label: 'Ej bokförd',
                onClick: () => sendReq(false)
            }
        ]
    })
 */
export function confirm(props) {
    renderOverlay(
        <Dialog
            header={props.header ? props.header : i18n('question')}
            message={props.message ? props.message : i18n('are_you_sure')}
            options={props.options}
            showCancel={props.showCancel}
        />);
}

export function confirmYes(callback) {
    confirm({
        options: [
            {
                label: i18n('yes'),
                onClick: callback
            }
        ]
    })
}