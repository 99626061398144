import React from 'react';
import {Controller} from "react-hook-form";
import FormItemAccount from "../../../components/EditForm/FormItemAccount";
import FormItemCheckbox from "../../../components/EditForm/FormItemCheckbox";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemText from "../../../components/EditForm/FormItemText";
import SimpleEditForm from "../../../components/EditForm/SimpleEditForm";
import TabPanel from "../../../components/TabView/TabPanel";
import Role from "../../../enums/Role";
import Unit from "../../../enums/Unit";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import {i18n} from "../../../I18n/I18n";
import {findByField} from "../../../utils/ArrayUtil";
import ArticleService from "../ArticleService";
import ArticlepriceGrid from "./ArticlepriceGrid";

const ArticleEditForm = () => {
    const preload = usePreload()
    const articleList = preload.getArticleList();

    const preSaveFunction = data => {
        if (!data.unit) delete data.unit
        if (!data.price) data.price = 0

        const a = findByField(articleList, 'number', data.number)
        if (a !== undefined && a.id !== data.id) {
            return i18n('error_artno_already_exists')
        }
    }

    return <SimpleEditForm
        writeRole={Role.ARTICLE_WRITE}
        headlineI18n='article'
        defaultValues={{
            unit: undefined,
            accountId: undefined,
            name: '',
            number: '',
            price: 0,
            active: true,
            priceList: []
        }}
        loadFunction={ArticleService.findById}
        preSaveFunction={preSaveFunction}
        saveFunction={ArticleService.update}
        initLeftFields={form => [
            <FormItemText key='name' name='name' label={i18n('name')} maxLength={128} required {...form}/>,
            <FormItemText key='number' name='number' label={i18n('number')} maxLength={128} required {...form}/>,
            <FormItemDropdown key='unit' name='unit' label={i18n('unit')}
                              options={Unit.values()}
                              showSelect
                              width={160}
                              {...form} />,
            <FormItemAccount key='account' name='accountId' {...form} />,
            <FormItemCheckbox key='employeeExposed' name='employeeExposed'
                              label={i18n('show_to_employees')} {...form}/>,
            <FormItemCheckbox key='active' name='active' label={i18n('active')} {...form}/>
        ]}
        initTabs={form => [
            <TabPanel key='price' header={i18n('prices')}>
                <div className="formItem">
                    <Controller name='priceList'
                                control={form.control}
                                render={({field: {ref, value, onChange}}) =>
                                    <ArticlepriceGrid
                                        inputRef={ref} value={value} disabled={form.disabled} onChange={onChange}
                                    />}/>
                </div>
            </TabPanel>
        ]}
        preloadType={PreloadType.ARTICLE_LIST}
    />
}

export default ArticleEditForm;
