import React from "react";
import {i18n} from "../../../../I18n/I18n";

const AllowanceAvatarTooltip = props => {
    const {
        employee,
        timeregallowance,
    } = props

    return <table>
        <tbody>
        <tr>
            <td><b>{i18n('employee')}:</b></td>
            <td><b>{employee?.name || '?'}</b></td>
        </tr>
        < tr>
            <td> {i18n('quantity')}:</td>
            <td>{timeregallowance.quantity}</td>
        </tr>
        </tbody>
    </table>
}
export default AllowanceAvatarTooltip;