import '@components/EditForm/EditForm.scss'
import Role from "@enums/Role";
import React, {useState} from 'react';
import {Controller} from "react-hook-form";
import {useLocation} from "react-router-dom";
import FormItemDate from "../../../../components/EditForm/FormItemDate";
import FormItemDropdown from "../../../../components/EditForm/FormItemDropdown";
import FormItemTextArea from "../../../../components/EditForm/FormItemTextArea";
import SimpleEditForm from "../../../../components/EditForm/SimpleEditForm";
import {hideLoading, showLoading} from "../../../../components/Overlay/Loading";
import TabPanel from "../../../../components/TabView/TabPanel";
import {i18n} from "../../../../I18n/I18n";
import {sortByField} from "../../../../utils/ArrayUtil";
import {formatTimeUtc, todayDate} from "../../../../utils/DateUtils";
import RouterUtils from "../../../../utils/RouterUtils";
import HyrletService from "../../HyrletService";
import useHyrletLoginInfo from "../../useHyrletLoginInfo";
import HyrletOrderStatuses from "../HyrletOrderStatuses";
import HyrletOrderrowGrid from "./HyrletOrderrowGrid";

const HyrletOrderEditForm = () => {
    const [number, setNumber] = useState('')
    const [customerPriceMap, setCustomerPriceMap] = useState();
    const [stockQuantityMap, setStockQuantityMap] = useState();
    const [articlegroupList, setArticlegroupList] = useState();

    const [articleList, setArticleList] = useState();

    const location = useLocation()
    const id = RouterUtils.getId(location)
    const customerId = id ? id.substring(0, id.indexOf('-')) : undefined
    const orderId = id ? id.substring(id.indexOf('-') + 1) : undefined

    const hyrletLoginInfo = useHyrletLoginInfo()
    const customerList = sortByField(hyrletLoginInfo.getCurrentCustomerList(), 'fullName')

    const disabled = number?.length > 0

    const loadStockQuantities = async (customerId, orderId, typeId, ctime) => {
        showLoading()
        console.log("Loading stock quantities.", " type=" + typeId, "ctime=", ctime)

        let promises = [HyrletService.listArticlegroups(customerId), HyrletService.customerPrices(customerId, ctime),];

        switch (typeId) {
            case 'RENT':
                promises.push(orderId > 0 ? HyrletService.listArticlesByOrder(customerId, orderId) : HyrletService.listAvailableArticles(customerId))
                promises.push(HyrletService.stockQuantities(customerId, ctime))
                break;
            case 'RETURN':
                promises.push(orderId > 0 ? HyrletService.listArticlesByOrder(customerId, orderId) : HyrletService.listRentedArticles(customerId))
                promises.push(HyrletService.customerStockQuantities(customerId, ctime))
                break;
            default:
                break;
        }

        const [_articlegroupList, _customerPriceList, _articleList, _stockQuantityList] = await Promise.all(promises)

        setArticlegroupList(_articlegroupList.filter(ac => ac.active));

        const _customerPriceMap = new Map();
        _customerPriceList.list.forEach(cp => _customerPriceMap.set(cp.articleId, cp));
        setCustomerPriceMap(_customerPriceMap);

        setArticleList(_articleList);

        const map = new Map();
        _stockQuantityList.forEach(sq => map.set(sq.article, sq.stockQuantity));
        setStockQuantityMap(map);

        hideLoading()
    }

    const preLoadFunction = async id => {
        const _customerId = customerList.length === 1 ? customerList[0].id : undefined
        if (_customerId && !id) loadStockQuantities(_customerId, id, 'RENT', formatTimeUtc())

        // const promises = [
        //     HyrletService.listArticlegroups(customerId)
        // ]
        //
        // Promise.all(promises).then(async ([_articlegroupList]) => {
        //     setArticlegroupList(_articlegroupList.filter(ac => ac.active));
        // })


        // const [
        //     _articleList,
        //     _customerPriceList
        // ] = await Promise.all([
        //     id > 0 ? HyrletService.findArticlesByOrder(id) : HyrletService.findArticles(),
        //     HyrletService.findPriceList(now),
        //     HyrletService.findStockQuantities(now)
        // ])
        //
        // setArticleList(_articleList)
        //
        // const _customerPriceMap = new Map();
        // _customerPriceList.list.forEach(cp => _customerPriceMap.set(cp.articleId, cp));
        // setCustomerPriceMap(_customerPriceMap);
    }
    const postLoadFunction = _model => {
        setNumber(_model.number)
        loadStockQuantities(_model.customer, _model.id, _model.type, _model.ctime)

    }

    const preSaveFunction = async data => {
        // if (!data.id && data.regNo.length > 0) {
        //     const exists = await CustomerService.exists(data.regNo)
        //     if (exists) {
        //         return I18n.t('customer_already_exists')
        //     }
        // }
        // if (data.vatNo === '') delete data.vatNo
    }

    const getCustomerPrice = articleId => {
        if (!customerPriceMap) return undefined;
        return customerPriceMap.get(articleId);
    }

    return <SimpleEditForm
        disabled={disabled}
        writeRole={Role.HYRLET}
        headline={'Hyrlet - Order ' + number}
        defaultValues={{
            id: 0,
            customer: customerList.length === 1 ? customerList[0].id : undefined,
            type: 'RENT',
            deliveryDate: todayDate(),
            status: HyrletOrderStatuses.WAITING_FOR_CONFIRMATION,
            note: '',
            rowList: []
        }}
        preLoadFunction={preLoadFunction}
        loadFunction={() => HyrletService.findOrderById(customerId, orderId)}
        postLoadFunction={postLoadFunction}
        preSaveFunction={preSaveFunction}
        saveFunction={HyrletService.update}
        initLeftFields={form => [
            <FormItemDropdown key='customer' name='customer' labelI18n='customer'
                              options={customerList}
                              optionLabel='name'
                              onChange={e => {
                                  const _type = form.getValues('type')
                                  if (_type) {
                                      loadStockQuantities(e.target.value, 0, _type, formatTimeUtc(),)
                                  }
                              }}
                              required
                              showSelect={false}
                              {...form} />,
            <FormItemDropdown key='type' name='type' labelI18n='type'
                              options={[{id: 'RENT', name: 'Hyra'}, {id: 'RETURN', name: 'Återlämning'},]}
                              onChange={e => {
                                  const _customer = form.getValues('customer')
                                  if (_customer) {
                                      loadStockQuantities(_customer, 0, e.target.value, formatTimeUtc())
                                  }
                              }}
                              required {...form} />,]}
        initCenterFields={form => [<FormItemDate key='deliveryDate' name='deliveryDate' label='Leveransdatum'
                                                 minDate={todayDate()} required {...form} />]}
        initRightFields={form => [<FormItemDropdown key='status' name='status' labelI18n='status'
                                                    options={HyrletOrderStatuses.LIST}
                                                    optionLabel='label'
                                                    optionValue='value'
                                                    required
                                                    {...form}
                                                    disabled={true}
        />, <FormItemTextArea key='note' name='note' labelI18n='note' maxLength={255} {...form}/>,]}
        initTabs={form => [
            <TabPanel key='rowList' header={i18n('rows')}>
                <Controller name="rowList"
                            control={form.control}
                            render={({field: {ref, value, onChange}}) => <HyrletOrderrowGrid
                                inputRef={ref} value={value} onChange={onChange} disabled={disabled}
                                articleList={articleList}
                                getCustomerPrice={getCustomerPrice}
                                stockQuantityMap={stockQuantityMap}
                                articlegroupList={articlegroupList}
                            />}/>
            </TabPanel>
        ]}
    />
}

export default HyrletOrderEditForm;
