import React, {useContext, useState} from 'react';
import {useForm} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../AppContext";
import useMountEffect from "../../hooks/useMountEffect";
import usePreload from "../../hooks/usePreload";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import RouterUtils from "../../utils/RouterUtils";
import {CancelButton, RemoveButton, SaveButton} from "../Buttons/Button";
import {errorAlert} from "../Overlay/Alert";
import {confirmYes} from "../Overlay/Confirm";
import {hideLoading, showLoading} from "../Overlay/Loading";
import Spinner from "../Spinner/Spinner";
import TabView from "../TabView/TabView";

const SimpleEditForm = props => {
    const context = useContext(AppContext)
    const roles = useRoles()
    const preload = usePreload()

    const {
        disabled,
        writeRole,
        companyId = props.legalCompany ? (context.currentCompany.parentId || context.currentCompany.id) : context.currentCompany.id,
        headline,
        headlineI18n,
        defaultValues,
        onFormCreated,
        preLoadFunction,
        loadFunction,
        postLoadFunction,
        preSaveFunction,
        postSaveFunction, //REMOVE!!
        saveFunction,
        removeFunction,
        initRightButtons,
        initLeftFields,
        initCenterFields,
        initRightFields,
        initTabs,
        preloadType,
        style
    } = props
    const [loading, setLoading] = useState(true)
    const _disabled = disabled || !roles.hasRole(writeRole);

    const location = useLocation()
    const navigate = useNavigate()

    const form = {...useForm({defaultValues: defaultValues}), disabled: _disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, getValues} = form

    const onSubmit = async () => {
        showLoading()
        const data = getValues();
        data.company = companyId
        if (preSaveFunction) {
            const errorMsg = await preSaveFunction(data)
            if (errorMsg?.length > 0) {
                hideLoading()
                errorAlert(errorMsg)
                return
            }
        }
        return saveFunction(data).then(async savedModel => {
            if (savedModel) {
                if (preloadType) await preload.load(preloadType)
                if (postSaveFunction) {
                    postSaveFunction(savedModel)
                } else {
                    RouterUtils.goBack(navigate, savedModel)
                }
            }
            hideLoading()
        })
    };

    const onRemove = () => {
        confirmYes(() => {
            showLoading()
            removeFunction(getValues()).then(() => {
                RouterUtils.goBack(navigate)
                hideLoading()
            })
        })
    }

    useMountEffect(async () => {
        const id = RouterUtils.getId(location)

        if (onFormCreated) await onFormCreated(form)
        if (preLoadFunction) await preLoadFunction(id)

        // console.log("IDDD", id, typeof id)
        if (id > 0 || typeof id === 'string') {
            showLoading()
            const _model = await loadFunction(id)
            postLoadFunction && postLoadFunction(_model)
            reset(_model);
            hideLoading()
        } else {
            reset(defaultValues);
        }
        setLoading(false)
    }, [props, reset])

    if (loading) return <Spinner/>
    return (
        <div className="EditForm" style={style}>
            <div className="headline">
                <h1>{headlineI18n ? i18n(headlineI18n) : headline + ' '}</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="button-row">
                    <div className='left-buttons'>
                        {!_disabled && <SaveButton onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>}
                        {
                            !_disabled && removeFunction && <RemoveButton onClick={onRemove}/>
                        }
                        <CancelButton onClick={e => RouterUtils.goBack(navigate)}/>
                    </div>
                    <div className='divider'></div>
                    <div className='right-buttons'>{initRightButtons && initRightButtons(form)}</div>
                </div>

                <div className='columnContainer'>
                    <div className='column'>{initLeftFields && initLeftFields(form)}</div>
                    <div className='column'>{initCenterFields && initCenterFields(form)}</div>
                    <div className='column'>{initRightFields && initRightFields(form)}</div>
                </div>

                {initTabs &&
                    <TabView>
                        {initTabs(form)}
                    </TabView>
                }
            </form>
        </div>
    );
}

export default SimpleEditForm;
