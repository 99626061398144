const DRAFT = 'DRAFT';
const SENT = 'SENT';
const CANCELLED = 'CANCELLED';

const LIST = [{
    value: DRAFT,
    label: 'Utkast',
    labelMultiple: 'Utkast'
}, {
    value: SENT,
    label: "Skickad",
    labelMultiple: 'Skickade'
}, {
    value: CANCELLED,
    label: "Makulerad",
    labelMultiple: 'Makulerade'
}]

const NewsletterStatus = {
    DRAFT,
    SENT,
    CANCELLED,

    LIST,

    getName: id => {
        const res = LIST.find(os => os.value === id);
        return res ? res.label : '';
    },
    getLabel: id => {
        const res = LIST.find(os => os.value === id);
        return res ? res.label : '';
    },

    getLabelMultiple: id => {
        const res = LIST.find(os => os.value === id);
        return res ? res.labelMultiple : '';
    }
}
export default NewsletterStatus;