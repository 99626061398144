import {useState} from "react";
import ArrayUtil from "../../utils/ArrayUtil";
import {STORAGE_PREFIX} from "../../utils/LocalStorageUtils";

export const useFilter = (NAME, values) => {
    const [state, setState] = useState({})

    const getFilter = (gridName, name, defaultValue) => {
        let key = gridName + '-' + name;
        let result = defaultValue;
        if (state[key] !== undefined) {
            result = state[key];
        } else {
            let sessionFilter = JSON.parse(sessionStorage.getItem(STORAGE_PREFIX + 'filter'));
            if (sessionFilter && sessionFilter[key] !== undefined) {
                result = sessionFilter[key];
            }
        }
        return result;
    }

    const setFilter = (gridName, name, value) => {
        let key = gridName + '-' + name;
        console.log("Setting filter:", key, 'to', value);
        let sessionFilter = sessionStorage.getItem(STORAGE_PREFIX + 'filter');
        if (!sessionFilter) {
            sessionFilter = {}
        } else {
            sessionFilter = JSON.parse(sessionFilter);
        }
        sessionFilter[key] = value;
        sessionStorage.setItem(STORAGE_PREFIX + 'filter', JSON.stringify(sessionFilter));

        setState(Object.assign({}, state, {[key]: value}))
    }

    const removeFilter = (gridName, name) => {
        let key = gridName + '-' + name
        console.log("Removing filter:", key)
        let sessionFilter = sessionStorage.getItem(STORAGE_PREFIX + 'filter')
        if (sessionFilter) {
            sessionFilter = JSON.parse(sessionFilter)
        }
        delete sessionFilter[key]
        sessionStorage.setItem(STORAGE_PREFIX + 'filter', JSON.stringify(sessionFilter))

        setState(Object.assign({}, state, {[key]: undefined}))
    }

    const result = {}

    values.forEach(value => {
        result[value.name] = getFilter(NAME, value.name, value.defaultValue)

        if (result[value.name] === 'undefined') {
            result[value.name] = undefined
        }

        // If we got the list of possible values
        if (result[value.name] && value.values) {
            if (ArrayUtil.findById(value.values, result[value.name]) === undefined) {
                result[value.name] = undefined
            }
        }
    })

    result.update = (name, value) => {
        if (value === undefined) {
            setFilter(NAME, name, 'undefined')
        } else {
            setFilter(NAME, name, value)
        }
    }

    result.get = (name, defaultValue) => {
        return getFilter(NAME, name, defaultValue)
    }

    result.remove = (name) => {
        return removeFilter(NAME, name)
    }

    return result

}
