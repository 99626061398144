import React from "react";
import {PdfViewerModal} from "../../../components/Appendix/PdfViewer";
import {PdfButton} from "../../../components/Buttons/Button";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import {i18n} from "../../../I18n/I18n";

export const DownloadInvoiceButton = props => {
    const handleSubmit = props.handleSubmit
    const save = props.save

    const downloadInvoicePdf = data => {
        showLoading();
        save(data).then(_model => {
            if (_model) {
                // InvoiceService.downloadInvoicePdf(_model.id).then(() => hideLoading())
                renderOverlay(<PdfViewerModal label={i18n('invoice') + ' ' + (_model.number || '')}
                                              url={'/api/invoices/' + _model.id + '/pdf'}/>)
                hideLoading()
            } else {
                hideLoading()
            }
        })
    }

    return <PdfButton
        labelI18n='preview'
        onClick={handleSubmit(downloadInvoicePdf)}
    />
}
