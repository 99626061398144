import {faPen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import {Controller} from "react-hook-form";
import {i18n} from "../../I18n/I18n";
import ValidateUtils from "../../utils/ValidateUtils";
import XlnzDropdown from "../XlnzDropdown/XlnzDropdown";
import ErrorText from "./ErrorText";
import Label from "./Label";

const FormItemDropdown = props => {
    const {
        formState: {errors},
        control,
        disabled,
        forceDisabled,
        name,
        label,
        labelI18n,
        options,
        optionLabel,
        optionValue = 'id',
        showSelect,
        showSelectValue = '',
        onChange,
        required,
        width,
        filter = true,
        filterBy,
        itemTemplate,
        style
    } = props


    const _optionLabel = optionLabel ? optionLabel : 'name'

    const none = {id: showSelectValue}
    none[_optionLabel] = i18n('select') + '...'

    const _options = showSelect ? [none, ...options] : options

    const validation = {
        onChange: e => {
            if (onChange) onChange(e)
        }
    }

    if (required) {
        validation.required = ValidateUtils.required()
    }

    const _style = style || {}
    if (width) {
        _style.width = width + 'px'
    } else {
        _style.width = '100%'
    }

    return <div className="formItem">
        <Label htmlFor={name}>
            {labelI18n ? i18n(labelI18n) : label}
            {
                props.onEdit &&
                <div className='edit'>
                    <FontAwesomeIcon icon={faPen} className='faIcon' onClick={props.onEdit}/>
                </div>
            }
        </Label>

        <Controller name={name}
                    control={control}
                    rules={validation}
                    render={({field: {ref, value, onChange}}) => {
                        // console.log("value", value)
                        return <XlnzDropdown
                            value={value}
                            options={_options}
                            optionLabel={_optionLabel}
                            optionValue={optionValue}
                            onChange={e => onChange(e.value)}
                            disabled={disabled || forceDisabled}
                            // showClear={showSelect !== undefined} //Fungerar inte att cleara billingType i dagsplanneringen.
                            placeholder={i18n('select') + '...'}
                            filter={filter && options.length > 10}
                            filterBy={filterBy ? filterBy : _optionLabel}
                            autoFocus={true}
                            style={_style}
                            optionDisabled={() => ('')}
                            itemTemplate={itemTemplate}
                        />;
                    }
                    }/>
        <ErrorText errors={errors} name={name}/>
    </div>
}
export default FormItemDropdown;