import NetUtils from "../../utils/NetUtils";


function addFilterBy(list) {
    // console.log("ProjectService-addFilterBy: THIS SHOULD NOT BE USED")
    list.forEach(item => {
        item.filterBy = [item.number, item.name, item.projectLeaderName, item.markup].join(';')
    })
}

const ProjectService = {
    findAll: async params => {
        const result = await NetUtils.doGet('/api/projects', params)
        addFilterBy(result)
        return result
    },
    listInvoiceOverview: params => NetUtils.doGet('/api/projects/invoice-overview', params),
    listResultOverview: params => NetUtils.doGet('/api/projects/result-overview', params),
    ongoingProjectsReport: date => NetUtils.doGet('/api/projects/ongoing-projects-report', {date: date}),

    findById: id => NetUtils.doGet('/api/projects/' + id),
    update: (model, files) => NetUtils.doPostWithFiles('/api/projects', model, files),
    updateValue: (id, name, value) => NetUtils.doPost('/api/projects/update-value', {
        id: id, name: name, value: value
    }),

    downloadAppendix: (projectId, appendixId) => NetUtils.downloadFile('/api/projects/' + projectId + "/appendix/" + appendixId),

    downloadKma: async projectId => NetUtils.downloadFile('/api/projects/' + projectId + '/kma-pdf'),
    downloadScaffoldingPlan: async projectId => NetUtils.downloadFile('/api/projects/' + projectId + '/scaffolding-plan-pdf'),

    sendDocumentsToCustomer: async (projectId, toEmail, documentList) =>
        NetUtils.doPost('/api/projects/' + projectId + '/send-documents-to-customer', {
            toEmail: toEmail,
            documentList: documentList
        }),

    generateRentForAllCompanies: () => NetUtils.doGet('/api/projects/generate-rent'),
    generateRent: id => NetUtils.doGet('/api/projects/' + id + '/generate-rent'),
    findRentsByTender: tenderId => NetUtils.doGet('/api/projectrents', {tender: tenderId}),
    findRentsByProject: projectId => NetUtils.doGet('/api/projectrents', {project: projectId}),
    findSupplierrowsByProject: projectId => NetUtils.doGet('/api/supplierinvoicerows', {project: projectId}),

    downloadWorkorderPdf: async projectId => NetUtils.downloadFile('/api/projects/' + projectId + '/workorder-pdf'),

    endProject: async projectId => NetUtils.doGet('/api/projects/' + projectId + '/end-project'),
    sendRecoInvite: async projectId => NetUtils.doGet('/api/projects/' + projectId + '/send-reco-invite'),

    sendConstructionLogByEmail: async (projectId, model) => NetUtils.doPost('/api/projects/' + projectId + '/send-constructionlog-by-email', model),

}
export default ProjectService;