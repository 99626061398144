import React from 'react';
import Button from "../../../components/Buttons/Button";
import Grid from "../../../components/Grid/Grid";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import useRoles from "../../../hooks/useRoles";
import CustomercontactEditFormDialog from "./CustomercontactEditFormDialog";

const CustomercontactGrid = props => {
    const {
        value
    } = props

    const roles = useRoles()

    const fireChange = (list) => {
        props.onChange(list);
    }

    const columns = [
        {field: 'name', headerI18n: 'name',},
        {field: 'ssn', headerI18n: 'ssn', width: 120},
        {field: 'phone0', headerI18n: 'mobile', width: 120},
        {field: 'email', headerI18n: 'email',}
    ]

    function showEditDialog(modelToEdit) {
        renderOverlay(<CustomercontactEditFormDialog visible={modelToEdit !== undefined}
                                                     model={modelToEdit}
                                                     onSave={savedModel => {
                                                         // console.log("modelToEditmodelToEdit", modelToEdit)
                                                         if (modelToEdit.newPrice) {
                                                             delete modelToEdit.newPrice;
                                                             let newList = [...props.value];
                                                             newList.push(savedModel);
                                                             fireChange(newList);
                                                         } else {
                                                             fireChange([...props.value]);
                                                         }
                                                     }}
                                                     onRemove={removeModel => {
                                                         let newList = [...props.value]
                                                         newList.splice(
                                                             props.value.findIndex(d => d.id === removeModel.id),
                                                             1
                                                         );
                                                         fireChange(newList);
                                                     }}
        />)
    }

    return <div style={{width: '800px'}}>
        <Grid
            xlnzGrid={true}
            hideFooter={true}
            scrollable={false}
            columns={columns}
            value={value}
            onRowSelect={e => showEditDialog(e.data)}
        />

        {
            roles.hasRole(Role.CUSTOMER_WRITE) &&
            <div style={{paddingTop: '10px'}}>
                <Button faicon={Icon.ADD} onClick={e => {
                    e.preventDefault()
                    showEditDialog({
                        name: '',
                        ssn: '',
                        email: '',
                        newPrice: true
                    })
                }}/>
            </div>
        }

    </div>
}

export default CustomercontactGrid;
