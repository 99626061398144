import React from "react";
import CheckboxInput from "../../../../components/EditForm/CheckboxInput";
import {FORMROW_KEY_PREFIX} from "../FormanswerEditForm";

export default function FormtypeCheck(props) {
    const model = props.model
    const name = FORMROW_KEY_PREFIX + model.id

    return <div style={styles.container}>
        <CheckboxInput name={name} {...props} />
        <label htmlFor={name} style={styles.name}>
            {model.name}
            {/*{model.id}*/}
        </label>
    </div>
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px'
    },
    name: {
        paddingLeft: '10px',
        fontSize: '12px'
    }
}

/*
Ställningsnummer
Lastklass enl. SS-EN 12811-1:2004
Maxlast kg/m 2
Antal inplankade bomlag (st)
Antal samtidigt belastade bomlag (st)

1. Uppförd enl. typkontrollintyg eller monteringsinstruktioner för systemställning
2. Uppförd enl. typfall för röroch kopplingsställningar
3. Uppförd enl. typkontrollintyg eller typfall med kompletterande förenklade beräkningar
4. Uppförd enl. särskilda beräkningar i det enskilda fallet
Egenkontroll utförd och godkänd av
Avsynad och godkänd av






*/