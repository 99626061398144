import React, {useMemo} from 'react';
import useEmployees from "../../hooks/useEmployees";
import usePreload from "../../hooks/usePreload";
import {findById} from "../../utils/ArrayUtil";
import SelectEditor from "./SelectEditor";

const EmployeeSelectEditor = props => {
    const {
        event
    } = props
    if (!event) throw new Error(props)

    const employees = useEmployees()
    const preload = usePreload()

    const list = useMemo(() => {
        const result = employees.activeUsable

        const subcontractorList = preload.getSupplierList().filter(item => item.subcontractor)

        result.forEach(item => {
            const subcontractorName = findById(subcontractorList, item.subcontractor)?.name
            item.subcontractorName = subcontractorName

            item.filterBy = [item.number, item.name, item.subcontractorName].join(';')
        })

        return result
    }, [preload, employees])

    function getSubInfo(item) {
        let result = ''
        if (item.subcontractorName) {
            result += item.subcontractorName
        }
        return result
    }

    return <SelectEditor
        event={event}
        options={list}
        optionValue='id'
        filterBy={'filterBy'}
        itemTemplate={item => <div>
            <div>{item.name}</div>
            <div style={{opacity: '0.7'}}>{getSubInfo(item)}</div>
        </div>}
        onChange={e2 => {
            event.editorCallback(e2?.value)
        }}/>
}

export default EmployeeSelectEditor;

