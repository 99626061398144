import React, {useContext, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import AppContext from "../../../AppContext";
import {CancelButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemAppendixGrid from "../../../components/EditForm/FormItemAppendixGrid";
import FormItemDate from "../../../components/EditForm/FormItemDate";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemNumber from "../../../components/EditForm/FormItemNumber";
import FormItemRowGrid from "../../../components/EditForm/FormItemRowGrid";
import FormItemSupplier from "../../../components/EditForm/FormItemSupplier";
import FormItemText from "../../../components/EditForm/FormItemText";
import FormItemTextArea from "../../../components/EditForm/FormItemTextArea";
import {confirm} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Spinner from "../../../components/Spinner/Spinner";
import TabPanel from "../../../components/TabView/TabPanel";
import TabView from "../../../components/TabView/TabView";
import Currency from "../../../enums/Currency";
import Role from "../../../enums/Role";
import useMountEffect from "../../../hooks/useMountEffect";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import ArrayUtil, {findById, findByMaxId} from "../../../utils/ArrayUtil";
import {addDays, formatDate, todayDate} from "../../../utils/DateUtils";
import {isNumeric, round} from "../../../utils/NumberUtils";
import RouterUtils from "../../../utils/RouterUtils";
import ValidateUtils from "../../../utils/ValidateUtils";
import ProjectService from "../../Projects/ProjectService";
import ProjectStatus from "../../Projects/ProjectStatus";
import SupplierinvoiceService from "../SupplierinvoiceService";
import SupplierinvoiceStatus from "../SupplierinvoiceStatus";
import SupplierinvoiceVatType from "../SupplierinvoiceVatType";
import {ExportSupplierinvoiceToFortnoxButton} from "./ExportSupplierinvoiceToFortnoxButton";
import SupplierinvoiceAttestation from "./SupplierinvoiceAttestation";
import SupplierinvoiceattestGrid from "./SupplierinvoiceattestGrid";
import SupplierinvoiceImageSidebar from "./SupplierinvoiceImageSidebar";
import SupplierinvoiceRowGridFooter from "./SupplierinvoiceRowGridFooter";

const SupplierinvoiceEditForm = props => {
    const context = useContext(AppContext)
    const roles = useRoles()
    const preload = usePreload()

    const [model, setModel] = useState();
    const [common, setCommon] = useState();

    const disabled = !roles.hasRole(Role.SUPPLIERINVOICE_WRITE);

    const location = useLocation()
    const navigate = useNavigate()
    const {id} = useParams();

    const form = {
        ...useForm({
            defaultValues: {
                company: context.currentCompany.id,
                cuser: context.user.id,
                number: '',
                ocr: '',
                yourReference: '',
                markup: '',
                invoiceDate: todayDate(),
                dueDate: todayDate(),
                priceInclVat: 0,
                vatPrice: 0,
                currency: context.currentCompany.currency,
                exchangeRate: 1,
                vatType: SupplierinvoiceVatType.NORMAL.id,
                status: SupplierinvoiceStatus.DRAFT.id,
                note: '',
                rowList: [],
                attestList: [],
                appendixList: []
            }
        }), disabled
    }
    const {handleSubmit, reset, formState: {isSubmitting}, setValue, getValues, watch, control} = form;

    const [
        watchSupplier,
        watchBookkeepingId,
        watchInvoiceDate, watchPriceInclVat, watchCurrency, watchVatPrice, watchVatType, watchRowList,
        watcHAttestList,
        watchAppendixList
    ] =
        watch(['supplier', 'bookkeepingId', 'invoiceDate', 'priceInclVat', 'currency', 'vatPrice', 'vatType', 'rowList',
            'attestList',
            'appendixList'
        ]);

    function getDifference() {
        let difference = watchPriceInclVat - watchVatPrice
        watchRowList.forEach(_row => {
            difference -= _row.price
        })
        return round(difference, 2)
    }

    const save = async (data, files, filesName = 'invoiceImage') => {

        if (!disabled) {
            if (!isNumeric(data.priceInclVat)) data.priceInclVat = 0
            if (!isNumeric(data.vatPrice)) data.vatPrice = 0

            console.log("SAVE MODEL", data, files)
            return SupplierinvoiceService.update(data, files, filesName).then(_model => {
                if (_model) {
                    navigate('/supplierinvoices/' + _model.id, {replace: true});
                    setModel(_model);
                    reset(_model);

                    return _model;
                }
            })
        } else {
            return model;
        }
    }

    async function validateSupplierAndNumber({id, supplier, number}, callback) {
        if (supplier > 0 && number.length > 0) {
            const duplicates = (await SupplierinvoiceService.findSupplierAndNumber(Number(supplier), number))
                .filter(item => item.id !== id)

            if (duplicates.length > 0) {
                let duplicatesInfo = '.\n\n'
                if (duplicates.length <= 5) {
                    duplicatesInfo = ':\n\n' + duplicates.map(item => {
                        let result = '- ' + item.invoiceDate
                        if (item.bookkeepingId) {
                            result += ' (' + i18n('bookkeeping_id') + ' ' + item.bookkeepingId + ')'
                        }
                        return result
                    }).join('\n') + '\n\n'
                }

                confirm({
                    header: i18n('question'),
                    message: 'Fakturanumret du angivit finns redan registrerat på andra leverantörsfakturor' +
                        duplicatesInfo +
                        'Vill du använda fakturanumret ändå?',
                    options: [
                        {
                            label: i18n('yes'),
                            onClick: () => callback()
                        }
                    ]
                })
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const onSubmit = () => {
        const data = getValues()
        validateSupplierAndNumber(data, () => {
            showLoading()
            return save(data).then(_model => {
                hideLoading();
                if (_model) {
                    RouterUtils.goBack(navigate)
                }
            })
        })
    }

    const onReset = _model => {
        setModel(_model);
        reset(_model)
    }

    useMountEffect(async () => {
        showLoading();
        const _supplierList = preload.get(PreloadType.SUPPLIER_LIST)

        const loadCommons = async (_model) => {
            const [
                _projectList
            ] = await Promise.all([
                ProjectService.findAll({
                    status: ProjectStatus.ONGOING.id,
                    startDate: formatDate(_model?.cTime),
                    endDate: formatDate(_model?.cTime)
                })
            ])

            if (_model) {
                for (const item of _model.rowList) {
                    if (item.project > 0 && !findById(_projectList, item.project)) {
                        _projectList.push(await ProjectService.findById(item.project))
                    }
                }
            }

            setCommon({
                accountList: preload.get(PreloadType.ACCOUNT_LIST),
                costcenterList: preload.get(PreloadType.COSTCENTER_LIST),
                supplierList: _supplierList.filter(c => c.active || c.id === _model?.supplier),
                projectList: _projectList,
            })
        }

        if (location.state) {
            const _model = Object.assign({}, location.state)
            if (!(Number(_model.supplier) > 0)) _model.supplier = findByMaxId(_supplierList).id
            await loadCommons(_model);
            setModel(_model)
            reset(_model)
        } else {
            if (id > 0) {
                const _model = await SupplierinvoiceService.findById(id)
                setModel(_model);
                reset(_model);
                loadCommons(_model);
            } else {
                loadCommons();
            }
        }
        hideLoading();
    })

    if (!common) return <Spinner/>

    function onPriceChange(e) {
        // Need som work here..
        // const [_priceInclVatStr, _vatPriceStr, rowList] = getValues(['priceInclVat', 'vatPrice', 'rowList'])
        // const _priceInclVat = Number(_priceInclVatStr)
        // const _vatPrice = Number(_vatPriceStr)
        // const _priceExclVat = _priceInclVat - _vatPrice
        //
        //
        // if (rowList.length <= 1) {
        //     const _rowList = [...rowList]
        //     rowList.push({
        //         id: 0,
        //         name: 'HEJ!',
        //         quantity: 0,
        //         aprice: 0,
        //         discount: 0,
        //         price: _priceExclVat,
        //         vat: Vat.VAT25.id,
        //         percentageMarkup: 0,
        //         toInvoice: 0
        //     })
        //     setValue('rowList', _rowList)
        // } else if (rowList.length === 1) {
        //     const _rowList = [...rowList]
        //     _rowList[0].price = _priceExclVat
        //     setValue('rowList', _rowList)
        // }
        //
        // console.log("_priceExclVat", _priceExclVat, rowList)
    }

    return <div className='form-container'>
        <div className="EditForm">
            <div className="headline">
                <h1>{i18n('supplier_invoice')} {watchBookkeepingId || ''}</h1>
            </div>
            {/*<form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>*/}
            <form onSubmit={handleSubmit(onSubmit)}>
                <SupplierinvoiceAttestation save={save} form={form}/>

                <div className="button-row">
                    <div className='left-buttons'>
                        {!disabled && <SaveButton onClick={handleSubmit(onSubmit)} disabled={isSubmitting}/>}
                        <CancelButton onClick={e => RouterUtils.goBack(navigate)}/>
                    </div>
                    <div className='divider'></div>
                    <div className='right-buttons'>
                        <ExportSupplierinvoiceToFortnoxButton handleSubmit={handleSubmit}
                                                              validateSupplierAndNumber={validateSupplierAndNumber}
                                                              difference={getDifference()}
                                                              save={save} reset={onReset}/>
                    </div>
                </div>

                <div className='columnContainer'>
                    <div className='column'>
                        <FormItemSupplier name='supplier' labelI18n='supplier'
                                          onChange={e => {
                                              const supplier = ArrayUtil.findById(common.supplierList, e.target.value)
                                              setValue('supplier', supplier.id)
                                              setValue('vatType', supplier.vatType)
                                              setValue('dueDate', addDays(watchInvoiceDate, supplier.paymentTerms))
                                          }}
                                          onEdit={() => {
                                              const data = getValues()
                                              navigate(location.pathname, {replace: true, state: data});
                                              navigate('/suppliers/' + (data.supplier ? data.supplier : 0));
                                          }}
                                          {...form}
                        />
                        <FormItemText name='number' labelI18n='invoice_number' maxLength={100} {...form}/>
                        <FormItemNumber name='bookkeepingId' labelI18n='bookkeeping_id' {...form}/>
                        <FormItemDate name='invoiceDate' labelI18n='invoice_date' onChange={date => {
                            const supplier = ArrayUtil.findById(common.supplierList, watchSupplier)
                            if (supplier) setValue('dueDate', addDays(date, supplier.paymentTerms))
                        }} {...form} />

                        <FormItemDate name='dueDate' labelI18n='due_date' {...form} />
                    </div>
                    <div className='column'>
                        <FormItemNumber name='priceInclVat' labelI18n='price_incl_vat'
                                        onKeyUp={onPriceChange} {...form} />
                        <FormItemNumber name='vatPrice' labelI18n='vat' onKeyUp={onPriceChange} {...form} />
                        <FormItemDropdown name='vatType' labelI18n='vat_type'
                                          options={SupplierinvoiceVatType.values()}
                                          required
                                          width={200}
                                          {...form} />
                        <FormItemDropdown name='currency' labelI18n='currency'
                                          options={Currency.values()} optionLabel='id'
                                          width={80} required {...form} />
                        <FormItemNumber name='exchangeRate' labelI18n='exchange_rate' required {...form} />
                    </div>


                    <div className='column'>
                        <FormItemText name='ocr' label='OCR' maxLength={100}
                                      validation={{validate: ValidateUtils.ocr}} {...form}/>
                        <FormItemText name='yourReference' labelI18n='your_reference' maxLength={100} {...form}/>
                        <FormItemText name='markup' labelI18n='reference_no' maxLength={250} {...form}/>
                        <FormItemTextArea name='note' labelI18n='note' maxLength={1000} {...form}/>
                        <FormItemDropdown name='status' labelI18n='status' options={SupplierinvoiceStatus.values()}
                                          required width={160} {...form} />
                    </div>
                </div>

                <TabView>
                    <TabPanel key='rows' header={i18n('rows') + ' (' + (watchRowList.length) + ')'}>
                        <FormItemRowGrid name='rowList'
                                         accountList={common.accountList}
                                         costcenterList={common.costcenterList}
                                         projectList={common.projectList}
                                         vatType={watchVatType}
                                         numOfFreeRows={1}
                                         footer={<SupplierinvoiceRowGridFooter
                                             id={id}
                                             supplier={watchSupplier}
                                             priceInclVat={watchPriceInclVat}
                                             currency={watchCurrency}
                                             vatPrice={watchVatPrice}
                                             difference={getDifference()}
                                             onRowListChange={_rowList => setValue('rowList', _rowList)}
                                         />}
                                         isColumnHidden={column => {
                                             const hiddenColumns = ['unit', 'quantity', 'aprice', 'discount'];
                                             return hiddenColumns.includes(column.field)
                                         }}
                                         {...form}/>
                    </TabPanel>
                    <TabPanel key='attestation' header={i18n('attestation') + ' (' + (watcHAttestList.length) + ')'}>
                        <div className="formItem">
                            <Controller name='attestList'
                                        control={control}
                                        render={({field: {ref, value, onChange}}) =>
                                            <SupplierinvoiceattestGrid inputRef={ref} value={value}
                                                                       onChange={onChange}/>
                                        }/>
                        </div>
                    </TabPanel>
                    <TabPanel key='appendices' header={i18n('appendices') + ' (' + (watchAppendixList.length) + ')'}>
                        <FormItemAppendixGrid name='appendixList' save={async (data, files) => {
                            return save(data, files, 'file')
                        }} {...form}/>
                    </TabPanel>
                </TabView>
            </form>
        </div>
        <SupplierinvoiceImageSidebar save={save} form={form}/>
    </div>
}

export default SupplierinvoiceEditForm;
