import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import SupplierinvoiceEditForm from "./edit/SupplierinvoiceEditForm";
import SupplierinvoiceGrid from "./SupplierinvoiceGrid";

const InvoicesIndex = {
    routeProps: {
        path: '/supplierinvoices',
        exact: true,
        element: <SupplierinvoiceGrid/>,
    },
    nameKey: 'supplier_invoices',
    icon: Icon.SUPPLIER_INVOICE,
    subModules: [{
        routeProps: {
            path: '/supplierinvoices/:id',
            exact: true,
            element: <SupplierinvoiceEditForm/>,
        }
    }],
    roles: [Role.SUPPLIERINVOICE_READ]
};
export default InvoicesIndex;