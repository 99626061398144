import ArrayUtil from "../utils/ArrayUtil";

export default class DocumentType {
    static get KMA() {
        return Object.freeze({id: 'KMA', name: 'KMA-plan'});
    }

    // static get SCAFFOLDING_PLAN() {
    //     return Object.freeze({id: 'SCAFFOLDING_PLAN', name: I18n.t('scaffolding_plan')});
    // }

    static values() {
        const result = [this.KMA, this.SCAFFOLDING_PLAN];
        ArrayUtil.sortOnField(result, 'name')
        return result
    }

    static findById(id) {
        switch (id) {
            case this.KMA.id:
                return this.KMA;
            // case this.SCAFFOLDING_PLAN.id:
            //     return this.SCAFFOLDING_PLAN;
            default:
                throw new Error("Invalid document id: " + id)
        }
    }
}