import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import CustomerGrid from "./CustomerGrid";
import CustomerEditForm from "./edit/CustomerEditForm";

const CustomersIndex = {
    routeProps: {
        path: '/customers',
        exact: true,
        element: <CustomerGrid/>,
    },
    nameKey: 'customers',
    icon: Icon.CUSTOMER,
    subModules: [{
        routeProps: {
            path: '/customers/:id',
            exact: true,
            element: <CustomerEditForm/>
        }
    }],
    roles: [Role.CUSTOMER_READ]
};
export default CustomersIndex;