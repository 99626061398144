import React, {useCallback, useEffect} from "react";
import renderOverlay, {removeOverlay} from "./renderOverlay";

const ELEMENT_ID_LIST = []

const Tooltip = props => {

    const {
        value,
        style
    } = props

    const onContextmenu = useCallback(e => {
        removeAll()
    }, [])

    useEffect(() => {
        window.addEventListener("contextmenu", onContextmenu);
        return () => window.removeEventListener("contextmenu", onContextmenu);
    }, [onContextmenu]);

    function removeAll() {
        while (ELEMENT_ID_LIST.length) {
            const elementId = ELEMENT_ID_LIST.pop();
            removeOverlay(elementId)
        }
    }

    function onMouseOver(e) {

        const style = {
            position: 'fixed',
            transform: 'translateX(Min(' + e.clientX + 'px, calc(100vw - 100%))) translateY(Min(' + e.clientY + 'px, calc(100vh - 100%)))'
        }

        const elementId = renderOverlay(
            <div className='tooltip'
                 style={style}>
                {
                    typeof value === 'string' &&
                    value.split('\n').map((row, index) => <div key={index}>{row}</div>)
                }
                {
                    typeof value !== 'string' &&
                    value
                }
            </div>)

        ELEMENT_ID_LIST.push(elementId)
    }

    function onMouseLeave(e) {
        removeAll()
    }

    if (!value) return <>{props.children}</>
    return <div className='TooltipContent'
                onMouseEnter={onMouseOver}
                onMouseLeave={onMouseLeave}
                onClick={onMouseLeave}
                style={style}
    >
        {props.children}
    </div>
}
export default Tooltip
