import React from "react";
import Tooltip from "../../../../components/Overlay/Tooltip";
import {i18n} from "../../../../I18n/I18n";
import {getDayInMonth, getHolidayName} from "../../../../utils/DateUtils";
import './getDayHeader.scss'

const getDayHeader = (date, i, locked) => {
    const day = getDayInMonth(date);
    const holidayName = getHolidayName(date, true)

    const classNames = ['getDayHeader']
    if (locked) classNames.push('locked')

    return <div className={classNames.join(' ')}>
        <Tooltip value={
            (holidayName || locked) &&
            <div>
                {holidayName}
                {locked ? <div>{i18n('day_locked')}</div> : ''}
            </div>
        }>
            <span>{day}</span>
        </Tooltip>
    </div>
};
export default getDayHeader