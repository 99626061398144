import {useMemo} from "react";
import Companyprop from "../../enums/Companyprop";
import useCompanyProp from "../../hooks/useCompanyProp";
import usePreload from "../../hooks/usePreload";

export default function useHyrletLoginInfo() {
    const hyrletCustomerId = Number(useCompanyProp(Companyprop.HYRLET_CUSTOMER_ID))
    const preload = usePreload();
    const hyrletLoginInfo = useMemo(() => preload.gethyrletLoginInfo(), [preload])

    return useMemo(() => ({
        ...hyrletLoginInfo,
        isConnected: () => {
            return hyrletLoginInfo !== undefined
        },
        getCurrentCustomerList: () => {
            if (!hyrletLoginInfo) return []

            const _res = hyrletLoginInfo.customerList.filter(item =>
                item.id === hyrletCustomerId ||
                item.parent?.id === hyrletCustomerId
            )
            return _res
        }
    }), [hyrletCustomerId, hyrletLoginInfo])
}