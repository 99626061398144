import {faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import React, {useRef} from "react";
import Icon from "../../enums/Icon";
import {i18n} from "../../I18n/I18n";
import Button from "../Buttons/Button";
import XlnzMenu from "../XlnzMenu/XlnzMenu";


const ExtraMenu = props => {
    const menuRef = useRef()
    const items = []

    if (props.itemList) {
        props.itemList
            .filter(item => !item.hidden)
            .forEach(item => items.push(item));
    }

    if (props.onExportToExcel) {
        items.push({
            label: i18n('export_to_excel'),
            faicon: Icon.EXCEL,
            command: props.onExportToExcel
        })
    }

    return <div className='ExtraMenu'>
        <XlnzMenu model={items} ref={el => menuRef.current = el}/>
        <Button type="button" faicon={faEllipsisVertical}
                onClick={e => menuRef.current.toggle(e)}
                label={props.label}
                aria-controls="popup_menu" aria-haspopup={true}></Button>
    </div>
}

export default ExtraMenu;