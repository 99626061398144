import {faStar} from "@fortawesome/free-solid-svg-icons";
import Role from "../../enums/Role";
import Superadmin from './Superadmin.js';

const SuperadminIndex = {
    routeProps: {
        path: '/superadmin',
        exact: true,
        element: <Superadmin/>,
    },
    nameKey: 'superadmin',
    icon: faStar,
    roles: [Role.SUPERADMIN]
};
export default SuperadminIndex;