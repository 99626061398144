import React, {useEffect, useState} from 'react';
import MonthDropdown from "../../../components/Date/MonthDropdown";
import YearFilter from "../../../components/filters/YearFilter";
import {useFilter} from "../../../components/Grid/filter";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Icon from "../../../enums/Icon";
import {GridTemplates} from "../../../GridTemplates";
import {todayYear} from "../../../utils/DateUtils";
import NetUtils from "../../../utils/NetUtils";
import {i18n} from "../../../I18n/I18n";
import {matchSearch} from "../../../functions/match";

const Recoinvites = () => {
    const filter = useFilter('Recoinvites', [
        {name: 'year', defaultValue: todayYear()},
        {name: 'month', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);

    const filteredList = list.filter(item => matchSearch(filter, item))

    useEffect(() => {
        async function fetchData() {
            showLoading()
            const params = {
                year: filter.year,
                month: filter.month
            }

            const _list = await NetUtils.doGet('/api/reports/reco-invites', params)
            setList(_list);
            hideLoading()
        }

        fetchData()
    }, [filter.year, filter.month])

    const columns = [
        {field: 'ctime', headerI18n: 'created', body: GridTemplates.time, width: 160},
        {field: 'cuserName', headerI18n: 'user'},
        {field: 'firstName', headerI18n: 'first_name'},
        {field: 'lastName', headerI18n: 'last_name'},
        {field: 'email', headerI18n: 'email'},
        {field: 'projectString', headerI18n: 'project'}
    ];

    const leftFilters = [
        <div>
            <label className="label">{i18n('year')}</label>
            <YearFilter value={filter.year} onChange={e => filter.update('year', e.value)}/>
        </div>,
        <div>
            <label className="label">{i18n('month')}</label>
            <MonthDropdown value={filter.month} onChange={e => {
                filter.update('month', e.value)
            }}/>
        </div>
    ]

    return <Grid xlnzGrid={true}
                 id='Recoinvites'
                 forceDesktop={true}
                 labelI18n='reco_invites'
                 icon={Icon.REPORT}
                 leftFilters={leftFilters}
                 filter={filter}
                 updateFilter={filter.update}
                 columns={columns}
                 value={filteredList}
                 onRowSelect={() => {
                 }}
    />
}

export default Recoinvites;
