import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {CancelButton, MainButton} from "../../../../components/Buttons/Button";
import FormItemCheckbox from "../../../../components/EditForm/FormItemCheckbox";
import {useFilter} from "../../../../components/Grid/filter";
import {FooterAggregate} from "../../../../components/Grid/getFooterGroup";
import Grid from "../../../../components/Grid/Grid";
import NumberGridInput from "../../../../components/Grid/NumberGridInput";
import onRowChange from "../../../../components/Grid/onRowChange";
import XlnzDialog from "../../../../components/XlnzDialog/XlnzDialog";
import {GridTemplates} from "../../../../GridTemplates";
import {notContains} from "../../../../utils/ArrayUtil";
import {getDiscountFactor, round} from "../../../../utils/NumberUtils";


export default function FetchTenderoptionsDialog(props) {
    const filter = useFilter('FetchTenderoptionsDialog', [
        {name: 'showTitle', defaultValue: true}
    ])

    const {
        onSave,
        onHide
    } = props

    const [tenderoptionList, setTenderoptionList] = useState(props.tenderoptionList)
    const [selection, setSelection] = useState([])

    const defaultValues = {
        showTitle: filter.showTitle,
    }

    const form = {...useForm({defaultValues: defaultValues})}
    const {handleSubmit, formState: {isSubmitting}, getValues} = form

    const onSubmit = () => {
        const data = getValues();
        filter.update('showTitle', data.showTitle)
        onSave(tenderoptionList.filter(item => item.priceToInvoice !== 0), {
            showTitle: data.showTitle,
        })
        onHide()
    }

    const onQuantityToInvoice = list => {
        setTenderoptionList(list)
    }

    const columns = [
        {field: 'name', headerI18n: 'option'},
        {
            field: 'quantity', headerI18n: 'quantity', body: GridTemplates.numberTwoDecimalsIfNeeded, align: 'right',
            width: 100
        },
        {
            field: 'aprice', headerI18n: 'aprice', body: GridTemplates.currencyTwoDecimals, align: 'right', width: 120
        },
        {
            field: 'discount', headerI18n: 'discount', body: GridTemplates.percentTimes100, align: 'right', width: 50
        },
        {
            field: 'price', headerI18n: 'price', body: GridTemplates.currencyTwoDecimals, align: 'right',
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
            width: 120
        },
        {
            field: 'leftToInvoicePrice', headerI18n: 'left_to_invoice', body: GridTemplates.currency, align: 'right',
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
            width: 120
        },
        {
            field: 'quantityToInvoice', headerI18n: 'quantity_to_invoice', align: 'right',
            body: GridTemplates.numberTwoDecimals,
            editor: e => <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: e => {
                let newValue = round(e.newValue, 2)
                let oldValue = round(e.oldValue, 2)
                const row = e.rowData

                if (newValue > row.quantity) {
                    newValue = row.quantity
                }

                if (row.leftToInvoicePrice > 0 && newValue > row.leftToInvoicePrice) {
                    newValue = row.leftToInvoicePrice
                } else if (row.leftToInvoicePrice < 0 && newValue < row.leftToInvoicePrice) {
                    newValue = row.leftToInvoicePrice
                }
                onRowChange(e, newValue, onQuantityToInvoice)

                if (newValue !== oldValue) {
                    row.priceToInvoice = newValue * row.aprice * getDiscountFactor(row.discount)
                    onQuantityToInvoice([...tenderoptionList])
                }

                // Check the box
                if (newValue !== 0 && notContains(selection, row)) {
                    const _selection = [...selection]
                    _selection.push(row)
                    setSelection(_selection)
                }
            },
            width: 120
        },
        {
            field: 'priceToInvoice', headerI18n: 'to_invoice', align: 'right',
            body: GridTemplates.currencyTwoDecimals,
            editor: e => <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: e => {
                let newValue = e.newValue
                const row = e.rowData
                if (row.leftToInvoicePrice > 0 && newValue > row.leftToInvoicePrice) {
                    newValue = row.leftToInvoicePrice
                } else if (row.leftToInvoicePrice < 0 && newValue < row.leftToInvoicePrice) {
                    newValue = row.leftToInvoicePrice
                }
                onRowChange(e, newValue, onQuantityToInvoice)

                row.quantityToInvoice = calculateQuantityToInvoice(row)
                onQuantityToInvoice([...tenderoptionList])

                // Check the box
                if (newValue !== 0 && notContains(selection, row)) {
                    const _selection = [...selection]
                    _selection.push(row)
                    setSelection(_selection)
                }
            },
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
            width: 120
        },
        {field: 'selection', selectionMode: 'multiple', width: 40},
    ]

    function calculateQuantityToInvoice(row) {
        const quantity = round(row.priceToInvoice / row.aprice / getDiscountFactor(row.discount), 2)
        const comesOutEven = quantity * row.aprice * getDiscountFactor(row.discount) === row.priceToInvoice
        return comesOutEven ? quantity : 0
    }

    function onSelectionChange(e) {
        if (e.row) {
            e.row.priceToInvoice = e.checked ? e.row.leftToInvoicePrice : 0
            e.row.quantityToInvoice = e.checked ? calculateQuantityToInvoice(e.row) : 0
        } else {
            const _tenderoptionList = [...tenderoptionList]
            _tenderoptionList.forEach(row => {
                row.priceToInvoice = e.checked ? row.leftToInvoicePrice : 0
                row.quantityToInvoice = e.checked ? calculateQuantityToInvoice(row) : 0
            })
            setTenderoptionList(_tenderoptionList)
        }
        setSelection(e.value)
    }

    return <XlnzDialog headerI18n='options'
                       onHide={props.onHide}
                       leftButtons={() => [
                           <FormItemCheckbox key='showTitle' name='showTitle' labelI18n='show_title' {...form}/>
                       ]}
                       buttons={() => [
                           <MainButton key='do_invoice' labelI18n='do_invoice' onClick={e => handleSubmit(onSubmit)()}
                                       disabled={isSubmitting}/>,
                           <CancelButton key='cancel' onClick={e => props.onHide()}/>
                       ]}
    >
        <div style={{width: '1100px'}}>
            <Grid xlnzGrid={true}
                  forceDesktop={true}
                  columns={columns}
                  value={tenderoptionList}
                  heightOffset={370}
                  selection={selection}
                  onSelectionChange={onSelectionChange}
                  rowClassName={e => ({'bold': e.headline})}
            />
        </div>
    </XlnzDialog>
}