const onRowChange = (editorEvent, value, fireChange) => {
    const {
        field,
        rowIndex,
        rowData
    } = editorEvent

    rowData[field] = value

    // console.log("ediiioo", editorEvent, editorEvent.newValue, value)

    //Varför har vi denna rad?? Fungerr dåligt i ex. Projekt->Riskanalys
    // if (editorEvent.value === editorEvent.newValue) return

    const _rowList = [...editorEvent.props.value]
    _rowList[rowIndex][field] = value
    _rowList[rowIndex].dirty = true

    fireChange(_rowList, editorEvent, value)
}
export default onRowChange