import {groupBy} from "lodash";
import React, {useState} from "react";
import {FetchButton} from "../../../../components/Buttons/Button";
import renderOverlay from "../../../../components/Overlay/renderOverlay";
import Companyprop from "../../../../enums/Companyprop";
import Vat from "../../../../enums/Vat";
import VatType from "../../../../enums/VatType";
import calculatePrice from "../../../../functions/calculatePrice";
import useCompanyProp from "../../../../hooks/useCompanyProp";
import useDepsEffect from "../../../../hooks/useEffect";
import usePreload from "../../../../hooks/usePreload";
import {i18n} from "../../../../I18n/I18n";
import ArrayUtil, {sum} from "../../../../utils/ArrayUtil";
import {NumberUtils, round} from "../../../../utils/NumberUtils";
import {deepCopy} from "../../../../utils/ObjectUtils";
import ProjectService from "../../../Projects/ProjectService";
import {addEmptyRow, addTitle} from "../FetchHelper";
import FetchSupplierinvoicerowsDialog from "./FetchSupplierinvoicerowsDialog";

export const FetchSupplierinvoicerowsButton = props => {
    const {
        project,
        rowList,
        vatType,
        onAddRows,
        onLeftToInvoiceReport
    } = props

    const [uninvoicePrice, setUninvoicePrice] = useState(0)
    const [allSupplierinvoicerowList, setAllSupplierinvoicerowList] = useState(0)
    const [supplierinvoicerowList, setSupplierinvoicerowList] = useState(0)

    const preload = usePreload()
    const accountList = preload.getAccountList()

    // When project is changed
    useDepsEffect(() => {
        if (project && project.id > 0) {
            ProjectService.findSupplierrowsByProject(project.id).then(_supplierinvoicerowList => {
                _supplierinvoicerowList.forEach(item => item.price = item.toInvoice)
                _supplierinvoicerowList = filterSupplierinvoicerowsInOtherInvoices(_supplierinvoicerowList)
                setAllSupplierinvoicerowList(_supplierinvoicerowList)
            })
        }
    }, [project?.id])

    // When invoice rows are changed
    useDepsEffect(() => {
        if (allSupplierinvoicerowList && project && project.id > 0) {
            let _supplierinvoicerowList = deepCopy(allSupplierinvoicerowList)

            calculateLeftToInvoice(_supplierinvoicerowList)
            // decreaseQuantityOfAlreadyInvoicedRows(_supplierinvoicerowList)
            decreaseQuantityOfUnsavedRowsInThisInvoice(_supplierinvoicerowList)

            setSupplierinvoicerowList(_supplierinvoicerowList)
            const _leftToInvoicePrice = ArrayUtil.sum(_supplierinvoicerowList, 'leftToInvoicePrice')
            setUninvoicePrice(_leftToInvoicePrice)
            onLeftToInvoiceReport(_leftToInvoicePrice)

            // console.log("_supplierinvoicerowList", _supplierinvoicerowList)
        }
    }, [allSupplierinvoicerowList, rowList])

    const filterSupplierinvoicerowsInOtherInvoices = supplierinvoicerowList => {
        supplierinvoicerowList.forEach(tr => {
            tr.leftToInvoicePrice = tr.price
            const proposalIrtrList = tr.invoicerowsupplierinvoicerowList
            proposalIrtrList.forEach(proposalIrtr => {
                if (proposalIrtr.invoicerow > 0 && !isInThisInvoice(proposalIrtr)) {
                    tr.leftToInvoicePrice -= proposalIrtr.price
                }
            })
        })

        return supplierinvoicerowList
    }

    function isInThisInvoice(proposalIrtr) {
        return getInvoiceIrtrList().filter(invoiceIrtr => proposalIrtr.invoicerow === invoiceIrtr.invoicerow &&
            proposalIrtr.supplierinvoicerow === invoiceIrtr.supplierinvoicerow).length > 0
    }

    function getInvoiceIrtrList() {
        return rowList
            .flatMap(r => r.supplierinvoicerowList)
            .filter(invoiceIrtr => invoiceIrtr !== undefined)
    }

    function decreaseQuantityOfUnsavedRowsInThisInvoice(_supplierinvoicerowList) {
        getInvoiceIrtrList().forEach(invoiceIrtr => {
            // if (invoiceIrtr.invoicerow === 0) {
            const _tr = ArrayUtil.findById(_supplierinvoicerowList, invoiceIrtr.supplierinvoicerow)
            if (_tr) _tr.leftToInvoicePrice -= invoiceIrtr.price
            // }
        })
    }

    function calculateLeftToInvoice(supplierinvoicerowList) {
        supplierinvoicerowList.forEach(item => item.priceToInvoice = 0)
    }

    const ACCOUNT_SUPPLIER_INVOICE = useCompanyProp(Companyprop.ACCOUNT_SUPPLIER_INVOICE, undefined)
    const ACCOUNT_SUPPLIER_INVOICE_REVERSED_VAT = useCompanyProp(Companyprop.ACCOUNT_SUPPLIER_INVOICE_REVERSED_VAT, undefined)

    function getAccount() {
        console.log("vatType", vatType)
        switch (vatType) {
            case VatType.SEREVERSEDVAT.id:
                console.log("ACCOUNT_SUPPLIER_INVOICE_REVERSED_VAT", ACCOUNT_SUPPLIER_INVOICE_REVERSED_VAT)

                if (ACCOUNT_SUPPLIER_INVOICE_REVERSED_VAT) return accountList.find(a => a.number === Number(ACCOUNT_SUPPLIER_INVOICE_REVERSED_VAT))
                break;
            default:
                console.log("ACCOUNT_SUPPLIER_INVOICE", ACCOUNT_SUPPLIER_INVOICE)
                if (ACCOUNT_SUPPLIER_INVOICE) return accountList.find(a => a.number === Number(ACCOUNT_SUPPLIER_INVOICE))
                break
        }
    }

    const onSave = (supplierinvoicerowList, config) => {
        // console.log("supplierinvoicerowList", supplierinvoicerowList, config)

        const invoiceRows = []
        addTitle(rowList, config, invoiceRows, i18n(supplierinvoicerowList.length > 1 ? 'supplier_invoices' : 'supplier_invoice'))

        const groupedSupplierinvoicerowList = groupBy(supplierinvoicerowList, item => {
            let result = 0
            if (config.separatePercentageMarkup) {
                result = item.percentageMarkup
            }
            return result
        })

        let i = 0
        for (const key in groupedSupplierinvoicerowList) {
            if (i++ > 0) {
                addEmptyRow(invoiceRows)
            }

            const surchargeInvoicerowsupplierinvoicerowList = []

            groupedSupplierinvoicerowList[key].forEach(sir => {
                let _priceToInvoice
                let _surcharge

                if (config.separatePercentageMarkup) {
                    const factor = 1 + (sir.percentageMarkup / 100)
                    _priceToInvoice = NumberUtils.round(sir.priceToInvoice / factor, 2)
                    _surcharge = sir.priceToInvoice - _priceToInvoice
                } else {
                    _priceToInvoice = sir.priceToInvoice
                }

                const invoicerowsupplierinvoicerowList = []
                invoicerowsupplierinvoicerowList.push({
                    invoicerow: 0,
                    supplierinvoicerow: sir.id,
                    price: _priceToInvoice
                })

                invoiceRows.push({
                    headline: sir.headline,
                    onlyTotal: sir.onlyTotal,
                    name: sir.supplierName
                        + (sir.name?.length > 0 ? ' - ' + sir.name : '')
                        + (sir.supplierinvoiceNumber?.length > 0 ? ', ' + sir.supplierinvoiceNumber : ''),
                    unit: sir.unit,
                    quantity: 1,
                    aprice: _priceToInvoice,
                    discount: 0,
                    price: calculatePrice(1, _priceToInvoice, 0),
                    vat: Vat.VAT25.id,
                    account: getAccount()?.id,
                    costcenter: project.costcenter,
                    supplierinvoicerowList: invoicerowsupplierinvoicerowList
                })

                if (config.separatePercentageMarkup) {
                    surchargeInvoicerowsupplierinvoicerowList.push({
                        invoicerow: 0,
                        supplierinvoicerow: sir.id,
                        price: _surcharge
                    })
                }
            })

            const _surcharge = sum(surchargeInvoicerowsupplierinvoicerowList, 'price')
            if (config.separatePercentageMarkup && _surcharge > 0) {
                invoiceRows.push({
                    headline: false,
                    onlyTotal: true,
                    name: i18n('surcharge') + ' ' + NumberUtils.formatTwoDecimalsIfNeeded(Number(key)) + ' %',
                    // unit: sir.unit,
                    quantity: 1,
                    aprice: _surcharge,
                    discount: 0,
                    price: calculatePrice(1, _surcharge, 0),
                    vat: Vat.VAT25.id,
                    account: getAccount()?.id,
                    costcenter: project.costcenter,
                    supplierinvoicerowList: surchargeInvoicerowsupplierinvoicerowList
                })
            }
        }
        onAddRows(invoiceRows)
    }

    if (round(uninvoicePrice) === 0) return undefined
    return <FetchButton
        label={i18n('supplier_invoices') + ' (' + NumberUtils.formatCurrency(uninvoicePrice) + ')'}
        onClick={e => {
            supplierinvoicerowList.forEach(item => item.priceToInvoice = 0)
            renderOverlay(<FetchSupplierinvoicerowsDialog
                supplierinvoicerowList={supplierinvoicerowList}
                onSave={onSave}/>)
        }}
    />
}