import React, {useState} from 'react';
import {MainButton} from "../../../components/Buttons/Button";
import DatePicker from "../../../components/Date/DatePicker";
import XlnzDropdown from "../../../components/XlnzDropdown/XlnzDropdown";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import {matchSearch} from "../../../functions/match";
import {GridTemplates} from "../../../GridTemplates";
import useMountEffect from "../../../hooks/useMountEffect";
import {i18n} from "../../../I18n/I18n";
import {insert, sortByField} from "../../../utils/ArrayUtil";
import {getFirstDateOfMonth, getLastDateOfMonth, todayDate} from "../../../utils/DateUtils";
import HyrletDisconnectedView from "../HyrletDisconnectedView";
import useHyrletLoginInfo from "../useHyrletLoginInfo";
import PortalRentHistoryDialog from "./PortalRentHistoryDialog";
import PortalRentService from "./PortalRentService";


const PortalRentGrid = () => {
    const hyrletLoginInfo = useHyrletLoginInfo()
    const customerList = sortByField(hyrletLoginInfo.getCurrentCustomerList(), 'fullName')

    const filter = useFilter('PortalRentGrid', [
        {name: 'customer', defaultValue: customerList.length === 1 ? customerList[0].id : undefined},
        {name: 'startDate', defaultValue: getFirstDateOfMonth()},
        {name: 'endDate', defaultValue: getLastDateOfMonth(todayDate())},
        {name: 'search', defaultValue: ''}
    ])


    const [list, setList] = useState([]);
    const filteredList = list.filter(c => matchSearch(filter, c))

    const loadData = () => {
        if (filter.customer > 0) {
            showLoading()
            PortalRentService.findAll({
                customer: filter.customer,
                startDate: filter.startDate,
                endDate: filter.endDate
            }).then(res => {
                setList(res ? res : [])
                hideLoading()
            });
        }
    }

    useMountEffect(() => {
        if (filter.customer) {
            loadData()
        }
    })

    const leftFilters = [
        <div>
            <label className="label">{i18n('start_date')}</label>
            <DatePicker value={filter.startDate} onChange={date => filter.update('startDate', date)}/>
        </div>,
        <div>
            <label className="label">{i18n('end_date')}</label>
            <DatePicker value={filter.endDate} onChange={date => filter.update('endDate', date)}/>
        </div>,
        <div>
            <label className="label">&nbsp;</label>
            <MainButton onClick={() => loadData()} labelI18n='create_report'/>
        </div>
    ]

    if (customerList.length !== 1) {
        insert(leftFilters, <div>
            <label className="label">{i18n('customer')}</label>
            <XlnzDropdown
                value={filter.customer}
                options={customerList}
                optionLabel='name'
                showClear={true}
                placeholder={i18n('select') + '...'}
                optionValue='id'
                onChange={e => filter.update('customer', e.value)}
                style={{width: '350px'}}/>
        </div>, 0)
    }

    const columns = [
        {
            field: 'name',
            headerI18n: 'article',
            body: GridTemplates.onelineString
        },
        {field: 'number', headerI18n: 'art_no', width: 120, mobile: true},
        {
            field: 'invoiceDays',
            header: 'Faktureringsdagar',
            body: GridTemplates.onelineString,
            align: 'right', width: 120,
        },
        {
            field: 'quantityString',
            headerI18n: 'quantity',
            // body: GridTemplates.onelineString,
            align: 'right',
            mobileWithHeader: true
        },
        {
            field: 'rentPrice',
            headerI18n: 'price',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            align: 'right', width: 100,
            mobileWithHeader: true
        },
    ]

    if (!hyrletLoginInfo.isConnected()) return <HyrletDisconnectedView/>

    return <div>
        <Grid
            xlnzGrid={true}
            id='PortalRentGrid'
            name='Hyrlet - Hyresunderlag'
            label='Hyrlet - Hyresunderlag'
            leftFilters={leftFilters}
            filter={filter}
            updateFilter={filter.update}
            columns={columns}
            value={filteredList}
            paginator={true}
            onRowSelect={e => {
                renderOverlay(<PortalRentHistoryDialog customer={filter.customer} model={e.data}/>)
            }}
        />
    </div>
}

export default PortalRentGrid;