import React from 'react';
import {useNavigate} from "react-router-dom";
import {MainButton} from "../../components/Buttons/Button";
import {i18n} from "../../I18n/I18n";

const AttestInfo = props => {
    const numToAttest = props.data.numToAttest
    const navigate = useNavigate()

    if (!numToAttest || numToAttest.quantity === 0) return
    return <div className='card' style={{
        border: '2px solid rgba(255, 0, 0, 0.5)',
        backgroundColor: 'rgba(255, 0, 0, 0.05)'
    }}>
        <div style={{marginBottom: '15px'}}>
            {i18n('you_have_supplier_invoices_to_attest').replace('{}', numToAttest.quantity)}
        </div>
        <MainButton labelI18n='show_invoices' onClick={() => {
            navigate('/supplierinvoices?attestant=' + numToAttest.employeeId)
        }}/>
    </div>
}

export default AttestInfo;
