import React from 'react';
import {i18n} from "../../I18n/I18n";
import {MainButton} from "../Buttons/Button";
import XlnzDialog from "../XlnzDialog/XlnzDialog";


export default function HelpDialog(props) {
    return <XlnzDialog headerI18n='help'
                       onHide={props.onHide}
                       buttons={() => [<MainButton key='close' labelI18n='close' onClick={e => props.onHide()}/>]}
    >
        <div style={{fontSize: '12px'}}>
            <div>{i18n('contact_support_info')}</div>
            <div style={{padding: '10px 0'}}>
                <a href='mailto:support@verktyg1.se'>support@verktyg1.se</a>
            </div>
        </div>
    </XlnzDialog>
}