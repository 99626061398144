import {useContext} from "react";
import AppContext from "../AppContext";
import Company from "../enums/Company";

export default function useBetaPaymentplan() {
    const context = useContext(AppContext)
    return context.user.superAdmin === true
        || context.currentCompany.id === Company.MOBILBYGG
        || context.currentCompany.id === Company.REELS_PROJEKT

    // return true
}