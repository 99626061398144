import React from 'react';
import FormItemCheckbox from "../../components/EditForm/FormItemCheckbox";
import FormItemInteger from "../../components/EditForm/FormItemInteger";
import FormItemNumber from "../../components/EditForm/FormItemNumber";
import FormItemText from "../../components/EditForm/FormItemText";
import FormItemTimeregdebittype from "../../components/EditForm/FormItemTimeregdebittype";
import SimpleEditForm from "../../components/EditForm/SimpleEditForm";
import Role from "../../enums/Role";
import {PreloadType} from "../../hooks/usePreload";
import {i18n} from "../../I18n/I18n";
import TimeregsalarytypeService from "./TimeregsalarytypeService";

const TimeregsalarytypeEditForm = () => {

    function preSaveFunction(data) {
        if (!data.employeeCostPercentageMarkup) data.employeeCostPercentageMarkup = 0
    }

    return <SimpleEditForm
        writeRole={Role.ADMIN}
        headlineI18n='salary_types'
        defaultValues={{
            employeeCostPercentageMarkup: 0,
            active: true
        }}
        loadFunction={TimeregsalarytypeService.findById}
        preSaveFunction={preSaveFunction}
        saveFunction={TimeregsalarytypeService.update}
        initLeftFields={form => [
            <FormItemText key='name' name='name' labelI18n='name' maxLength={128} required {...form}/>,
            <FormItemText key='abbr' name='abbr' labelI18n='abbreviation' maxLength={8} required {...form}/>,
            <FormItemText key='salaryCode' name='salaryCode' labelI18n='salary_code' maxLength={20} {...form}/>,
            <FormItemTimeregdebittype key='debittype' labelI18n='corresponding_debit_type' required {...form}  />,
            <FormItemNumber key='employeeCostPercentageMarkup' name='employeeCostPercentageMarkup'
                            label={i18n('employee_cost') + ' - ' + i18n('percentage_markup')}
                            min={0}
                            max={9999.99}
                            {...form}/>,
            <FormItemInteger key='listOrder' name='listOrder' labelI18n='list_order' required {...form}/>,
            <FormItemCheckbox key='active' name='active' labelI18n='active' {...form}/>
        ]}
        preloadType={PreloadType.TIMEREGSALARYTYPE_LIST}
    />
}

export default TimeregsalarytypeEditForm;
