import Role from "../../enums/Role";
import EmployeeEditForm from "./edit/EmployeeEditForm";
import EmployeeGrid from "./EmployeeGrid";

const EmployeesIndex = {
    routeProps: {
        path: '/employees',
        exact: true,
        element: <EmployeeGrid/>,
    },
    nameKey: 'employees',
    icon: 'pi pi-fw pi-briefcase',
    subModules: [{
        routeProps: {
            path: '/employees/:id',
            exact: true,
            element: <EmployeeEditForm/>
        }
    }],
    roles: [Role.EMPLOYEE_READ]
};
export default EmployeesIndex;