import '@components/EditForm/EditForm.scss'
import React, {useContext, useState} from 'react';
import {Controller, useForm, useWatch} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../../AppContext";
import {CancelButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemAddress from "../../../components/EditForm/FormItemAddress";
import FormItemCheckbox from "../../../components/EditForm/FormItemCheckbox";
import FormItemEmail from "../../../components/EditForm/FormItemEmail";
import FormItemOptionalDate from "../../../components/EditForm/FormItemOptionalDate";
import FormItemPhone from "../../../components/EditForm/FormItemPhone";
import FormItemText from "../../../components/EditForm/FormItemText";
import FormItemTextArea from "../../../components/EditForm/FormItemTextArea";
import FormItemWww from "../../../components/EditForm/FormItemWww";
import {errorAlert} from "../../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Spinner from "../../../components/Spinner/Spinner";
import TabPanel from "../../../components/TabView/TabPanel";
import TabView from "../../../components/TabView/TabView";
import Country from "../../../enums/Country";
import Role from "../../../enums/Role";
import useLoadCompanyInfo from "../../../hooks/useLoadCompanyInfo";
import useMountEffect from "../../../hooks/useMountEffect";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import RouterUtils from "../../../utils/RouterUtils";
import LeadService from "../LeadService";
import LeadcontactGrid from "./LeadcontactGrid";
import LeadlogGrid from "./LeadlogGrid";

const LeadEditForm = () => {
    const context = useContext(AppContext)
    const roles = useRoles()

    const [model, setModel] = useState()

    const location = useLocation()
    const navigate = useNavigate()

    const loadCompanyInfo = useLoadCompanyInfo()

    const disabled = !model || !roles.hasRole(Role.CRM_WRITE)

    const defaultValues = {
        name: '',
        number: '',
        address: {
            address: '',
            zip: 0,
            city: '',
            country: Country.SE.id
        },
        note: '',
        active: true,
        contactList: [],
        logList: []
    }

    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, getValues, control} = form

    const watchLogList = useWatch({control, name: 'logList'})
    const watchContactList = useWatch({control, name: 'contactList'})


    const validate = async data => {
        if (!data.id && data.regNo.length > 0) {
            const exists = await LeadService.exists(data.regNo)
            if (exists) {
                errorAlert(i18n('customer_already_exists'))
                return false
            }
        }
        return true
    }

    const save = async (data) => {
        if (!await validate(data)) {
            hideLoading()
            return undefined
        }

        if (!disabled) {
            data.company = context.currentCompany.id

            console.log("SAVE MODEL:", data)
            const _model = await LeadService.update(data)

            if (_model) {
                setModel(_model)
                reset(_model)
            }
            return _model
        } else {
            return model;
        }
    };

    const onSubmit = () => {
        showLoading();
        const data = getValues();
        return save(data).then(_model => {
            hideLoading()
            if (_model) RouterUtils.goBack(navigate)
        });
    }

    useMountEffect(async () => {
        showLoading();
        const id = RouterUtils.getId(location)
        if (id > 0) {
            const _model = await LeadService.findById(id)
            setModel(_model)
            reset(_model)
        } else {
            setModel(defaultValues)
        }
        hideLoading();
    })

    async function findCompanyInfoByRegNo(_regNo) {
        const name = getValues('name');
        if (name.length === 0) {
            const _companyInfo = await loadCompanyInfo.findByRegNo(_regNo)
            if (!_companyInfo) return
            form.setValue('regNo', _companyInfo.regNo)
            form.setValue('name', _companyInfo.name)
            form.setValue('address', _companyInfo.address)
        }
    }

    if (!model) return <Spinner/>
    return (
        <div className='EditForm'>
            <div className="headline">
                <h1>{i18n('lead')} {model?.number}</h1>
            </div>
            <form>
                <div className="button-row">
                    <div className='left-buttons'>
                        {!disabled &&
                            <SaveButton onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>}
                        <CancelButton onClick={e => RouterUtils.goBack(navigate)}/>
                    </div>
                </div>
                <div className='columnContainer'>
                    <div className='column'>
                        <FormItemText key='regNo' name='regNo' labelI18n='reg_no' maxLength={32} width={150}
                                      onBlur={e => findCompanyInfoByRegNo(e.target.value)} {...form}/>
                        <FormItemText key='name' name='name' labelI18n='name' maxLength={128} required {...form}/>
                        <FormItemOptionalDate name='followUpDate' labelI18n='follow_up' {...form} />
                    </div>
                    <div className='column'>
                        <FormItemAddress key='address' name='address' labelI18n='address' {...form}/>
                        <FormItemPhone key='phone0' name='phone0' labelI18n='mobile' {...form}/>
                        <FormItemEmail key='email' name='email' labelI18n='email' {...form}/>
                        <FormItemWww key='www' name='www' labelI18n='homepage' {...form}/>
                    </div>
                    <div className='column'>
                        <FormItemTextArea key='note' name='note' labelI18n='note' maxLength={5000} {...form}/>
                        <FormItemCheckbox key='active' name='active' labelI18n='active' {...form}/>
                    </div>
                </div>
                <TabView>
                    <TabPanel key='logs' header={i18n('logs') + ' (' + (watchLogList.length) + ')'}>

                        <Controller name="logList"
                                    control={control}
                                    render={({field: {ref, value, onChange}}) =>
                                        <LeadlogGrid inputRef={ref} value={value} onChange={onChange}/>
                                    }/>
                    </TabPanel>
                    <TabPanel key='contact_persons' header={i18n('contact_persons') + ' (' + (watchContactList.length) + ')'}>

                        <Controller name="contactList"
                                    control={control}
                                    render={({field: {ref, value, onChange}}) =>
                                        <LeadcontactGrid inputRef={ref} value={value} onChange={onChange}/>
                                    }/>
                    </TabPanel>
                </TabView>
            </form>
        </div>
    )
}

export default LeadEditForm;
