import React from 'react';
import './MonthStepper.scss';
import I18n from "../../I18n/I18n";
import XlnzDropdown from "../XlnzDropdown/XlnzDropdown";

const MonthDropdown = props => {
    const {
        value,
        onChange,
        showClear = true
    } = props

    const list = [
        {id: 1, label: I18n.t('january')},
        {id: 2, label: I18n.t('february')},
        {id: 3, label: I18n.t('march')},
        {id: 4, label: I18n.t('april')},
        {id: 5, label: I18n.t('may')},
        {id: 6, label: I18n.t('june')},
        {id: 7, label: I18n.t('july')},
        {id: 8, label: I18n.t('august')},
        {id: 9, label: I18n.t('september')},
        {id: 10, label: I18n.t('october')},
        {id: 11, label: I18n.t('november')},
        {id: 12, label: I18n.t('december')}
    ]

    return <XlnzDropdown
        value={value}
        options={list}
        optionValue='id'
        placeholder={I18n.t('select') + '...'}
        showClear={showClear}
        onChange={onChange}
        style={{width: '150px'}}/>
}
export default MonthDropdown;
