import React, {useState} from 'react';
import AppendixViewer from "../../components/Appendix/AppendixViewer";
import {AddButton} from "../../components/Buttons/Button";
import {Thumbnail} from "../../components/EditForm/FormItemAppendixGrid";
import ActiveFilter from "../../components/Grid/ActiveFilter";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import renderOverlay from "../../components/Overlay/renderOverlay";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import {matchActive, matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useMountEffect from "../../hooks/useMountEffect";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import DocumentService from "./DocumentService";

const DocumentGrid = () => {
    const roles = useRoles()

    const filter = useFilter('AccountGrid', [
        {name: 'active', defaultValue: true},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);
    const filteredList = list.filter(c => matchActive(filter, c) && matchSearch(filter, c))


    useMountEffect(async () => {
        showLoading()
        setList(await DocumentService.findAll())
        hideLoading()
    }, [])

    const leftFilters = [
        roles.hasRole(Role.DOCUMENT_WRITE) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <label className="label">{i18n('status')}</label>
            <ActiveFilter value={filter.active} onChange={e => filter.update('active', e.value)}/>
        </div>
    ]

    const columns = [
        {
            field: 'thumbnail',
            width: 140,
            body: row => row.fileSize > 0 ? <Thumbnail appendix={row} onClick={e => {
                e.stopPropagation()
                renderOverlay(<AppendixViewer
                    imageList={filteredList}
                    index={filteredList.indexOf(row)}
                    onReload={setList}
                />)
            }}/> : undefined
        },
        // FileColumn(rowData => rowData.fileSize > 0,
        //     rowData => rowData.name,
        //     async rowData => (await DocumentService.getImageUrl(rowData.id)).url
        // ),
        {field: 'description', headerI18n: 'name'},
        {field: 'name', headerI18n: 'filename'},
        {field: 'note', headerI18n: 'note'},
        {field: 'active', headerI18n: 'active', body: GridTemplates.boolean, width: 100},
    ];

    return <Grid
        xlnzGrid={true}
        id='DocumentGrid'
        labelI18n='documents'
        icon={Icon.DOCUMENT}
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
    />
}

export default DocumentGrid;
