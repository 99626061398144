import React from 'react';
import {i18n} from "../../I18n/I18n";
import FormItemText from "./FormItemText";

const FormItemSsn = props => {

    function validatePersonnummer(personnummer) {
        if (personnummer.length !== 13) {
            return false;
        }

        try {
            const year = parseInt(personnummer.substring(0, 4));
            const month = parseInt(personnummer.substring(4, 6));
            const day = parseInt(personnummer.substring(6, 8));
            const controlNumber = parseInt(personnummer[12]);

            if (year < 1900 || year > new Date().getFullYear() ||
                month < 1 || month > 12 ||
                day < 1 || day > 31) {
                return false;
            }

            // Kontrollera om personnumret är giltigt baserat på datum och kontrollsiffra
            const validControlNumber = calculateControlNumber(personnummer.substring(2, 12).replace('-', '')) === controlNumber;

            return validControlNumber;
        } catch (error) {
            return false;
        }
    }

    function calculateControlNumber(personnummer) {
        let sum = 0;

        for (let i = 0; i < personnummer.length; i++) {
            let digit = parseInt(personnummer.charAt(i));
            if (i % 2 === 0) {
                digit *= 2;
                if (digit > 9) {
                    digit = digit % 10 + 1;
                }
            }
            sum += digit;
        }

        const controlNumber = (10 - (sum % 10)) % 10;
        return controlNumber;
    }

    const validation = {}
    validation.validate = value => {
        if (value.length === 0) return true
        return validatePersonnummer(value) ? undefined : i18n('error_invalid_ssn')
    }

    return <FormItemText labelI18n='ssn' validation={validation} {...props} />
}
export default FormItemSsn;