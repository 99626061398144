import I18n from "../I18n/I18n";

export default class CustomerType {
    static get COMPANY() {
        return Object.freeze({id: 'COMPANY', name: I18n.t('company')});
    }

    static get PRIVATE_PERSON() {
        return Object.freeze({id: 'PRIVATE_PERSON', name: I18n.t('private_person')});
    }

    static values() {
        return [
            {id: this.COMPANY.id, name: this.COMPANY.name},
            {id: this.PRIVATE_PERSON.id, name: this.PRIVATE_PERSON.name}
        ];
    }
}