import React, {useCallback, useState} from 'react';
import {MainButton} from "../../../components/Buttons/Button";
import DatePicker from "../../../components/Date/DatePicker";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Icon from "../../../enums/Icon";
import {matchSearch} from "../../../functions/match";
import {GridTemplates} from "../../../GridTemplates";
import {i18n} from "../../../I18n/I18n";
import {getFirstDateOfMonth, todayDate} from "../../../utils/DateUtils";
import NetUtils from "../../../utils/NetUtils";

const Borealis = () => {
    const filter = useFilter('CustomerHoursAndInvoiced', [
        {name: 'startDate', defaultValue: getFirstDateOfMonth()},
        {name: 'endDate', defaultValue: todayDate()},
        {name: 'search', defaultValue: ''},
    ])
    const [list, setList] = useState([])
    const filteredList = list.filter(item => matchSearch(filter, item))

    const loadData = useCallback(() => {
        showLoading()
        NetUtils.doGet('/api/reports/borealis', {
            startDate: filter.startDate,
            endDate: filter.endDate
        }).then(res => {
            setList(res)
            hideLoading()
        });
    }, [filter.startDate, filter.endDate]);

    const columns = [
        {field: 'date', header: 'Delivery Date', width: 120},
        {field: 'articleNumber', header: 'Service', width: 120},
        {field: 'articleName', header: 'Service description'},
        {
            field: 'quanityOrPrice', header: 'Quantity/Cost',
            body: GridTemplates.numberTwoDecimalsIfNeeded,
            align: 'right',
            width: 120
        },
        {field: 'articleUnitAbbr', header: 'Uom/Curr', width: 120},
        {
            field: 'invoicePrice', header: 'Invoice Price',
            body: GridTemplates.currencyTwoDecimals,
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
            align: 'right',
            width: 120
        },
        {field: 'respPerson', header: 'Resp. Person'},
        {field: 'projectMarkup', header: 'Order'}
    ]

    const leftFilters = [
        <div>
            <label className="label">{i18n('start_date')}</label>
            <DatePicker value={filter.startDate} onChange={date => filter.update('startDate', date)}/>
        </div>,

        <div>
            <label className="label">{i18n('end_date')}</label>
            <DatePicker value={filter.endDate} onChange={date => filter.update('endDate', date)}/>
        </div>
    ]

    leftFilters.push(<div>
        <label className="label">&nbsp;</label>
        <MainButton labelI18n='create_report' onClick={() => loadData()}/>
    </div>)

    async function onExportToExcel() {
        console.log("onExportToExcel")
        showLoading()
        await NetUtils.downloadFile('/api/reports/borealis/excel', {
            startDate: filter.startDate,
            endDate: filter.endDate
        })
        hideLoading()
    }

    return <Grid
        xlnzGrid={true}
        id='Borealis'
        forceDesktop={true}
        label='Borealis'
        icon={Icon.REPORT}
        leftFilters={leftFilters}
        onExportToExcel={onExportToExcel}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        onRowSelect={e => {
        }}
    />
}

export default Borealis;