import React, {useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Role from "../../enums/Role";
import {matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useMountEffect from "../../hooks/useMountEffect";
import useRoles from "../../hooks/useRoles";
import UsergroupService from "./UsergroupService";

export default function UsergroupGrid() {
    const roles = useRoles()

    const filter = useFilter('UsergroupGrid', [
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);
    const filteredList = list.filter(c => matchSearch(filter, c))

    filteredList.forEach(item => {
        item.roleSet.sort()
    })

    useMountEffect(async () => {
        showLoading()
        setList(await UsergroupService.findAll())
        hideLoading()
    })

    const leftFilters = [
        roles.hasRole(Role.USERGROUP_WRITE) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
    ]

    const columns = [
        {field: 'name', headerI18n: 'name', width: 200},
        {field: 'hideArticlePrices', header: 'Hide article prices', body: GridTemplates.boolean, width: 120},
        {
            field: 'roleListString', headerI18n: 'roles',
            body: row => {
                return row.roleSet
                    .map(roleName => roles.getRoleName(roles.findByName(roleName)))
                    .join(', ')
            },
            style: {flexDirection: 'column', alignItems: 'flex-start'}
        }
    ];

    return <Grid
        xlnzGrid={true}
        labelI18n='user_groups'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
    />
}
