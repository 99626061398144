import React, {useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Role from "../../enums/Role";
import {matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useMountEffect from "../../hooks/useMountEffect";
import useRoles from "../../hooks/useRoles";
import TendertermService from "./TendertermService";

const TendertermGrid = () => {
    const roles = useRoles()

    const filter = useFilter('TendertermGrid', [
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);
    const filteredList = list.filter(c => matchSearch(filter, c))

    useMountEffect(async () => {
        showLoading()
        setList(await TendertermService.findAll())
        hideLoading()
    }, [])

    const leftFilters = [
        roles.hasRole(Role.ADMIN) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        // <div>
        //     <label className="label">{I18n.t('status')}</label>
        //     <ActiveFilter value={filter.active} onChange={e => filter.update('active', e.value)}/>
        // </div>
    ]

    const columns = [
        {field: 'name', headerI18n: 'name'},
        {field: 'text', headerI18n: 'text', body: GridTemplates.multilineString},
        {field: 'listOrder', headerI18n: 'list_order', align: 'right'},
    ];

    return <Grid
        xlnzGrid={true}
        labelI18n='tender_terms'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
    />
}

export default TendertermGrid;
