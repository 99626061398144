import {groupBy} from "lodash";
import Vat from "../../../../enums/Vat";
import usePreload, {PreloadType} from "../../../../hooks/usePreload";
import {findById} from "../../../../utils/ArrayUtil";


export default function useTimeregarticleInvoicerowsGenerator() {
    const preload = usePreload()
    const articleList = preload.get(PreloadType.ARTICLE_LIST)
    const accountList = preload.get(PreloadType.ACCOUNT_LIST)

    function getAccount(articleId) {
        if (articleId) {
            const article = findById(articleList, articleId)
            return article && article.accountId ? findById(accountList, article.accountId) : undefined
        }
        return undefined
    }

    return {
        getSeparated: (project, joinedList, config) => {
            let invoiceRows = []

            const timeregarticleList = joinedList.filter(item => item.type === 'timeregarticle')

            const groupedTimeregarticleList = groupBy(timeregarticleList, item => {
                let result = ''
                if (config.separateDates) {
                    result += item.date + ', '
                }
                result += item.name
                if (config.separateEmployees && item?.employeeName.length > 0) {
                    result += ', ' + item.employeeName
                }

                result += ';;' +
                    item.unit + ',' +
                    item.aprice + ',' +
                    item.discount

                return result
            })

            for (const key in groupedTimeregarticleList) {
                let date = ''
                let article
                let unit
                let quantity = 0
                let aprice
                let discount
                let price = 0

                // findById(articleList, tra.articleId)

                const invoicerowtimeregList = []
                groupedTimeregarticleList[key].forEach(tr => {
                    // article = findById(articleList, tr.articleId)
                    article = tr.articleId
                    date = tr.date
                    unit = tr.unit
                    quantity += tr.quantity
                    aprice = tr.aprice
                    discount = tr.discount
                    price += tr.price

                    invoicerowtimeregList.push({
                        invoicerow: 0,
                        timeregarticle: tr.id,
                        quantity: tr.quantity
                    })
                })


                // const invoicerowtimeregList = []
                // invoicerowtimeregList.push({
                //     invoicerow: 0,
                //     timeregarticle: tra.id,
                //     quantity: tra.quantity
                // })

                invoiceRows.push({
                    priority: 2,
                    date: date,
                    article: article,
                    name: key.substring(0, key.indexOf(';;')),
                    unit: unit,
                    quantity: quantity,
                    aprice: aprice,
                    discount: discount,
                    price: price,
                    vat: Vat.VAT25.id,
                    account: getAccount(article)?.id,
                    costcenter: project.costcenter,
                    timeregarticleList: invoicerowtimeregList
                })
            }
            return invoiceRows
        },
        // getGrouped: (joinedList) => {
        //     const invoiceRows = []
        //     const timeregarticleList = joinedList.filter(item => item.type === 'timeregarticle')
        //
        //     console.log("timeregarticleList", timeregarticleList)
        //     if (timeregarticleList.length > 0) {
        //         let price = 0
        //
        //         const invoicerowtimeregList = []
        //         timeregarticleList.forEach(tra => {
        //             price += tra.price
        //             invoicerowtimeregList.push({
        //                 invoicerow: 0,
        //                 timeregarticle: tra.id,
        //                 quantity: tra.quantity
        //             })
        //
        //         })
        //
        //         invoiceRows.push({
        //             name: i18n('articles'),
        //             unit: undefined,
        //             quantity: 1,
        //             aprice: price,
        //             discount: 0,
        //             price: price,
        //             vat: Vat.VAT25.id,
        //             account: undefined,
        //             costcenter: project.costcenter,
        //             timeregarticleList: invoicerowtimeregList
        //         })
        //     }
        //
        //     return invoiceRows
        // }
    }
}