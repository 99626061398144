import React, {useState} from "react";
import PdfViewer from "../../components/Appendix/PdfViewer";
import {CancelButton, SaveButton} from "../../components/Buttons/Button";
import {confirmYes} from "../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Spinner from "../../components/Spinner/Spinner";
import useMountEffect from "../../hooks/useMountEffect";
import {initI18n} from "../../I18n/I18n";
import {formatTime} from "../../utils/DateUtils";
import NetUtils from "../../utils/NetUtils";
import './SupplierinvoiceAttest.scss';
import {formatTwoDecimalsIfNeeded} from "../../utils/NumberUtils";

export const SupplierinvoiceAttest = props => {
    const token = props.token
    const [model, setModel] = useState(null)
    const answered = model?.supplierinvoiceattest?.answerTime !== undefined

    const [comment, setComment] = useState('')

    useMountEffect(async () => {
        showLoading()
        await initI18n()
        const _model = await NetUtils.doGet('/api/external/supplier-invoice-attest/' + token)
        setModel(_model)
        hideLoading()
    })

    if (!model) return <Spinner/>

    function answer(approved) {
        showLoading()
        const _answer = {
            approved: approved,
            comment: comment
        }
        NetUtils.doPost('/api/external/supplier-invoice-attest/' + token + '/answer', _answer).then(_model => {
            if (_model) {
                setModel(_model)
                hideLoading()
            }
        })
    }

    let errorMsg = undefined
    if (!model.imageUrl) {
        console.log("model", model)
    } else if (!model.imageUrl) {
        errorMsg = 'Leverantörsfakturan saknar bild.'
    }

    function getPremiumPercentage(item) {
        const result = ((item.toInvoice / item.price) - 1) * 100
        if (Math.round(result) === -100) return ''
        return result > 0 || result < 0
            ? formatTwoDecimalsIfNeeded(result) + ' %'
            : ''
    }

    return <div id='App'>
        <div className='SupplierinvoiceAttest'>
            {
                model.imageUrl &&
                <>
                    {
                        answered &&
                        <header>
                            {model.supplierinvoiceattest.status === 'APPROVED' ? 'Godkänd' : 'Nekad'} {formatTime(model.supplierinvoiceattest.answerTime)}<br/>
                            {
                                model.supplierinvoiceattest.comment?.length > 0 &&
                                <div> "{model.supplierinvoiceattest.comment}" </div>
                            }
                        </header>
                    }
                    {
                        model.answerable && !answered &&
                        <header>
                            <div className='label'>
                                Attestering
                            </div>

                            <div className='comment'>
                                <input placeholder='Kommentar' onChange={e => setComment(e.target.value)}/>
                            </div>

                            <div className='answer-buttons'>
                                <SaveButton labelI18n='approve' onClick={e => {
                                    confirmYes(() => answer(true))
                                }}/>
                                <CancelButton labelI18n='deny' onClick={e => {
                                    confirmYes(() => answer(false))
                                }}/>
                            </div>
                        </header>
                    }
                </>
            }
            <div className="document-container">
                {
                    model.imageUrl && model?.rowList?.length > 0 &&
                    <div className='row-info'>
                        {
                            model.rowList.map(item => <div key={item.id} className='row'>
                                <div className='project-label'>{item.projectLabel ? item.projectLabel : '----'}</div>
                                <div>Summa: {formatTwoDecimalsIfNeeded(item.price, 2, true)}</div>

                                {
                                    item.toInvoice > 0 &&
                                    <div> Påslag: {getPremiumPercentage(item)}</div>
                                }

                                {
                                    item.toInvoice > 0 &&
                                    <div>Att fakturera: {formatTwoDecimalsIfNeeded(item.toInvoice)}</div>
                                }
                            </div>)
                        }

                    </div>
                }
                {
                    model.imageUrl &&
                    <div className='pdf-viewer-container'>
                        <PdfViewer url={model.imageUrl} enableZoom={false}/>
                    </div>
                }
                {
                    errorMsg &&
                    <div className='error-showing-image'>{errorMsg}</div>
                }
            </div>
        </div>
    </div>
}
