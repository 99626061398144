import {isFreeRow} from "./freeRows";

const RemoveColumn = (onRowRemove, numOfFreeRows = 0, isVisible) => ({
    field: 'remove',
    body: (rowData, column) => {
        if (rowData.readonly) return

        return (numOfFreeRows > 0 && isFreeRow(column.props.value, rowData))
        || (isVisible && !isVisible(rowData))
            ? ''
            : <i className="pi pi-times"
                 style={{cursor: 'pointer'}}
                 onClick={e2 => {
                     onRowRemove(rowData)
                 }}
            />
    },
    style: {maxWidth: '26px', width: '26px', textAlign: 'center'},
    align: 'center',
    width: 26,
})

export default RemoveColumn