import NetUtils from "../../utils/NetUtils";

const TimeregallowanceService = {
    findByDate: date => NetUtils.doGet('/api/timeregallowances', {date: date}),
    findById: id => NetUtils.doGet('/api/timeregallowances/' + id),
    findUnnotarized: date => NetUtils.doGet('/api/timeregallowances/unnotarized'),

    update: model => NetUtils.doPost('/api/timeregallowances', model),
    updateList: modelList => NetUtils.doPost('/api/timeregallowances/list', modelList),
    notarizeIdList: modelIdList => NetUtils.doPost('/api/timeregallowances/notarize-id-list', modelIdList),
};
export default TimeregallowanceService;