import React from 'react';
import Button from "../../../components/Buttons/Button";
import Icon from "../../../enums/Icon";
import {i18n} from "../../../I18n/I18n";

const LockButton = props => {
    const {
        label,
        labelI18n,
        locked,
        onClick,
        disabled
    } = props

    function getLabel() {
        if (label) return label
        if (labelI18n) return i18n(labelI18n)
        return !locked ? i18n('lock') : i18n('unlock')
    }

    return <Button faicon={!locked ? Icon.UNLOCK : Icon.LOCK}
                   label={getLabel()}
                   onClick={onClick}
                   disabled={disabled}
                   style={{
                       backgroundColor: '#48575e',
                       color: '#fff'
                   }}
    />
}
export default LockButton;
