import {faChevronDown, faSearch, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import './XlnzDropdown.scss';
import {findByField} from "../../utils/ArrayUtil";

export default function XlnzDropdown(props) {
    const {
        disabled = false,
        options,
        value,
        optionValue = 'id',
        optionLabel = 'label',
        onChange,
        placeholder,
        showClear,
        style,
        filter = false,
        filterBy,
        itemTemplate
    } = props

    const [isOpen, setIsOpen] = useState(false);
    const [filterText, setFilterText] = useState('');
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const portalRef = useRef(document.createElement('div'));

    useEffect(() => {
        const portalNode = portalRef.current;
        document.body.appendChild(portalNode);

        return () => {
            document.body.removeChild(portalNode);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                !portalRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isOpen && filter && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isOpen, filter]);

    const handleOptionClick = (option) => {
        if (option.disabled || disabled) return

        const _value = option[optionValue]
        fireChange(_value)
        setIsOpen(false)
    };

    const handleClearSelection = (e) => {
        e.preventDefault();
        e.stopPropagation();
        fireChange(undefined);
        setIsOpen(false);
    };

    const handleFilterChange = (e) => {
        setFilterText(e.target.value);
    };

    const filteredOptions = options.filter((option) => {
        const q = option[filterBy || optionLabel] + ''
        return q.toLowerCase().includes(filterText.toLowerCase())
    })

    function fireChange(_value) {
        if (_value !== value) {  // Kontrollera om det nya värdet skiljer sig från det nuvarande värdet
            onChange({
                value: _value,
            });
        }
    }

    const valueObj = findByField(options, optionValue, value)

    // Beräkna positionen för dropdown-options relativt till viewport
    const calculateDropdownPosition = () => {
        const rect = dropdownRef.current.getBoundingClientRect();
        return {
            position: 'absolute',
            top: `${rect.bottom}px`,
            left: `${rect.left}px`,
            width: `${rect.width}px`,
            zIndex: 3000
        };
    }

    return (
        <div ref={dropdownRef} className={`Dropdown ${disabled ? 'disabled' : ''}`} style={style}>
            <div className="header" onClick={() => !disabled && setIsOpen(!isOpen)}>
                {valueObj && <span>{valueObj[optionLabel]}</span>}
                {!valueObj && <span className="placeholder">{placeholder}</span>}
                {showClear && valueObj && (
                    <span onClick={handleClearSelection}>
                        <FontAwesomeIcon icon={faXmark} style={{color: "#999"}}/>
                    </span>
                )}
                <span>
                    <FontAwesomeIcon icon={faChevronDown} style={{color: "#999"}}/>
                </span>
            </div>

            {isOpen && !disabled && createPortal(
                <div
                    className="xlnz-dropdown-options"
                    style={calculateDropdownPosition()} // Applicera absolut positionering här
                >
                    {filter && (
                        <div className="dropdown-filter">
                            <input
                                ref={inputRef}
                                type="text"
                                value={filterText}
                                onChange={handleFilterChange}
                                disabled={disabled}
                            />
                            <span className="search-icon">
                                <FontAwesomeIcon icon={faSearch}/>
                            </span>
                        </div>
                    )}
                    {filteredOptions.map((option) => (
                        <div
                            key={option[optionValue]}
                            className={`dropdown-option ${valueObj === option ? 'selected' : ''}`}
                            onClick={() => handleOptionClick(option)}
                        >
                            {itemTemplate ? itemTemplate(option) : option[optionLabel]}
                        </div>
                    ))}
                </div>,
                portalRef.current
            )}
        </div>
    );
}
