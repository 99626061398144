import {EmailValidator} from "commons-validator-js";
import {i18n} from "../I18n/I18n";

const ValidateUtils = {
    required: () => 'Detta fält måste fyllas i',
    email: value => {
        if (value.length === 0) return undefined
        return new EmailValidator().isValid(value) ? undefined : 'E-postadressen har fel format'
    },
    maxLength: value => ({
        value: value,
        message: `Max ${value} tecken`
    }),
    integerPattern: () => ({
        value: /^\d+$/,
        message: "Endast heltal är tillåtet"
    }),
    numberPattern: () => ({
        value: /^(\d*\.)?\d+$/,
        message: "Endast nummer är tillåtet"
    }),
    numberPatternTwoDecimals: () => ({
        value: /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/,
        message: "Endast nummer med två decimaler är tillåtet"
    }),
    datePattern: () => ({
        value: /^\d{4}-\d{2}-\d{2}$/,
        message: "Datum ska matas in enligt ÅÅÅÅ-MM-DD"
    }),
    regNoPattern: () => ({
        value: /([5][0-9]([1][3-9]|[2-9][0-9])\d{2}[-]?\d{4})/,
        message: "XXXXXX-XXXX"
    }),
    max: value => ({
        value: value,
        message: `Största värde är ${value}`
    }),
    min: value => ({
        value: value,
        message: `Minsta värde är ${value}`
    }),

    isEmailValid: email => {
        if (!email) return false;
        return new EmailValidator().isValid(email)
    },
    ocr: value => {
        if (!value) return undefined;
        return validateOcr(value) ? undefined : 'Ogiltigt OCR-nummer'
    },
    bankgiro: value => {
        if (!value) return undefined;
        return validateBankgiro(value) ? undefined : i18n('error_invalid_bankgiro')
    },
}

export default ValidateUtils;


function validateOcr(ocrNumber) {
    // Kontrollera först att strängen enbart innehåller siffror
    if (!/^\d+$/.test(ocrNumber)) {
        return false; // Returnera false om strängen innehåller något annat än siffror
    }

    const reversedDigits = ocrNumber.split('').reverse();
    let sum = 0;

    reversedDigits.forEach((digit, index) => {
        let value = parseInt(digit, 10);
        if (index % 2 !== 0) {  // Multiplicera varannan siffra med 2
            value *= 2;
            if (value > 9) {
                value -= 9;  // Subtrahera 9 om värdet är större än 9
            }
        }
        sum += value;
    });

    return sum % 10 === 0;  // Returnera true om summan är jämnt delbar med 10
}

export function validateRegNo(regNo) {
    // Rensa organisationsnumret från eventuella bindestreck
    const cleanOrgNum = regNo.replace(/-/g, '');

    // Kontrollera att organisationsnumret består av 10 siffror
    if (!/^\d{10}$/.test(cleanOrgNum)) {
        return false;
    }

    // Använd Luhn-algoritmen för att validera kontrollsiffran
    return validateLuhn(cleanOrgNum);
}

export function validateBankgiro(bankgiroNumber) {
    if (!bankgiroNumber) return false
    // Rensa strängen från mellanslag och bindestreck
    const cleanNumber = bankgiroNumber.replace(/[\s-]/g, '');

    // Kontrollera att strängen enbart består av siffror
    if (!/^\d+$/.test(cleanNumber)) {
        return false;
    }

    // Kontrollera att längden är 7 eller 8 siffror
    if (cleanNumber.length !== 7 && cleanNumber.length !== 8) {
        return false;
    }

    // Luhn-algoritmen för att validera kontrollsiffran
    return validateLuhn(cleanNumber);
}

function validateLuhn(number) {
    let sum = 0;
    let shouldDouble = false; // Vi börjar med att dubbla vartannat tal från höger (kontrollsiffran ingår inte i dubbleringen först)

    // Gå igenom siffrorna från höger till vänster
    for (let i = number.length - 1; i >= 0; i--) {
        let digit = parseInt(number.charAt(i));

        if (shouldDouble) {
            digit *= 2; // Dubbla siffran
            if (digit > 9) {
                digit -= 9; // Subtrahera 9 om resultatet blir tvåsiffrigt
            }
        }

        sum += digit;
        shouldDouble = !shouldDouble; // Växla flaggan
    }

    // Om summan modulo 10 är 0, är numret giltigt
    return sum % 10 === 0;
}