import React from 'react';
import FormItemCheckbox from "../../components/EditForm/FormItemCheckbox";
import FormItemInteger from "../../components/EditForm/FormItemInteger";
import FormItemText from "../../components/EditForm/FormItemText";
import SimpleEditForm from "../../components/EditForm/SimpleEditForm";
import Role from "../../enums/Role";
import {PreloadType} from "../../hooks/usePreload";
import TimeregdebittypeService from "./TimeregdebittypeService";

const TimeregdebittypeEditForm = () => {
    return <SimpleEditForm
        writeRole={Role.ADMIN}
        headlineI18n='debit_types'
        defaultValues={{
            active: true
        }}
        loadFunction={TimeregdebittypeService.findById}
        saveFunction={TimeregdebittypeService.update}
        initLeftFields={form => [
            <FormItemText key='name' name='name' labelI18n='name' maxLength={128} required {...form}/>,
            <FormItemText key='abbr' name='abbr' labelI18n='abbreviation' maxLength={8} required {...form}/>,
            <FormItemInteger key='listOrder' name='listOrder' labelI18n='list_order' required {...form}/>,
            <FormItemCheckbox key='active' name='active' labelI18n='active' {...form}/>
        ]}
        preloadType={PreloadType.TIMEREGDEBITTYPE_LIST}
    />
}

export default TimeregdebittypeEditForm;
