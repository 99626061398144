import React from 'react';
import './Spinner.scss';

const Spinner = props => {
    return <div className="Spinner" {...props}>
        <div className="loader">
            <div className="inner one"></div>
            <div className="inner two"></div>
            <div className="inner three"></div>
        </div>
    </div>
}
export default Spinner;

export const InitSpinner = props => {
    return <div className="Spinner" {...props}>
        <div className="loader">
            <div className="inner one"></div>
            <div className="inner two"></div>
            <div className="inner three"></div>
        </div>
    </div>
}