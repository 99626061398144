import React, {useContext, useState} from 'react';
import {Controller, useForm, useWatch} from "react-hook-form";
import AppContext from "../../../../AppContext";
import {PdfViewerModal} from "../../../../components/Appendix/PdfViewer";
import {CancelButton, PdfButton, RemoveButton, SaveButton} from "../../../../components/Buttons/Button";
import FormItemDate from "../../../../components/EditForm/FormItemDate";
import FormItemDropdown from "../../../../components/EditForm/FormItemDropdown";
import FormItemEmployee from "../../../../components/EditForm/FormItemEmployee";
import FormItemNumber from "../../../../components/EditForm/FormItemNumber";
import {hideLoading, showLoading} from "../../../../components/Overlay/Loading";
import renderOverlay from "../../../../components/Overlay/renderOverlay";
import Spinner from "../../../../components/Spinner/Spinner";
import TabPanel from "../../../../components/TabView/TabPanel";
import TabView from "../../../../components/TabView/TabView";
import XlnzDialog from "../../../../components/XlnzDialog/XlnzDialog";
import Role from "../../../../enums/Role";
import useMountEffect from "../../../../hooks/useMountEffect";
import usePreload from "../../../../hooks/usePreload";
import useUserPersmissions from "../../../../hooks/useUserPersmissions";
import {i18n} from "../../../../I18n/I18n";
import {arraySum, findById} from "../../../../utils/ArrayUtil";
import {todayDate} from "../../../../utils/DateUtils";
import PaymentplanDrawDateGrid from "./PaymentplanDrawDateGrid";
import PaymentplanrowGrid from "./PaymentplanrowGrid";
import PaymentplanService from "./PaymentplanService";
import {round} from "../../../../utils/NumberUtils";
import {deepCopy} from "../../../../utils/ObjectUtils";
import {confirmYes} from "../../../../components/Overlay/Confirm";

const PaymentplanDialog = props => {
    const context = useContext(AppContext)
    const userPersmissions = useUserPersmissions()
    const [retainedFundsRow, setRetainedFundsRow] = useState()
    const [draws, setDraws] = useState()
    const [loaded, setLoaded] = useState(false)

    const {
        onHide,
        tender,
        project
    } = props

    const preload = usePreload()
    const customerList = preload.getCustomerList()

    const customer = findById(customerList, project.customer)

    // betaPaymentplan && !isChangeOrder
    const disabled = !userPersmissions.hasRole(Role.PROJECT_WRITE);

    const defaultValues = {
        id: 0,
        company: context.currentCompany.id,
        project: project.id,

        revisedDate: todayDate(),
        price: tender ? tender.price : '',

        ourReference: project.projectLeader,
        customercontact: project.customercontact,

        retainedFundsPercentage: 0,
        retainedFundsDeductionPercentage: 0,

        drawDateList: [todayDate()],
        rowList: [],
    }

    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, getValues, control} = form

    const watchPrice = useWatch({control, name: 'price'})
    const watchRetainedFundsPercentage = useWatch({control, name: 'retainedFundsPercentage'})
    const watchRetainedFundsDeductionPercentage = useWatch({control, name: 'retainedFundsDeductionPercentage'})
    const watchRowList = useWatch({control, name: 'rowList'})
    const watchDrawDateList = useWatch({control, name: 'drawDateList'})

    const numOfDraws = watchDrawDateList.length

    const difference = watchPrice - arraySum(watchRowList, 'price')

    useMountEffect(async () => {
        const [
            paymentplan,
            _draws
        ] = await Promise.all([
            PaymentplanService.findByProject(project.id),
            PaymentplanService.findDrawsByProject(project.id)
        ])

        if (paymentplan) {
            extractRetainedFundsRow(paymentplan)
            reset(paymentplan)
        } else {
            reset(defaultValues)
        }

        setDraws(_draws)
        setLoaded(true)
    })

    function extractRetainedFundsRow(paymentplan) {
        const _retainedFundsRow = paymentplan.rowList.find(item => item.retainedFunds)
        setRetainedFundsRow(_retainedFundsRow)
        paymentplan.rowList = paymentplan.rowList.filter(item => !item.retainedFunds)
    }

    async function save() {
        showLoading()
        const data = deepCopy(getValues())

        if (!data.retainedFundsPercentage) data.retainedFundsPercentage = 0
        if (!data.retainedFundsDeductionPercentage) data.retainedFundsDeductionPercentage = 0

        const _retainedFundsRow = retainedFundsRow || {
            id: 0,
            retainedFunds: true,
            name: '',
            comment: ''
        }

        _retainedFundsRow.price = getMaxRetainedFunds(watchRowList)
        _retainedFundsRow.drawPriceList = watchDrawDateList.map((item, index) => -getRetainedFunds(watchRowList, index))
        data.rowList.push(_retainedFundsRow)

        console.log("SAVE PAYMENTPLAN", data)

        const savedModel = await PaymentplanService.update(data)
        if (savedModel) {
            extractRetainedFundsRow(savedModel)
            reset(savedModel)
        }
        hideLoading()
        return savedModel
    }

    function getMaxRetainedFunds(_rowList) {
        return round(arraySum(_rowList, 'price') * watchRetainedFundsPercentage / 100, 2)
    }

    function getRetainedFunds(_rowList, drawIndex) {
        let retainedFundsLeft = getMaxRetainedFunds(_rowList)
        for (let i = 0; i < numOfDraws; i++) {

            let drawSum = 0
            _rowList.forEach(row => {
                let _value = 0
                if (row?.drawPriceList) {
                    _value = row?.drawPriceList[i] || 0
                }
                drawSum += _value
            })
            let deduction = round(drawSum * watchRetainedFundsDeductionPercentage / 100, 2)

            if (deduction > retainedFundsLeft) deduction = retainedFundsLeft
            if (i === drawIndex) {
                return deduction
            }
            retainedFundsLeft -= deduction
        }
    }

    async function onSubmit() {
        const savedModel = await save()
        if (savedModel) onHide()
    }

    async function onRemove() {
        confirmYes(async () => {
            showLoading()
            const ok = await PaymentplanService.delete(getValues())
            if (ok) onHide()
            hideLoading()
        })
    }

    const downloadPdf = data => {
        showLoading()
        save().then(_model => {
            if (_model) {
                renderOverlay(<PdfViewerModal
                    label={i18n('payment_plan')}
                    url={'/api/paymentplans/' + _model.id + '/pdf'}
                    landscape={true}/>)
            }
            hideLoading();
        })
    }

    function isInvoiced() {
        if (!draws) return false
        const invoicerowpaymentplanrowList = draws.flatMap(item => item.invoicerowpaymentplanrowList)
        return invoicerowpaymentplanrowList.length > 0
    }

    if (!loaded) return <Spinner/>
    return <XlnzDialog headerI18n='payment_plan'
                       buttons={props => [
                           !isInvoiced() && getValues('id') > 0 &&
                           <RemoveButton key='remove' onClick={e => handleSubmit(onRemove)()}
                                         disabled={isSubmitting}/>,
                           <PdfButton key='preview' labelI18n='preview'
                                      onClick={e => handleSubmit(downloadPdf)()}/>,
                           <SaveButton key='save' onClick={e => handleSubmit(onSubmit)()}
                                       disabled={isSubmitting}/>,
                           <CancelButton key='cancel' onClick={e => props.onHide()}/>
                       ]}
                       onHide={onHide}>
        <div className="EditForm" style={{width: '1200px', margin: 0}}>

            <div className='columnContainer'>
                <div className='column'>
                    <FormItemDate name='revisedDate' labelI18n='revised' {...form}/>
                    <FormItemNumber name='price'
                                    labelI18n='price'
                                    {...form}
                                    required
                                    width={100}
                                    disabled={isInvoiced()}/>
                </div>
                <div className='column'>
                    <FormItemEmployee name='ourReference' labelI18n='our_reference'
                                      required {...form}/>

                    <FormItemDropdown name='customercontact' labelI18n='contact_person'
                                      options={customer?.contactList || []}
                                      showSelect {...form} />
                </div>
                <div className='column'>
                    <FormItemNumber name='retainedFundsPercentage'
                                    label={i18n('retained_funds') + ' (%)'}
                                    width={50} {...form}
                                    disabled={isInvoiced()}/>

                    <FormItemNumber name='retainedFundsDeductionPercentage'
                                    label={i18n('retained_funds_deduction') + ' (%)'}
                                    width={50} {...form}
                                    disabled={isInvoiced()}/>
                </div>
            </div>

            <TabView>

                <TabPanel key='rows' header={i18n('rows') + ' (' + (watchRowList?.length) + ')'}>
                    <Controller name='rowList'
                                control={control}
                                render={({field: {ref, value, onChange}}) =>
                                    <PaymentplanrowGrid
                                        inputRef={ref}
                                        value={value}
                                        onChange={onChange}
                                        numOfDraws={numOfDraws}
                                        getMaxRetainedFunds={getMaxRetainedFunds}
                                        getRetainedFunds={getRetainedFunds}
                                        retainedFundsPercentage={watchRetainedFundsPercentage}
                                        retainedFundsDeductionPercentage={watchRetainedFundsDeductionPercentage}
                                        drawDateList={watchDrawDateList}
                                        draws={draws}
                                        difference={difference}
                                    />
                                }/>
                </TabPanel>

                <TabPanel key='drawDateList' header={i18n('draws') + ' (' + (watchDrawDateList?.length) + ')'}>
                    <Controller name='drawDateList'
                                control={control}
                                render={({field: {ref, value, onChange}}) =>
                                    <PaymentplanDrawDateGrid
                                        inputRef={ref}
                                        value={value}
                                        onChange={onChange}
                                        draws={draws}
                                    />
                                }/>
                </TabPanel>

            </TabView>
        </div>
    </XlnzDialog>
}

export default PaymentplanDialog;
