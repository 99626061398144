import React, {useCallback, useContext} from 'react';
import AppContext from "../../../../AppContext";
import Grid from "../../../../components/Grid/Grid";
import NumberGridInput from "../../../../components/Grid/NumberGridInput";
import {hideLoading, showLoading} from "../../../../components/Overlay/Loading";
import {GridTemplates} from "../../../../GridTemplates";
import usePreload from "../../../../hooks/usePreload";
import {i18n} from "../../../../I18n/I18n";
import {addDays} from "../../../../utils/DateUtils";
import {formatTwoDecimalsIfNeeded} from "../../../../utils/NumberUtils";
import {deepCopy} from "../../../../utils/ObjectUtils";
import TimeregabsenceService from "../../TimeregabsenceService";
import {MONTHOVERVIEW_VIEW_DAY_WIDTH} from "../MonthoverviewView";
import getDayStyle from "../workedtime/getDayStyle";
import filterTimeregabsencetypes from "./filterTimeregabsencetypes";
import './MonthoverviewAbsenceGrid.scss'

const MonthoverviewAbsenceGrid = props => {
    const context = useContext(AppContext);
    const preload = usePreload()

    const disabled = props.disabled
    const employeeId = props.employeeId
    const startDate = props.startDate
    const numOfDays = props.numOfDays
    const isDayLocked = props.isDayLocked
    const timeregabsenceList = props.timeregabsenceList
    const timeregabsencetypeList = deepCopy(filterTimeregabsencetypes(preload.getTimeregabsencetypeList(), timeregabsenceList, disabled))
    const onChange = props.onChange

    timeregabsencetypeList.forEach(type => {

            for (let i = 0; i < numOfDays; i++) {
                const date = addDays(startDate, i)
                const value = timeregabsenceList
                    .filter(ta => ta.date === date)
                    .filter(ta => ta.type === type.id)
                    .map(ta => ta.hours)
                    .reduce((sum, hours) => sum + hours, 0)

                type[date] = value
            }

            type.totalHours = timeregabsenceList
                .filter(ta => ta.type === type.id)
                .map(ta => ta.hours)
                .reduce((sum, hours) => sum + hours, 0)
        }
    )

    const onCellEditComplete = useCallback(async e => {
        const type = e.rowData
        const date = e.field
        const timeregabsence = timeregabsenceList.find(ta => ta.date === date && ta.type === type.id)

        const oldValue = timeregabsence?.hours || 0
        const newValue = e.newValue || 0

        if (newValue !== oldValue) {
            showLoading()
            if (timeregabsence) {
                if (!timeregabsence.id && timeregabsence.hours === 0) {
                    // No need to update.
                } else {
                    timeregabsence.hours = newValue
                    const savedTimeregabsence = await TimeregabsenceService.update(timeregabsence)
                    Object.assign(timeregabsence, savedTimeregabsence)
                    onChange([...timeregabsenceList])
                }
            } else {
                const newTimeregabsence = {
                    company: context.currentCompany.id,
                    type: type.id,
                    employee: employeeId,
                    date: date,
                    hours: newValue
                }
                const savedTimeregabsence = await TimeregabsenceService.update(newTimeregabsence)

                const _timeregabsenceList = [...timeregabsenceList]
                _timeregabsenceList.push(savedTimeregabsence)
                onChange(_timeregabsenceList)
            }
            hideLoading()
        }
    }, [context, employeeId, onChange, timeregabsenceList])

    const columns = [{field: 'name', header: i18n('absence')}]

    for (let i = 0; i < numOfDays; i++) {
        const date = addDays(startDate, i)
        const dayLocked = isDayLocked(date)

        columns.push({
            field: date,
            body: GridTemplates.numberTwoDecimalsIfNeeded,
            editor: e => dayLocked
                ? formatTwoDecimalsIfNeeded(e.value)
                : <NumberGridInput editorEvent={e} min={0} max={999}/>,
            onCellEditComplete: onCellEditComplete,
            width: MONTHOVERVIEW_VIEW_DAY_WIDTH,
            align: 'center',
            className: 'dayColumn',
            style: getDayStyle(date),
        })
    }

    columns.push({
        field: 'totalHours',
        body: GridTemplates.numberTwoDecimalsIfNeeded,
        width: MONTHOVERVIEW_VIEW_DAY_WIDTH,
        align: 'center',
        style: {fontWeight: 'bold'},
    })

    return <div className='MonthoverviewAbsenceGrid'>
        <Grid xlnzGrid={true}
              forceDesktop={true}
              scrollable={false}
              columns={columns}
              value={timeregabsencetypeList}
              hideFooter={true}
              sortable={false}
              onRowSelect={() => {
              }}
        >
        </Grid>
    </div>
}

export default MonthoverviewAbsenceGrid;
