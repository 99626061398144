import NetUtils from "../../../utils/NetUtils";

const WeekplanningService = {
    findByDate: date => NetUtils.doGet('/api/weekplannings', {date: date}),
    // findById: id => NetUtils.doGet('/api/weekplannings/' + id),
    update: model => NetUtils.doPost('/api/weekplannings', model),
    updateNote: (projectId, note) => NetUtils.doPost('/api/weekplannings/project/note', {
        project: projectId,
        note: note
    }),
    // delete: model => NetUtils.doDelete('/api/weekplannings', model),
};
export default WeekplanningService;