import React, {useContext, useEffect, useState} from 'react';
import {Controller, useForm, useWatch} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../../AppContext";
import {PdfViewerModal} from "../../../components/Appendix/PdfViewer";
import {CancelButton, PdfButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemAddress from "../../../components/EditForm/FormItemAddress";
import FormItemAppendixGrid, {AppendixGrid} from "../../../components/EditForm/FormItemAppendixGrid";
import FormItemCheckbox from "../../../components/EditForm/FormItemCheckbox";
import FormItemConstant from "../../../components/EditForm/FormItemConstant";
import FormItemCostcenter from "../../../components/EditForm/FormItemCostcenter";
import FormItemCustomerAndContact from "../../../components/EditForm/FormItemCustomerAndContact";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemEmployee from "../../../components/EditForm/FormItemEmployee";
import FormItemNumber from "../../../components/EditForm/FormItemNumber";
import FormItemOptionalDate from "../../../components/EditForm/FormItemOptionalDate";
import FormItemRowGrid from "../../../components/EditForm/FormItemRowGrid";
import FormItemText from "../../../components/EditForm/FormItemText";
import FormItemTextArea from "../../../components/EditForm/FormItemTextArea";
import ExtraMenu from "../../../components/Grid/ExtraMenu";
import {isFreeRow} from "../../../components/Grid/freeRows";
import onRowChange from "../../../components/Grid/onRowChange";
import SelectEditor from "../../../components/Grid/SelectEditor";
import {errorAlert} from "../../../components/Overlay/Alert";
import {confirmYes} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import Spinner from "../../../components/Spinner/Spinner";
import TabPanel from "../../../components/TabView/TabPanel";
import TabView from "../../../components/TabView/TabView";
import Companyprop from "../../../enums/Companyprop";
import Country from "../../../enums/Country";
import CustomerType from "../../../enums/CustomerType";
import Icon from "../../../enums/Icon";
import RentType from "../../../enums/RentType";
import Role from "../../../enums/Role";
import TaxReductionType from "../../../enums/TaxReductionType";
import {getCustomerEmailByIds} from "../../../functions/getCustomerEmailByIds";
import useBetaPaymentplan from "../../../hooks/useBetaPaymentplan";
import useCompanyProp from "../../../hooks/useCompanyProp";
import useDialog from "../../../hooks/useDialog";
import useEmployees from "../../../hooks/useEmployees";
import useMountEffect from "../../../hooks/useMountEffect";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import useQuery from "../../../hooks/useQuery";
import useUserPersmissions from "../../../hooks/useUserPersmissions";
import {i18n} from "../../../I18n/I18n";
import ArrayUtil, {findById, findByMaxId} from "../../../utils/ArrayUtil";
import {isAfter, todayDate} from "../../../utils/DateUtils";
import RouterUtils from "../../../utils/RouterUtils";
import useCustomercompanypricelistUtil from "../../Customers/useCustomercompanypricelist";
import FormanswerGrid from "../../Formanswers/FormanswerGrid";
import {SendTenderButton} from "../../Tenders/edit/SendTenderButton";
import {RentFooter} from "../../Tenders/edit/TenderEditForm";
import TenderEmailLogDialog from "../../Tenders/edit/TenderEmailLogDialog";
import useTenderDefaultValues from "../../Tenders/edit/useTenderDefaultValues";
import TenderService from "../../Tenders/TenderService";
import TenderStatus from "../../Tenders/TenderStatus";
import ConstructionLogDialog from "../ConstructionLogDialog";
import ProjectService from "../ProjectService";
import ProjectSummaryDialog from "../ProjectSummary/ProjectSummaryDialog";
import EmpTimeregEmployeeGridFormItem from "./EmpTimeregEmployeeGrid";
import {GenerateRentButton} from "./GenerateRentButton";
import {PaymentplanButton} from "./paymentplan/PaymentplanButton";
import ProjecttimeregdebittypepriceGrid from "./ProjecttimeregdebittypepriceGrid";
import {SendDocumentsDialog} from "./SendDocumentsDialog";

const ProjectEditForm = props => {
    const context = useContext(AppContext)
    const userPersmissions = useUserPersmissions()
    const preload = usePreload()
    const pricelistList = preload.getPricelistList()
    const dialog = useDialog()

    const employees = useEmployees()
    const customercompanypricelistUtil = useCustomercompanypricelistUtil()

    const betaPaymentplan = useBetaPaymentplan()

    const [model, setModel] = useState({
        rowList: [],
        rentList: []
    });

    const [common, setCommon] = useState();
    const [projectrentList, setProjectrentList] = useState();
    const [numOfForms, setNumOfForms] = useState('-')
    const [parent, setParent] = useState()
    const [tenderList, setTenderList] = useState()
    const [tenderrowList, setTenderrowList] = useState()

    const tenderDefaultValues = useTenderDefaultValues({clean: true})

    const HIDE_ARTICLE_PRICES = userPersmissions.isHideArticlePrices()

    const EDITABLE_NUMBER = userPersmissions.hasRole(Role.PROJECT_NUMBER_WRITE)
    const RENT_DEFAULT_STRATEGY = useCompanyProp(Companyprop.RENT_DEFAULT_STRATEGY, RentType.WEEK.id)
    const PROJECT_EMP_TIMEREG_HIDE = useCompanyProp(Companyprop.PROJECT_EMP_TIMEREG_HIDE, false)
    const PROJECT_EMP_TIMEREG_FIXED_TIME_DISABLED = useCompanyProp(Companyprop.PROJECT_EMP_TIMEREG_FIXED_TIME_DISABLED, false)
    const PROJECT_EMP_TIMEREG_COSTPLUS_TIME_DISABLED = useCompanyProp(Companyprop.PROJECT_EMP_TIMEREG_COSTPLUS_TIME_DISABLED, false)
    const USING_RECO = useCompanyProp(Companyprop.RECO_VENUE_ID, null) !== null

    const location = useLocation()
    const navigate = useNavigate()
    const query = useQuery();

    const customerList = preload.get(PreloadType.CUSTOMER_LIST)

    const disabled = !model || !userPersmissions.hasRole(Role.PROJECT_WRITE);

    const defaultValues = {
        id: 0,
        company: context.currentCompany.id,
        cuser: context.user.id,
        // customer: customerList.length === 1 ? customerList[0].id : undefined, //Change customer event must be called.
        customer: undefined,
        customercontact: undefined,
        projectLeader: undefined,
        startDate: todayDate(),
        address: {
            address: '',
            zip: 0,
            city: '',
            country: Country.SE.id
        },
        markup: '',
        workorderInfo: '',
        standardValues: {
            empTimeregHide: PROJECT_EMP_TIMEREG_HIDE,
            empTimeregFixedTimeDisabled: PROJECT_EMP_TIMEREG_FIXED_TIME_DISABLED,
            empTimeregCostplusTimeDisabled: PROJECT_EMP_TIMEREG_COSTPLUS_TIME_DISABLED,
            empTimeregEmployeeList: [],
            purchasePercentageMarkup: 0,
            budgetPrice: 0,
            purchasePrice: 0,
            estimatedRemainingCostAdjustmentList: [],
        },
        note: '',
        cancelled: false,
        timeregdebittypepriceList: [],
        rentbasisList: [],
        rentList: [],
        appendixList: [],
        documentsentlogList: [],

        // Change Order
        tenderStatus: TenderStatus.DRAFT,
        tenderRowList: [],
    }

    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, setValue, getValues, control} = form

    const watchProjectLeader = Number(useWatch({control, name: 'projectLeader'}))
    const watchCustomer = Number(useWatch({control, name: 'customer'}))
    const watchCustomercontact = Number(useWatch({control, name: 'customercontact'}))
    const watchTenderId = useWatch({control, name: 'tender'})
    const watchRentList = useWatch({control, name: 'rentList'})
    const watchRentbasisList = useWatch({control, name: 'rentbasisList'})
    const watchAppendixList = useWatch({control, name: 'appendixList'})
    const watchPricelist = useWatch({control, name: 'pricelist'})
    const pricelist = findById(pricelistList, watchPricelist)

    const isChangeOrder = useWatch({control, name: 'changeOrder'})

    useEffect(() => {
        if (isChangeOrder && !userPersmissions.hasRole(Role.TENDER_READ)) {
            errorAlert("Role TENDER_READ needed.")
            RouterUtils.goBack(navigate)
        }
    }, [isChangeOrder, userPersmissions, navigate])

    const watchTenderRowList = useWatch({control, name: 'tenderRowList'})

    const tender = tenderList?.find(t => t.id === watchTenderId)

    useEffect(() => {
        if (tender) {
            setValue('tenderAppendixList', tender.appendixList)
        }
    }, [setValue, tender]);

    useEffect(() => {
        if (model.id > 0) return

        async function loadParent() {
            const parentId = query.get("parent")
            if (parentId) {
                const _parent = await ProjectService.findById(parentId)
                setValue('parent', _parent.id)
                setValue('name', _parent.name)
                setValue('address', _parent.address)
                setValue('customer', _parent.customer)
                setValue('customercontact', _parent.customercontact)
                setValue('markup', _parent.markup)
                setValue('costcenter', _parent.costcenter)

                // console.log("_parent", _parent)
                setValue('standardValues.purchasePercentageMarkup', _parent.standardValues.purchasePercentageMarkup)
                // setValue('standardValues.budgetPrice', _parent.standardValues.budgetPrice)
                // setValue('standardValues.purchasePrice', _parent.standardValues.purchasePrice)

                setValue('timeregdebittypepriceList', _parent.timeregdebittypepriceList.map(item => ({
                    timeregdebittype: item.timeregdebittype,
                    price: item.price
                })))

                setParent(_parent)

                const changeOrder = query.get("changeOrder")
                // console.log("changeOrder", changeOrder)
                if (changeOrder) {
                    setValue('changeOrder', true)
                }

                // Make sure we only do this once by clearing the values.
                navigate('/projects/0', {replace: true})
            }
        }

        loadParent()
    }, [model, navigate, setValue, query])

    function validate(data) {
        if (data.startDate && data.endDate &&
            isAfter(data.startDate, data.endDate)) {
            errorAlert(i18n('error_end_date_before_start_date'))
            return false
        }
        return true
    }

    const save = async (data, files) => {
        if (!data) data = getValues()
        if (!validate(data)) {
            hideLoading()
            return undefined
        }
        if (!disabled) {
            const saveModel = Object.assign({}, model, data);
            let _tender = undefined

            if (isChangeOrder) {
                const _tenderModel = {
                    ...tenderDefaultValues,
                    id: data.tender,
                    ctime: data.ctime,
                    changeOrder: true,
                    name: saveModel.name,
                    customer: saveModel.customer,
                    customercontact: saveModel.customercontact,
                    ourReference: saveModel.projectLeader,
                    costcenter: saveModel.costcenter,
                    date: saveModel.startDate,
                    address: saveModel.address,
                    markup: saveModel.markup,
                    validityTime: 0,
                    status: saveModel.tenderStatus,
                    rowList: saveModel.tenderRowList,
                    sentlogList: tender?.sentlogList || []
                }
                console.log("SAVE TENDER", _tenderModel)
                _tender = await TenderService.update(_tenderModel)
                if (!_tender.id) return
                saveModel.tender = _tender.id
            }

            console.log("SAVE PROJECT:", saveModel)
            const _model = await ProjectService.update(saveModel, files)
            if (_model) {
                if (_tender) {
                    _model.tenderRowList = _tender.rowList
                    _model.tenderStatus = _tender.status
                }

                setModel(_model)
                reset(_model)
            }
            return _model
        } else {
            return model;
        }
    }

    const onSubmit = () => {
        showLoading();
        const data = getValues();
        return save(data).then(_model => {
            hideLoading()
            if (_model) RouterUtils.goBack(navigate)
        });
    }

    useMountEffect(async () => {
        showLoading();
        const _tenderList = userPersmissions.hasRole(Role.TENDER_READ) ? await TenderService.findUnassigned() : []

        if (location.state) {
            const _model = Object.assign({}, location.state)
            console.log("_model", _model)

            // Loading the tender
            if (userPersmissions.hasRole(Role.TENDER_READ) && _model.tender) {
                const tender = await TenderService.findById(_model.tender)
                _tenderList.unshift(tender)
            }

            let _customer
            if (!(Number(_model.customer) > 0)) {
                _customer = findByMaxId(customerList)
                _model.customer = _customer.id
            }
            setModel(_model)
            reset(_model)
            onCustomerChange(_customer)
        } else {
            let id = RouterUtils.getId(location)
            if (id > 0) {
                const [
                    _model,
                    _projectrentList
                ] = await Promise.all([
                    ProjectService.findById(id),
                    ProjectService.findRentsByProject(id)
                ])
                setProjectrentList(_projectrentList)

                if (userPersmissions.hasRole(Role.TENDER_READ) && _model.tender) {
                    const [
                        _tender,
                        _tenderrowList
                    ] = await Promise.all([
                        TenderService.findById(_model.tender),
                        TenderService.findRowsByTender(_model.tender)
                    ])
                    _tenderList.unshift(_tender)
                    _model.tenderStatus = _tender.status
                    _model.tenderRowList = _tender.rowList
                    setTenderrowList(_tenderrowList)
                }

                if (_model.parent) {
                    const _parent = await ProjectService.findById(_model.parent)
                    setParent(_parent)
                }

                if (_model.changeOrder) {
                    if (!_model.tenderRowList) _model.tenderRowList = []
                }

                setModel(_model);
                reset(_model);
            } else {
                setValue('projectLeader', employees.activeUsable.find(item => item.email.toLowerCase() === context.user.email.toLowerCase())?.id)
            }
        }

        setTenderList(_tenderList)
        setCommon({
            customerList: preload.get(PreloadType.CUSTOMER_LIST),
            articleList: preload.get(PreloadType.ARTICLE_LIST),
            timeregdebittypeList: preload.get(PreloadType.TIMEREGDEBITTYPE_LIST).filter(c => c.active)
        })
        hideLoading();
    })

    if (!common) return <Spinner/>

    function isRentInvoiced(pr) {
        const _tr = projectrentList?.find(item => item.id === pr.id)
        return _tr?.invoicerowprojectrentList.length > 0

    }

    function isRentBasisInvoiced(prb) {
        const _tr = projectrentList?.find(item => item.projectrentbasis === prb.id)
        return _tr?.invoicerowprojectrentList.length > 0
    }

    function isRentBasisRemovable(prb) {
        return model.rentList.find(item => item.projectrentbasis === prb.id) === undefined
    }

    function isHouseWork() {
        const customer = ArrayUtil.findById(common.customerList, watchCustomer)
        return customer?.type === CustomerType.PRIVATE_PERSON.id
    }

    const getCustomerEmail = () => {
        const customer = ArrayUtil.findById(common.customerList, watchCustomer)
        const customercontact = customer.contactList.find(cc => cc.id === watchCustomercontact);
        return customercontact?.email ? customercontact?.email : customer.email
    }

    const menuItems = [
        {
            label: i18n('project_summary') + '...',
            faicon: Icon.LOG,
            command: item => dialog.show(<ProjectSummaryDialog projectId={model.id}/>),
            hide: !model.id
        },
        {
            label: i18n('send_documents_to_customer') + '...',
            faicon: Icon.SEND,
            command: handleSubmit(data => {
                showLoading()
                save(data).then(_model => {
                    if (_model) {
                        dialog.show(<SendDocumentsDialog
                            project={_model}
                            tender={tender}
                            toEmail={getCustomerEmail()}
                            onSent={async () => {
                                const _project = await ProjectService.findById(_model.id)
                                setModel(_project);
                                reset(_project);
                            }}/>)
                    }
                    hideLoading()
                })
            }),
        },
        {
            label: i18n('email_log') + '...',
            faicon: Icon.LOG,
            command: () => dialog.show(<TenderEmailLogDialog tender={tender}/>),
            hide: !model.id || !isChangeOrder
        },
        {
            label: i18n('change_orders'),
            faicon: Icon.PDF,
            command: () => downloadChangeOrdersPdf(getValues()),
            hide: !model.id
        },
        {
            label: i18n('construction_log') + '...',
            faicon: Icon.PDF,
            command: () => dialog.show(<ConstructionLogDialog employeeList={employees.activeUsable}
                                                              toEmail={getCustomerEmail()}
                                                              save={save}/>),
            hide: !model.id
        },
        {
            label: 'Skicka Reco-inbjudan...',
            faicon: Icon.SEND,
            command: handleSubmit(data => {
                confirmYes(() => {
                    showLoading()
                    save(data).then(_model => {
                        if (_model) {
                            ProjectService.sendRecoInvite(_model.id)
                        }
                        hideLoading()
                    })
                })
            }),
            hide: !USING_RECO || !userPersmissions.hasRole(Role.SUPERADMIN)
        },
    ].filter(item => !item.hide)

    async function onTenderChange(tenderId) {
        if (tenderId > 0) {
            showLoading()
            const tender = findById(tenderList, tenderId)

            if (getValues('name').length === 0) setValue('name', tender.name)
            setValue('address', tender.address)
            setValue('projectLeader', tender.ourReference)
            setValue('customer', tender.customer)
            setValue('customercontact', tender.customercontact)
            setValue('markup', tender.markup)
            setValue('costcenter', tender.costcenter)
            setValue('pricelist', tender.pricelist)
            setValue('timeregdebittypepriceList', tender.timeregdebittypepriceList
                .map(item => ({
                    timeregdebittype: item.timeregdebittype,
                    price: item.price
                })))
            setValue('taxReductionType', tender.taxReductionType)
            hideLoading()
        }
    }

    const downloadChangeOrderPdf = data => {
        showLoading()
        save(data).then(_model => {
            if (_model) {
                renderOverlay(<PdfViewerModal label={i18n('change_order') + ' ' + _model.number}
                                              url={'/api/projects/' + _model.id + '/change-order-pdf'}/>)
            }
            hideLoading();
        })
    }

    const downloadChangeOrdersPdf = data => {
        showLoading()
        save(data).then(_model => {
            if (_model) {
                const _id = _model.changeOrder ? _model.parent : _model.id
                renderOverlay(<PdfViewerModal label={i18n('change_order') + ' ' + _model.number}
                                              url={'/api/projects/' + _id + '/change-orders-pdf'}
                                              landscape={true}/>)
            }
            hideLoading();
        })
    }

    const downloadWorkorderPdf = data => {
        showLoading()
        save(data).then(_model => {
            if (_model) {
                renderOverlay(<PdfViewerModal label={i18n('workorder') + ' ' + _model.number}
                                              url={'/api/projects/' + _model.id + '/workorder-pdf'}/>)
                hideLoading();
            } else {
                hideLoading();
            }
        })
    }

    function onCustomerChange(_customer) {
        if (_customer) {
            setValue('markup', _customer.markup)
            setValue('standardValues.purchasePercentageMarkup', _customer.purchasePercentageMarkup)
            setValue('timeregdebittypepriceList', _customer.timeregdebittypepriceList.map(item => ({
                    price: item.price,
                    timeregdebittype: item.timeregdebittype,
                })
            ))
            setValue('pricelist', customercompanypricelistUtil.getPricelist(_customer.companypricelistList))
        }
    }

    return (
        <div className="EditForm">
            <div className="headline">
                <h1>{i18n(isChangeOrder ? 'change_order' : 'project')} {model.number}</h1>
            </div>
            <form>
                <div className="button-row">
                    <div className='left-buttons'>
                        {!disabled &&
                            <SaveButton onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>}
                        <CancelButton onClick={e => RouterUtils.goBack(navigate)}/>
                    </div>
                    <div className='divider'></div>
                    <div className='right-buttons'>
                        {
                            !isChangeOrder && betaPaymentplan &&
                            <PaymentplanButton tender={tender} handleSubmit={handleSubmit}
                                               saveProject={save} {...form}/>
                        }
                        {
                            !isChangeOrder &&
                            <GenerateRentButton tender={tender} handleSubmit={handleSubmit} saveProject={save}
                                                onAfterGenerate={_project => {
                                                    setModel(_project);
                                                    reset(_project);
                                                }}
                                                {...form}
                            />
                        }
                        {
                            isChangeOrder &&
                            <>
                                <PdfButton labelI18n='preview' onClick={e => handleSubmit(downloadChangeOrderPdf)()}
                                           disabled={isSubmitting}/>
                                <SendTenderButton changeOrder={true}
                                                  customerId={watchCustomer}
                                                  dialog={dialog}
                                                  email={getCustomerEmailByIds(common.customerList, watchCustomer, watchCustomercontact)}
                                                  ourReference={employees.findById(watchProjectLeader)}
                                                  handleSubmit={handleSubmit} save={save} reset={reset}/>
                            </>
                        }

                        {menuItems.length > 0 && <ExtraMenu itemList={menuItems}/>}
                    </div>
                </div>

                <div className='columnContainer'>
                    <div className='column'>
                        {parent && <FormItemConstant label={i18n('main_project')} value={parent.label}/>}
                        {
                            userPersmissions.hasRole(Role.TENDER_READ) && !isChangeOrder &&
                            <FormItemDropdown name='tender' label={i18n('tender')}
                                              options={tenderList}
                                              showSelect
                                              onEdit={model?.id > 0 && watchTenderId > 0 ? () => {
                                                  const data = getValues()
                                                  navigate(location.pathname, {replace: true, state: data});
                                                  navigate('/tenders/' + data.tender);
                                              } : undefined}
                                              onChange={e => onTenderChange(e.target.value)}
                                              {...form} />
                        }
                        {
                            EDITABLE_NUMBER &&
                            <FormItemText name='number' labelI18n='number' width={120} {...form}/>
                        }
                        <FormItemText name='name' label={i18n('name')} maxLength={128} required {...form}/>
                        {!isChangeOrder && <FormItemAddress name='address' label={i18n('address')} {...form}/>}
                        <FormItemEmployee name='projectLeader' labelI18n='project_leader' required {...form}/>
                    </div>
                    <div className='column'>
                        <FormItemCustomerAndContact
                            onCustomerEdit={() => {
                                const data = getValues()
                                navigate(location.pathname, {replace: true, state: data});
                                navigate('/customers/' + (data.customer ? data.customer : 0));
                            }}
                            onCustomerChange={onCustomerChange}
                            form={form}/>
                        {
                            !isChangeOrder &&
                            <FormItemTextArea name='markup' labelI18n='reference_no' maxLength={250} {...form}/>
                        }
                    </div>
                    <div className='column'>
                        <FormItemOptionalDate name='startDate' label={i18n('start_date')} {...form}/>
                        <FormItemOptionalDate name='endDate' label={i18n('end_date')} {...form}/>
                        <FormItemCostcenter {...form} />
                        <FormItemTextArea name='note' label={i18n('note')} maxLength={5000} {...form}/>
                        {!isChangeOrder && <FormItemCheckbox name='cancelled' label={i18n('cancelled')} {...form}/>}

                        {
                            isChangeOrder &&
                            <FormItemDropdown
                                name='tenderStatus' labelI18n='status'
                                options={TenderStatus.LIST.map(c => ({id: c.value, name: c.label}))}
                                width={160}
                                onChange={e => {
                                    const _status = e.target.value
                                    const _answerDate = getValues('answerDate')
                                    if (!_answerDate && [TenderStatus.ACCEPTED, TenderStatus.NOT_ACCEPTED].indexOf(_status) !== -1) {
                                        setValue('answerDate', todayDate())
                                    }
                                    setValue('cancelled', _status === TenderStatus.CANCELLED)
                                }}
                                required {...form} />
                        }
                    </div>
                </div>

                <TabView>
                    {
                        isChangeOrder &&
                        <TabPanel key='rows' header={i18n('rows') + ' (' + (watchTenderRowList?.length) + ')'}>
                            <FormItemRowGrid name='tenderRowList'
                                             articleList={common.articleList}
                                             pricelist={pricelist}
                                             isCellEditable={(field, rowData) => {
                                                 const _tr = tenderrowList?.find(item => item.id === rowData.id)
                                                 return !_tr || _tr.invoicerowtenderrowList.length === 0
                                             }}
                                             defaultRowValue={{
                                                 id: 0,
                                                 article: undefined,
                                                 onlyTotal: true,
                                                 name: '',
                                                 quantity: 0,
                                                 aprice: 0,
                                                 discount: 0,
                                                 price: 0
                                             }}
                                             housework={isHouseWork()}
                                             {...form}/>
                        </TabPanel>
                    }

                    <TabPanel key='workorder' header={i18n('workorder')}>
                        <div className='columnContainer' style={{marginTop: '20px'}}>
                            <div className='column'>
                                <PdfButton labelI18n='create_workorder'
                                           onClick={e => handleSubmit(downloadWorkorderPdf)()}
                                           disabled={isSubmitting}/>
                                <FormItemTextArea name='workorderInfo' label={i18n('description')}
                                                  maxLength={5000} {...form}/>
                            </div>
                            <div className='column'></div>
                            <div className='column'></div>
                        </div>
                    </TabPanel>
                    <TabPanel key='standard_values' header={i18n('standard_values')}>
                        <div className='columnContainer'>

                            <div className='column'>
                                {
                                    pricelistList.length > 0 &&
                                    <FormItemDropdown name='pricelist' labelI18n='pricelist'
                                                      options={pricelistList.filter(item => item.id === model?.pricelist || item.active)}
                                                      showSelect
                                                      {...form} />
                                }

                                {
                                    !watchPricelist && !HIDE_ARTICLE_PRICES &&
                                    <div className="formItem">
                                        <h3>{i18n('costplus_time')}</h3>
                                        <Controller name='timeregdebittypepriceList'
                                                    control={control}
                                                    render={({field: {ref, value, onChange}}) =>
                                                        <ProjecttimeregdebittypepriceGrid inputRef={ref}
                                                                                          value={value}
                                                                                          onChange={onChange}
                                                                                          timeregdebittypeList={common.timeregdebittypeList}
                                                        />
                                                    }/>
                                    </div>
                                }
                            </div>
                            <div className='column'>
                                <div className="formItem">
                                    <h3 style={{marginBottom: 0}}>{i18n('time_registration') + ' - ' + i18n('employees')}</h3>
                                    <FormItemCheckbox name='standardValues.empTimeregHide'
                                                      label={i18n('hide_project')}
                                                      {...form}/>
                                    <div style={{display: 'flex', gap: '20px'}}>
                                        <FormItemCheckbox name='standardValues.empTimeregFixedTimeDisabled'
                                                          label={i18n('hide_fixed_time')}
                                                          {...form}/>
                                        <FormItemCheckbox name='standardValues.empTimeregCostplusTimeDisabled'
                                                          label={i18n('hide_costplus_time')}
                                                          {...form}/>
                                    </div>
                                    <EmpTimeregEmployeeGridFormItem
                                        name='standardValues.empTimeregEmployeeList'
                                        label={'Endast personal'}
                                        {...form}/>
                                </div>
                            </div>
                            <div className='column'>
                                <FormItemNumber name='standardValues.purchasePercentageMarkup'
                                                labelI18n='purchase_percentage_markup'
                                                {...form}/>
                                {/*<FormItemNumber name='standardValues.budgetPrice' labelI18n='budget_price'*/}
                                {/*                {...form}/>*/}
                                {/*<FormItemNumber name='standardValues.purchasePrice' labelI18n='purchase_price'*/}
                                {/*                {...form}/>*/}
                            </div>
                        </div>
                    </TabPanel>

                    {
                        !isChangeOrder &&
                        <TabPanel key='rent_rows' header={i18n('rent_rows') + ' (' + (watchRentList.length) + ')'}>
                            <FormItemRowGrid name='rentList' numOfFreeRows={0} {...form}
                                             isCellEditable={(field, rowData) => !isRentInvoiced(rowData)}
                                             getCustomColumns={(rowList, fireChange) => {
                                                 return [{
                                                     field: 'type', header: i18n('type'),
                                                     body: row => RentType.getName(row.type),
                                                     afterField: 'name',
                                                     width: 80,
                                                 }]
                                             }}
                            />
                        </TabPanel>
                    }
                    {
                        !isChangeOrder &&
                        <TabPanel key='rental_basis'
                                  header={i18n('rental_basis') + ' (' + (watchRentbasisList.length) + ')'}>
                            <FormItemRowGrid name='rentbasisList'
                                             useArticleId={true}
                                             articleList={common.articleList}
                                             pricelist={pricelist}
                                             isCellEditable={(field, rowData) =>
                                                 field === 'remove'
                                                     ? !isRentBasisInvoiced(rowData) && isRentBasisRemovable(rowData)
                                                     : true}
                                             numOfFreeRows={1}
                                             defaultRowValue={{
                                                 id: 0,
                                                 onlyTotal: true,
                                                 name: '',
                                                 type: RENT_DEFAULT_STRATEGY,
                                                 quantity: 0,
                                                 aprice: 0,
                                                 discount: 0,
                                                 price: 0
                                             }}
                                             getCustomColumns={(rowList, fireChange) => {
                                                 return [{
                                                     field: 'type', header: i18n('type'),
                                                     body: row => !isFreeRow(rowList, row)
                                                         ? RentType.getName(row.type)
                                                         : '',
                                                     editor: e => isRentBasisInvoiced(e.rowData) ? RentType.getName(e.rowData.type) :
                                                         <SelectEditor event={e}
                                                                       options={RentType.values()}
                                                                       optionValue='id'
                                                                       hideNoneOption={true}
                                                                       onChange={e2 => e.editorCallback(e2?.value)}/>,
                                                     onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
                                                     afterField: 'name',
                                                     width: 80,
                                                 }]
                                             }}
                                             footer={<RentFooter name='rentbasisList' form={form}/>}
                                             {...form}/>
                        </TabPanel>
                    }
                    {
                        isHouseWork() && !isChangeOrder &&
                        <TabPanel key='house_work' header={i18n('house_work')}>
                            <div className='columnContainer'>
                                <div className='column'>
                                    <div style={{width: '100px'}}>
                                        <FormItemDropdown name='taxReductionType' labelI18n='house_work'
                                                          options={TaxReductionType.values()}
                                                          showSelect showSelectValue={null}
                                                          {...form} />
                                    </div>

                                    <FormItemText name='propertyDesignation' label={i18n('property_designation')}
                                                  maxLength={100} {...form}/>
                                    <FormItemText name='brfOrgno' label={"BRF:s orgnr"} maxLength={11}{...form}/>
                                </div>
                            </div>
                        </TabPanel>
                    }
                    <TabPanel key='forms' header={i18n('forms') + ' (' + (numOfForms) + ')'}>
                        <FormanswerGrid projectId={model?.id || 0} save={save} form={form}
                                        onLoad={e => {
                                            setNumOfForms(e.count)
                                        }}
                        />
                    </TabPanel>
                    <TabPanel key='appendices'
                              header={i18n('appendices') + ' (' + (watchAppendixList.length + (tender?.appendixList?.length || 0)) + ')'}>
                        <FormItemAppendixGrid name='appendixList' save={save}
                                              hideAttach={true}
                                              documentSupport={true}
                                              showToEmployees={true}
                                              {...form}/>

                        {
                            tender?.appendixList?.length > 0 &&
                            <div className="formItem">
                                <label>{i18n('from_tender')}</label>
                                <AppendixGrid value={tender?.appendixList}
                                              disabled={true}
                                              getValues={getValues}
                                              hideAttach={true}
                                />
                            </div>
                        }
                    </TabPanel>
                </TabView>
            </form>
            {dialog.render()}
        </div>
    );
}

export default ProjectEditForm;
