import React, {useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import ActiveFilter from "../../components/Grid/ActiveFilter";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import {match, matchActive, matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useMountEffect from "../../hooks/useMountEffect";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import StringUtils from "../../utils/StringUtil";
import LeadService from "./LeadService";

const LeadGrid = () => {
    const roles = useRoles()

    const filter = useFilter('LeadGrid', [
        {name: 'active', defaultValue: true},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([])
    const filteredList = list.filter(c => matchActive(filter, c) && match(filter, c, 'type') && matchSearch(filter, c))

    useMountEffect(async () => {
        showLoading()
        const _list = await LeadService.findAll()
        _list.forEach(item => {
            item.addressStr = StringUtils.formatAddress(item.address)
        })
        setList(_list)
        hideLoading()
    })

    const leftFilters = [
        roles.hasRole(Role.CRM_WRITE) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <label className="label">{i18n('status')}</label>
            <ActiveFilter value={filter.active} onChange={e => filter.update('active', e.value)}/>
        </div>
    ]

    const columns = [
        {field: 'number', headerI18n: 'number', width: 100},
        {field: 'name', headerI18n: 'name'},
        {field: 'regNo', headerI18n: 'reg_no', width: 130, mobile: true},
        {field: 'followUpDate', headerI18n: 'follow_up', width: 130, mobile: true},
        {field: 'addressStr', headerI18n: 'address'},
        {field: 'note', headerI18n: 'note', body: GridTemplates.stringLimit100, mobile: true},
        {field: 'active', headerI18n: 'active', width: 60, body: GridTemplates.boolean},
    ].filter(item => !item.hidden)

    return <Grid
        xlnzGrid={true}
        id='LeadGrid'
        labelI18n='leads'
        icon={Icon.LEAD}
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        paginator={true}
    />
}

export default LeadGrid;
