import React, {useContext} from 'react';
import {Controller} from "react-hook-form";
import AppContext from "../../../AppContext";
import {PdfButton} from "../../../components/Buttons/Button";
import FormItemCheckbox from "../../../components/EditForm/FormItemCheckbox";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemText from "../../../components/EditForm/FormItemText";
import SimpleEditForm from "../../../components/EditForm/SimpleEditForm";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import TabPanel from "../../../components/TabView/TabPanel";
import Role from "../../../enums/Role";
import {i18n} from "../../../I18n/I18n";
import FormanswerEditForm from "../../Formanswers/edit/FormanswerEditForm";
import FormService from "../FormService";
import FormrowGrid from "./FormrowGrid";

const FormEditForm = props => {
    const context = useContext(AppContext)

    function postLoadFunction(model) {
        model.targetCompany = model.company
    }

    function preSaveFunction(data) {
        delete data.company
        data.company = data.targetCompany
    }

    function downloadPdf(item) {
        console.log("FOOO", item)
        const newModel = {
            id: 0,
            company: context.currentCompany.id,
            form: item,
            rowList: []
        }

        renderOverlay(<FormanswerEditForm
            model={newModel}
            // onSave={onSave}
            defaultFormValues={{
                '$projectAddressString': '$projectAddressString',
                '$customercontactName': '$customercontactName'
            }}
            preview={true}
        />)


        // renderOverlay(<FormanswerEditForm model={data} onSave={onSave}/>)
    }

    return <SimpleEditForm
        writeRole={Role.SUPERADMIN}
        headlineI18n='form'
        style={{
            width: '1400px !important'
        }}
        defaultValues={{
            active: true,
            rowList: []
        }}
        loadFunction={FormService.findById}
        postLoadFunction={postLoadFunction}
        preSaveFunction={preSaveFunction}
        saveFunction={FormService.update}

        initRightButtons={form => [
            <PdfButton key='preview' labelI18n='preview' onClick={e => form.handleSubmit(downloadPdf)()}/>
        ]}

        initLeftFields={form => [
            <FormItemDropdown key='targetCompany' name='targetCompany' labelI18n='company'
                              options={context.companies}
                              showSelect={true}
                              {...form}
                              disabled={form.getValues('id') > 0}
            />,
            <FormItemText key='name' name='name' labelI18n='name' maxLength={128} required {...form}/>,
            <FormItemCheckbox key='active' name='active' labelI18n='active' {...form}/>
        ]}
        initCenterFields={form => [
            <div key='Variables' style={{fontSize: '12px'}}>
                <b>Variables:</b><br/>
                <div>$customercontactName</div>
                <div>$projectAddressString</div>
            </div>
        ]}
        initTabs={form => [
            <TabPanel key='rowList' header={i18n('rows')}>
                <Controller name="rowList"
                            control={form.control}
                            render={({field: {ref, value, onChange}}) =>
                                <FormrowGrid inputRef={ref} value={value} onChange={onChange}/>
                            }/>
            </TabPanel>
        ]}
    />
}

export default FormEditForm;
