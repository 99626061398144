import React, {useContext} from 'react';
import AppContext from "../../AppContext";
import FormItemCheckbox from "../../components/EditForm/FormItemCheckbox";
import FormItemInteger from "../../components/EditForm/FormItemInteger";
import FormItemText from "../../components/EditForm/FormItemText";
import SimpleEditForm from "../../components/EditForm/SimpleEditForm";
import Role from "../../enums/Role";
import {PreloadType} from "../../hooks/usePreload";
import TimeregabsencetypeService from "./TimeregabsencetypeService";

const TimeregabsencetypeEditForm = () => {
    const context = useContext(AppContext)

    return <SimpleEditForm
        writeRole={Role.ADMIN}
        companyId={context.currentCompany.parentId}
        headlineI18n='absence_types'
        defaultValues={{
            active: true
        }}
        loadFunction={TimeregabsencetypeService.findById}
        saveFunction={TimeregabsencetypeService.update}
        initLeftFields={form => [
            <FormItemText key='name' name='name' labelI18n='name' maxLength={128} required {...form}/>,
            <FormItemText key='salaryCode' name='salaryCode' labelI18n='salary_code' maxLength={20} {...form}/>,
            <FormItemInteger key='listOrder' name='listOrder' labelI18n='list_order' required {...form}/>,
            <FormItemCheckbox key='active' name='active' labelI18n='active' {...form}/>
        ]}
        preloadType={PreloadType.TIMEREGABSENCETYPE_LIST}
    />
}

export default TimeregabsencetypeEditForm;
