import React, {useState} from 'react';
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import {matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useMountEffect from "../../hooks/useMountEffect";
import AgreementService from "./AgreementService";

const UsersWithoutAgreementGrid = () => {
    const filter = useFilter('UsersWithoutAgreementGrid', [
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);
    const filteredList = list.filter(c => matchSearch(filter, c))

    useMountEffect(async () => {
        showLoading()
        setList(await AgreementService.findUsersWithoutAgreement())
        hideLoading()
    })

    const columns = [
        {field: 'name', headerI18n: 'name', minWidth: 150},
        {field: 'email', headerI18n: 'email', minWidth: 200},
        {field: 'ctime', headerI18n: 'created', body: GridTemplates.time, minWidth: 150},
        {field: 'lastLoginTime', header: 'Senast inloggad', body: GridTemplates.time, minWidth: 150},
    ];

    return <Grid
        forceDesktop={true}
        label='Användare utan avtal'
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
    />
}

export default UsersWithoutAgreementGrid;
