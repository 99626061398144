import {useMemo} from "react";
import Role from "../enums/Role";
import {i18n} from "../I18n/I18n";
import {findByField} from "../utils/ArrayUtil";

let ROLES
let RESTRICT_PATHS
let HIDE_ARTICLE_PRICES

export default function useUserPersmissions() {

    const result = useMemo(() => {
        return {
            set: _USER_PERMISSIONS => {
                ROLES = new Set(_USER_PERMISSIONS.roleList)
                RESTRICT_PATHS = new Set(_USER_PERMISSIONS.restrictPathList)
                HIDE_ARTICLE_PRICES = _USER_PERMISSIONS.hideArticlePrices
                // console.log("USER_PERMISSIONS updated!", _USER_PERMISSIONS)
            },

            // ROLES
            findAllRoles: () => Array.from(ROLES),
            findRoleByName: _name => findByField(Role.values(), 'name', _name),
            hasRole: role => {
                return ROLES.has(role.name)
            },
            hasRoles: roles => {
                return roles &&
                    roles.filter(role => {
                            if (!role) throw new Error("Invalid role:; " + role)
                            return ROLES.has(role.name)
                        }
                    ).length === roles.length
            },
            getRoleName: getRoleName,


            // RESTRICT_PATHS
            hasRestrictPath: restrictPath => RESTRICT_PATHS.size === 0 || RESTRICT_PATHS.has(restrictPath),


            // HIDE ARTICLE PRICES
            isHideArticlePrices: () => HIDE_ARTICLE_PRICES
        }
    }, [])

    return result
}


export function getRoleName(role) {
    const getReadLabel = typeI18n => i18n('read') + ' ' + i18n(typeI18n).toLowerCase();
    const getWriteLabel = typeI18n => i18n('edit') + ' ' + i18n(typeI18n).toLowerCase();

    switch (role) {
        case Role.ADMIN:
            return 'Admin'
        case Role.DASHBOARD_READ:
            return i18n('dashboard')
        case Role.CUSTOMER_READ:
            return getReadLabel('customers')
        case Role.CUSTOMER_WRITE:
            return getWriteLabel('customers')
        case Role.DOCUMENT_READ:
            return getReadLabel('documents')
        case Role.DOCUMENT_WRITE:
            return getWriteLabel('documents')
        case Role.SUPPLIER_READ:
            return getReadLabel('suppliers')
        case Role.SUPPLIER_WRITE:
            return getWriteLabel('suppliers')
        case Role.USERGROUP_READ:
            return getReadLabel('user_groups')
        case Role.USERGROUP_WRITE:
            return getWriteLabel('user_groups')
        case Role.USER_READ:
            return getReadLabel('users')
        case Role.USER_WRITE:
            return getWriteLabel('users')
        case Role.AGREEMENT_READ:
            return getReadLabel('agreements')
        case Role.AGREEMENT_WRITE:
            return getWriteLabel('agreements')
        case Role.ARTICLE_READ:
            return getReadLabel('articles')
        case Role.ARTICLE_WRITE:
            return getWriteLabel('articles')
        case Role.EMPLOYEE_READ:
            return getReadLabel('employees')
        case Role.EMPLOYEE_WRITE:
            return getWriteLabel('employees')
        case Role.REPORT_READ:
            return getReadLabel('reports')
        case Role.TENDER_READ:
            return getReadLabel('tenders')
        case Role.TENDER_WRITE:
            return getWriteLabel('tenders')
        case Role.PROJECT_READ:
            return getReadLabel('projects')
        case Role.PROJECT_WRITE:
            return getWriteLabel('projects')
        case Role.PROJECT_NUMBER_WRITE:
            return getWriteLabel('project_number')
        case Role.TIMEREG_READ:
            return getReadLabel('time_registration')
        case Role.TIMEREG_WRITE:
            return getWriteLabel('time_registration')
        case Role.TIMEREG_UNLOCK_DAY:
            return i18n('unlock') + ' ' + i18n('day').toLowerCase()
        case Role.TIMEREG_UNLOCK_MONTH:
            return i18n('unlock') + ' ' + i18n('month').toLowerCase()
        case Role.INVOICE_READ:
            return getReadLabel('customer_invoices')
        case Role.INVOICE_WRITE:
            return getWriteLabel('customer_invoices')
        case Role.INVOICE_UNLOCK:
            return i18n('unlock') + ' ' + i18n('customer_invoice').toLowerCase()
        case Role.SUPPLIERINVOICE_READ:
            return getReadLabel('supplier_invoices')
        case Role.SUPPLIERINVOICE_WRITE:
            return getWriteLabel('supplier_invoices')
        case Role.CRM_READ:
            return i18n('read') + ' CRM';
        case Role.CRM_WRITE:
            return i18n('edit') + ' CRM';

        default:
            return role.name
    }
}