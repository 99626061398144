import React, {useCallback, useContext, useEffect, useState} from "react";
import {useForm, useWatch} from "react-hook-form";
import AppContext from "../../AppContext";
import {CancelButton, SaveButton} from "../../components/Buttons/Button";
import FormItemDropdown from "../../components/EditForm/FormItemDropdown";
import FileUpload from "../../components/FileUpload/FileUpload";
import Grid from "../../components/Grid/Grid";
import {infoAlert} from "../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import TabPanel from "../../components/TabView/TabPanel";
import TabView from "../../components/TabView/TabView";
import XlnzDialog from "../../components/XlnzDialog/XlnzDialog";
import Role from "../../enums/Role";
import {GridTemplates} from "../../GridTemplates";
import useEmployees from "../../hooks/useEmployees";
import usePreload from "../../hooks/usePreload";
import useUserPersmissions from "../../hooks/useUserPersmissions";
import {i18n} from "../../I18n/I18n";
import {filterActive, findByField, sortByField} from "../../utils/ArrayUtil";
import NetUtils from "../../utils/NetUtils";
import {deepCopy, deepFreezeObject} from "../../utils/ObjectUtils";
import useEmployeeDefaultValues from "./edit/useEmployeeDefaultValues";
import EmployeeService from "./EmployeeService";

export default function ImportEmployeesFromExcelDialog(props) {
    const {
        onHide,
        onDone
    } = props

    const context = useContext(AppContext)
    const userPersmissions = useUserPersmissions()

    const preload = usePreload()
    const subcontractorList = sortByField(preload.getSupplierList().filter(item => item.subcontractor), 'name')

    const employees = useEmployees()
    const employeeDefaultValues = deepFreezeObject(useEmployeeDefaultValues())

    const [list, setList] = useState()


    const excelColumnList = Array.from({length: 26}, (_, i) => String.fromCharCode(65 + i))
        .map(item => ({
            id: item,
            name: item
        }))


    const form = {
        ...useForm({
            defaultValues: {}
        }), disabled: !userPersmissions.hasRole(Role.EMPLOYEE_WRITE)
    }
    const {handleSubmit, formState: {isSubmitting}, getValues, control, setValue} = form

    const watchColumnFirstName = useWatch({control, name: 'columnFirstName'})
    const watchColumnLastName = useWatch({control, name: 'columnLastName'})
    const watchColumnPhone0 = useWatch({control, name: 'columnPhone0'})
    const watchSubcontractor = Number(useWatch({control, name: 'subcontractor'}))

    const uploadAndExtractExcel = async (data, files) => {
        const _model = await NetUtils.doPostWithFiles('/api/excel/extract-data', {}, files)
        if (_model) {
            setList(_model)
        }
        return _model
    }

    const getFieldName = useCallback((watchColumn, defaultName) => {
        if (watchColumn) {
            const excelColumn = findByField(excelColumnList, 'id', watchColumn);
            const index = excelColumnList.indexOf(excelColumn);
            return 'columnData' + index;
        }
        return defaultName;
    }, [excelColumnList])

    const getFirstNameFieldName = useCallback(() =>
            getFieldName(watchColumnFirstName, 'firstNameUnknown')
        , [getFieldName, watchColumnFirstName])

    const getLastNameFieldName = useCallback(() =>
            getFieldName(watchColumnLastName, 'lastNameUnknown')
        , [getFieldName, watchColumnLastName])

    const getPhone0FieldName = useCallback(() =>
            getFieldName(watchColumnPhone0, 'phone0Unknown')
        , [getFieldName, watchColumnPhone0])

    const getRowObj = useCallback(item => ({
        firstName: item[getFirstNameFieldName()],
        lastName: item[getLastNameFieldName()],
        phone0: item[getPhone0FieldName()]
    }), [getFirstNameFieldName, getLastNameFieldName, getPhone0FieldName])


    const employeeList = employees.all.filter(item => {
        if (watchSubcontractor) {
            return item.subcontractor === watchSubcontractor
        } else {
            return false
        }
    })

    const filteredList = list?.slice(1)

    filteredList?.forEach(item => {
        const rowObj = getRowObj(item)
        employeeList.forEach(employee => {
            if (
                employee.firstName === rowObj.firstName &&
                employee.lastName === rowObj.lastName
            ) {
                item.exists = true
            }
        })

        // console.log("rowObj", rowObj)
    })


    useEffect(() => {
        if (list?.length > 0) {
            list.forEach(item => {
                item.columnData.forEach((columnData, i) => {
                    item['columnData' + i] = columnData
                })
            })

            const firstRow = list[0]
            firstRow.columnData.forEach((columnData, i) => {
                if (columnData.toLowerCase().indexOf('first') !== -1) {
                    setValue('columnFirstName', excelColumnList[i].id)
                }
                if (columnData.toLowerCase().indexOf('last') !== -1) {
                    setValue('columnLastName', excelColumnList[i].id)
                }
                if (columnData.toLowerCase().indexOf('phone') !== -1) {
                    setValue('columnPhone0', excelColumnList[i].id)
                }
            })
        }
    }, [list, employees, watchSubcontractor, setValue, excelColumnList, filteredList, getRowObj]);

    const onSubmit = async () => {
        showLoading()

        let numSaved = 0
        for (const item of filteredList) {
            const rowObj = getRowObj(item)

            if (!item.exists && rowObj.firstName?.length > 0 && rowObj.lastName?.length > 0) {
                const model = deepCopy(employeeDefaultValues)
                model.company = context.currentCompany.parentId || context.currentCompany.id
                model.firstName = rowObj.firstName
                model.lastName = rowObj.lastName
                model.phone0 = rowObj.phone0 || ''
                model.hired = watchSubcontractor > 0
                model.subcontractor = watchSubcontractor

                console.log("SAVE MODEL", model)
                await EmployeeService.update(model)
                numSaved++
            }
        }

        infoAlert(i18n('quantity') + ': ' + numSaved)

        onHide()
        await onDone()
        hideLoading()
    }


    const columns = [
        {field: getFirstNameFieldName(), headerI18n: 'first_name'},
        {field: getLastNameFieldName(), headerI18n: 'last_name'},
        {field: getPhone0FieldName(), headerI18n: 'phone'},
        {field: 'exists', headerI18n: 'imported', body: GridTemplates.boolean}
    ]


    return <XlnzDialog headerI18n='import_from_excel'
                       onHide={props.onHide}
                       buttons={() => [
                           <SaveButton key='save' onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>,
                           <CancelButton key='cancel' onClick={e => props.onHide()}/>
                       ]}
    >
        <div className='EditForm' style={{width: '900px', minHeight: '500px', margin: 0}}>
            <form>
                {
                    !filteredList &&
                    <div className="formItem">
                        <FileUpload multiple={false} save={uploadAndExtractExcel}
                                    accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                    {...form}/>
                    </div>
                }
                {
                    filteredList &&
                    <div>
                        <div className='columnContainer'>
                            <div className='column'>
                                <FormItemDropdown key='columnFirstName' name='columnFirstName'
                                                  label={i18n('column') + ': ' + i18n('first_name')}
                                                  options={excelColumnList}
                                                  filter={false}
                                                  showSelect={true}
                                                  {...form} />
                                <FormItemDropdown key='columnLastName' name='columnLastName'
                                                  label={i18n('column') + ': ' + i18n('last_name')}
                                                  options={excelColumnList}
                                                  filter={false}
                                                  showSelect={true}
                                                  {...form} />
                                <FormItemDropdown key='columnPhone0' name='columnPhone0'
                                                  label={i18n('column') + ': ' + i18n('mobile')}
                                                  options={excelColumnList}
                                                  filter={false}
                                                  showSelect={true}
                                                  {...form} />
                            </div>
                            <div className='column'>
                                <FormItemDropdown key='subcontractor' name='subcontractor' labelI18n='subcontractor'
                                                  options={filterActive(subcontractorList, getValues('subcontractor'))}
                                                  showSelect={true}
                                                  {...form} />
                            </div>

                            <div className='column'>
                            </div>
                        </div>
                        {
                            watchSubcontractor > 0 &&
                            <TabView>
                                <TabPanel key='price' header={i18n('rows')}>
                                    <div className="formItem">
                                        <Grid
                                            xlnzGrid={true}
                                            columns={columns}
                                            value={filteredList}
                                            heightOffset={800}
                                            onRowSelect={() => {
                                            }}
                                        />
                                    </div>
                                </TabPanel>
                            </TabView>
                        }
                    </div>
                }
            </form>
        </div>

    </XlnzDialog>
}