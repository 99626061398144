import React, {useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import Checkbox from "../../components/EditForm/Checkbox";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Language from "../../enums/Language";
import Role from "../../enums/Role";
import {matchSearch} from "../../functions/match";
import useMountEffect from "../../hooks/useMountEffect";
import useRoles from "../../hooks/useRoles";
import I18nService from "./I18nService";

const I18nGrid = () => {
    const roles = useRoles()

    const filter = useFilter('I18nGrid', [
        {name: 'onlyUntranslated', defaultValue: false},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);

    function matchLanguage(item) {
        if (filter.language) {
            return item.id.startsWith(filter.language)
        } else {
            return true
        }
    }

    function matchOnlyUntranslated(item) {
        if (filter.onlyUntranslated) {
            return !item.valueSv || !item.valueEn
        } else {
            return true
        }
    }

    const filteredList = list.filter(item => matchLanguage(item) && matchOnlyUntranslated(item) && matchSearch(filter, item))

    useMountEffect(async () => {
        showLoading()

        const i18nList = await I18nService.findAll()

        function getValue(language, id) {
            return i18nList.find(item => item.id === language.id + '_' + id)?.value
        }

        const set = new Set(i18nList.map(item => item.id.substring(3)))
        setList(Array.from(set.values()).map(id => ({
                id: id,
                valueEn: getValue(Language.ENGLISH, id),
                valueSv: getValue(Language.SWEDISH, id)
            }))
        )
        hideLoading()
    }, [])

    const leftFilters = [
        roles.hasRole(Role.ADMIN) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <label className="label">Only Untranslated</label>
            <Checkbox
                checked={filter.onlyUntranslated}
                onChange={e => filter.update('onlyUntranslated', e.target.checked)}/>
        </div>
    ]

    const columns = [
        {field: 'id', header: 'ID', width: 300},
        {field: 'valueSv', header: 'Swedish'},
        {field: 'valueEn', header: 'English'},
    ];

    return <Grid
        xlnzGrid={true}
        id='I18nGrid'
        label='I18n'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
    />
}

export default I18nGrid;
