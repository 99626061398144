import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Icon from "../../enums/Icon";

export default function HyrletDisconnectedView() {


    return <div style={{padding: '50px'}}>
        <FontAwesomeIcon icon={Icon.DISCONNECTED} style={{width: '100px', height: '100px', color: 'gray'}}/>
        <div>
            Det gick inte att ansluta till Hyrlet.<br/>
            Kontakta supporten för att åtgärda problemet.
        </div>
    </div>
}