import React, {useCallback, useEffect, useRef, useState} from "react";
import useWindowSize from "../../hooks/useWindowSize";
import {findByField} from "../../utils/ArrayUtil";
import {isNumeric, round} from "../../utils/NumberUtils";
import {getValueByField} from "../../utils/ObjectUtils";
import ContextMenu from "../ContextMenu/ContextMenu";
import renderOverlay, {removeOverlay} from "../Overlay/renderOverlay";
import useXlnzGridOutsideClickEvent from "./useXlnzGridOutsideClickEvent";
import './XlnzGrid.scss'
import XlnzGridContent from "./XlnzGridContent";
import XlnzGridCustomFooter from "./XlnzGridCustomFooter";
import XlnzGridFooter from "./XlnzGridFooter";
import XlnzGridHeader from "./XlnzGridHeader";

export default function XlnzGrid(props) {
    const {
        id,
        disabled,
        columns,
        uberColumns = [],
        onRowReorder,
        onRowSelect,
        width,
        scrollHeight,
        sortable = true,
        onSortFieldChange,
        onSortOrderChange,
        contextMenuOptions,
        hideHeader = false,
        hideFooter = false,
        customFooter,
        selection,
        onSelectionChange,

        rowClassName,
        cellClassName
    } = props

    const [WINDOW_WIDTH] = useWindowSize()
    const [gridWidth, setGridWidth] = useState(WINDOW_WIDTH)

    const getSessionState = useCallback(field => {
        if (id) return sessionStorage.getItem(id + '-' + field)
    }, [id])

    const setSessionState = useCallback((field, value) => {
        if (id) sessionStorage.setItem(id + '-' + field, value)
    }, [id])

    const [sortField, setSortField] = useState(getSessionState('sortField'))
    const [sortOrder, setSortOrder] = useState(getSessionState('sortOrder'))

    const [contextMenuId, setContextMenuId] = useState()
    const [contextMenuProps, setContextMenuProps] = useState()

    const ref = useRef()

    const value = getSortedValue(props.value)


    useEffect(() => {
        // if (Env.isDev() && !id) console.log("Grid: No ID present")
        if (id && value?.length > 0) {
            const gridElement = document.querySelector('.XlnzReactGrid');
            if (gridElement) {
                const scrollPosition = getSessionState('scrollPosition');
                if (scrollPosition !== null) gridElement.scrollTop = scrollPosition


                gridElement.addEventListener('scroll', () => setSessionState('scrollPosition', gridElement.scrollTop))
            }
        }
    }, [id, value, getSessionState, setSessionState]);

    function getSortedValue(value) {
        if (!sortField) return value
        const _value = [...value]

        const sortFunction = findByField(columns, 'field', sortField)?.sortFunction
        if (sortFunction) {
            _value.sort(sortFunction)
        } else {
            _value.sort((a, b) => {
                    const aVal = getValueByField(a, sortField, '')
                    const bVal = getValueByField(b, sortField, '')

                    if (isNumeric(aVal) && isNumeric(bVal)) {
                        const aValNum = Number(aVal)
                        const bValNum = Number(bVal)
                        return aValNum > bValNum ? 1 : bValNum > aValNum ? -1 : 0
                    } else {
                        return aVal > bVal ? 1 : bVal > aVal ? -1 : 0
                    }
                }
            )
        }

        if (sortOrder < 0) _value.reverse()
        return _value
    }

    function getColumnWidth(col) {
        // if(!col) return 0
        if (!col) console.log("col", col, columns)

        if (col.width) return col.width + 'px'

        const fixedWidths = columns
            .filter(col => col.width > 0)
            .reduce((sum, col) => sum + col.width, 0)

        const numOfUnwidthed = columns.filter(col => !col.width).length
        const scrollWidth = scrollHeight ? 20 : 2 //Donno why 2?? Maybe because of the red test border.

        const widthToUse = width ? width : gridWidth

        return round((widthToUse - fixedWidths - scrollWidth) / numOfUnwidthed, 2) + 'px'
    }


    useEffect(() => {
        setGridWidth(ref.current.offsetWidth)
    }, [WINDOW_WIDTH]);

    useXlnzGridOutsideClickEvent(() => {
        // This is a hack. We have no width of thew component until it is rendered. Check this with the new tab navigator. //JW 2023-09-26
        setGridWidth(ref.current.offsetWidth)
        setContextMenuProps(undefined)
    })

    function hideContextMenu() {
        if (contextMenuId) {
            removeOverlay(contextMenuId)
            setContextMenuId(undefined)
        }
    }

    function _onRowSelect(e) {
        hideContextMenu()
        onRowSelect(e)
    }

    useEffect(() => {
        hideContextMenu()
        if (contextMenuProps) {
            const id = renderOverlay(<ContextMenu options={contextMenuOptions} {...contextMenuProps} />)
            setContextMenuId(id)
        }
        // eslint-disable-next-line
    }, [contextMenuProps]);

    return <div ref={ref} className='XlnzReactGrid' style={{
        maxHeight: scrollHeight,
        // borderTop: Env.isDev() ? '1px solid green' : undefined
    }}>
        {!width && !gridWidth && <div>No gridWidth</div>}

        {
            (width || gridWidth) > 0 &&
            <>
                {/*{contextMenuProps && <ContextMenu options={contextMenuOptions} {...contextMenuProps} />}*/}

                {
                    !hideHeader &&
                    <XlnzGridHeader
                        disabled={disabled}
                        uberColumns={uberColumns}
                        columns={columns}
                        data={value}
                        getColumnWidth={getColumnWidth}

                        selection={selection}
                        onSelectionChange={onSelectionChange}

                        sortable={sortable}
                        sortField={sortField}
                        onSortFieldChange={_sortfield => {
                            setSortField(_sortfield)
                            setSessionState('sortField', _sortfield)
                            if (onSortFieldChange) onSortFieldChange(_sortfield)
                        }}
                        sortOrder={sortOrder}
                        onSortOrderChange={_sortOrder => {
                            setSortOrder(_sortOrder)
                            setSessionState('sortOrder', _sortOrder)
                            if (onSortOrderChange) onSortOrderChange(_sortOrder)
                        }}
                    />
                }


                <XlnzGridContent
                    disabled={disabled}
                    columns={columns}
                    data={value}
                    getColumnWidth={getColumnWidth}

                    selection={selection}
                    onSelectionChange={onSelectionChange}
                    onRowSelect={_onRowSelect}

                    onRowReorder={onRowReorder}

                    contextMenuOptions={contextMenuOptions}
                    setContextMenuProps={setContextMenuProps}

                    rowClassName={rowClassName}
                    cellClassName={cellClassName}
                />

                {!hideFooter && <XlnzGridFooter columns={columns} data={value} getColumnWidth={getColumnWidth}/>}
                {customFooter && <XlnzGridCustomFooter columns={columns} data={value} getColumnWidth={getColumnWidth}
                                                       customFooter={customFooter}/>}
            </>
        }
    </div>
}
