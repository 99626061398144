import React from "react";
import FormItemTextArea from "../../../../components/EditForm/FormItemTextArea";
import {FORMROW_KEY_PREFIX} from "../FormanswerEditForm";

export default function FormtypeTextarea(props) {
    const model = props.model
    return <FormItemTextArea label={model.name}
                             name={FORMROW_KEY_PREFIX + model.id}
                             placeholder={model.example}
                             {...props}
    />
}