import {i18n} from "../I18n/I18n";
import {sortByField} from "../utils/ArrayUtil";

export default class InvoiceSendMethod {
    static get EMAIL() {
        return Object.freeze({id: 'EMAIL', name: i18n('email')});
    }

    static get MAIL() {
        return Object.freeze({id: 'MAIL', name: i18n('mail')});
    }

    static get EINVOICE() {
        return Object.freeze({id: 'EINVOICE', name: i18n('einvoice')});
    }

    static values() {
        return sortByField([
            {id: this.EMAIL.id, name: this.EMAIL.name},
            {id: this.MAIL.id, name: this.MAIL.name},
            {id: this.EINVOICE.id, name: this.EINVOICE.name},
        ], 'name')
    }

    static findById(id) {
        return this.values().filter(item => item.id === id)[0]
    }
}