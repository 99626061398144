import React, {useState} from "react";
import {useForm, useWatch} from "react-hook-form";
import {CancelButton, MainButton} from "../../../../components/Buttons/Button";
import FormItemCheckbox from "../../../../components/EditForm/FormItemCheckbox";
import FormItemOptionalDate from "../../../../components/EditForm/FormItemOptionalDate";
import {useFilter} from "../../../../components/Grid/filter";
import {FooterAggregate} from "../../../../components/Grid/getFooterGroup";
import Grid from "../../../../components/Grid/Grid";
import XlnzDialog from "../../../../components/XlnzDialog/XlnzDialog";
import RentType from "../../../../enums/RentType";
import {GridTemplates} from "../../../../GridTemplates";
import {i18n} from "../../../../I18n/I18n";
import {contains} from "../../../../utils/ArrayUtil";
import {isAfter, isBefore} from "../../../../utils/DateUtils";

export default function FetchProjectrentsDialog(props) {
    const filter = useFilter('FetchProjectrentsDialog', [
        {name: 'startDate', defaultValue: undefined},
        {name: 'endDate', defaultValue: undefined},
        {name: 'showTitle', defaultValue: true},
        {name: 'separateDates', defaultValue: false}
    ])

    const {
        onSave
    } = props
    const [projectrentList, setProjectrentList] = useState(props.projectrentList)
    const [selection, setSelection] = useState([])

    const defaultValues = {
        startDate: filter.startDate,
        endDate: filter.endDate,
        showTitle: filter.showTitle,
        separateDates: filter.separateDates,
    }

    const form = {...useForm({defaultValues: defaultValues})}
    const {handleSubmit, formState: {isSubmitting}, getValues, control} = form

    const watchStartDate = useWatch({control, name: 'startDate'})
    const watchEndDate = useWatch({control, name: 'endDate'})

    const filteredList = projectrentList.filter(item => {
        if (watchStartDate && isBefore(item.date, watchStartDate)) {
            return false;
        }
        if (watchEndDate && isAfter(item.date, watchEndDate)) {
            return false;
        }
        return item
    })

    const onSubmit = () => {
        const data = getValues()
        filter.update('showTitle', data.showTitle)
        filter.update('separateDates', data.separateDates)

        const _selection = selection.filter(item => contains(filteredList, item))

        onSave(_selection, {
            showTitle: data.showTitle,
            separateDates: data.separateDates
        })
    }

    // const onQuantityToInvoice = list => {
    //     setProjectrentList(list)
    // }

    const columns = [{field: 'name', headerI18n: 'rent'},
        {
            field: 'type', header: i18n('type'),
            body: row => RentType.getName(row.type),
            width: 80,
        },
        {
            field: 'quantity',
            headerI18n: 'quantity',
            body: GridTemplates.numberTwoDecimalsIfNeeded,
            align: 'right',
            width: 100
        }, {
            field: 'aprice', headerI18n: 'aprice', body: GridTemplates.currency, align: 'right', width: 100
        }, {
            field: 'discount', headerI18n: 'discount', body: GridTemplates.percentTimes100, align: 'right', width: 50
        }, {
            field: 'price',
            headerI18n: 'price',
            body: GridTemplates.currencyTwoDecimals,
            align: 'right',
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
            width: 100
        },
        // {
        //     field: 'leftToInvoice',
        //     headerI18n: 'left_to_invoice',
        //     body: GridTemplates.currencyTwoDecimals,
        //     align: 'right',
        //     footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
        //     width: 120
        // },
        // {
        //     field: 'quantityToInvoice',
        //     headerI18n: 'quantity_to_invoice',
        //     align: 'right',
        //     body: GridTemplates.numberTwoDecimals,
        //     editor: e => <NumberGridInput editorEvent={e}/>,
        //     onCellEditComplete: e => {
        //         let newValue = round(e.newValue, 2)
        //         const row = e.rowData
        //
        //         if (newValue > row.quantity) {
        //             newValue = row.quantity
        //         }
        //
        //         if (row.quantityToInvoice > row.leftToInvoiceQuantity) {
        //             row.quantityToInvoice = row.leftToInvoiceQuantity
        //         }
        //         onRowChange(e, newValue, onQuantityToInvoice)
        //
        //         // Check the box
        //         if (newValue !== 0 && notContains(selection, row)) {
        //             const _selection = [...selection]
        //             _selection.push(row)
        //             setSelection(_selection)
        //         }
        //     },
        //     width: 120
        // },
        // {
        //     field: 'toInvoice', headerI18n: 'to_invoice', body: row => {
        //         return formatCurrency(row.quantityToInvoice * row.aprice * getDiscountFactor(row.discount), 2)
        //     }, align: 'right', footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS, width: 120
        // },
        {field: 'selection', selectionMode: 'multiple', width: 40},
    ]


    function onSelectionChange(e) {
        if (e.row) {
            e.row.quantityToInvoice = e.checked ? e.row.leftToInvoiceQuantity : 0
        } else {
            const _projectrentList = [...projectrentList]
            _projectrentList.forEach(row => {
                row.quantityToInvoice = e.checked ? row.leftToInvoiceQuantity : 0
            })
            setProjectrentList(_projectrentList)
        }
        setSelection(e.value)
    }

    return <XlnzDialog headerI18n='rent'
                       onHide={props.onHide}
                       leftButtons={() => [
                           <FormItemCheckbox key='showTitle' name='showTitle' labelI18n='show_title' {...form}/>,
                           <FormItemCheckbox key='separateDates' name='separateDates'
                                             labelI18n='separate_dates' {...form}/>
                       ]}
                       buttons={() => [
                           <MainButton key='save' labelI18n='do_invoice' onClick={e => {
                               handleSubmit(onSubmit)()
                               props.onHide()
                           }} disabled={isSubmitting}/>,
                           <CancelButton key='cancel' onClick={e => props.onHide()}/>
                       ]}
    >
        <div key='FetchProjectRentsGrid' style={{width: '900px'}}>
            <div className='button-row' style={{paddingBottom: '20px', display: 'flex', gap: '20px'}}>
                <FormItemOptionalDate name='startDate' labelI18n='start_date'
                                      onChange={_date => {
                                          if (_date) filter.update('startDate', _date)
                                          if (!_date) filter.remove('startDate')
                                      }}
                                      {...form}/>
                <FormItemOptionalDate name='endDate' labelI18n='end_date'
                                      onChange={_date => {
                                          if (_date) filter.update('endDate', _date)
                                          if (!_date) filter.remove('endDate')
                                      }}
                                      {...form}/>
            </div>
            <Grid xlnzGrid={true}
                  forceDesktop={true}
                  columns={columns}
                  value={filteredList}
                  heightOffset={370}
                  selection={selection}
                  onSelectionChange={onSelectionChange}
                  rowClassName={e => ({'bold': e.headline})}
            />
        </div>
    </XlnzDialog>
}