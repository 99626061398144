import React, {useEffect} from 'react';
import {useForm, useWatch} from "react-hook-form";
import Unit from "../../enums/Unit";
import {i18n} from "../../I18n/I18n";
import LocalStorageUtils from "../../utils/LocalStorageUtils";
import {isNumeric, round} from "../../utils/NumberUtils";
import {CancelButton, SaveButton} from "../Buttons/Button";
import XlnzDialog from "../XlnzDialog/XlnzDialog";
import FormItemInteger from "./FormItemInteger";
import FormItemUnit from "./FormItemUnit";

const Calculator = props => {
    const {
        onSave,
        onHide
    } = props

    const defaultValues = JSON.parse(LocalStorageUtils.getItem('calculator'))

    const form = {
        ...useForm({
            defaultValues: {
                inUnit: defaultValues?.inUnit ? defaultValues.inUnit : Unit.SQUARE_METER.id,
                inQuantity: defaultValues?.inQuantity ? defaultValues.inQuantity : '',
                inAprice: defaultValues?.inAprice ? defaultValues.inAprice : '',
                inShare: '100',
                inPrice: '',
                outUnit: defaultValues?.outUnit ? defaultValues.outUnit : Unit.HOUR_WORK.id,
                outAprice: defaultValues?.outAprice ? defaultValues.outAprice : '',
                outPrice: ''
            }
        })
    }
    const {handleSubmit, formState: {isSubmitting}, control, setValue} = form

    const watchInUnit = useWatch({control, name: 'inUnit'})
    const watchInQuantity = useWatch({control, name: 'inQuantity'})
    const watchInShare = useWatch({control, name: 'inShare'})
    const watchInShareProcent = round(watchInShare / 100, 2)

    const watchInAprice = useWatch({control, name: 'inAprice'})

    const inUnit = Unit.findById(watchInUnit)
    const inPrice = watchInQuantity * watchInShareProcent * watchInAprice

    useEffect(() => {
        setValue('inPrice', inPrice)
    }, [setValue, inPrice])


    const watchOutUnit = useWatch({control, name: 'outUnit'})
    const watchOutAprice = useWatch({control, name: 'outAprice'})

    const outUnit = Unit.findById(watchOutUnit)
    const outQuantity = round(inPrice / watchOutAprice, 2)

    useEffect(() => {
        setValue('outQuantity', isNumeric(outQuantity) ? outQuantity : '')
    }, [setValue, outQuantity])
    const outPrice = round(outQuantity * watchOutAprice, 2)

    useEffect(() => {
        setValue('outPrice', isNumeric(outPrice) ? outPrice : '')
    }, [setValue, outPrice])

    const onSubmit = data => {
        LocalStorageUtils.setItem('calculator', JSON.stringify(data))
        onSave(data)
        onHide()
    }


    return <div>
        <XlnzDialog headerI18n='calculator'
                    onHide={onHide}
                    buttons={() => [
                        <SaveButton key='save' disabled={isSubmitting} onClick={handleSubmit(onSubmit)}/>,
                        <CancelButton key='cancel' onClick={e => onHide()}/>
                    ]}
        >

            <div className="EditForm" style={{padding: '0 20px', width: '400'}}>

                <div style={{display: 'flex'}}>
                    <div style={{paddingRight: '50px'}}>
                        <h2>{i18n('input_value')}</h2>
                        <FormItemUnit name='inUnit' labelI18n='unit' {...form} />
                        <FormItemInteger name='inQuantity' labelI18n='quantity' required {...form}/>
                        <FormItemInteger name='inShare' label={i18n('share') + ' %'} required {...form}/>
                        <FormItemInteger name='inAprice' label={i18n('aprice') + '/' + inUnit?.symbol}
                                         required {...form}/>
                        <FormItemInteger name='inPrice' label={i18n('price')} disabled={true} {...form}/>
                    </div>
                    <div>
                        <h2>{i18n('target_value')}</h2>
                        <FormItemUnit name='outUnit' labelI18n='unit' {...form} />
                        <FormItemInteger name='outQuantity' label={i18n('quantity')} disabled={true} {...form}/>
                        <FormItemInteger name='outAprice' label={i18n('aprice') + '/' + outUnit.symbol}
                                         required {...form}/>
                        <FormItemInteger name='outPrice' label={i18n('price')} disabled={true} {...form}/>
                    </div>
                </div>
            </div>
        </XlnzDialog>
    </div>
}
export default Calculator;

