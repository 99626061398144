import Role from "../../enums/Role";
import I18nEditForm from "./I18nEditForm";
import I18nGrid from "./I18nGrid";

const I18nsIndex = {
    routeProps: {
        path: '/i18ns',
        exact: true,
        element: <I18nGrid/>,
    },
    name: 'I18n',
    subModules: [{
        routeProps: {
            path: '/i18ns/:id',
            exact: true,
            element: <I18nEditForm/>,
        }
    }],
    roles: [Role.SUPERADMIN]
};
export default I18nsIndex;