import React, {useMemo} from 'react';
import Grid from "../../../components/Grid/Grid";
import NumberGridInput from "../../../components/Grid/NumberGridInput";
import {GridTemplates} from "../../../GridTemplates";
import usePreload from "../../../hooks/usePreload";
import {i18n} from "../../../I18n/I18n";
import ArrayUtil, {filterActive, findByField} from "../../../utils/ArrayUtil";
import {deepCopy} from "../../../utils/ObjectUtils";

const CustomertimeregdebittypepriceGrid = props => {
    const {
        value,
        disabled
    } = props

    const preload = usePreload()
    const timeregdebittypeList = useMemo(() => filterActive(preload.getTimeregdebittypeList()), [preload])

    const _timeregdebittypeList = deepCopy(timeregdebittypeList)
    _timeregdebittypeList.forEach(item => {
        const foo = ArrayUtil.findByField(value, 'timeregdebittype', item.id)
        item.price = foo?.price || 0
    })

    function onPriceChange(e) {
        const _value = [...value]

        const timeregdebittype = e.rowData.id
        const price = e.newValue

        const oldValue = findByField(_value, 'timeregdebittype', timeregdebittype)
        if (oldValue) {
            oldValue.price = price
        } else {
            _value.push({
                timeregdebittype: timeregdebittype,
                price: price
            })
        }

        props.onChange(_value);
    }

    const columns = [
        {field: 'name', header: i18n('debit_type'),},
        {
            field: 'price', header: i18n('price'),
            body: GridTemplates.currencyTwoDecimals,
            editor: e => <NumberGridInput editorEvent={e}/>,
            // onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            onCellEditComplete: onPriceChange,
            style: {width: '100px'},
            align: 'right'
        },
    ]

    return <div style={{width: '400px'}}>
        <Grid columns={columns}
              value={_timeregdebittypeList}
              disabled={disabled}
              footerColumnGroup={<div/>}
        />
    </div>
}
export default CustomertimeregdebittypepriceGrid;
