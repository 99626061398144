export default function FormtypeInfo({model}) {
    return <div style={styles}>
        {model.name}
    </div>
}

const styles = {
    fontStyle: 'italic',
    marginTop: '5px',
    marginBottom: '10px',
    fontSize: '12px',
    color: '#666'
}