import React from "react";
import {Controller} from "react-hook-form";
import Checkbox from "./Checkbox";

export default function CheckboxInput(props) {
    const {
        control,
        disabled,
        name,
        onBlur,
        label,
        labelStyle
    } = props

    if (!name) throw new Error('Property "name" is missing.')

    return <Controller
        name={name}
        control={control}
        render={({field: {ref, value, onChange}}) =>
            <Checkbox checked={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={disabled}
                      label={label}
                      labelStyle={labelStyle}/>
        }/>
}