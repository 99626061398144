import Company from "../../enums/Company";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import AgreementGrid from "./AgreementGrid";
import AgreementInvoiceOverviewGrid from "./AgreementInvoiceOverviewGrid";
import AllUsersGrid from "./AllUsersGrid";
import AgreementEditForm from "./edit/AgreementEditForm";
import AgreementUserEditForm from "./edit/AgreementUserEditForm";
import UsersWithoutAgreementGrid from "./UsersWithoutAgreementGrid";

const InvoicesIndex = {
    routeProps: {
        path: '/agreements',
        exact: true,
        items: [
            {
                nameKey: 'all_agreements',
                path: '/agreements/list',
                exact: true,
                element: <AgreementGrid/>,
                onRowSelectPath: '/agreements'
            },
            {
                nameKey: 'all_users',
                path: '/agreements/users',
                exact: true,
                element: <AllUsersGrid/>,
                onRowSelectPath: '/agreements/user'
            },
            {
                nameKey: 'invoice_overview',
                path: '/agreements/invoice-overview',
                exact: true,
                element: <AgreementInvoiceOverviewGrid/>,
                onRowSelectPath: '/agreements'
            },
            {
                name: 'Användare utan avtal',
                path: '/agreements/users-without-agreement',
                exact: true,
                element: <UsersWithoutAgreementGrid/>,
            },
        ]
    },
    nameKey: 'agreements',
    icon: Icon.AGREEMENT,
    showHeadline: true,
    subModules: [
        {
            routeProps: {
                path: '/agreements/:id',
                exact: true,
                element: <AgreementEditForm/>,
            }
        },
        {
            routeProps: {
                path: '/agreements/user/:id',
                exact: true,
                element: <AgreementUserEditForm/>,
            }
        },
        {
            routeProps: {
                path: '/agreements/users-without-agreement/:id',
                exact: true,
                element: <AgreementUserEditForm/>,
            }
        },
    ],
    isAccessable: (context) => context.currentCompany.id === Company.XLNZ_TECH,
    roles: [Role.AGREEMENT_READ]
};
export default InvoicesIndex;