import React, {useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import YearFilter from "../../components/filters/YearFilter";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Role from "../../enums/Role";
import {match, matchActive, matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useMountEffect from "../../hooks/useMountEffect";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import {todayYear} from "../../utils/DateUtils";
import NewsletterService from "./NewsletterService";
import NewsletterStatus from "./NewsletterStatus";

const NewsletterGrid = () => {
    const roles = useRoles()

    const filter = useFilter('NewsletterGrid', [
        {name: 'year', defaultValue: todayYear()},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([])
    const filteredList = list.filter(c => matchActive(filter, c) && match(filter, c, 'type') && matchSearch(filter, c))

    useMountEffect(async () => {
        showLoading()
        const _list = await NewsletterService.findAll()
        _list.forEach(item => {
            item.statusName = NewsletterStatus.getName(item.status)
        })
        setList(_list.reverse())
        hideLoading()
    })

    const leftFilters = [
        roles.hasRole(Role.CRM_WRITE) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <label className="label">{i18n('year')}</label>
            <YearFilter value={filter.year} onChange={e => filter.update('year', e.value)}/>
        </div>
    ]

    const columns = [
        {field: 'ctime', headerI18n: 'created', width: 140, body: GridTemplates.time, mobileWithHeader: true},
        {field: 'name', headerI18n: 'name'},
        {field: 'statusName', headerI18n: 'status', width: 140},
        {field: 'sentTime', headerI18n: 'sent', width: 140, body: GridTemplates.time, mobileWithHeader: true},
        {
            field: 'openingPercentage',
            header: 'Läst',
            width: 140,
            align: 'right',
            body: GridTemplates.percentTimes100,
            mobileWithHeader: true,
        }
    ]

    return <Grid
        xlnzGrid={true}
        labelI18n='newsletters'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        paginator={true}
    />
}

export default NewsletterGrid;
