import React, {useContext, useMemo, useState} from 'react';
import {useForm, useWatch} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../../AppContext";
import Button, {CancelButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemDate from "../../../components/EditForm/FormItemDate";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemEmployee from "../../../components/EditForm/FormItemEmployee";
import FormItemNumber from "../../../components/EditForm/FormItemNumber";
import FormItemTextArea from "../../../components/EditForm/FormItemTextArea";
import Label from "../../../components/EditForm/Label";
import {confirmYes} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Spinner from "../../../components/Spinner/Spinner";
import TabPanel from "../../../components/TabView/TabPanel";
import TabView from "../../../components/TabView/TabView";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import Unit from "../../../enums/Unit";
import useMountEffect from "../../../hooks/useMountEffect";
import usePreload from "../../../hooks/usePreload";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import {arraySum, filterActive} from "../../../utils/ArrayUtil";
import {
    formatDate,
    getDateObject,
    getDayInMonth,
    getDayName,
    getHolidayName,
    isBuildDay,
    todayDate
} from "../../../utils/DateUtils";
import {formatHours} from "../../../utils/NumberUtils";
import RouterUtils from "../../../utils/RouterUtils";
import AppforleaveService from "./AppforleaveService";
import AppforleaveStatus from "./AppforleaveStatus";
import './AppforleaveEditForm.scss'

const AppforleaveEditForm = () => {
    const context = useContext(AppContext)
    const roles = useRoles()
    const preload = usePreload()
    const timeregabsencetypeList = preload.getTimeregabsencetypeList()

    const location = useLocation()
    const navigate = useNavigate()

    const [model, setModel] = useState()

    const disabled = !model || !roles.hasRole(Role.TIMEREG_WRITE)


    const defaultValues = {
        // startDate: '2024-03-01',
        startDate: todayDate(),
        endDate: todayDate(),
        // hoursPerDay: 0,
        employeeComment: '',
        status: AppforleaveStatus.DRAFT.id
    }

    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, getValues, control} = form

    const watchStartDate = useWatch({control, name: 'startDate'})
    const watchEndDate = useWatch({control, name: 'endDate'})
    const watchHoursPerDay = Number(useWatch({control, name: 'hoursPerDay'}))


    const dayList = useMemo(() => {
            let result = []
            let currentDate = getDateObject(watchStartDate);
            const endDateDate = getDateObject(watchEndDate)

            while (currentDate <= endDateDate) {
                const buildDay = isBuildDay(currentDate)
                result.push({
                    date: formatDate(currentDate),
                    workDay: buildDay,
                    hours: buildDay ? watchHoursPerDay : 0
                })
                currentDate.setDate(currentDate.getDate() + 1);
            }
            return result

        }, [watchStartDate, watchEndDate, watchHoursPerDay]
    )

    const numWorkDays = dayList.filter(item => item.workDay).length
    const numCalenderDays = dayList.length
    const numHours = arraySum(dayList.filter(item => item.workDay), 'hours')


    const save = async (data) => {
        if (!disabled) {
            data.company = context.currentCompany.id

            console.log("SAVE MODEL:", data)
            const _model = await AppforleaveService.update(data)

            if (_model) {
                setModel(_model)
                reset(_model)
            }
            return _model
        } else {
            return model;
        }
    }

    const onSubmit = () => {
        showLoading();
        const data = getValues();
        return save(data).then(_model => {
            hideLoading()
            if (_model) RouterUtils.goBack(navigate)
        });
    }

    useMountEffect(async () => {
        showLoading();
        const id = RouterUtils.getId(location)
        if (id > 0) {
            const _model = await AppforleaveService.findById(id)
            setModel(_model)
            reset(_model)
        } else {
            setModel(defaultValues)
        }
        hideLoading();
    })

    function addToTimereg(data) {
        confirmYes(async () => {
            showLoading()
            let _model = await save(data)
            if (_model) {
                _model = await AppforleaveService.addToTimereg(_model.id)
                if (_model) RouterUtils.goBack(navigate)
            }
            hideLoading()
        })
    }

    function removeFromTimereg(data) {
        confirmYes(async () => {
            showLoading()
            let _model = await save(data)
            if (_model) {
                _model = await AppforleaveService.removeFromTimereg(_model.id)
                if (_model) RouterUtils.goBack(navigate)
            }
            hideLoading()
        })
    }

    if (!model) return <Spinner/>
    return <div className='EditForm AppforleaveEditForm'>
        <div className="headline">
            <h1>{i18n('application_for_leave')}</h1>
        </div>
        <form>
            <div className="button-row">
                <div className='left-buttons'>
                    {!disabled &&
                        <SaveButton onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>}
                    <CancelButton onClick={e => RouterUtils.goBack(navigate)}/>
                </div>
                <div className='divider'></div>
                <div className='right-buttons'>
                    {
                        !model.importedTime && roles.hasRole(Role.TIMEREG_WRITE) &&
                        <Button faicon={Icon.GENERATE}
                                labelI18n='add_to_time_registration'
                                onClick={handleSubmit(addToTimereg)}/>
                    }
                    {
                        model.importedTime && roles.hasRole(Role.TIMEREG_WRITE) &&
                        <Button faicon={Icon.GENERATE}
                                labelI18n='remove_from_time_registration'
                                onClick={handleSubmit(removeFromTimereg)}/>
                    }
                </div>
            </div>
            <div className='columnContainer'>
                <div className='column'>
                    <FormItemEmployee key='employee' name='employee' labelI18n='employee' required {...form}/>
                    <FormItemDropdown key='timeregabsencetype' name='timeregabsencetype' labelI18n='absence_type'
                                      options={filterActive(timeregabsencetypeList)}
                                      required {...form} />
                </div>
                <div className='column'>
                    <FormItemDate key='startDate' name='startDate' labelI18n='start_date' required {...form} />
                    <FormItemDate key='endDate' name='endDate' labelI18n='end_date' required {...form} />
                    <FormItemNumber key='hoursPerDay' name='hoursPerDay' labelI18n='hours_per_day'
                                    required
                                    min={0.1}
                                    onBlur={e => {
                                        console.log("BLUR", e.target.value)
                                    }}
                                    max={99} {...form}/>
                </div>
                <div className='column'>
                    <FormItemTextArea key='employeeComment' name='employeeComment' labelI18n='comment_from_employee'
                                      {...form} disabled={true}/>
                    <FormItemTextArea key='note' name='note' labelI18n='note' maxLength={5000} {...form}/>
                    <FormItemDropdown key='status' name='status' labelI18n='status' options={AppforleaveStatus.values()}
                                      required width={160} {...form} />
                </div>
            </div>
        </form>
        <TabView renderActiveOnly={false}>
            <TabPanel key='summary' header={i18n('summary')}>
                <div className='columnContainer'>
                    <div className='column'>
                        <div className='summary'>
                            {
                                dayList.map(item => <div key={formatDate(item.date)}
                                                         className={'row' + (!item.workDay ? ' workday' : '')}>
                                    <div className='date'>
                                        <div>{getDayInMonth(item.date)}</div>
                                        <div>{getDayName(item.date).substring(0, 3)}</div>
                                    </div>
                                    <div>
                                        <div>{getHolidayName(item.date, true)}</div>
                                        <div>{formatHours(item.hours)}</div>
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                    <div className='column'>
                        <div className='days-sum'>
                            <Label valueI18n='working_days'/>
                            <div>{numWorkDays + ' ' + Unit.PIECE.symbol}</div>
                        </div>
                        <div className='days-sum'>
                            <Label valueI18n='calendar_days'/>
                            <div>{numCalenderDays + ' ' + Unit.PIECE.symbol}</div>
                        </div>
                        <div className='days-sum'>
                            <Label valueI18n='hours'/>
                            <div>{formatHours(numHours, true)}</div>
                        </div>
                    </div>
                    <div className='column'></div>
                </div>
            </TabPanel>
        </TabView>
    </div>
}

export default AppforleaveEditForm;