import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {APP_NAME} from "../../App";
import useSecurity from "../../hooks/useSecurity";
import {i18n} from "../../I18n/I18n";
import logo from '../../resources/images/logo.png'
import NetUtils from "../../utils/NetUtils";
import Button, {LinkButton, MainButton} from "../Buttons/Button";
import FormItemEmail from "../EditForm/FormItemEmail";
import FormItemText from "../EditForm/FormItemText";
import {infoAlert} from "../Overlay/Alert";
import Spinner from "../Spinner/Spinner";
import './SignInScreen.scss';

const SignInScreen = props => {
    const redirectReason = NetUtils.getParam('reason')

    let defaultErrorMsg = undefined
    if(redirectReason === 'multiple-user-usage') {
        defaultErrorMsg = i18n('error_multiple_user_usage')
    }

    const [loading, setLoading] = useState()
    const [errorMsg, setErrorMsg] = useState(defaultErrorMsg)
    const [showForgotPassword, setShowForgotPassword] = useState(false)

    const security = useSecurity()

    // console.log("redirectReason", redirectReason)
    //multiple-user-usage

    const defaultValues = {
        username: '',
    }

    const form = {...useForm({defaultValues: defaultValues})}
    const {handleSubmit, reset} = form

    const onSubmit = data => {
        setLoading(true);

        if (!showForgotPassword) {
            security.authenticate(data.username, data.password).then(res => {
                window.location = '/';
            }, res => {
                let newErrorMsg;
                switch (res.message) {
                    case 'USER_DISABLED':
                        newErrorMsg = i18n('error_user_is_inactive')
                        break;
                    case 'INVALID_CREDENTIALS':
                        newErrorMsg = i18n('error_wrong_username_or_password')
                        break;
                    default:
                        newErrorMsg = res.toString();
                        break

                }
                setErrorMsg(newErrorMsg)
                setLoading(false);
            });
        } else {
            security.sendNewPassword(data.username).then(res => {
                infoAlert(i18n('new_password_sent_to_email'))
                setShowForgotPassword(false);
                setLoading(false);
                reset({
                    username: data.username
                })
            });
        }
    };

    if (loading) {
        return <Spinner/>
    }

    return <div className="SignInScreen">
        <div className='EditForm'>
            <form>
                <div className="logo-container">
                    <img src={logo} alt={APP_NAME}/>
                </div>

                {errorMsg && <div className="errorMsg">{errorMsg}</div>}

                <div className="credBox">
                    <FormItemEmail name='username' label={i18n('email')} autoComplete='username' required {...form}/>

                    {
                        !showForgotPassword &&
                        <div>
                            <FormItemText name='password' type='password' label={i18n('password')} maxLength={128}
                                          required {...form}/>
                            <div>
                                <LinkButton label={i18n('forgot_password')}
                                            onClick={() => setShowForgotPassword(true)}/>
                            </div>
                        </div>
                    }
                </div>
                {
                    !showForgotPassword &&
                    <div className='button-row'>
                        <MainButton label={i18n('log_in')} onClick={handleSubmit(onSubmit)}/>
                    </div>
                }

                {
                    showForgotPassword &&
                    <div className='button-row'>
                        <Button label={i18n('back')} onClick={() => setShowForgotPassword(false)}/>
                        <MainButton label={i18n('send_new_password')} onClick={handleSubmit(onSubmit)}/>
                    </div>
                }

            </form>
        </div>
    </div>
}
export default SignInScreen;