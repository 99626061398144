import {useContext} from "react";
import AppContext from "../../../AppContext";
import VatType from "../../../enums/VatType";
import {addDays, todayDate} from "../../../utils/DateUtils";
import InvoiceStatus from "../InvoiceStatus";

const useInvoiceDefaultValues = () => {
    const context = useContext(AppContext);

    return {
        id: 0,
        company: context.currentCompany.id,
        cuser: context.user.id,
        customer: undefined,
        customercontact: undefined,
        number: '',
        invoiceDate: todayDate(),
        dueDate: addDays(new Date(), 30),
        paymentDate: null,
        paymentTerms: 30,
        markup: '',
        vatType: VatType.SEVAT.id,
        status: InvoiceStatus.DRAFT.id,
        note: '',
        rowList: [],
        houseworkList: [],
        appendixList: [],
        reminderList: [],
        sentlogList: []
    }
}
export default useInvoiceDefaultValues