import React, {useContext, useState} from 'react';
import AppContext from "../../AppContext";
import FormItemAddress from "../../components/EditForm/FormItemAddress";
import FormItemCheckbox from "../../components/EditForm/FormItemCheckbox";
import FormItemDropdown from "../../components/EditForm/FormItemDropdown";
import FormItemEmail from "../../components/EditForm/FormItemEmail";
import FormItemInteger from "../../components/EditForm/FormItemInteger";
import FormItemPhone from "../../components/EditForm/FormItemPhone";
import FormItemText from "../../components/EditForm/FormItemText";
import FormItemTextArea from "../../components/EditForm/FormItemTextArea";
import FormItemWww from "../../components/EditForm/FormItemWww";
import SimpleEditForm from "../../components/EditForm/SimpleEditForm";
import Country from "../../enums/Country";
import Role from "../../enums/Role";
import useLoadCompanyInfo from "../../hooks/useLoadCompanyInfo";
import {PreloadType} from "../../hooks/usePreload";
import ValidateUtils from "../../utils/ValidateUtils";
import SupplierinvoiceVatType from "../Supplierinvoices/SupplierinvoiceVatType";
import SupplierService from "./SupplierService";

const SupplierEditForm = () => {
    const context = useContext(AppContext)
    const [form, setForm] = useState()
    const loadCompanyInfo = useLoadCompanyInfo()


    const preSaveFunction = data => {
        if (context.currentCompany.parentId) data.company = context.currentCompany.parentId
        if (data.number.trim().length === 0) delete data.number
    }

    async function findCompanyInfoByRegNo(_regNo) {
        const name = form.getValues('name')
        if (name.length === 0) insertCompanyInfo(await loadCompanyInfo.findByRegNo(_regNo))
    }

    async function findCompanyInfoByBankgiro(_bankgiro) {
        const name = form.getValues('name')
        if (name.length === 0) insertCompanyInfo(await loadCompanyInfo.findByBankgiro(_bankgiro))
    }

    function insertCompanyInfo(_companyInfo) {
        if (!_companyInfo) return
        form.setValue('regNo', _companyInfo.regNo)
        form.setValue('name', _companyInfo.name)
        form.setValue('vatNo', _companyInfo.vatNo)
        form.setValue('address', _companyInfo.address)
        form.setValue('bankgiro', _companyInfo.bankgiro)
    }

    return <SimpleEditForm
        writeRole={Role.SUPPLIER_WRITE}
        headlineI18n='supplier'
        defaultValues={{
            regNo: '',
            number: '',
            name: '',
            vatNo: '',
            vatType: SupplierinvoiceVatType.NORMAL.id,

            address: {
                address: '',
                zip: 0,
                city: '',
                country: Country.SE.id
            },
            phone0: '',
            email: '',
            www: '',

            bankgiro: '',
            plusgiro: '',
            iban: '',
            paymentTerms: 30,
            note: '',
            active: true
        }}
        onFormCreated={form => setForm(form)}
        loadFunction={SupplierService.findById}
        preSaveFunction={preSaveFunction}
        saveFunction={SupplierService.update}
        initLeftFields={form => [
            <FormItemText key='regNo' name='regNo' labelI18n={'reg_no'}
                          maxLength={32} width={150}
                          onBlur={e => findCompanyInfoByRegNo(e.target.value)} {...form}/>,
            <FormItemText key='number' name='number' labelI18n='number' maxLength={128} {...form}/>,
            <FormItemText key='name' name='name' labelI18n='name' maxLength={128} required {...form}/>,
            <FormItemText key='vatNo' name='vatNo' labelI18n='vat_no' maxLength={45} width={130} {...form}/>,
            <FormItemDropdown key='vatType' name='vatType' labelI18n='vat_type'
                              options={SupplierinvoiceVatType.values()}
                              required {...form} />,
            <FormItemCheckbox key='subcontractor' name='subcontractor' labelI18n='subcontractor' {...form}/>,
        ]}
        initCenterFields={form => [
            <FormItemAddress key='address' name='address' labelI18n='address' {...form}/>,
            <FormItemPhone key='phone0' name='phone0' labelI18n='mobile' {...form}/>,
            <FormItemEmail key='email' name='email' labelI18n='email' {...form}/>,
            <FormItemWww key='www' name='www' labelI18n='homepage' {...form}/>,
        ]}
        initRightFields={form => [
            <FormItemText key='bankgiro' name='bankgiro' label='Bankgiro' maxLength={9}
                          validation={{validate: ValidateUtils.bankgiro}}
                          onBlur={e => findCompanyInfoByBankgiro(e.target.value)} {...form}/>,
            <FormItemText key='plusgiro' name='plusgiro' label='Plusgiro' maxLength={9} {...form}/>,
            <FormItemText key='iban' name='iban' label='IBAN' maxLength={30} {...form}/>,
            <FormItemInteger key='paymentTerms' name='paymentTerms' labelI18n='payment_terms' {...form}/>,
            <FormItemTextArea key='note' name='note' labelI18n='note' maxLength={5000} {...form}/>,
            <FormItemCheckbox key='active' name='active' labelI18n='active' {...form}/>,
        ]}
        preloadType={PreloadType.SUPPLIER_LIST}
    />
}

export default SupplierEditForm;
