import {useFilter} from "@components/Grid/filter";
import Spinner from "@components/Spinner/Spinner";
import React, {useState} from 'react';
import MonthStepper from "../../components/Date/MonthStepper";
import XlnzDropdown from "../../components/XlnzDropdown/XlnzDropdown";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Role from "../../enums/Role";
import useDepsEffect from "../../hooks/useEffect";
import usePreload from "../../hooks/usePreload";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import {filterActive, isEmpty, sortByField} from "../../utils/ArrayUtil";
import {getLastDateOfMonth, todayDate} from "../../utils/DateUtils";
import HyrletService from "../Hyrlet/HyrletService";
import useHyrletLoginInfo from "../Hyrlet/useHyrletLoginInfo";
import SupplierinvoiceService from "../Supplierinvoices/SupplierinvoiceService";
import AttestInfo from "./AttestInfo";
import './Dashboard.scss'
import DashboardService from "./DashboardService";
import HyrletListPriceChart from "./Hyrlet/HyrletListPriceChart";
import HyrletRentChart from "./Hyrlet/HyrletRentChart";
import TenderAnswerChart from "./TenderAnswerChart";
import TenderChart from "./TenderChart";
import TenderTable from "./TenderTable";
import TurnoverChart from "./TurnoverChart";
import TurnoverInfo from "./TurnoverInfo";

const Dashboard = () => {
    const roles = useRoles()
    const filter = useFilter('Dashboard', [
        {name: 'date', defaultValue: todayDate()},
        {name: 'costcenter', defaultValue: undefined},
    ])
    const [data, setData] = useState();

    const preload = usePreload()
    const costcenterList = filterActive(preload.getCostcenterList())

    const hyrletLoginInfo = useHyrletLoginInfo()
    const customerList = sortByField(hyrletLoginInfo.getCurrentCustomerList(), 'fullName')

    useDepsEffect(() => {
        showLoading()
        const dateEndOfMonth = getLastDateOfMonth(filter.date)

        const loadArray = [
            DashboardService.all(filter.date, filter.costcenter),
        ]

        if (roles.hasRole(Role.SUPPLIERINVOICE_READ)) {
            loadArray.push(SupplierinvoiceService.numToAttest())
        }
        if (roles.hasRole(Role.HYRLET)) {
            customerList.forEach(item => loadArray.push(HyrletService.getDashboard(item.id, dateEndOfMonth)))
        }

        Promise.all(loadArray).then((resp) => {
            const _data = resp[0]
            _data.numToAttest = resp[1]

            let index = 2
            customerList.forEach(item => {
                if (resp[index]) {
                    const dataset = resp[index].rentChart.datasets[0]
                    if (dataset.total > 0) {
                        if (!_data.hyrletDashboard) {
                            _data.hyrletDashboard = resp[index]
                        } else {
                            _data.hyrletDashboard.rentChart.datasets.push(resp[index].rentChart.datasets[0])
                            _data.hyrletDashboard.listPriceChart.datasets.push(resp[index].listPriceChart.datasets[0])
                        }
                    }
                }

                index++
            })


            setData(_data);
            hideLoading()
        })
    }, [filter.date, filter.costcenter])

    if (!data) return <Spinner/>
    return <div className="Dashboard">
        <div className='dashboard-content'>
            <div className='Grid'>
                <div className="filter">
                    <MonthStepper value={filter.date} onChange={date => filter.update('date', date)}/>
                    {
                        !isEmpty(costcenterList) &&
                        <div className='costcenter-filter'>
                            <label className="label">{i18n('costcenter')}</label>
                            <XlnzDropdown
                                value={filter.costcenter}
                                options={costcenterList}
                                optionLabel='label'
                                showClear={true}
                                placeholder={i18n('select') + '...'}
                                optionValue='id'
                                onChange={e => filter.update('costcenter', e.value)}
                                style={{width: '250px'}}/>
                        </div>
                    }
                </div>
            </div>

            <AttestInfo data={data}/>

            <div className='card'>
                <h1>{i18n('turnover')}</h1>
                <TurnoverChart data={data}/>
                <TurnoverInfo date={filter.date} data={data}/>
            </div>

            <div className='card'>
                <h1>{i18n('tenders')}</h1>

                <h2 style={{color: '#888'}}>{i18n('based_on_answer_date')}</h2>
                <TenderAnswerChart data={data}/>

                <h2 style={{color: '#888', marginTop: '50px'}}>{i18n('based_on_tender_date')}</h2>
                <TenderChart data={data}/>
                <TenderTable date={filter.date} data={data}/>
            </div>

            {
                data.hyrletDashboard &&
                <div className='card'>
                    <h1>Hyrlet</h1>
                    {
                        data.hyrletDashboard.rentChart &&
                        <HyrletRentChart data={data}/>
                    }
                    <div style={{height: '30px'}}></div>
                    {
                        data.hyrletDashboard.listPriceChart &&
                        <HyrletListPriceChart date={filter.date} data={data}/>
                    }
                </div>
            }

        </div>
    </div>
}

export default Dashboard;
