import React from 'react';
import Button from "../../../components/Buttons/Button";
import Grid from "../../../components/Grid/Grid";
import NumberGridInput from "../../../components/Grid/NumberGridInput";
import onRowChange from "../../../components/Grid/onRowChange";
import {GridTemplates} from "../../../GridTemplates";
import {i18n} from "../../../I18n/I18n";
import ArrayUtil, {findByField} from "../../../utils/ArrayUtil";
import {NumberUtils} from "../../../utils/NumberUtils";
import InvoiceEntity from "./InvoiceEntity";

const HouseworkGrid = props => {
    const {
        value,
        disabled,
        customer,
        vatType,
        rowList,
        taxReductionType,
        houseworkType
    } = props

    const list = []
    const invoiceEntity = new InvoiceEntity(vatType, rowList, value, taxReductionType, houseworkType)

    if (customer.regNo) {
        const amount = value.find(item => item.ssn === customer.regNo)?.amount || 0
        list.push({
            typeName: i18n('customer'),
            name: customer.name,
            ssn: customer.regNo,
            amount: amount
        })
    }

    customer.contactList.forEach(cc => {
        if (cc.ssn) {
            const amount = value.find(item => item.ssn === cc.ssn)?.amount || 0

            const isSsnAlreadyAdded = findByField(list, 'ssn', cc.ssn) !== undefined

            if (!isSsnAlreadyAdded) {
                list.push({
                    typeName: i18n('contact_person'),
                    name: cc.name,
                    ssn: cc.ssn,
                    amount: amount
                })
            }
        }
    })

    const totalAmount = ArrayUtil.sum(list, 'amount')
    const remainingAmount = invoiceEntity.houseworkPrice - totalAmount

    const fireChange = (list) => {
        props.onChange(list
            .filter(item => item.amount > 0)
            .map(item => ({
                name: item.name,
                ssn: item.ssn,
                amount: Math.floor(item.amount)
            }))
        );
    }

    const columns = [
        {field: 'typeName', header: i18n('type')},
        {field: 'name', header: i18n('name')},
        {field: 'ssn', header: i18n('ssn'),},
        {
            field: 'amount', header: i18n('amount'),
            body: GridTemplates.currencyTwoDecimals,
            editor: !disabled ? e => <NumberGridInput editorEvent={e}/> : undefined,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            style: {width: '100px'},
            align: 'right'
        },
    ]

    function splitAvailableAmount(e) {
        const share = invoiceEntity.houseworkPrice / list.length

        const _list = [...list]
        let totalAmount = 0
        for (let i = 0; i < _list.length; i++) {
            const item = _list[i]
            let amount
            if (i < _list.length - 1) {
                amount = Math.floor(share)
            } else {
                amount = invoiceEntity.houseworkPrice - totalAmount
            }
            totalAmount += amount
            item.amount = amount
        }
        fireChange(_list)
    }

    function getTaxReductionPercentageFormatted() {
        return NumberUtils.formatPercent(invoiceEntity.taxReductionPercentage, 0)
    }

    return <div className='FormItemRowGrid' style={{width: '500px'}}>
        <Grid
            columns={columns}
            value={list}
            footerColumnGroup={<div/>}
        />

        <div className='footer'>
            <div className='left'>
                {
                    !disabled && invoiceEntity.houseworkPrice > 0 &&
                    <Button label={i18n('distribute') + ' (' + getTaxReductionPercentageFormatted() + ')'}
                            onClick={splitAvailableAmount}/>
                }
            </div>
            <div className='middle'></div>
            <div className='right'>
                <table>
                    <tbody>
                    <tr className='to-pay'>
                        <td>{i18n('total')}</td>
                        <td>{NumberUtils.formatCurrency(totalAmount, 2, true)}</td>
                    </tr>
                    </tbody>
                    {
                        remainingAmount !== 0 &&
                        <tbody>
                        <tr>
                            <td colSpan={2}>
                                <hr/>
                            </td>
                        </tr>
                        <tr>
                            <td>{i18n('possible_tax_reduction')}</td>
                            <td>{NumberUtils.formatCurrency(invoiceEntity.houseworkPrice, 2)}</td>
                        </tr>
                        <tr>
                            <td>{i18n('tax_reduction_not_applied_for')}</td>
                            <td>{NumberUtils.formatCurrency(remainingAmount, 2)}</td>
                        </tr>
                        </tbody>
                    }
                </table>
            </div>
        </div>


    </div>
}
export default HouseworkGrid;
