import Button from "../../../components/Buttons/Button";
import Icon from "../../../enums/Icon";
import {formatCurrency, formatHours, isNumeric} from "../../../utils/NumberUtils";
import InvoiceEntity from "./InvoiceEntity";

export default function SpecialHamntjanstInfo(props) {
    const {
        form
    } = props


    const vatType = form.getValues('vatType')
    const rowList = form.getValues('rowList')
    const houseworkList = form.getValues('houseworkList')
    const taxReductionType = form.getValues('taxReductionType')
    const houseworkType = form.getValues('houseworkType')

    const invoiceEntity = new InvoiceEntity(vatType, rowList, houseworkList, taxReductionType, houseworkType)


    let totalPrice = 0
    let timeCost = 0
    let time = 0
    let materialCost = 0
    let ueCost = 0
    rowList.forEach(ir => {
        if (isNumeric(ir.price)) {
            totalPrice += ir.price

            if (ir.timeregList?.length > 0) {
                timeCost += ir.price

                ir.timeregList.forEach(irtr => {
                    time += irtr.hours
                })
            }

            if (ir.timeregarticleList?.length > 0) {
                materialCost += ir.price
            }

            if (ir.supplierinvoicerowList?.length > 0) {
                ueCost += ir.price
            }
        }
    })
    const otherCosts = totalPrice - timeCost - materialCost - ueCost

    return <div style={{
        marginBottom: '30px',
        padding: '30px',
        fontSize: '12px',
        border: '1px solid #b8cf69',
        width: 'fit-content'
    }}>
        <table id='SpecialHamntjanstInfo'>
            <tbody>
            <tr>
                <td colSpan={2}>&nbsp;&nbsp;&nbsp;-- Totaler --</td>
            </tr>
            <tr>
                <td style={{width: '75px'}}>Tid&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td>{formatCurrency(timeCost, 2, true)}&nbsp;&nbsp;&nbsp;{formatHours(time)}</td>
            </tr>
            <tr>
                <td>Material&nbsp;&nbsp;&nbsp;</td>
                <td>{formatCurrency(materialCost, 2, true)}</td>
            </tr>
            <tr>
                <td>Maskiner&nbsp;&nbsp;&nbsp;</td>
                <td>0 kr</td>
            </tr>
            <tr>
                <td>UE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td>{formatCurrency(ueCost, 2, true)}</td>
            </tr>
            <tr>
                <td>Övrigt&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td>{formatCurrency(otherCosts, 2, true)}</td>
            </tr>
            <tr>
                <td>Moms&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td>{formatCurrency(invoiceEntity.vatPrice, 2, true)}</td>
            </tr>
            </tbody>
        </table>

        <div style={{
            marginTop: '20px'
        }}>
            <Button labelI18n='do_copy' faicon={Icon.COPY} onClick={e => {
                let text = '    -- Totaler --'

                text += '\nTid          ' + formatCurrency(timeCost, 2, true) + '   ' + formatHours(time)
                text += '\nMaterial     ' + formatCurrency(materialCost, 2, true)
                text += '\nMaskiner     0 kr'
                text += '\nUE           ' + formatCurrency(ueCost, 2, true)
                text += '\nÖvrigt       ' + formatCurrency(otherCosts, 2, true)
                text += '\nMoms         ' + formatCurrency(invoiceEntity.vatPrice, 2, true)

                navigator.clipboard.writeText(text).then(r => {
                });
            }}/>
        </div>
    </div>
}