import Role from "../../enums/Role";
import AccountEditForm from "./AccountEditForm";
import AccountGrid from "./AccountGrid";

const AccountsIndex = {
    routeProps: {
        path: '/accounts',
        exact: true,
        element: <AccountGrid/>,
    },
    nameKey: 'chart_of_accounts',
    icon: 'pi pi-fw pi-folder',
    subModules: [{
        routeProps: {
            path: '/accounts/:id',
            exact: true,
            element: <AccountEditForm/>,
        }
    }],
    roles: [Role.INVOICE_READ]
};
export default AccountsIndex;