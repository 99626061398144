import React from 'react';
import Grid from "../../../../components/Grid/Grid";
import {i18n} from "../../../../I18n/I18n";
import {addDays} from "../../../../utils/DateUtils";
import {formatTwoDecimalsIfNeeded} from "../../../../utils/NumberUtils";
import {MONTHOVERVIEW_VIEW_DAY_WIDTH} from "../MonthoverviewView";
import getDayStyle from "../workedtime/getDayStyle";
import DayBody from "./getDayBody";
import getDayHeader from "./getDayHeader";

const MonthoverviewWorkedtimeGrid = props => {
    const {
        employeeId,
        startDate,
        isDayLocked,
        model
    } = props

    const columns = [
        {
            field: 'salarytypeName',
            body: rowData =>
                <div style={{
                    fontWeight: !model.breakdownPresent || rowData.breakdown ? '' : 'bold'
                }}>
                    {rowData.salarytypeName}
                </div>,
            header: i18n('worked_time')
        }
    ]

    if (model) {
        for (let i = 0; i < model.numOfDays; i++) {
            const date = addDays(startDate, i)

            const dayLocked = isDayLocked(date)

            columns.push({
                field: date,
                header: getDayHeader(date, i, dayLocked),
                // body: item => getDayBody(employeeId, date, item, i),
                body: item => <DayBody model={model} employeeId={employeeId} date={date} rowData={item} i={i}/>,
                width: MONTHOVERVIEW_VIEW_DAY_WIDTH,
                align: 'center',
                className: 'dayColumn',
                style: getDayStyle(date),
            })
        }

        columns.push({
            field: 'totalHours',
            // body: GridTemplates.numberTwoDecimalsIfNeeded,
            body: rowData =>
                <div style={{
                    fontWeight: !model.breakdownPresent || rowData.breakdown ? '' : 'bold'
                }}>
                    {formatTwoDecimalsIfNeeded(rowData.totalHours)}
                </div>,
            width: MONTHOVERVIEW_VIEW_DAY_WIDTH,
            align: 'center',
            className: 'dayColumn',
            // style: {fontWeight: 'bold'},
        })
    }

    return <div className='MonthoverviewWorkedtimeGrid'>
        <Grid xlnzGrid={true}
              forceDesktop={true}
              scrollable={false}
              columns={columns}
              value={model.workedHours}
              hideFooter={true}
              sortable={false}
              onRowSelect={() => {
              }}
        />
    </div>
}

export default MonthoverviewWorkedtimeGrid;
