import React, {useState} from 'react';
import {CancelButton, SaveButton} from "../../../../components/Buttons/Button";
import XlnzDropdown from "../../../../components/XlnzDropdown/XlnzDropdown";
import {useFilter} from "../../../../components/Grid/filter";
import Grid from "../../../../components/Grid/Grid";
import NumberGridInput from "../../../../components/Grid/NumberGridInput";
import onRowChange from "../../../../components/Grid/onRowChange";
import XlnzDialog from "../../../../components/XlnzDialog/XlnzDialog";
import {match, matchSearch} from "../../../../functions/match";
import {GridTemplates} from "../../../../GridTemplates";
import {i18n} from "../../../../I18n/I18n";
import {HyrletArticletype} from "../../HyrletArticletype";


const HyrletOrderAddArticlesDialog = props => {
    const {
        onHide,
        articlegroupList,
        articleList,
        stockQuantityMap,
        rowList
    } = props

    const filter = useFilter('HyrletOrderAddArticlesDialog', [
        {name: 'type'},
        {name: 'group'},
        {name: 'search', defaultValue: ''}
    ])

    const [list, setList] = useState(articleList
        .filter(a => props.stockQuantityMap.get(a.id) > 0
            && !rowList.find(or => or.article === a.id)
        )
        .map(a => Object.assign({quantity: 0}, a)));

    const matchGroup = article => !filter.group || article.group === filter.group;

    const filteredList = list.filter(a => match(filter, a, 'type') && matchGroup(a) && matchSearch(filter, a))

    filteredList.forEach(item => {
        item.stockQuantity = stockQuantityMap.get(item.id)
    })

    const onQuantityChange = (article, value) => {
        const stockQuantity = props.stockQuantityMap.get(article.id);
        if (value > stockQuantity) {
            value = stockQuantity;
        }
        article.quantity = Number(value);
        setList([...list]);
    }

    const leftFilters = [
        <div>
            <label className="label">{i18n('type')}</label>
            <XlnzDropdown
                value={filter.type}
                options={HyrletArticletype.list}
                optionValue='value'
                optionLabel='labelMultiple'
                onChange={e => filter.update('type', e.value)}
                showClear={true}
                placeholder={i18n('all')}
                style={{width: '180px'}}/>
        </div>,
        <div>
            <label className="label">{i18n('article_group')}</label>
            <XlnzDropdown
                value={filter.group}
                options={articlegroupList}
                optionLabel='name'
                optionValue='id'
                onChange={e => filter.update('group', e.value)}
                showClear={true}
                placeholder={i18n('all')}
                scrollHeight='250px'
                style={{width: '180px'}}/>
        </div>
    ]

    const columns = [
        {field: 'label', headerI18n: 'article',},
        {field: 'description', headerI18n: 'description',},
        {
            field: 'stockQuantity',
            header: 'Lagersaldo',
            body: GridTemplates.integer,
            width: 120,
            align: 'right'
        },
        {
            field: 'quantity',
            header: i18n('quantity'),
            body: GridTemplates.integer,
            editor: e => <NumberGridInput editorEvent={e} integer={true}/>,
            onCellEditComplete: e => onRowChange(e, Math.min(e.newValue, e.rowData.stockQuantity), onQuantityChange),
            width: 120,
            align: 'right'
        }
    ]

    const onSave = () => props.onSave(list.filter(a => a.quantity > 0))

    return <XlnzDialog header='Lägg till artiklar'
                       onHide={onHide}
                       buttons={() => [
                           <SaveButton key='save' onClick={() => {
                               onSave()
                               onHide()
                           }}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >
        <div className="EditForm" style={{width: '900px'}}>
            <Grid
                xlnzGrid={true}
                leftFilters={leftFilters}
                filter={filter}
                updateFilter={filter.update}
                columns={columns}
                value={filteredList}
                paginator={true}
                onRowSelect={e => {
                }}
                scrollable={true}
                heightOffset={450}
            />
        </div>
    </XlnzDialog>
}

export default HyrletOrderAddArticlesDialog;
