import Role from "../../enums/Role";
import WorkcrewEditForm from "./WorkcrewEditForm";
import WorkcrewGrid from "./WorkcrewGrid";

const WorkcrewIndex = {
    routeProps: {
        path: '/workcrews',
        exact: true,
        element: <WorkcrewGrid/>,
    },
    nameKey: 'work_crews',
    subModules: [{
        routeProps: {
            path: '/workcrews/:id',
            exact: true,
            element: <WorkcrewEditForm/>
        }
    }],
    roles: [Role.EMPLOYEE_READ]
};
export default WorkcrewIndex