export default class Role {
    static SUPERADMIN = new Role("SUPERADMIN")
    static ADMIN = new Role("ADMIN")

    static DASHBOARD_READ = new Role("DASHBOARD_READ")

    static CUSTOMER_READ = new Role("CUSTOMER_READ")
    static CUSTOMER_WRITE = new Role("CUSTOMER_WRITE")

    static DOCUMENT_READ = new Role("DOCUMENT_READ")
    static DOCUMENT_WRITE = new Role("DOCUMENT_WRITE")

    static SUPPLIER_READ = new Role("SUPPLIER_READ")
    static SUPPLIER_WRITE = new Role("SUPPLIER_WRITE")

    static USERGROUP_READ = new Role("USERGROUP_READ", undefined)
    static USERGROUP_WRITE = new Role("USERGROUP_WRITE", undefined)

    static USER_READ = new Role("USER_READ", [Role.USERGROUP_READ])
    static USER_WRITE = new Role("USER_WRITE", [Role.USERGROUP_READ], true)

    static AGREEMENT_READ = new Role("AGREEMENT_READ", [Role.CUSTOMER_READ, Role.USER_READ], true)
    static AGREEMENT_WRITE = new Role("AGREEMENT_WRITE", [Role.CUSTOMER_READ, Role.USER_READ], true)

    static ARTICLE_READ = new Role("ARTICLE_READ")
    static ARTICLE_WRITE = new Role("ARTICLE_WRITE")

    static EMPLOYEE_READ = new Role("EMPLOYEE_READ")
    static EMPLOYEE_WRITE = new Role("EMPLOYEE_WRITE")

    static REPORT_READ = new Role("REPORT_READ")

    static TENDER_READ = new Role("TENDER_READ", [Role.CUSTOMER_READ, Role.EMPLOYEE_READ])
    static TENDER_WRITE = new Role("TENDER_WRITE", [Role.CUSTOMER_READ, Role.EMPLOYEE_READ])

    static PROJECT_READ = new Role("PROJECT_READ", [Role.CUSTOMER_READ, Role.EMPLOYEE_READ])
    static PROJECT_WRITE = new Role("PROJECT_WRITE", [Role.CUSTOMER_READ, Role.EMPLOYEE_READ])
    static PROJECT_NUMBER_WRITE = new Role("PROJECT_NUMBER_WRITE", [Role.PROJECT_WRITE])

    static TIMEREG_READ = new Role("TIMEREG_READ", [Role.CUSTOMER_READ, Role.EMPLOYEE_READ, Role.PROJECT_READ])
    static TIMEREG_WRITE = new Role("TIMEREG_WRITE", [Role.CUSTOMER_READ, Role.EMPLOYEE_READ, Role.PROJECT_READ])
    static TIMEREG_UNLOCK_DAY = new Role("TIMEREG_UNLOCK_DAY", [Role.TIMEREG_WRITE])
    static TIMEREG_UNLOCK_MONTH = new Role("TIMEREG_UNLOCK_MONTH", [Role.TIMEREG_WRITE])

    static INVOICE_READ = new Role("INVOICE_READ", [Role.CUSTOMER_READ, Role.EMPLOYEE_READ, Role.PROJECT_READ])
    static INVOICE_WRITE = new Role("INVOICE_WRITE", [Role.CUSTOMER_READ, Role.EMPLOYEE_READ, Role.PROJECT_READ])
    static INVOICE_UNLOCK = new Role("INVOICE_UNLOCK", [Role.INVOICE_WRITE])

    static SUPPLIERINVOICE_READ = new Role("SUPPLIERINVOICE_READ", [Role.CUSTOMER_READ, Role.EMPLOYEE_READ, Role.PROJECT_READ])
    static SUPPLIERINVOICE_WRITE = new Role("SUPPLIERINVOICE_WRITE", [Role.CUSTOMER_READ, Role.EMPLOYEE_READ, Role.PROJECT_READ])

    static HYRLET = new Role("HYRLET")

    static CRM_READ = new Role("CRM_READ", [Role.CUSTOMER_READ, Role.EMPLOYEE_READ], true)
    static CRM_WRITE = new Role("CRM_WRITE", [Role.CUSTOMER_READ, Role.EMPLOYEE_READ], true)

    constructor(name, dependencies, superadmin = false) {
        // console.log("THISSS", this)
        this.name = name
        this.dependencies = dependencies ? dependencies : []
        this.superadmin = superadmin
    }

    static values() {
        return Object.keys(Role).map(roleName => Role[roleName])
    }

    static isSpecial(role) {
        return role === this.SUPERADMIN || this.ADMIN
    }

    static getString(role) {
        return "return" + role.roleList
    }
}