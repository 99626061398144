import React from "react";
import FormItemDropdown from "../../../../components/EditForm/FormItemDropdown";
import {FORMROW_KEY_PREFIX} from "../FormanswerEditForm";

export default function FormtypeSelect(props) {
    const model = props.model
    const name = FORMROW_KEY_PREFIX + model.id

    const list = model.value.split(';').map(item => {
        return {
            id: item,
            name: item
        }
    })

    if (!props.getValues(name)) props.setValue(name, list[0].name)

    return <FormItemDropdown label={model.name}
                             name={FORMROW_KEY_PREFIX + model.id}
                             options={list}
                             {...props} />
}