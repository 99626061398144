import React from "react";
import {useNavigate} from "react-router-dom";
import {NBSP} from "../../../../App";
import {FooterAggregate} from "../../../../components/Grid/getFooterGroup";
import Grid from "../../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../../components/Overlay/Loading";
import renderOverlay, {removeOverlay} from "../../../../components/Overlay/renderOverlay";
import Tooltip from "../../../../components/Overlay/Tooltip";
import XlnzDialog from "../../../../components/XlnzDialog/XlnzDialog";
import makeId from "../../../../functions/makeId";
import {GridTemplates} from "../../../../GridTemplates";
import usePreload from "../../../../hooks/usePreload";
import {i18n} from "../../../../I18n/I18n";
import {findById} from "../../../../utils/ArrayUtil";
import {NumberUtils} from "../../../../utils/NumberUtils";
import DayplanningService from "../../Dayplannings/DayplanningService";
import './getDayHeader.scss'

export default function DayBody(props) {
    const {
        model,
        employeeId,
        date,
        rowData,
        i
    } = props

    const navigate = useNavigate()

    const preload = usePreload()
    const salarytypeList = preload.getTimeregsalarytypeList()

    const q = rowData.dayList[i]
    const info = rowData.projectInfoList[i]
    const genId = makeId()

    let className = undefined
    return <div id={'item' + genId} className={className}
                style={{
                    fontWeight: !model.breakdownPresent || rowData.breakdown ? '' : 'bold'
                }}
                onClick={async () => {
                    showLoading()
                    const _dayplanningList = await DayplanningService.findByFilter({date: date, search: ''})

                    const _list = _dayplanningList.flatMap(item => item.timeregList)
                        // .filter(item => item.salarytype === rowData.salarytype)
                        .filter(item => item.employee === employeeId)
                        .map(item => {
                            const dp = findById(_dayplanningList, item.dayplanning)

                            const salarytype = findById(salarytypeList, item.salarytype)

                            return {
                                dayplanningId: dp.id,
                                projectName: dp.projectName,
                                customerName: dp.customerName,
                                salarytypeName: salarytype.name,
                                ...item
                            }
                        })

                    if (_list.length > 0) {
                        const columns = [
                            {field: 'projectName', headerI18n: 'project', width: 250},
                            {field: 'customerName', headerI18n: 'customer', width: 250},
                            {field: 'salarytypeName', headerI18n: 'salary_type', width: 200},
                            {
                                field: 'salaryHours', headerI18n: 'hours',
                                body: GridTemplates.hours,
                                footer: FooterAggregate.SUM_HOURS,
                                width: 100, align: 'right'
                            }
                        ]

                        const dialogId = renderOverlay(<XlnzDialog header={i18n('worked_time') + ' ' + date}>
                            <Grid xlnzGrid={true}
                                  forceDesktop={true}
                                  scrollable={false}
                                  columns={columns}
                                  value={_list}
                                  sortable={false}
                                  onRowSelect={e => {
                                      removeOverlay(dialogId)
                                      navigate('/timereg/dayplanning/' + e.data.dayplanningId + '/' + date)
                                  }}
                            />
                        </XlnzDialog>)
                    }
                    hideLoading()
                }}
    >
        <Tooltip value={info}>
            {q !== 0 ? NumberUtils.formatTwoDecimalsIfNeeded(q) : NBSP}
        </Tooltip>
    </div>
}