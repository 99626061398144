const filterTimeregabsencetypes = (timeregabsencetypeList, timeregabsenceList, disabled) => {

    const hasTimeregabsences = type => timeregabsenceList.find(ta => ta.type === type.id) !== undefined

    if (disabled) {
        return timeregabsencetypeList.filter(type => hasTimeregabsences(type))
    } else {
        return timeregabsencetypeList.filter(type => type.active || hasTimeregabsences(type))
    }
}
export default filterTimeregabsencetypes