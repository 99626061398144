import SearchUtils from "../utils/SearchUtils";

export const matchActive = (filter, item) => filter.active === undefined || item.active === filter.active;

export const matchSearch = (filter, item) => SearchUtils.isMatching(item, filter.search);

export function matchOnlyEmployees(item, onlyEmployees) {
    if (!onlyEmployees) {
        return true
    } else if (onlyEmployees) {
        return !item.hired
    }
    return false
}

export function matchOnlySubcontractors(item, onlySubcontractors) {
    if (!onlySubcontractors) {
        return true
    } else if (onlySubcontractors) {
        return item.subcontractor
    }
    return false
}

export const matchCostcenter = (filter, item) => match(filter, item, 'costcenter')

export const match = (filter, item, field) => !filter[field] || item[field] === filter[field]
