import {faPen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MultiSelect} from "primereact/multiselect";
import React from 'react';
import {Controller} from "react-hook-form";
import {i18n} from "../../I18n/I18n";
import ValidateUtils from "../../utils/ValidateUtils";
import ErrorText from "./ErrorText";

const FormItemMultiSelect = props => {
    const {
        control,
        formState: {errors},
        disabled,
        name,
        label,
        labelI18n,
        options,
        optionValue,
        optionLabel,
        onChange,

        required,

        width
    } = props

    const validation = {
        onChange: e => {
            if (onChange) onChange(e)
        }
    }
    if (required) {
        validation.required = ValidateUtils.required()
    }

    const style = {}
    if (width) {
        style.width = width + 'px'
    } else {
        style.width = '100%'
    }

    return <div className="formItem">
        <label htmlFor={name}>
            {labelI18n ? i18n(labelI18n) : label}
            {
                props.onEdit &&
                <div className='edit'>
                    <FontAwesomeIcon icon={faPen} className='faIcon' onClick={props.onEdit}/>
                </div>
            }
        </label>

        <Controller name={name}
                    control={control}
                    render={({field: {ref, value, onChange}}) =>
                        <MultiSelect value={value}
                                     onChange={onChange}
                                     options={options}
                                     optionValue={optionValue}
                                     optionLabel={optionLabel}
                                     display="chip"
                                     disabled={disabled}
                                     style={{width: '100%'}}
                        />

                    }
                    rules={validation}
        />
        <ErrorText errors={errors} name={name}/>
    </div>
}
export default FormItemMultiSelect;