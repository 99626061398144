import Role from "../../enums/Role";
import TimeregabsencetypeEditForm from "./TimeregabsencetypeEditForm";
import TimeregabsencetypeGrid from "./TimeregabsencetypeGrid";
import { faCoffee } from '@fortawesome/free-solid-svg-icons';

const TimeregabsencetypeIndex = {
    routeProps: {
        path: '/timeregabsencetypes',
        exact: true,
        element: <TimeregabsencetypeGrid/>,
    },
    nameKey: 'absence_types',
    // icon: 'pi pi-fw pi-folder',
    icon: faCoffee,
    subModules: [{
        routeProps: {
            path: '/timeregabsencetypes/:id',
            exact: true,
            element: <TimeregabsencetypeEditForm/>,
        }
    }],
    roles: [Role.ADMIN]
};
export default TimeregabsencetypeIndex;