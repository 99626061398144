import NetUtils from "../../utils/NetUtils";

const InvoiceService = {
    findAll: params => NetUtils.doGet('/api/invoices', params),
    findById: id => NetUtils.doGet('/api/invoices/' + id),
    findByProject: projectId => NetUtils.doGet('/api/invoices/project/' + projectId),
    update: (model, files) => NetUtils.doPostWithFiles('/api/invoices', model, files),

    downloadInvoicePdf: async invoiceId => NetUtils.downloadFile('/api/invoices/' + invoiceId + '/pdf'),

    generateRent: async (invoiceId, startDate, endDate) => NetUtils.doGet('/api/invoices/' + invoiceId + '/generate-rent',
        {
            startDate: startDate,
            endDate: endDate
        }),

    exportToFortnox: async (invoiceId, bookkeep) => NetUtils.doGet('/api/invoices/' + invoiceId + '/export-to-fortnox', {bookkeep: bookkeep}),

    sendByEmail: async (invoiceId, toEmail) => NetUtils.doGet('/api/invoices/' + invoiceId + '/send-by-email', {toEmail: toEmail}),

    downloadAppendix: (invoiceId, appendixId) => NetUtils.downloadFile('/api/invoices/' + invoiceId + "/appendix/" + appendixId),

    generateCreditNote: invoiceId => NetUtils.doGet('/api/invoices/generate-credit-note', {invoiceId: invoiceId}),

    copy: invoiceId => NetUtils.doGet('/api/invoices/' + invoiceId + "/copy"),

    downloadReminderPdf: async (invoiceId, reminderId) => NetUtils.downloadFile('/api/invoices/' + invoiceId + '/reminder/' + reminderId + '/pdf'),

    sendReminderByEmail: async (invoiceId, reminderId, toEmail) => NetUtils.doGet('/api/invoices/' + invoiceId + '/reminder/' + reminderId + '/send-by-email', {toEmail: toEmail}),

    checkPaid: () => NetUtils.doGet('/api/invoices/check-paid'),

    fetchFromFortnox: () => NetUtils.doGet('/api/invoices/fetch-from-fortnox'),
}
export default InvoiceService;