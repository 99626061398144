import React from 'react';
import {FooterAggregate} from "../../components/Grid/getFooterGroup";
import Grid from "../../components/Grid/Grid";
import {GridTemplates} from "../../GridTemplates";
import {i18n} from "../../I18n/I18n";
import {getYear} from "../../utils/DateUtils";
import TenderStatus from "../Tenders/TenderStatus";
import './Dashboard.scss'

const TenderTable = props => {
    const date = props.date
    const tenderUserStatusPriceList = props.data.tenderUserStatusPriceList;

    const columns = [
        {field: 'ourReferenceName', header: i18n('our_reference'),},
        {
            field: 'quantity', header: i18n('quantity'),
            body: GridTemplates.integer, width: 50, align: 'right', footer: FooterAggregate.SUM_INTEGER
        },
        {
            field: 'draftPrice', header: TenderStatus.getLabelMultiple(TenderStatus.DRAFT),
            body: GridTemplates.currency, width: 90, align: 'right', footer: FooterAggregate.SUM_CURRENCY
        },
        {
            field: 'unansweredPrice', header: TenderStatus.getLabelMultiple(TenderStatus.UNANSWERED),
            body: GridTemplates.currency, width: 90, align: 'right', footer: FooterAggregate.SUM_CURRENCY
        },
        {
            field: 'acceptedPrice', header: TenderStatus.getLabelMultiple(TenderStatus.ACCEPTED),
            body: GridTemplates.currency, width: 90, align: 'right', footer: FooterAggregate.SUM_CURRENCY
        },
        {
            field: 'notAcceptedPrice', header: TenderStatus.getLabelMultiple(TenderStatus.NOT_ACCEPTED),
            body: GridTemplates.currency, width: 100, align: 'right', footer: FooterAggregate.SUM_CURRENCY
        },
        {
            field: 'totalPrice', header: i18n('total'),
            body: GridTemplates.currency, width: 90, align: 'right', footer: FooterAggregate.SUM_CURRENCY
        },
        {
            field: 'yearPrice', header: TenderStatus.getLabelMultiple(TenderStatus.ACCEPTED) + ' ' + getYear(date),
            body: GridTemplates.currency, width: 120, align: 'right', footer: FooterAggregate.SUM_CURRENCY
        },
    ]

    return <div style={{width: '800px', margin: '40px auto 0 auto'}}>
        <Grid
            xlnzGrid={true}
            columns={columns}
            value={tenderUserStatusPriceList}
            onRowSelect={() => {
            }}
            forceDesktop={true}
            scrollable={false}
        />
    </div>
}

export default TenderTable;
