import {faChartLine} from "@fortawesome/free-solid-svg-icons";
import Dashboard from './Dashboard.js';
import Role from "../../enums/Role";

const DashboardIndex = {
    routeProps: {
        path: '/dashboard',
        exact: true,
        element: <Dashboard/>,
    },
    nameKey: 'dashboard',
    icon: faChartLine,
    roles: [Role.DASHBOARD_READ]
};
export default DashboardIndex;