import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import {addDays, formatDate} from "../../utils/DateUtils";
import DatePicker from "./DatePicker";
import './DateStepper.scss';

const DateStepper = props => {
    const {
        value,
        weekPicker = false
    } = props

    const onDateChange = e => {
        props.onChange(formatDate(e));
    }

    const onStep = numOfDays => {
        props.onChange(addDays(value, numOfDays));
    }

    return <div className='DateStepper'>
        <div className='stepper-left' onClick={e => {
            e.preventDefault()
            onStep(weekPicker ? -7 : -1)
        }}>
            <FontAwesomeIcon icon={faChevronLeft} className='faIcon'/>
        </div>
        <DatePicker value={value} weekPicker={weekPicker} onChange={onDateChange} showIcon={false}/>
        <div className='stepper-right' onClick={e => onStep(weekPicker ? 7 : 1)}>
            <FontAwesomeIcon icon={faChevronRight} className='faIcon'/>
        </div>
    </div>
}
export default DateStepper;
