export const STORAGE_PREFIX = 'Verktyg1-'

const LocalStorageUtils = {
    setItem: (key, value) => {
        localStorage.setItem(STORAGE_PREFIX + key, value + '')
    },
    getItem: (key, defaultValue = null) => {
        const result = localStorage.getItem(STORAGE_PREFIX + key)
        return result ? result : defaultValue
    },
    removeItem: (key) => localStorage.removeItem(STORAGE_PREFIX + key),
    clear: () => localStorage.clear()
}

export default LocalStorageUtils