import React from "react";


const TextAreaGridInput = props => {
    const {
        editorEvent,
        maxLength
    } = props

    if (!editorEvent) throw new Error("editorEvent parameter missing")

    const field = editorEvent.field
    const value = editorEvent.rowData[field]

    return <textarea
        type='text'
        autoComplete='off'

        defaultValue={value}
        onChange={e => {
            let _value = e.target.value
            if (maxLength > 0 && value.length > maxLength) _value = value.substring(0, maxLength)
            editorEvent.editorCallback(_value)
        }}
        autoFocus
        onFocus={e => e.target.select()}
        style={{
            width: '100%',
            height: '100%',
            padding: '5px',
            textAlign: 'left',
            fontSize: '12px'
        }}
    />
}
export default TextAreaGridInput;