import NetUtils from "../../../../utils/NetUtils";

const PaymentplanService = {
    findByProject: project => NetUtils.doGet('/api/paymentplans', {project: project}),
    findById: id => NetUtils.doGet('/api/paymentplans/' + id),
    update: model => NetUtils.doPost('/api/paymentplans', model),
    delete: model => NetUtils.doDelete('/api/paymentplans', model),

    downloadPdf: async id => NetUtils.downloadFile('/api/paymentplans/' + id + '/pdf'),

    findDrawsByProject: projectId => NetUtils.doGet('/api/paymentplandraws', {project: projectId}),
}
export default PaymentplanService;