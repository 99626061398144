import {useFilter} from "../../../components/Grid/filter";
import React, {useState} from "react";
import {matchSearch} from "../../../functions/match";
import usePreload from "../../../hooks/usePreload";
import useMountEffect from "../../../hooks/useMountEffect";
import SupplierinvoiceService from "../../Supplierinvoices/SupplierinvoiceService";
import ProjectService from "../ProjectService";
import {isNumeric, round} from "../../../utils/NumberUtils";
import {findById, sortByField} from "../../../utils/ArrayUtil";
import PdfColumn from "../../../components/Grid/PdfColumn";
import {GridTemplates} from "../../../GridTemplates";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Spinner from "../../../components/Spinner/Spinner";
import Grid from "../../../components/Grid/Grid";
import {i18n} from "../../../I18n/I18n";

export default function ProjectSummarySupplierinvoicesGrid({project, updateQuantity}) {
    const filter = useFilter('ProjectSummarySupplierinvoicesGrid', [
        {name: 'search', defaultValue: ''},
    ])
    const [list, setList] = useState()
    const filteredList = list?.filter(item => matchSearch(filter, item)) || []

    const preload = usePreload()
    const supplierList = preload.getSupplierList()

    useMountEffect(async () => {
        const [_invoiceList, invoicerowList] = await Promise.all([
            SupplierinvoiceService.findByProject(project.id),
            ProjectService.findSupplierrowsByProject(project.id)
        ])


        const getPercentageMarkup = sir => {
            const result = round((sir.toInvoice / sir.price - 1) * 100, 2)
            return isNumeric(result) ? result : 0
        }

        function getLeftToInvoice(sir) {
            return findById(invoicerowList, sir.id)?.leftToInvoice || 0
        }

        const _list = _invoiceList.flatMap(si => si.rowList
            .filter(sir => sir.project === project.id)
            .map(sir => {
                return {
                    supplierinvoiceId: si.id,
                    supplierinvoiceFileSize: si.fileSize,
                    supplierName: findById(supplierList, si.supplier)?.name,
                    supplierinvoiceNumber: si.number,
                    supplierinvoiceDate: si.invoiceDate,
                    name: sir.name,
                    price: sir.price,
                    percentageMarkup: getPercentageMarkup(sir),
                    toInvoice: sir.toInvoice,
                    leftToInvoice: getLeftToInvoice(sir),
                }
            }))

        sortByField(_list, 'supplierName')
        updateQuantity(_list.length)
        setList(_list)
    })

    const columns = [
        PdfColumn(rowData => rowData.supplierinvoiceFileSize > 0,
            rowData => rowData.supplierinvoiceNumber + ' - ' + rowData.supplierName,
            async rowData => (await SupplierinvoiceService.getImageUrl(rowData.supplierinvoiceId)).url
        ),
        {field: 'supplierName', headerI18n: 'supplier'},
        {field: 'supplierinvoiceNumber', headerI18n: 'invoice_number', width: 140},
        {field: 'supplierinvoiceDate', headerI18n: 'invoice_date', width: 120},
        {field: 'name', headerI18n: 'description'},
        {
            field: 'price',
            headerI18n: 'amount',
            body: GridTemplates.currencyTwoDecimals,
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
            align: 'right', width: 100
        },
        {
            field: 'percentageMarkup',
            headerI18n: 'percentage_markup',
            body: (rowData, column) => rowData.percentageMarkup !== -100 ? GridTemplates.percentTimes100(rowData, column) : '',
            align: 'right', width: 100,
        },
        {
            field: 'toInvoice',
            headerI18n: 'to_invoice',
            body: GridTemplates.currencyTwoDecimals,
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
            align: 'right', width: 100
        },
        {
            field: 'leftToInvoice',
            headerI18n: 'left_to_invoice',
            body: GridTemplates.currencyTwoDecimals,
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
            align: 'right', width: 120
        },
    ]

    if (!list) return <Spinner/>
    return <Grid
        xlnzGrid={true}
        name={project.name + ' - ' + i18n('supplier_invoices')}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        onRowSelect={() => {
        }}
        heightOffset={520}
    />
}