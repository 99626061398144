import React from 'react';
import Button from "../../../components/Buttons/Button";
import Grid from "../../../components/Grid/Grid";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import {GridTemplates} from "../../../GridTemplates";
import useRoles from "../../../hooks/useRoles";
import {formatTimeUtc, todayDate} from "../../../utils/DateUtils";
import LeadlogEditFormDialog from "./LeadlogEditFormDialog";

const LeadlogGrid = props => {
    const {
        value
    } = props

    const roles = useRoles()

    const fireChange = (list) => {
        props.onChange(list);
    }

    const columns = [
        {field: 'ctime', headerI18n: 'created', width: 140, body: GridTemplates.time},
        {field: 'date', headerI18n: 'date', width: 120},
        {field: 'value', headerI18n: 'value', body: GridTemplates.multilineString}
    ]

    function showEditDialog(modelToEdit) {
        console.log("modelToEdit",modelToEdit)
        renderOverlay(<LeadlogEditFormDialog visible={modelToEdit !== undefined}
                                             model={modelToEdit}
                                             onSave={savedModel => {
                                                 // console.log("modelToEditmodelToEdit", modelToEdit)
                                                 if (modelToEdit.newPrice) {
                                                     delete modelToEdit.newPrice;
                                                     let newList = [...props.value];
                                                     newList.push(savedModel);
                                                     fireChange(newList);
                                                 } else {
                                                     fireChange([...props.value]);
                                                 }
                                             }}
                                             onRemove={removeModel => {
                                                 let newList = [...props.value]
                                                 newList.splice(
                                                     props.value.findIndex(d => d.id === removeModel.id),
                                                     1
                                                 );
                                                 fireChange(newList);
                                             }}
        />)
    }

    return <div>
        <Grid
            xlnzGrid={true}
            hideFooter={true}
            scrollable={false}
            columns={columns}
            value={value}
            onRowSelect={e => showEditDialog(e.data)}
        />

        {
            roles.hasRole(Role.CRM_WRITE) &&
            <div style={{paddingTop: '10px'}}>
                <Button faicon={Icon.ADD} onClick={e => {
                    e.preventDefault()
                    showEditDialog({
                        ctime: formatTimeUtc(),
                        date: todayDate(),
                        value: '',
                        newPrice: true
                    })
                }}/>
            </div>
        }

    </div>
}

export default LeadlogGrid;
