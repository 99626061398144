import React, {useMemo} from 'react';
import {useWatch} from "react-hook-form";
import usePreload, {PreloadType} from "../../hooks/usePreload";
import {filterActive, findById} from "../../utils/ArrayUtil";
import {deepCopy} from "../../utils/ObjectUtils";
import FormItemDropdown from "./FormItemDropdown";

const FormItemCustomerAndContact = props => {
    const preload = usePreload()

    const onCustomerChange = props.onCustomerChange
    const form = props.form
    const {
        control,
        setValue,
        // getValues
    } = form

    const watchCustomerId = useWatch({control, name: 'customer'})

    const customerList = useMemo(() => {
        return filterActive(preload.get(PreloadType.CUSTOMER_LIST), watchCustomerId)
    }, [preload, watchCustomerId])


    const customer = findById(customerList, watchCustomerId)

    const contactList = deepCopy(customer?.contactList) || []
    contactList.forEach(item => {
        item.nameAndPhone = item.name + (item.phone0 ? ' - ' + item.phone0 : '')
    })

    // useEffect(() => {
    //     const customercontactId = getValues('customercontact')
    //     setValue('customercontact', customercontactId)
    // }, [watchCustomerId, setValue, getValues])

    return <div>
        <FormItemDropdown name='customer' labelI18n='customer'
                          options={customerList}
                          optionLabel='name'
                          onChange={e => {
                              const customer = findById(customerList, Number(e.target.value))
                              setValue('customercontact', undefined)
                              if (onCustomerChange) {
                                  onCustomerChange(customer)
                              }
                          }}
                          onEdit={props.onCustomerEdit}
                          required {...form} />
        <FormItemDropdown name='customercontact' labelI18n='contact_person'
                          options={contactList}
                          optionLabel='nameAndPhone'
                          showSelect
                          {...form} />
    </div>
}

export default FormItemCustomerAndContact