import Company from "../../enums/Company";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import LeadEditForm from "./editLead/LeadEditForm";
import NewsletterEditForm from "./editNewsletter/NewsletterEditForm";
import NewsletterunsubscribeEditForm from "./editNewsletterunsubscribe/NewsletterunsubscribeEditForm";
import LeadGrid from "./LeadGrid";
import NewsletterGrid from "./NewsletterGrid";
import UnsubscribeGrid from "./UnsubscribeGrid";

const CustomersIndex = {
    routeProps: {
        path: '/crm',
        exact: true,
        items: [
            {
                nameKey: 'leads',
                path: '/crm/leads',
                exact: true,
                element: <LeadGrid/>,
                onRowSelectPath: '/crm/leads'
            },
            {
                nameKey: 'newsletters',
                path: '/crm/newsletters',
                exact: true,
                element: <NewsletterGrid/>,
                onRowSelectPath: '/crm/newsletters'
            },
            {
                nameKey: 'unsubscribes',
                path: '/crm/unsubscribes',
                exact: true,
                element: <UnsubscribeGrid/>,
                onRowSelectPath: '/crm/unsubscribes'
            },
            // {
            //     name: 'Unsubscribe-ctrls',
            //     path: '/crm/unsubscribes-ctrls',
            //     exact: true,
            //     element: <UnsubscribeCtrlsGrid/>
            // }
        ]
    },
    name: 'CRM',
    icon: Icon.LEAD,
    subModules: [
        {
            routeProps: {
                path: '/crm/leads/:id',
                exact: true,
                element: <LeadEditForm/>
            }
        },
        {
            routeProps: {
                path: '/crm/newsletters/:id',
                exact: true,
                element: <NewsletterEditForm/>
            }
        },
        {
            routeProps: {
                path: '/crm/unsubscribes/:id',
                exact: true,
                element: <NewsletterunsubscribeEditForm/>
            }
        }
    ],
    isAccessable: (context) => context.currentCompany.id === Company.XLNZ_TECH,
    roles: [Role.CRM_READ]
};
export default CustomersIndex;