import {MainButton} from "../../components/Buttons/Button";
import FortnoxService from "./FortnoxService";

const CLIENT_ID = 'EXAO7eUMqQlu'
const REDIRECT_URL = process.env.NODE_ENV === "production"
    ? 'https://app.verktyg1.se'
    : 'https://b14e-2-137-141-246.ngrok-free.app'
export const FORTNOX_INTEGRATION_STATE = 'FortnoxIntegration'

const scopeList = [
    'bookkeeping',
    'payment',
    'invoice',
    'companyinformation',
    'inbox',
    'connectfile',
    'costcenter',
    'customer',
    'supplier',
    'supplierinvoice',
    'project',
    // 'salary',
]


const FortnoxAuthorizeButton = () => {
    const onSetupClick = async () => {
        const authState = (await FortnoxService.getAuthState()).value

        const url = 'https://apps.fortnox.se/oauth-v1/auth' +
            '?client_id=' + CLIENT_ID +
            '&redirect_uri=' + REDIRECT_URL +
            '&scope=' + scopeList.join('%20') +
            '&state=' + authState +
            '&access_type=offline' +
            '&response_type=code'

        console.log("Fortnox OAuth URL", url)
        window.open(url, '_blank')
    }
    return <MainButton label='Skapa ny koppling till Fortnox' onClick={onSetupClick}/>
}
export default FortnoxAuthorizeButton