import React, {useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import Checkbox from "../../components/EditForm/Checkbox";
import Label from "../../components/EditForm/Label";
import ActiveFilter from "../../components/Grid/ActiveFilter";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Role from "../../enums/Role";
import {matchActive, matchOnlySubcontractors, matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useMountEffect from "../../hooks/useMountEffect";
import usePreload, {PreloadType} from "../../hooks/usePreload";
import useRoles from "../../hooks/useRoles";
import {deepCopy} from "../../utils/ObjectUtils";
import StringUtils from "../../utils/StringUtil";

const SupplierGrid = props => {
    const roles = useRoles()
    const preload = usePreload()
    const filter = useFilter('SupplierGrid', [
        {name: 'active', defaultValue: true},
        {name: 'onlySubcontractors', defaultValue: false},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);
    list.forEach(item => {
        item.addressStr = StringUtils.formatAddress(item.address)
    })
    const filteredList = list.filter(item =>
        matchActive(filter, item) &&
        matchOnlySubcontractors(item, filter.onlySubcontractors) &&
        matchSearch(filter, item)
    )

    useMountEffect(async () => {
        showLoading()
        setList(deepCopy(await preload.load(PreloadType.SUPPLIER_LIST)))
        hideLoading()
    }, [])

    const leftFilters = [
        roles.hasRole(Role.SUPPLIER_WRITE) ? <div>
            <Label/>
            <AddButton/>
        </div> : undefined,
        <div>
            <Label valueI18n='status'/>
            <ActiveFilter value={filter.active} onChange={e => filter.update('active', e.value)}/>
        </div>,
        <div>
            <Label valueI18n='only_subcontractors'/>
            <Checkbox checked={filter.onlySubcontractors}
                      onChange={e => filter.update('onlySubcontractors', e.target.checked)}
                      style={{marginTop: '5px'}}/>
        </div>
    ]

    const columns = [
        {field: 'name', headerI18n: 'name'},
        {field: 'number', headerI18n: 'number', width: 100, mobile: true},
        {field: 'regNo', headerI18n: 'reg_no', width: 130},
        {field: 'bankgiro', header: 'Bankgiro', width: 130},
        {field: 'plusgiro', header: 'Plusgiro', width: 130},
        {field: 'addressStr', headerI18n: 'address'},
        {field: 'note', headerI18n: 'note', body: GridTemplates.stringLimit100},
        {field: 'active', headerI18n: 'active', body: GridTemplates.boolean, width: 100},
    ]

    return <Grid
        xlnzGrid={true}
        id='SupplierGrid'
        labelI18n='suppliers'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        paginator={true}
    />
}

export default SupplierGrid;
