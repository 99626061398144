import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import './GridLabel.scss'
import {i18n} from "../../I18n/I18n";

const GridLabel = ({icon, label, labelI18n}) => {
    return <span className='GridLabel'>
        {icon && <FontAwesomeIcon icon={icon} className='icon'/>}
        {label && <h1>{label}</h1>}
        {labelI18n && <h1>{i18n(labelI18n)}</h1>}
    </span>
}
export default GridLabel