import React from "react";
import {FORMROW_KEY_PREFIX} from "../FormanswerEditForm";

export default function FormtypeRadio(props) {
    const model = props.model
    const name = FORMROW_KEY_PREFIX + model.id

    const list = model.value.split(';')

    if (!props.getValues(name)) props.setValue(name, list[0])

    return <div style={styles.container}>
        <div className="formItem">
            <label htmlFor={name}>{model.name}</label>
        </div>
        <div style={styles.options}>
            {list.map((item, index) =>
                <label key={name + '-' + item} htmlFor={name + '-' + item} style={styles.optionLabel}>
                    <input
                        type="radio"
                        value={item}
                        id={name + '-' + item}
                        style={{
                            verticalAlign: 'middle',
                            width: '15px',
                            height: '15px',
                        }}
                        {...props.register(name)}
                    />
                    <div style={styles.optionName}>
                        {item}
                    </div>
                </label>)}
        </div>

    </div>
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: '10px',
        marginBottom: '10px'
    },
    options: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '3px'
    },
    optionLabel: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px'
    },
    optionName: {
        marginTop: '3px',
        marginLeft: '1px',
        fontSize: '12px'
    }
}