import React, {useCallback, useState} from 'react';
import {MainButton} from "../../../components/Buttons/Button";
import DatePicker from "../../../components/Date/DatePicker";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Icon from "../../../enums/Icon";
import {matchSearch} from "../../../functions/match";
import {GridTemplates} from "../../../GridTemplates";
import usePreload from "../../../hooks/usePreload";
import {i18n} from "../../../I18n/I18n";
import {distinct} from "../../../utils/ArrayUtil";
import {getFirstDateOfMonth, todayDate} from "../../../utils/DateUtils";
import NetUtils from "../../../utils/NetUtils";

const EmployeeStatistics = () => {
    const filter = useFilter('EmployeeStatistics', [
        {name: 'startDate', defaultValue: getFirstDateOfMonth()},
        {name: 'endDate', defaultValue: todayDate()},
        {name: 'search', defaultValue: ''},
    ])
    const [list, setList] = useState([])
    const [salaryColumns, setSalaryColumns] = useState([])
    const [absenceColumns, setAbsenceColumns] = useState([])

    const preload = usePreload()
    const salarytypeList = preload.getTimeregsalarytypeList()
    const absencetypeList = preload.getTimeregabsencetypeList()

    const filteredList = list.filter(item => matchSearch(filter, item))

    const loadData = useCallback(() => {
        showLoading()
        NetUtils.doGet('/api/reports/employee-statistics', {
            startDate: filter.startDate,
            endDate: filter.endDate,
        }).then(res => {

            // const _typeColumns = [
            //     ...distinct(res.flatMap(item => item.salarytypeItemList).map(item => item.id)),
            //     ...distinct(res.flatMap(item => item.absencetypeItemList).map(item => item.id))
            // ]
            // setTypeColumns(_typeColumns)

            const _salaryTypesIdsUsedList = distinct(res.flatMap(item => item.salarytypeItemList).map(item => item.id))
            const _salaryTypesUsed = salarytypeList.filter(item => _salaryTypesIdsUsedList.indexOf(item.id) !== -1)
            const _salaryColumns = _salaryTypesUsed.map(item => ({
                field: 'salarytype' + item.id,
                header: <div className='nowrap alignRight'>{item.name}</div>,
                label: item.name,
                body: GridTemplates.hours,
                excelBody: (row, column) => row[column.field],
                footer: FooterAggregate.SUM_HOURS,
                width: 100,
                align: 'right'
            }))
            setSalaryColumns(_salaryColumns)

            const _absenceTypesIdsUsedList = distinct(res.flatMap(item => item.absencetypeItemList).map(item => item.id))
            const _absenceTypesUsed = absencetypeList.filter(item => _absenceTypesIdsUsedList.indexOf(item.id) !== -1)
            const _absenceColumns = _absenceTypesUsed.map(item => ({
                field: 'absencetype' + item.id,
                header: <div className='nowrap alignRight'>{item.name}</div>,
                label: item.name,
                body: GridTemplates.hours,
                excelBody: (row, column) => row[column.field],
                footer: FooterAggregate.SUM_HOURS,
                width: 100,
                align: 'right'
            }))
            setAbsenceColumns(_absenceColumns)


            setList(res.map(item => {
                const result = {
                    employeeName: item.employeeName,
                    totalHours: 0
                }

                item.salarytypeItemList.forEach(item => {
                    result['salarytype' + item.id] = item.hours
                    result.totalHours += item.hours
                })
                item.absencetypeItemList.forEach(item => {
                    result['absencetype' + item.id] = item.hours
                    result.totalHours += item.hours
                })


                return result
            }))
            hideLoading()
        });
    }, [filter.startDate, filter.endDate, salarytypeList, absencetypeList]);

    const columns = [
        {field: 'employeeName', headerI18n: 'employee', minWidth: 150},
    ];
    salaryColumns.forEach(c => columns.push(c))
    absenceColumns.forEach(c => columns.push(c))
    columns.push({
        field: 'totalHours',
        header: i18n('total'),
        body: GridTemplates.hours,
        excelBody: (row, column) => row[column.field],
        footer: FooterAggregate.SUM_HOURS,
        width: 80,
        align: 'right'
    })


    const leftFilters = [
        <div>
            <label className="label">{i18n('start_date')}</label>
            <DatePicker value={filter.startDate} onChange={date => filter.update('startDate', date)}/>
        </div>,

        <div>
            <label className="label">{i18n('end_date')}</label>
            <DatePicker value={filter.endDate} onChange={date => filter.update('endDate', date)}/>
        </div>,
        <div>
            <label className="label">&nbsp;</label>
            <MainButton labelI18n='create_report' onClick={() => loadData()}/>
        </div>
    ]

    return <div className="Grid" style={{padding: '10px 0'}}>
        <Grid
            xlnzGrid={true}
            id='EmployeeStatistics'
            forceDesktop={true}
            labelI18n='employee_statistics'
            icon={Icon.REPORT}
            leftFilters={leftFilters}
            filter={filter}
            updateFilter={filter.update}
            columns={columns}
            value={filteredList}
            onRowSelect={() => {
            }}
        />
    </div>
}

export default EmployeeStatistics;