import ArrayUtil, {findByField} from "../utils/ArrayUtil";

export default class Currency {
    static get DKK() {
        return Object.freeze({id: 'DKK', suffix: 'kr'});
    }
    static get EUR() {
        return Object.freeze({id: 'EUR', suffix: '€'});
    }
    static get GBP() {
        return Object.freeze({id: 'GBP', suffix: '£'});
    }
    static get NOK() {
        return Object.freeze({id: 'NOK', suffix: 'kr'});
    }
    static get PLN() {
        return Object.freeze({id: 'PLN', suffix: 'zł'});
    }
    static get SEK() {
        return Object.freeze({id: 'SEK', suffix: 'kr'});
    }
    static get USD() {
        return Object.freeze({id: 'USD', suffix: '$'});
    }

    static values() {
        const result = [
            this.DKK,
            this.EUR,
            this.GBP,
            this.NOK,
            this.PLN,
            this.SEK,
            this.USD
        ];
        ArrayUtil.sortOnField(result, 'name')
        return result
    }

    static findById(id) {
        return findByField(this.values(), 'id', id)
    }
}