import NetUtils from "@utils/NetUtils";

const UserService = {
    findAllUsers: () => NetUtils.doGet('/api/users'),
    findUserById: id => NetUtils.doGet('/api/users/' + id),
    updateUser: model => NetUtils.doPost('/api/users', model),
    deleteUser: model => NetUtils.doDelete('/api/users', model),

    getLoginInfo: async () => {
        return await fetch('/api/users/login-info', {
            method: 'GET',
        }).then(res => {
            return res.ok ? res.json() : false;
        }, () => {
            return false
        });
    },

    changeLanguage: id => NetUtils.doPost('/api/users/change-language', {id: id}),

    // getRoles: () => NetUtils.doGet('/api/users/roles'),
    getPermissions: () => NetUtils.doGet('/api/users/permissions'),
    findAll: () => NetUtils.doGet('/api/users/company-mappings'),
    findById: id => NetUtils.doGet('/api/users/company-mappings/' + id),
    addCollaborator: model => NetUtils.doPost('/api/users/company-mappings', model),
    removeCollaborator: model => NetUtils.doDelete('/api/users/company-mappings', model),
}
export default UserService;