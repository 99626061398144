import {useContext} from "react";
import AppContext from "../../../AppContext";
import Companyprop from "../../../enums/Companyprop";
import Country from "../../../enums/Country";
import RentType from "../../../enums/RentType";
import useCompanyProp from "../../../hooks/useCompanyProp";
import {todayDate} from "../../../utils/DateUtils";
import TenderStatus from "../TenderStatus";

const useTenderDefaultValues = props => {
    const clean = props?.clean || false

    const context = useContext(AppContext);

    const defaultMessage = useCompanyProp(Companyprop.TENDER_MESSAGE, '')
    const defaultValidityTime = useCompanyProp(Companyprop.TENDER_VALIDITY_TIME, 30)
    const defaultPurchasePercentageMarkup = useCompanyProp(Companyprop.TENDER_PURCHASE_PERCENTAGE_MARKUP, 0)
    const defaultExpectedMargin = useCompanyProp(Companyprop.TENDER_EXPECTED_MARGIN, 0)

    const defaultRentRow0 = useCompanyProp(Companyprop.TENDER_RENT_ROW0, '')
    const RENT_DEFAULT_STRATEGY = useCompanyProp(Companyprop.RENT_DEFAULT_STRATEGY, RentType.WEEK.id)

    function getDefaultRentList() {
        if (defaultRentRow0.length > 0) {
            return [{
                id: 0,
                article: undefined,
                onlyTotal: true,
                name: defaultRentRow0,
                type: RENT_DEFAULT_STRATEGY,
                quantity: 0,
                aprice: 0,
                discount: 0,
                price: 0
            }]
        } else {
            return []
        }
    }

    return {
        id: 0,
        company: context.currentCompany.id,
        cuser: context.user.id,
        date: todayDate(),
        customer: undefined,
        customercontact: undefined,
        address: {
            address: '', zip: 0, city: '', country: Country.SE.id
        },
        message: clean ? '' : defaultMessage,
        ourReference: undefined,
        markup: '',
        validityTime: defaultValidityTime,
        purchasePercentageMarkup: defaultPurchasePercentageMarkup,
        expectedMargin: defaultExpectedMargin,
        note: '',
        status: TenderStatus.DRAFT,
        rowList: [],
        optionList: [],
        rentList: clean ? [] : getDefaultRentList(),
        howtoList: [],
        timeregdebittypepriceList: [],
        appendixList: [],
        sentlogList: []
    }
}
export default useTenderDefaultValues