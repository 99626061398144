import React, {useEffect, useRef} from "react";
import {useWatch} from "react-hook-form";
import ValidateUtils from "../../utils/ValidateUtils";
import ErrorText from "./ErrorText";

export default function TextareaInput(props) {
    const {
        register,
        formState: {errors},
        disabled,
        name,
        required,
        maxLength,

        validation = {},
        placeholder
    } = props

    if (!name) throw new Error('Property "name" is missing.')

    const elementRef = useRef();
    // const cachedScrollHeight = React.useRef(0);

    if (required) {
        validation.required = ValidateUtils.required()
    }
    if (maxLength) {
        validation.maxLength = ValidateUtils.maxLength(maxLength)
    }

    function rezizeToContent(initial) {
        const textareaRef = elementRef
        if (textareaRef && textareaRef.current) {
            textareaRef.current.style.height = 'inherit'; // Nollställ höjden för att få korrekt mätning
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Anpassa till innehållets höjd
        }
        // const inputEl = elementRef.current;
        // console.log("inputEl", inputEl)
        // if (inputEl) {
        //     if (!cachedScrollHeight.current) {
        //         cachedScrollHeight.current = inputEl.scrollHeight;
        //         inputEl.style.overflow = 'hidden';
        //     }
        //
        //     if (cachedScrollHeight.current !== inputEl.scrollHeight || initial) {
        //         inputEl.style.height = '';
        //         inputEl.style.height = inputEl.scrollHeight + 'px';
        //
        //         if (parseFloat(inputEl.style.height) >= parseFloat(inputEl.style.maxHeight)) {
        //             inputEl.style.overflowY = 'scroll';
        //             inputEl.style.height = inputEl.style.maxHeight;
        //         } else {
        //             inputEl.style.overflow = 'hidden';
        //         }
        //
        //         cachedScrollHeight.current = inputEl.scrollHeight;
        //     }
        // }
    }

    const control = props.control
    const watchValue = useWatch({control, name: name})

    useEffect(() => {
        // console.log("useEffect")
        rezizeToContent();
        // setTimeout(rezizeToContent, 250); // Försenar exekveringen till nästa event loop

    }, [watchValue]); // Effekten körs när 'text' uppdateras


    // useLayoutEffect(() => {
    //     console.log("useLayoutEffect")
    //     rezizeToContent();
    // }, [watchValue]);

    // useEffect(() => {
    //     rezizeToContent(document.getElementById('textarea-' + name))
    // }, [name, watchValue]);

    const {ref, ...rest} = register(name, validation);

    return <>
        <textarea
            id={'textarea-' + name}
            rows="auto"
            className='textarea'
            disabled={disabled}
            placeholder={placeholder}
            onInput={e => rezizeToContent(e.target)}
            {...rest}
            ref={(e) => {
                ref(e)
                elementRef.current = e // you can still assign to ref
            }}
            style={{
                resize: 'none'
            }}
        />
        <ErrorText errors={errors} name={name}/>
    </>
}