import React from "react";
import Unit from "../../enums/Unit";
import FormItemDropdown from "./FormItemDropdown";

const FormItemUnit = props => {
    return <FormItemDropdown name='unit' labelI18n='unit'
                             options={Unit.values()}
                             required
                             {...props} />
}
export default FormItemUnit