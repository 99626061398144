import React, {Children, cloneElement, isValidElement, useRef, useState} from "react";
import './TabView.scss'
import {i18n} from "../../I18n/I18n";
import useForceUpdate from "../../hooks/useForceUpdate";

export default function TabView(props) {
    const {
        children
    } = props

    const forceUpdate = useForceUpdate()
    const quantityMapRef = useRef(new Map())

    const _children = children.filter !== undefined
        ? children.filter(item => item != null && item !== false)
        : children

    // Make sure keys are presnt.
    for (let i = 0; i < _children.length; i++) {
        const child = _children[i]
        if (!child.key) {
            throw new Error("Child '" + child.props.header + child.props.headerI18n + "' missing key.")
        }
    }

    function updateQuantity(index, _quantity) {
        quantityMapRef.current.set(index, _quantity)
        forceUpdate()
    }

    const [activeIndex, setActiveIndex] = useState(props.activeIndex || 0)

    const childrenWithProps = Children.map(_children, (child, index) => {
        if (isValidElement(child)) {
            return cloneElement(child, {
                active: activeIndex === index,
                updateQuantity: _quantity => updateQuantity(index, _quantity),
                quantity: index + 10
            });
        }
        return child;
    })

    return <div className='TabView'>
        <header>
            {
                React.Children
                    .map(_children, (item, index) => {
                            if (!item) return
                            return <TabHeaderItem key={item.key}
                                                  item={item}
                                                  index={index}
                                                  activeIndex={activeIndex}
                                                  onActiveIndexChange={setActiveIndex}
                                                  quantity={quantityMapRef.current.get(index)}
                            />
                        }
                    )
            }
        </header>

        {childrenWithProps}
    </div>
}


function TabHeaderItem(props) {
    const {
        item,
        index,
        activeIndex,
        onActiveIndexChange,
        quantity
    } = props

    let className
    if (index === activeIndex) {
        className = 'active'
    }
    return <div key={item.key}
                className={className}
                onClick={() => {
                    onActiveIndexChange(index)
                }}
    >
        {item.props.header}
        {item.props.headerI18n && i18n(item.props.headerI18n)}

        {quantity !== undefined && ' (' + quantity + ')'}
    </div>
}