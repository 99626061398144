import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import InvoiceEditForm from "./edit/InvoiceEditForm";
import InvoiceGrid from "./InvoiceGrid";

const InvoicesIndex = {
    routeProps: {
        path: '/invoices',
        exact: true,
        element: <InvoiceGrid/>,
    },
    nameKey: 'customer_invoices',
    icon: Icon.INVOICE,
    subModules: [{
        routeProps: {
            path: '/invoices/:id',
            exact: true,
            element: <InvoiceEditForm/>,
        }
    }],
    roles: [Role.INVOICE_READ]
};
export default InvoicesIndex;