import React from 'react';
import {DebounceInput} from "react-debounce-input";
import useMediaQuery from "../../hooks/useMediaQuery";
import {i18n} from "../../I18n/I18n";
import {getModule} from "../../modules/modules";
import Env from "../../utils/Env";
import NetUtils from "../../utils/NetUtils";
import XlnzGrid from "../XlnzGrid/XlnzGrid";
import ExtraMenu from "./ExtraMenu";
import './Grid.scss';
import GridLabel from "./GridLabel";
import MobileGrid from "./MobileGrid";

const Grid = props => {
    const {
        xlnzGrid = true,
        id,
        disabled,
        sortable = true,
        onSortFieldChange,
        onSortOrderChange,
        name,
        labelI18n,
        excelLabelI18n,
        icon,
        gridLabelRightContent,
        leftFilters,
        rightFilters,
        hideRightFilters,
        onExportToExcel,
        filter,
        updateFilter,
        // headerGroup,
        uberColumns,
        columns,
        rowClassName,
        value,
        width,
        heightOffset,
        rowSelectParams = '',
        selection,
        onSelectionChange,
        // footerColumnGroup,
        onRowReorder,
        scrollable = true,
        forceDesktop = false,
        // resizableColumns = false,
        hideHeader = false,
        hideFooter = false,
        hideExtraMenu = false,
        customFooter,
        cellClassName
    } = props
    let onRowSelect = props.onRowSelect
    let menuItems = props.menuItems

    const mediaQuery = useMediaQuery()

    const contextMenu = props.contextMenu
        ?.map(item => {
            const _item = {...item}
            _item.originalCommand = item.command
            _item.command = () => _item.originalCommand()
            // _item.command = () => _item.originalCommand(contextMenuSelection)

            _item.originalTemplate = item.template
            _item.template = (item, options) => _item.originalTemplate(item, options)
            // _item.template = (item, options) => _item.originalTemplate(item, options, contextMenuSelection)

            return _item
        })
    // let cm = useRef()
    // const [contextMenuSelection, setContextMenuSelection] = useState();

    // const paginator = props.paginator
    // const [pagination, setPagination] = useState({first: 0, rows: 50});


    // var gridElement = document.querySelector('.XlnzReactGrid');
    // if (id && gridElement) {
    //     gridElement.addEventListener('scroll',
    //         () => sessionStorage.setItem(id + '-scrollPosition', gridElement.scrollTop)
    //     )
    // }
    //
    // function saveStateBeforeLeaving() {
    //     if (id) {
    //         var gridElement = document.querySelector('.XlnzReactGrid');
    //         if (gridElement) {
    //             var scrollY = gridElement.scrollTop;
    //             sessionStorage.setItem(id + '-scrollPosition', scrollY)
    //         }
    //     } else {
    //         if (xlnzGrid) console.log("Grid: No id present")
    //     }
    // }
    //
    // function restoreStateAfterReturning() {
    //     if (id) {
    //         var gridElement = document.querySelector('.XlnzReactGrid');
    //         if (gridElement) {
    //             var savedScrollPosition = sessionStorage.getItem(id + '-scrollPosition');
    //             if (savedScrollPosition !== null) gridElement.scrollTop = savedScrollPosition
    //         }
    //     }
    // }
    //
    // restoreStateAfterReturning()


    let module = getModule(window.location.hash.substring(1))
    if (!onRowSelect && module) {
        if (module.onRowSelectPath) {
            onRowSelect = e => {
                window.location.href = '/#' + module.onRowSelectPath + '/' + e.data.id + rowSelectParams
            }
        } else {
            const path = module?.routeProps?.path || module?.path
            onRowSelect = e => window.location.href = '/#' + path + '/' + e.data.id + rowSelectParams
        }
    }


    // const onPage = e => {
    //     setPagination({
    //         first: e.first,
    //         rows: e.rows
    //     })
    // }

    // if (filter?.get('sortField')) {
    //     menuItems = menuItems ? [...menuItems] : []
    //     menuItems.push({
    //         label: i18n('clear_sort'),
    //         faicon: Icon.REMOVE,
    //         command: async () => {
    //             filter?.remove('sortField')
    //             filter?.remove('sortOrder')
    //         }
    //     })
    // }

    // function onSort(e) {
    //     const oldSortField = filter.get('sortField')
    //     const oldSortOrder = filter.get('sortOrder')
    //
    //     if (oldSortField === e.sortField && oldSortOrder === -1) {
    //         filter?.remove('sortField')
    //         filter?.remove('sortOrder')
    //         if (onSortFieldChange) onSortFieldChange({value: undefined})
    //     } else {
    //         filter?.update('sortField', e.sortField)
    //         filter?.update('sortOrder', e.sortOrder)
    //         // console.log("onSortFieldChange", onSortFieldChange, e.sortField)
    //         if (onSortFieldChange) onSortFieldChange({value: e.sortField})
    //     }
    // }

    // const showRowReorder = !disabled && onRowReorder

    if (Env.isDev() && (!mediaQuery.isMobile() || forceDesktop) && !xlnzGrid) {
        console.warn("Primereact DataTable is used: ", labelI18n ? i18n(labelI18n) : props.label)
    }


    const filterHeight = document.getElementById('filter')?.offsetHeight || 0

    return <div id={id} className="Grid">
        <div className='grid-label'>
            <GridLabel icon={icon} label={labelI18n ? i18n(labelI18n) : props.label}/>
            <div style={{flexGrow: '1'}}></div>
            <div className='right'>{gridLabelRightContent}</div>
        </div>
        {
            filter &&
            <div id='filter' className="filter">
                {leftFilters && leftFilters.map((f, index) => <div key={index}>{f}</div>)}

                <div style={{flexGrow: 1, padding: 0}}></div>

                {rightFilters && rightFilters.map((f, index) => <div key={index}>{f}</div>)}
                {
                    !hideRightFilters &&
                    <>
                        <div>
                            <label className="label">{i18n('search')}</label>
                            <DebounceInput
                                name='search'
                                value={filter.search}
                                className="search"
                                // minLength={2}
                                debounceTimeout={300}
                                onChange={(e) => {
                                    if (updateFilter) updateFilter('search', e.target.value)
                                    else filter.update('search', e.target.value)
                                }}/>
                        </div>


                        {
                            !hideExtraMenu &&
                            <div style={{paddingRight: 0}}>
                                <label className="label">&nbsp;</label>
                                <ExtraMenu itemList={menuItems}
                                           list={value}
                                           onExportToExcel={() => {
                                               if (onExportToExcel) onExportToExcel()
                                               else {
                                                   NetUtils.exportToExcel(name
                                                           ? name
                                                           : excelLabelI18n ? i18n(excelLabelI18n) : i18n(labelI18n)
                                                       , uberColumns, columns, value)
                                               }
                                           }
                                           }/>
                            </div>
                        }
                    </>
                }
            </div>
        }

        {/*{*/}
        {/*    contextMenu &&*/}
        {/*    <ContextMenu model={contextMenu} ref={cm} onHide={() => setContextMenuSelection(undefined)}/>*/}
        {/*}*/}

        {
            mediaQuery.isMobile() && !forceDesktop &&
            <MobileGrid value={value} columns={columns} onRowSelect={onRowSelect}/>
        }

        {
            (!mediaQuery.isMobile() || forceDesktop) && xlnzGrid &&
            <XlnzGrid
                id={id}
                value={value}
                disabled={disabled}
                uberColumns={uberColumns}
                columns={columns}
                sortable={sortable}
                onSortFieldChange={onSortFieldChange}
                onSortOrderChange={onSortOrderChange}
                onRowSelect={e => {
                    if (!onRowSelect) return
                    // if (e.originalEvent.target.className !== 'p-editable-column') {
                    onRowSelect(e)
                    // }
                }}
                width={width}
                scrollHeight={scrollable
                    ? ('calc(100dvh - ' + (heightOffset ? heightOffset : filterHeight + 140) + 'px)')
                    : undefined}
                contextMenuOptions={contextMenu}
                hideHeader={hideHeader}
                hideFooter={hideFooter}
                customFooter={customFooter}
                onRowReorder={disabled ? undefined : onRowReorder}
                selection={selection}
                onSelectionChange={onSelectionChange}
                rowClassName={rowClassName}
                cellClassName={cellClassName}
            />
        }
        {/*{*/}
        {/*    (!mediaQuery.isMobile() || forceDesktop) && !xlnzGrid &&*/}
        {/*    <DataTable*/}
        {/*        className="dataTable"*/}
        {/*        rowClassName={rowClassName}*/}
        {/*        value={value}*/}
        {/*        editMode='cell'*/}
        {/*        headerColumnGroup={headerGroup}*/}
        {/*        footerColumnGroup={footerColumnGroup ? footerColumnGroup : getFooterGroup(columns, value, showRowReorder)}*/}
        {/*        selectionMode={"single"}*/}
        {/*        onRowSelect={e => {*/}
        {/*            if (!onRowSelect) return*/}
        {/*            if (e.originalEvent.target.className !== 'p-editable-column') {*/}
        {/*                onRowSelect(e)*/}
        {/*            }*/}
        {/*        }}*/}
        {/*        scrollable={scrollable}*/}
        {/*        scrollHeight={scrollable ? ('calc(100vh - ' + (paginator ? 30 + heightOffset : heightOffset) + 'px)') : undefined}*/}

        {/*        selection={selection}*/}
        {/*        onSelectionChange={onSelectionChange}*/}

        {/*        contextMenuSelection={contextMenuSelection}*/}
        {/*        onContextMenuSelectionChange={CONTEXT_MENU_ENABLED ? e => setContextMenuSelection(e.value) : undefined}*/}
        {/*        onContextMenu={CONTEXT_MENU_ENABLED ? e => cm.current && cm.current.show(e.originalEvent) : undefined}*/}

        {/*        paginator={paginator}*/}
        {/*        first={pagination.first}*/}
        {/*        rows={pagination.rows}*/}
        {/*        onPage={onPage}*/}
        {/*        rowsPerPageOptions={[50, 100, 500, 1000]}*/}

        {/*        reorderableRows*/}
        {/*        onRowReorder={onRowReorder}*/}

        {/*        sortField={filter?.get('sortField')}*/}
        {/*        sortOrder={filter?.get('sortOrder')}*/}
        {/*        onSort={onSort}*/}
        {/*        resizableColumns={resizableColumns}*/}
        {/*        columnResizeMode='fit'*/}
        {/*    >*/}
        {/*        {*/}
        {/*            showRowReorder &&*/}
        {/*            <Column rowReorder style={{width: '20px', minWidth: '30px', maxWidth: '30px', cursor: 'pointer'}}/>*/}
        {/*        }*/}
        {/*        {*/}
        {/*            columns.map(c => {*/}
        {/*                const style = c.style || {}*/}
        {/*                style.minHeight = '30px'*/}
        {/*                if (c.align) {*/}
        {/*                    style.textAlign = c.align*/}
        {/*                    // style.display = 'flex'*/}
        {/*                    style.justifyContent = c.align*/}
        {/*                }*/}
        {/*                if (c.width) {*/}
        {/*                    const widthPx = c.width + 'px'*/}
        {/*                    style.width = (c.width - 10) + 'px'*/}
        {/*                    style.minWidth = widthPx*/}
        {/*                    style.maxWidth = widthPx*/}
        {/*                }*/}

        {/*                return <Column key={c.field}*/}
        {/*                               field={c.field}*/}
        {/*                               header={*/}
        {/*                                   c.header ? c.header*/}
        {/*                                       : c.headerI18n ? i18n(c.headerI18n) : undefined*/}
        {/*                               }*/}
        {/*                               headerStyle={style}*/}
        {/*                               style={style}*/}
        {/*                               className={c.className}*/}
        {/*                               body={c.body}*/}
        {/*                               editor={disabled ? undefined : c.editor}*/}
        {/*                               onCellEditComplete={c.onCellEditComplete}*/}
        {/*                               sortable={sortable}*/}
        {/*                               selectionMode={c.selectionMode}*/}
        {/*                />*/}
        {/*            })*/}
        {/*        }*/}
        {/*    </DataTable>*/}
        {/*}*/}
    </div>
}

export default Grid;

