import NetUtils from "../../../utils/NetUtils";

const MonthoverviewService = {
    findByFilter: filter => NetUtils.doGet('/api/timereg/monthoverview', filter),
    downloadPdf: (startDate, endDate, employeeIdList) =>
        NetUtils.downloadFile('/api/timereg/monthoverview/pdf/' + startDate + '/' + endDate, {
            employeeIdList: employeeIdList
        }),
    downloadVismalon: (startDate, endDate, employeeIdList) =>
        NetUtils.downloadFile('/api/timereg/monthoverview/vismalon/' + startDate + '/' + endDate, {
            employeeIdList: employeeIdList
        }),
    downloadFortnoxlon: (startDate, endDate, employeeIdList) =>
        NetUtils.downloadFile('/api/timereg/monthoverview/fortnoxlon/' + startDate + '/' + endDate, {
            employeeIdList: employeeIdList
        }),
    downloadHogiabygglon: (startDate, endDate, employeeIdList) =>
        NetUtils.downloadFile('/api/timereg/monthoverview/hogiabygglon/' + startDate + '/' + endDate, {
            employeeIdList: employeeIdList
        }),
};
export default MonthoverviewService;