import heic2any from "heic2any";


export const getConvertedImages = async files => {
    const promises = files.map(getConvertedImage);
    return await Promise.all(promises);
};

export const getConvertedImage = async file => {
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (fileExtension === 'heic') {
        const convertedBlob = await heic2any({
            blob: file,
            toType: 'image/jpeg',
            quality: 0.8,
        });
        const scaledBlob = await scaleImage(convertedBlob, 1000, 1000);
        return new File(
            [scaledBlob],
            file.name.replace(/\.[^.]+$/, '.jpg'), // Ändra filnamnets tillägg till .jpg
            {type: 'image/jpeg'}
        )
    } else if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
        const scaledBlob = await scaleImage(file, 1000, 1000);
        return new File(
            [scaledBlob],
            file.name,
            {type: 'image/jpeg'}
        )
    } else if (fileExtension === 'png') {
        const scaledBlob = await scaleImage(file, 1000, 1000);
        return new File(
            [scaledBlob],
            file.name,
            {type: 'image/png'}
        )
    } else {
        return file
    }
}

function scaleImage(blob, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(blob);
        img.onload = () => {
            let width = img.width;
            let height = img.height;

            // Beräkna skalfaktorn och justera bredd och höjd för att bevara proportionerna
            if (width > maxWidth || height > maxHeight) {
                const widthRatio = maxWidth / width;
                const heightRatio = maxHeight / height;
                const scaleFactor = Math.min(widthRatio, heightRatio);

                width = Math.floor(width * scaleFactor);
                height = Math.floor(height * scaleFactor);
            }

            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob((scaledBlob) => {
                if (scaledBlob) {
                    resolve(scaledBlob);
                } else {
                    reject(new Error('Skalning misslyckades.'));
                }
            }, 'image/jpeg', 0.8); // Output kvalitet och typ
        };
        img.onerror = (error) => reject(error);
    });
}