import Companyprop from "../../enums/Companyprop";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import HyrletOrderEditForm from "./Orders/edit/HyrletOrderEditForm";
import HyrletOrderGrid from "./Orders/HyrletOrderGrid";
import PortalRentGrid from "./Rent/PortalRentGrid";

const AccountsIndex = {
    routeProps: {
        path: '/hyrlet',
        exact: true,
        items: [
            {
                name: 'Ordrar',
                path: '/hyrlet/orders',
                exact: true,
                element: <HyrletOrderGrid/>,
                onRowSelectPath: '/hyrlet/orders'
            },
            {
                name: 'Hyresunderlag',
                path: '/hyrlet/rent',
                exact: true,
                element: <PortalRentGrid/>,
                onRowSelectPath: '/hyrlet/rent'
            },
        ]
    },
    name: 'Hyrlet',
    icon: Icon.PLUGIN,
    subModules: [{
        routeProps: {
            path: '/hyrlet/orders/:id',
            exact: true,
            element: <HyrletOrderEditForm/>,
        }
    }],
    isAccessable: (context) => {
        return context.currentCompany.propList.find(p => p.name === Companyprop.HYRLET_CUSTOMER_ID.name) !== undefined
    },
    roles: [Role.HYRLET]
};
export default AccountsIndex;