import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import DocumentEditForm from "./DocumentEditForm";
import DocumentGrid from "./DocumentGrid";

const DocumentsIndex = {
    routeProps: {
        path: '/documents',
        exact: true,
        element: <DocumentGrid/>,
    },
    nameKey: 'documents',
    icon: Icon.DOCUMENT,
    subModules: [{
        routeProps: {
            path: '/documents/:id',
            exact: true,
            element: <DocumentEditForm/>,
        }
    }],
    roles: [Role.DOCUMENT_READ]
};
export default DocumentsIndex;