import React from "react";
import FormItemCheckbox from "../../../components/EditForm/FormItemCheckbox";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemEmail from "../../../components/EditForm/FormItemEmail";
import FormItemText from "../../../components/EditForm/FormItemText";
import SimpleEditForm from "../../../components/EditForm/SimpleEditForm";
import Language from "../../../enums/Language";
import Role from "../../../enums/Role";
import {PreloadType} from "../../../hooks/usePreload";
import UserService from "../../Users/UserService";

export default function AgreementUserEditForm() {
    return <SimpleEditForm
        writeRole={Role.SUPERADMIN}
        headlineI18n='user'
        defaultValues={{
            active: true
        }}
        loadFunction={UserService.findUserById}
        saveFunction={UserService.updateUser}
        removeFunction={UserService.deleteUser}
        initLeftFields={form => [
            <FormItemText key='name' name='name' labelI18n='name' maxLength={128} required {...form}/>,
            <FormItemEmail key='email' name='email' labelI18n='email' maxLength={128} required {...form}/>,
            <FormItemDropdown key='language' name='language' labelI18n='language'
                              options={Language.values()}
                              showSelect={false}
                              {...form}
            />,
            <FormItemCheckbox key='active' name='active' labelI18n='active' {...form}/>
        ]}
        preloadType={PreloadType.ACCOUNT_LIST}
    />
}
