import '@components/EditForm/EditForm.scss'
import React, {useState} from 'react';
import Button, {CancelButton, MainButton} from "../../../components/Buttons/Button";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemEmail from "../../../components/EditForm/FormItemEmail";
import SimpleEditForm from "../../../components/EditForm/SimpleEditForm";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import Role from "../../../enums/Role";
import NewsletterService from "../NewsletterService";
import './NewsletterunsubscribeEditForm.scss'

const NewsletterunsubscribeEditForm = () => {
    const [newsletterList, setNewsletterList] = useState()

    return <SimpleEditForm
        writeRole={Role.CRM_WRITE}
        headlineI18n='unsubscribes'
        preLoadFunction={async id => {
            setNewsletterList(await NewsletterService.findAll())
        }}
        loadFunction={NewsletterService.findUnsubscribeById}
        saveFunction={NewsletterService.updateUnsubscribe}
        removeFunction={NewsletterService.deleteUnsubscribe}
        initRightButtons={form => [
            <Button key='token' label='Token' onClick={e => renderOverlay(<XlnzDialog
                buttons={dialogProps => [
                    <MainButton key='save' labelI18n='save'
                                onClick={async () => {
                                    const token = form.getValues('token')
                                    const _model = await NewsletterService.findUnsubscribeByToken(token)
                                    form.setValue('email', _model.email)
                                    form.setValue('newsletter', _model.newsletter)
                                    dialogProps.onHide()
                                }}
                    />,
                    <CancelButton key='cancel' onClick={dialogProps.onHide}/>
                ]}
            >
                <div className='EditForm'>
                    <FormItemEmail name='token' label='token'
                                   style={{minWidth: '700px'}}
                                   required {...form}/>
                </div>
            </XlnzDialog>)
            }/>
        ]}
        initLeftFields={form => [
            <FormItemEmail key='name' name='email' labelI18n='email'
                           required {...form}/>,
            <FormItemDropdown key='newsletter' name='newsletter' labelI18n='newsletter'
                              showSelect={true}
                              options={newsletterList}
                              style={{maxWidth: '200px'}}
                              {...form} />
        ]}
    />
}

export default NewsletterunsubscribeEditForm;
