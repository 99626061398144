import React, {useState} from "react";
import {FetchButton} from "../../../../components/Buttons/Button";
import renderOverlay from "../../../../components/Overlay/renderOverlay";
import Vat from "../../../../enums/Vat";
import useDepsEffect from "../../../../hooks/useEffect";
import {i18n} from "../../../../I18n/I18n";
import {findById, sum} from "../../../../utils/ArrayUtil";
import {getDiscountFactor, NumberUtils, round} from "../../../../utils/NumberUtils";
import {deepCopy} from "../../../../utils/ObjectUtils";
import TenderService from "../../../Tenders/TenderService";
import {addTitle} from "../FetchHelper";
import FetchTenderrowsDialog from "./FetchTenderrowsDialog";

export const FetchTenderrowsButton = props => {
    const {
        project,
        rowList,
        tender,
        onAddRows,
        onLeftToInvoiceReport
    } = props

    const [uninvoicePrice, setUninvoicePrice] = useState(0)
    const [allTenderrowList, setAllTenderrowList] = useState(0)
    const [tenderrowList, setTenderrowList] = useState(0)

    // When project is changed
    useDepsEffect(() => {
        if (project && project.id > 0 && project.tender > 0) {
            TenderService.findRowsByProject(project.id).then(_tenderrowList => {
                _tenderrowList = filterTenderrowsInOtherInvoices(_tenderrowList)
                setAllTenderrowList(_tenderrowList)
            })
        }
    }, [project?.id])

    // When invoice rows are changed
    useDepsEffect(() => {
        if (allTenderrowList && project && project.id > 0) {
            let _tenderrowList = deepCopy(allTenderrowList)

            decreaseQuantityOfUnsavedRowsInThisInvoice(_tenderrowList)
            calculateLeftToInvoice(_tenderrowList)

            setTenderrowList(_tenderrowList)
            const _leftToInvoicePrice = sum(_tenderrowList, 'leftToInvoicePrice');
            setUninvoicePrice(_leftToInvoicePrice)
            onLeftToInvoiceReport(_leftToInvoicePrice)
        }
    }, [allTenderrowList, rowList])

    const filterTenderrowsInOtherInvoices = tenderrowList => {
        tenderrowList.forEach(tr => {
            tr.leftToInvoicePrice = tr.price

            const proposalIrtrList = tr.invoicerowtenderrowList
            proposalIrtrList.forEach(proposalIrtr => {
                if (proposalIrtr.invoicerow > 0 && !isInThisInvoice(proposalIrtr)) {
                    tr.leftToInvoicePrice -= proposalIrtr.price
                }
            })
        })

        return tenderrowList
    }

    function isInThisInvoice(proposalIrtr) {
        return getInvoiceIrtrList().filter(invoiceIrtr => proposalIrtr.invoicerow === invoiceIrtr.invoicerow &&
            proposalIrtr.tenderrow === invoiceIrtr.tenderrow).length > 0
    }

    function getInvoiceIrtrList() {
        return rowList
            .flatMap(r => r.tenderrowList)
            .filter(invoiceIrtr => invoiceIrtr !== undefined)
    }

    function decreaseQuantityOfUnsavedRowsInThisInvoice(_tenderrowList) {
        getInvoiceIrtrList().forEach(invoiceIrtr => {
            const _tr = findById(_tenderrowList, invoiceIrtr.tenderrow)
            if (_tr) _tr.leftToInvoicePrice -= invoiceIrtr.price
        })
    }

    function calculateLeftToInvoice(tenderrowList) {
        tenderrowList.forEach(_tr => _tr.priceToInvoice = 0)
    }

    const onSave = (tenderrowList, config) => {
        console.log("tenderrowList", tenderrowList)
        const invoiceRows = []
        addTitle(rowList, config, invoiceRows, i18n('tender') + ' ' + tender?.number)

        tenderrowList.forEach(tr => {
            const invoicerowtenderrowList = []
            invoicerowtenderrowList.push({
                invoicerow: 0,
                tenderrow: tr.id,
                price: tr.priceToInvoice
            })

            const quantity = round(tr.priceToInvoice / tr.aprice / getDiscountFactor(tr.discount), 2)
            const comesOutEven = quantity * tr.aprice * getDiscountFactor(tr.discount) === tr.priceToInvoice

            invoiceRows.push({
                headline: tr.headline,
                onlyTotal: tr.onlyTotal,
                housework: tr.housework,
                name: tr.name,
                unit: tr.unit,
                quantity: comesOutEven ? quantity : 1,
                aprice: comesOutEven ? tr.aprice : tr.priceToInvoice,
                discount: comesOutEven ? tr.discount : 0,
                price: tr.priceToInvoice,
                vat: Vat.VAT25.id,
                account: tr.accountId,
                costcenter: project.costcenter,
                tenderrowList: invoicerowtenderrowList
            })
        })
        onAddRows(invoiceRows)
    }

    if (uninvoicePrice === 0) return undefined
    return <FetchButton
        label={i18n('tender') + ' (' + NumberUtils.formatCurrency(uninvoicePrice) + ')'}
        onClick={e => {
            tenderrowList.forEach(item => {
                item.quantityToInvoice = 0
                item.priceToInvoice = 0
            })
            renderOverlay(<FetchTenderrowsDialog
                tenderrowList={tenderrowList}
                onSave={onSave}
            />)
        }}
    />
}
