import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from 'react';
import useSecurity from "../../hooks/useSecurity";
import {i18n} from "../../I18n/I18n";
import {CancelButton, SaveButton} from "../Buttons/Button";
import Label from "../EditForm/Label";
import {showLoading} from "../Overlay/Loading";
import XlnzDialog from "../XlnzDialog/XlnzDialog";

const MIN_PASSWORD_LENGTH = 10

export default function ChangePassword(props) {
    const [password, setPassword] = useState('');

    const security = useSecurity()

    const onSubmit = e => {
        e.preventDefault();
        showLoading()
        security.changePassword(password).then(() => {
            security.signOut('change-password');
        })
    }

    return <div className='ChangePassword'>
        <XlnzDialog headerI18n="change_password"
                    onHide={props.onHide}
                    buttons={() => [
                        <SaveButton key='save' onClick={onSubmit} disabled={password.length < MIN_PASSWORD_LENGTH}/>,
                        <CancelButton key='cancel' onClick={e => props.onHide()}/>
                    ]}
        >

            <div className="EditForm" style={{fontSize: '12px'}}>
                <form>
                    <div className="formItem">
                        <Label valueI18n='new_password'/>
                        <Password
                            minLength={MIN_PASSWORD_LENGTH}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            toggleMask
                            weakLabel={i18n('weak')}
                            mediumLabel={i18n('medium')}
                            strongLabel={i18n('strong')}
                        />
                    </div>

                    <div>
                        - {i18n('at_least_x_characters').replace('$0', MIN_PASSWORD_LENGTH)}
                    </div>
                </form>
            </div>
        </XlnzDialog>
    </div>

}

const Password = ({
                      minLength = 8,
                      value = '',
                      onChange = () => {
                      },
                      toggleMask = true,
                      weakLabel = 'Weak',
                      mediumLabel = 'Medium',
                      strongLabel = 'Strong',
                      style = {}
                  }) => {
    const [isMasked, setIsMasked] = useState(true);
    const [strength, setStrength] = useState('');

    const handleToggleMask = () => {
        setIsMasked(!isMasked);
    };

    const calculateStrength = (password) => {
        if (password.length >= minLength) {
            if (password.match(/[a-z]/i) && password.match(/[0-9]/) && password.match(/[^a-zA-Z0-9]/)) {
                return strongLabel;
            } else if (password.match(/[a-z]/i) && password.match(/[0-9]/)) {
                return mediumLabel;
            } else {
                return weakLabel;
            }
        } else {
            return weakLabel;
        }
    };

    const handleChange = (e) => {
        const newPassword = e.target.value;
        onChange(e);
        setStrength(calculateStrength(newPassword));
    };

    return <div>
        <div style={{position: 'relative', ...style}}>
            <input
                type={isMasked ? 'password' : 'text'}
                value={value}
                onChange={handleChange}
                style={{width: '100%', padding: '10px'}}
            />
            {toggleMask && (
                <button
                    type="button"
                    onClick={handleToggleMask}
                    style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer'
                    }}
                >
                    {
                        isMasked
                            ? <FontAwesomeIcon icon={faEye}/>
                            : <FontAwesomeIcon icon={faEyeSlash}/>

                    }
                </button>
            )}
        </div>
        <div style={{
            marginTop: '3px',
            // fontSize: '12px',
            color: strength === strongLabel ? 'green' : strength === mediumLabel ? 'orange' : 'red'
        }}>
            {strength}
        </div>
    </div>
};