import I18n from '@I18n';
import ArrayUtil from "../utils/ArrayUtil";

export default class Country {
    static get AT() {
        return Object.freeze({id: 'AT', name: I18n.t('austria')});
    }

    static get DK() {
        return Object.freeze({id: 'DK', name: I18n.t('denmark')});
    }

    static get DE() {
        return Object.freeze({id: 'DE', name: I18n.t('germany')});
    }

    static get EE() {
        return Object.freeze({id: 'EE', name: I18n.t('estonia')});
    }

    static get ES() {
        return Object.freeze({id: 'ES', name: I18n.t('espana')});
    }

    static get FI() {
        return Object.freeze({id: 'FI', name: I18n.t('finland')});
    }

    static get GB() {
        return Object.freeze({id: 'GB', name: I18n.t('united_kingdom')});
    }

    static get HU() {
        return Object.freeze({id: 'HU', name: I18n.t('hungary')});
    }

    static get IE() {
        return Object.freeze({id: 'IE', name: I18n.t('ireland')});
    }

    static get LT() {
        return Object.freeze({id: 'LT', name: I18n.t('lithuania')});
    }

    static get NL() {
        return Object.freeze({id: 'NL', name: I18n.t('netherlands')});
    }

    static get NO() {
        return Object.freeze({id: 'NO', name: I18n.t('norway')});
    }

    static get PL() {
        return Object.freeze({id: 'PL', name: I18n.t('poland')});
    }

    static get SE() {
        return Object.freeze({id: 'SE', name: I18n.t('sweden')});
    }

    static get SK() {
        return Object.freeze({id: 'SK', name: I18n.t('slovakia')});
    }

    static values() {
        return ArrayUtil.sortOnField([
            {id: this.AT.id, name: this.AT.name},
            {id: this.DK.id, name: this.DK.name},
            {id: this.DE.id, name: this.DE.name},
            {id: this.EE.id, name: this.EE.name},
            {id: this.ES.id, name: this.ES.name},
            {id: this.FI.id, name: this.FI.name},
            {id: this.GB.id, name: this.GB.name},
            {id: this.HU.id, name: this.HU.name},
            {id: this.IE.id, name: this.IE.name},
            {id: this.LT.id, name: this.LT.name},
            {id: this.NL.id, name: this.NL.name},
            {id: this.NO.id, name: this.NO.name},
            {id: this.PL.id, name: this.PL.name},
            {id: this.SE.id, name: this.SE.name},
            {id: this.SK.id, name: this.SK.name},
        ], 'name');
    }

    static findById(id) {
        return this.values().filter(item => item.id === id)[0]
    }
}