import React from "react";
import ExtraMenu from "../../../components/Grid/ExtraMenu";
import {infoAlert} from "../../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Companyprop from "../../../enums/Companyprop";
import Icon from "../../../enums/Icon";
import useCompanyProp from "../../../hooks/useCompanyProp";
import usePreload from "../../../hooks/usePreload";
import {i18n} from "../../../I18n/I18n";
import {findById} from "../../../utils/ArrayUtil";
import NetUtils from "../../../utils/NetUtils";
import {deepCopy} from "../../../utils/ObjectUtils";

const DayplanningViewExtraMenu = props => {
    const {
        labelI18n = 'day_planning'
    } = props

    const preload = usePreload()
    const employeeList = preload.getEmployeeList()

    const pyramid = useCompanyProp(Companyprop.PYRAMID)

    const excelColumns = [
        {field: 'projectName', headerI18n: 'project'},
        {field: 'customerName', headerI18n: 'customer'},
        {field: 'employeesStr', headerI18n: 'employees'},
        {field: 'billingTypeName', headerI18n: 'billing_type'},
        {field: 'car', headerI18n: 'car'},
        {field: 'invoiceNote', headerI18n: 'invoice_note'},
        {field: 'todo', headerI18n: 'todo'},
        {field: 'workDone', headerI18n: 'work_done'},
        {field: 'note', headerI18n: 'note'},
    ];

    const menuItems = []

    if (pyramid) {
        menuItems.push({
            label: i18n('export_to_pyramid'),
            faicon: Icon.PLUGIN,
            command: async item => {
                showLoading()
                const _resp = await NetUtils.doGet('/api/pyramid/sync-export')
                infoAlert(_resp.value)
                hideLoading()
            }
        })
    }

    return <ExtraMenu itemList={menuItems} onExportToExcel={() => {
        const dayplanningList = deepCopy(props.dayplanningList)

        dayplanningList.forEach(item => {
            item.employeesStr = item.timeregList.map(tr => {
                return tr.employee > 0 ? findById(employeeList, tr.employee).name : '?'
            }).join(', ')

        })

        NetUtils.exportToExcel(i18n(labelI18n), undefined, excelColumns, dayplanningList)
    }}/>
}

export default DayplanningViewExtraMenu