import {cloneElement, useState} from "react";
import {createPortal} from "react-dom";


export default function useDialog() {
    // const mapRef = useRef(new Map())
    // const forceUpdate = useForceUpdate()

    const [child, setChild] = useState()

    // const onHide = id => {
    //     const _map = new Map(mapRef.current)
    //     _map.delete(id)
    //     mapRef.current = _map
    //     forceUpdate()
    // }

    return {
        show: child => {
            const _child = cloneElement(child, {
                onHide: () => setChild(undefined)
            })
            setChild(_child)
        },
        render: () => {
            if (!child) return
            return createPortal(child, document.body)
        }

        // show: child => {
        //     const id = makeId()
        //
        //     const _child = cloneElement(child, {
        //         key: id,
        //         onHide: () => onHide(id)
        //     })
        //
        //     const _map = new Map(mapRef.current)
        //     _map.set(id, _child)
        //     mapRef.current = _map
        //     forceUpdate()
        //     return id
        // },
        // render: () => {
        //     return createPortal(Array.from(mapRef.current.values()), document.body)
        // }
    }
}