import React, {useState} from 'react';
import {AddButton} from "../../../components/Buttons/Button";
import DateStepper from "../../../components/Date/DateStepper";
import XlnzDropdown from "../../../components/XlnzDropdown/XlnzDropdown";
import {useFilter} from "../../../components/Grid/filter";
import GridLabel from "../../../components/Grid/GridLabel";
import {errorAlert} from "../../../components/Overlay/Alert";
import {confirmYes} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Spinner from "../../../components/Spinner/Spinner";
import XlnzSideBar from "../../../components/XlnzSideBar/XlnzSideBar";
import BillingType from "../../../enums/BillingType";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import {match} from "../../../functions/match";
import useMountEffect from "../../../hooks/useMountEffect";
import usePreload from "../../../hooks/usePreload";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import {formatDate, getDayName, getHolidayName, getWeek, getYearMonth, todayDate} from "../../../utils/DateUtils";
import DaylockService from "../DaylockService";
import MonthlockService from "../MonthlockService";
import LockButton from "../Shared/LockButton";
import TimeregabsenceService from "../TimeregabsenceService";
import TimeregallowanceService from "../TimeregallowanceService";
import AbsenceGrid from "./absencegrid/AbsenceGrid";
import AllowanceGrid from "./allowancegrid/AllowanceGrid";
import DayplanningGrid from "./dayplanninggrid/DayplanningGrid";
import DayplanningService from "./DayplanningService";
import DayplanningSideBar from "./DayplanningSideBar";
import './DayplanningView.scss'
import DayplanningViewExtraMenu from "./DayplanningViewExtraMenu";

const DayplanningView = () => {
    const roles = useRoles()
    const preload = usePreload()

    const costcenterList = preload.getCostcenterList().filter(item => item.active)

    const filter = useFilter('DayplanningView', [
        {name: 'date', defaultValue: todayDate()},
        {name: 'projectCostcenter', defaultValue: undefined, values: costcenterList},
    ])


    const [dayplanningList, setDayplanningList] = useState()
    const [timeregabsenceList, setTimeregabsenceList] = useState()
    const [timeregallowanceList, setTimeregallowanceList] = useState()
    const [monthLocked, setMonthLocked] = useState();
    const [locked, setLocked] = useState();

    const filteredDayplanningList = dayplanningList?.filter(item => match(filter, item, 'projectCostcenter'))

    const loadData = async date => {
        showLoading()
        const dateStr = formatDate(date)

        const [
            _dayplanningList,
            _timeregabsenceList,
            _timeregallowanceList,
            _monthLocked,
            _locked,
        ] = await Promise.all([
            DayplanningService.findByFilter({date: dateStr, search: ''}),
            TimeregabsenceService.findByDate(dateStr),
            TimeregallowanceService.findByDate(dateStr),
            MonthlockService.isLocked(getYearMonth(date)),
            DaylockService.isLocked(dateStr)
        ])

        _dayplanningList.forEach(item => {
            item.billingTypeName = BillingType.getName(item.billingType)
        })
        setDayplanningList(_dayplanningList)
        setTimeregabsenceList(_timeregabsenceList)
        setTimeregallowanceList(_timeregallowanceList)
        setMonthLocked(_monthLocked)
        setLocked(_locked)

        hideLoading()
    }

    useMountEffect(async () => {
        await loadData(filter.date)
    })

    if (
        !dayplanningList
        || !timeregabsenceList
        || !timeregallowanceList
    ) return <Spinner/>

    let dayString = getDayName(filter.date)
    const holidayName = getHolidayName(filter.date, true)
    if (holidayName) {
        dayString += ' (' + holidayName + ')'
    }
    dayString += ', ' + i18n('week').toLowerCase() + ' ' + getWeek(filter.date)

    function validateLock() {
        if (dayplanningList.flatMap(dp => dp.timeregList).find(tr => !tr.employee) !== undefined) {
            errorAlert(i18n('error_employees_to_assign'))
            return false
        }
        if (dayplanningList.flatMap(dp => dp.timeregList).find(tr => !tr.notarized) !== undefined) {
            errorAlert(i18n('error_time_to_notarize'))
            return false
        }
        if (timeregabsenceList.find(tr => !tr.notarized) !== undefined) {
            errorAlert(i18n('error_time_to_notarize'))
            return false
        }
        if (timeregallowanceList.find(tr => !tr.notarized) !== undefined) {
            errorAlert(i18n('error_time_to_notarize'))
            return false
        }
        return true
    }

    const leftFilters = [
        <div>
            <label className="label">&nbsp;</label>
            <AddButton params={'/' + filter.date} disabled={locked}/>
        </div>,
        <div>
            <label className="label">{i18n('date')}</label>
            <DateStepper value={filter.date} onChange={date => {
                filter.update('date', date)
                loadData(date)
            }}/>
        </div>,
        costcenterList?.length > 0 &&
        <div>
            <label className="label">{i18n('costcenter')}</label>
            <XlnzDropdown
                value={filter.projectCostcenter}
                options={costcenterList}
                optionLabel='label'
                showClear={true}
                placeholder={i18n('select') + '...'}
                optionValue='id'
                onChange={e => filter.update('projectCostcenter', e.value)}
                style={{width: '250px'}}/>
        </div>,
        <div style={{marginLeft: '50px'}}>
            <label className="label">&nbsp;</label>
            <LockButton locked={locked}
                        disabled={
                            (locked && !roles.hasRole(Role.TIMEREG_UNLOCK_DAY)) ||
                            (locked && monthLocked)
                        }
                        onClick={async e => {
                            if (locked) {
                                confirmYes(async () => {
                                    showLoading()
                                    await DaylockService.unlock(filter.date)
                                    loadData(filter.date)
                                    hideLoading()
                                })

                            } else {
                                if (validateLock()) {
                                    confirmYes(async () => {
                                        showLoading()
                                        await DaylockService.lock(filter.date)
                                        hideLoading()
                                        loadData(filter.date)
                                    })
                                }
                            }
                        }}/>
        </div>
    ].filter(item => !item.hidden)

    const rightFilters = [
        <div style={{paddingRight: 0}}>
            <label className="label">&nbsp;</label>
            <DayplanningViewExtraMenu dayplanningList={filteredDayplanningList}/>
        </div>
    ]

    return <div className="DayplanningView">
        <GridLabel icon={Icon.TIMEREG} label={i18n('day_planning') + ' - ' + dayString}/>

        <div className='grid-container'>
            <DayplanningGrid
                date={filter.date}
                dayplanningList={filteredDayplanningList}
                dayplanningListChangeHandler={newList => setDayplanningList(newList)}
                leftFilters={leftFilters}
                rightFilters={rightFilters}
                onReload={() => loadData(filter.date)}
            />

            <div style={{marginTop: '20px'}}>
                <AbsenceGrid
                    date={filter.date}
                    timeregabsenceList={timeregabsenceList}
                />
            </div>

            <div style={{marginTop: '20px'}}>
                <AllowanceGrid
                    date={filter.date}
                    timeregallowanceList={timeregallowanceList}
                />
            </div>
        </div>

        <XlnzSideBar>
            <DayplanningSideBar
                dayplanningList={filteredDayplanningList}
                timeregabsenceList={timeregabsenceList}
            />
        </XlnzSideBar>
    </div>
}

export default DayplanningView;
