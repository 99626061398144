import React, {useContext} from 'react';
import {useForm, useWatch} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../AppContext";
import {CancelButton, SaveButton} from "../../components/Buttons/Button";
import FormItemAddress from "../../components/EditForm/FormItemAddress";
import FormItemAppendixGrid from "../../components/EditForm/FormItemAppendixGrid";
import FormItemCheckbox from "../../components/EditForm/FormItemCheckbox";
import FormItemDropdown from "../../components/EditForm/FormItemDropdown";
import FormItemEmail from "../../components/EditForm/FormItemEmail";
import FormItemNumber from "../../components/EditForm/FormItemNumber";
import FormItemPhone from "../../components/EditForm/FormItemPhone";
import FormItemText from "../../components/EditForm/FormItemText";
import FormItemTextArea from "../../components/EditForm/FormItemTextArea";
import FormItemWww from "../../components/EditForm/FormItemWww";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Spinner from "../../components/Spinner/Spinner";
import TabPanel from "../../components/TabView/TabPanel";
import TabView from "../../components/TabView/TabView";
import Companyprop from "../../enums/Companyprop";
import Country from "../../enums/Country";
import RentType from "../../enums/RentType";
import Role from "../../enums/Role";
import useMountEffect from "../../hooks/useMountEffect";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import {deepCopy} from "../../utils/ObjectUtils";
import RouterUtils from "../../utils/RouterUtils";
import CompanyService from "./CompanyService";

const CompanyEditForm = () => {
    const context = useContext(AppContext)
    const roles = useRoles()

    const location = useLocation()
    const navigate = useNavigate()

    const disabled = !roles.hasRole(Role.SUPERADMIN)

    const defaultValues = {
        name: '',
        address: {
            address: '',
            zip: 0,
            city: '',
            country: Country.SE.id
        },
        active: true,
        children: [],
        appendixList: []
    }
    defaultValues[Companyprop.RENT_DEFAULT_STRATEGY.name] = RentType.WEEK.id

    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, getValues, control} = form

    const watchParentId = useWatch({control, name: 'parentId'})
    const watchAppendixList = useWatch({control, name: 'appendixList'})

    const save = async (data, files) => {
        delete data.departmentList
        data.children.forEach(item => delete item.departmentList)

        if (data.vatNumber.length === 0) delete data.vatNumber
        if (data.bankgiro.length === 0) delete data.bankgiro

        const oldPropList = data.propList ? deepCopy(data.propList) : []

        data.propList = []
        Companyprop.values().forEach(prop => addProp(oldPropList, data.propList, data, prop.name))

        console.log("SAVE MODEL:", data)
        const _model = await CompanyService.update(data, files)
        reset(_model)

        return _model
    }

    const onSubmit = () => {
        showLoading();
        const data = getValues();
        return save(data).then(_model => {
            hideLoading()
            if (_model) RouterUtils.goBack(navigate)
        });
    }

    useMountEffect(async () => {
        showLoading();
        const id = RouterUtils.getId(location)
        if (id > 0) {
            const _model = await CompanyService.findById(id)

            if (_model.parent) {
                _model.parentId = _model.parent.id
            }

            Companyprop.values().forEach(prop => extractProp(_model, prop.name))

            reset(_model)
        }
        hideLoading();
    })


    function extractProp(_model, name) {
        const prop = _model.propList.filter(p => p.name === name)[0]

        const propType = Companyprop.findById(name)
        let value = prop?.value || ''

        if (propType?.type === 'boolean') {
            value = value === '1'
        }

        _model[name] = value
    }

    function addProp(oldPropList, propList, data, field) {
        let value = data[field]
        if (typeof value === 'boolean') {
            value = value ? '1' : ''
        }
        if (value?.length > 0) {
            propList.push({
                id: oldPropList.find(item => item.name === field)?.id,
                name: field,
                value: value
            })
        }
    }

    if (!watchAppendixList) return <Spinner/>
    return <div className='EditForm'>
        <div className="headline">
            <h1>{i18n('company')}</h1>
        </div>
        <form>
            <div className="button-row">
                <div className='left-buttons'>
                    {!disabled &&
                        <SaveButton onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>}
                    <CancelButton onClick={e => RouterUtils.goBack(navigate)}/>
                </div>
            </div>
            <div className='columnContainer'>
                <div className='column'>
                    <FormItemText key='name' name='name' labelI18n='name' maxLength={128} required {...form}/>
                    <FormItemText key='regNo' name='regNo' label={i18n('reg_no')} maxLength={45}
                                  width={130} {...form}/>
                    <FormItemText key='vatNumber' name='vatNumber' label={i18n('vat_no')} maxLength={45}
                                  width={130} {...form}/>
                    <FormItemAddress key='address' name='address' label={i18n('address')} {...form}/>

                    <FormItemDropdown key='parentId' name='parentId' label='Parent'
                                      options={context.companies}
                                      showSelect={true}
                                      {...form} />
                </div>
                <div className='column'>
                    <FormItemPhone key='phone0' name='phone0' label={i18n('mobile')} {...form}/>
                    <FormItemEmail key='email' name='email' label={i18n('email')} {...form}/>
                    <FormItemWww key='www' name='www' label={i18n('homepage')} {...form}/>
                </div>
                <div className='column'>
                    <FormItemText key='bankgiro' name='bankgiro' label={'Bankgiro'} maxLength={9}
                                  width={100} {...form}/>
                    <FormItemText key='bankgiroFactoring' name='bankgiroFactoring' label={'Bankgiro Factoring'}
                                  maxLength={9}
                                  width={100} {...form}/>
                    <FormItemText key='plusgiroFactoring' name='plusgiroFactoring' label={'Plusgiro Factoring'}
                                  maxLength={9}
                                  width={100} {...form}/>
                    <FormItemText key='iban' name='iban' label={'IBAN'} maxLength={30} {...form}/>
                    <FormItemText key='swift' name='swift' label={'Swift'} maxLength={16} {...form}/>
                    <FormItemText key='newCustomerEmails' name='newCustomerEmails'
                                  labelI18n='new_customer_emails'
                                  placeholder='one@example.com, two@example.com'
                                  maxLength={512}
                                  capitalize={false}
                                  {...form}/>
                    <FormItemCheckbox key='active' name='active' label={i18n('active')} {...form}/>
                </div>
            </div>
            <TabView renderActiveOnly={false}>
                <TabPanel key='standard_values' header={i18n('standard_values')}>
                    <div className='columnContainer'>
                        <div className='column'>
                            <FormItemNumber name={Companyprop.PRIORITY.name}
                                            label={Companyprop.PRIORITY.label} max={9999} {...form}/>

                            <FormItemNumber name={Companyprop.CUSTOMER_PAYMENT_TERMS_COMPANY.name}
                                            label={Companyprop.CUSTOMER_PAYMENT_TERMS_COMPANY.label} {...form}/>
                            <FormItemNumber name={Companyprop.CUSTOMER_PAYMENT_TERMS_PRIVATE.name}
                                            label={Companyprop.CUSTOMER_PAYMENT_TERMS_PRIVATE.label} {...form}/>

                            <FormItemCheckbox name={Companyprop.STIB_AUTHORIZED.name}
                                              label={Companyprop.STIB_AUTHORIZED.label} {...form}/>
                        </div>
                        <div className='column'>
                            <FormItemDropdown name={Companyprop.RENT_DEFAULT_STRATEGY.name}
                                              label={Companyprop.RENT_DEFAULT_STRATEGY.name}
                                              options={RentType.values()}
                                              width={160}
                                              required
                                              {...form} />
                            <FormItemCheckbox name={Companyprop.RENT_WORK_DAY_STRATEGY.name}
                                              label={Companyprop.RENT_WORK_DAY_STRATEGY.label} {...form}/>

                            <FormItemEmail name={Companyprop.CUSTOM_SUPPLIER_INVOICE_EMAIL.name}
                                           label={Companyprop.CUSTOM_SUPPLIER_INVOICE_EMAIL.label}
                                           maxLength={1000} {...form}/>
                        </div>
                        <div className='column'>
                            <h2>{i18n('tender')}</h2>
                            <FormItemCheckbox name={Companyprop.TENDER_DONT_USE_STALLNING19.name}
                                              label={Companyprop.TENDER_DONT_USE_STALLNING19.label} {...form}/>
                            <FormItemTextArea name={Companyprop.TENDER_MESSAGE.name}
                                              label={Companyprop.TENDER_MESSAGE.label} maxLength={512} {...form}/>
                            <FormItemText name={Companyprop.TENDER_RENT_ROW0.name}
                                          label={Companyprop.TENDER_RENT_ROW0.label} maxLength={1000} {...form}/>
                            <FormItemNumber name={Companyprop.TENDER_DAYS_UNTIL_AUTO_ANSWER.name}
                                            label={Companyprop.TENDER_DAYS_UNTIL_AUTO_ANSWER.label}
                                            max={9999} {...form}/>
                            <FormItemNumber name={Companyprop.TENDER_PURCHASE_PERCENTAGE_MARKUP.name}
                                            label={Companyprop.TENDER_PURCHASE_PERCENTAGE_MARKUP.label}
                                            max={9999} {...form}/>
                            <FormItemNumber name={Companyprop.TENDER_EXPECTED_MARGIN.name}
                                            label={Companyprop.TENDER_EXPECTED_MARGIN.label}
                                            max={9999} {...form}/>
                            <FormItemNumber name={Companyprop.TENDER_VALIDITY_TIME.name}
                                            label={Companyprop.TENDER_VALIDITY_TIME.label} max={9999} {...form}/>
                            <FormItemCheckbox name={Companyprop.TENDER_REQUIRE_KMA.name}
                                              label={Companyprop.TENDER_REQUIRE_KMA.label} {...form}/>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel key='timereg' header={i18n('time_registration')}>
                    <div className='columnContainer'>
                        <div className='column'>
                            <FormItemCheckbox name={Companyprop.EMP_TIMEREG_ENABLE_DEBIT_TYPES.name}
                                              label={Companyprop.EMP_TIMEREG_ENABLE_DEBIT_TYPES.label} {...form}/>
                            <FormItemCheckbox name={Companyprop.EMP_TIMEREG_REQUIRE_COMMENT.name}
                                              label={Companyprop.EMP_TIMEREG_REQUIRE_COMMENT.label} {...form}/>
                            <FormItemCheckbox name={Companyprop.EMP_TIMEREG_ONLY_PLANNED_REGISTRATIONS.name}
                                              label={Companyprop.EMP_TIMEREG_ONLY_PLANNED_REGISTRATIONS.label} {...form}/>
                        </div>
                        <div className='column'>
                            <FormItemCheckbox name={Companyprop.PROJECT_EMP_TIMEREG_HIDE.name}
                                              label={Companyprop.PROJECT_EMP_TIMEREG_HIDE.label} {...form}/>
                            <FormItemCheckbox name={Companyprop.PROJECT_EMP_TIMEREG_FIXED_TIME_DISABLED.name}
                                              label={Companyprop.PROJECT_EMP_TIMEREG_FIXED_TIME_DISABLED.label} {...form}/>
                            <FormItemCheckbox name={Companyprop.PROJECT_EMP_TIMEREG_COSTPLUS_TIME_DISABLED.name}
                                              label={Companyprop.PROJECT_EMP_TIMEREG_COSTPLUS_TIME_DISABLED.label} {...form}/>
                        </div>
                        <div className='column'>
                            <FormItemText name={Companyprop.APPFORLEAVE_NOTIFICATION_EMAILS.name}
                                          label={Companyprop.APPFORLEAVE_NOTIFICATION_EMAILS.label}
                                          capitalize={false}
                                          maxLength={1000} {...form}/>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel key='supplier_invoices' header={i18n('supplier_invoices')}>
                    <div className='columnContainer'>
                        <div className='column'>
                            <FormItemText name={Companyprop.SUPPLIERINVOICE_DENIED_ATTEST_NOTIFICATION_EMAILS.name}
                                          label={Companyprop.SUPPLIERINVOICE_DENIED_ATTEST_NOTIFICATION_EMAILS.label}
                                          maxLength={1000} {...form}/>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel key='customer_invoice' header={i18n('customer_invoice')}>
                    <div className='columnContainer'>
                        <div className='column'>
                            <FormItemNumber name={Companyprop.INVOICE_INTEREST_ON_OVERDUE_PAYMENT.name}
                                            label={Companyprop.INVOICE_INTEREST_ON_OVERDUE_PAYMENT.label} {...form}/>
                            <FormItemCheckbox name={Companyprop.INVOICE_USE_INVOICING_DATA.name}
                                              label={Companyprop.INVOICE_USE_INVOICING_DATA.label} {...form}/>
                            <FormItemCheckbox name={Companyprop.INVOICE_HIDE_PRICES.name}
                                              label={Companyprop.INVOICE_HIDE_PRICES.label} {...form}/>
                        </div>
                        <div className='column'>
                            <FormItemNumber name={Companyprop.ACCOUNT_NUMBER_RENT.name}
                                            label={Companyprop.ACCOUNT_NUMBER_RENT.label} max={9999} {...form}/>
                            <FormItemNumber name={Companyprop.ACCOUNT_NUMBER_DEBIT_TIME.name}
                                            label={Companyprop.ACCOUNT_NUMBER_DEBIT_TIME.label} max={9999} {...form}/>
                            <FormItemNumber name={Companyprop.ACCOUNT_NUMBER_DEBIT_TIME_REVERSED_VAT.name}
                                            label={Companyprop.ACCOUNT_NUMBER_DEBIT_TIME_REVERSED_VAT.label}
                                            max={9999} {...form}/>
                            <FormItemNumber name={Companyprop.ACCOUNT_SUPPLIER_INVOICE.name}
                                            label={Companyprop.ACCOUNT_SUPPLIER_INVOICE.label} max={9999} {...form}/>
                            <FormItemNumber name={Companyprop.ACCOUNT_SUPPLIER_INVOICE_REVERSED_VAT.name}
                                            label={Companyprop.ACCOUNT_SUPPLIER_INVOICE_REVERSED_VAT.label}
                                            max={9999} {...form}/>
                        </div>
                        <div className='column'>
                            <FormItemTextArea name={Companyprop.INVOICE_TEXT.name}
                                              label={Companyprop.INVOICE_TEXT.label} {...form}/>
                            <FormItemTextArea name={Companyprop.INVOICE_FACTORING_TEXT.name}
                                              label={Companyprop.INVOICE_FACTORING_TEXT.label} {...form}/>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel key='fortnox' header='Fortnox'>
                    <div className='columnContainer'>
                        <div className='column'>
                            <FormItemCheckbox name={Companyprop.FORTNOX_INVOICE_SEPARATE_APPENDICES.name}
                                              label={Companyprop.FORTNOX_INVOICE_SEPARATE_APPENDICES.label} {...form}/>
                            <FormItemNumber name={Companyprop.FORTNOX_INVOICE_SYNC_START.name}
                                            label={Companyprop.FORTNOX_INVOICE_SYNC_START.label} {...form}/>
                            <FormItemNumber name={Companyprop.FORTNOX_SUPPLIER_INVOICE_SYNC_START.name}
                                            label={Companyprop.FORTNOX_SUPPLIER_INVOICE_SYNC_START.label} {...form}/>
                            <FormItemCheckbox name={Companyprop.FORTNOX_USE_PROJECTS.name}
                                              label={Companyprop.FORTNOX_USE_PROJECTS.label} {...form}/>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel key='hyrlet' header='Hyrlet'>
                    <div className='columnContainer'>
                        <div className='column'>
                            <FormItemNumber name={Companyprop.HYRLET_CUSTOMER_ID.name}
                                            label={Companyprop.HYRLET_CUSTOMER_ID.label} {...form}/>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel key='reco' header='Reco'>
                    <div className='columnContainer'>
                        <div className='column'>
                            <FormItemNumber name={Companyprop.RECO_VENUE_ID.name}
                                            label={Companyprop.RECO_VENUE_ID.label} {...form}/>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel key='pyramid' header='Pyramid'>
                    <div className='columnContainer'>
                        <div className='column'>
                            <FormItemCheckbox name={Companyprop.PYRAMID.name}
                                              label={Companyprop.PYRAMID.label} {...form}/>
                            <FormItemNumber name={Companyprop.DEPARTMENT.name}
                                            label={Companyprop.DEPARTMENT.label} {...form}/>
                            <FormItemText name={Companyprop.PROJECT_NO.name}
                                          label={Companyprop.PROJECT_NO.label} {...form}/>
                            <FormItemText name={Companyprop.OBJECT_NO.name}
                                          label={Companyprop.OBJECT_NO.label} {...form}/>
                            {
                                !watchParentId &&
                                <FormItemText name={Companyprop.PYRAMID_ARTICLE_NO_CSV.name}
                                              label={Companyprop.PYRAMID_ARTICLE_NO_CSV.label} {...form}/>
                            }
                        </div>
                        <div className='column'>
                            <FormItemCheckbox name={Companyprop.PYRAMID_SYNC_CUSTOMERS.name}
                                              label={Companyprop.PYRAMID_SYNC_CUSTOMERS.label} {...form}/>
                            <FormItemText name={Companyprop.PYRAMID_SYNC_PROJECT_WITH_OBJNO.name}
                                          label={Companyprop.PYRAMID_SYNC_PROJECT_WITH_OBJNO.label} {...form}/>
                            <FormItemCheckbox name={Companyprop.PYRAMID_SYNC_COSTCENTERS.name}
                                              label={Companyprop.PYRAMID_SYNC_COSTCENTERS.label} {...form}/>
                            <FormItemCheckbox name={Companyprop.PYRAMID_SYNC_TIMEREGS.name}
                                              label={Companyprop.PYRAMID_SYNC_TIMEREGS.label} {...form}/>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel key='appendices' header={i18n('appendices') + ' (' + (watchAppendixList.length) + ')'}>
                    <FormItemAppendixGrid name='appendixList' save={save} hideAttach={true} {...form}/>
                </TabPanel>

            </TabView>
        </form>
    </div>
}

export default CompanyEditForm;
