import {STORAGE_PREFIX} from "./LocalStorageUtils";

const SessionStorageUtils = {
    setItem: (key, value) => {
        sessionStorage.setItem(STORAGE_PREFIX + key, value + '')
    },
    getItem: (key, defaultValue = null) => {
        const result = sessionStorage.getItem(STORAGE_PREFIX + key)
        return result ? result : defaultValue
    },
    removeItem: (key) => sessionStorage.removeItem(STORAGE_PREFIX + key),
    clear: () => sessionStorage.clear()
}

export default SessionStorageUtils