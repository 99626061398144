import NetUtils from "../../../utils/NetUtils";

const AppforleaveService = {
    findAll: params => NetUtils.doGet('/api/appforleaves', params),
    findByActive: async () => NetUtils.doGet('/api/appforleaves').then(res => res.filter(c => c.active)),

    findById: id => NetUtils.doGet('/api/appforleaves/' + id),
    update: model => NetUtils.doPost('/api/appforleaves', model),

    addToTimereg: id => NetUtils.doGet('/api/appforleaves/' + id + '/add-to-timereg'),
    removeFromTimereg: id => NetUtils.doGet('/api/appforleaves/' + id + '/remove-from-timereg')
}
export default AppforleaveService;