import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {CancelButton, SaveButton} from "../../../../components/Buttons/Button";
import FormItemCheckbox from "../../../../components/EditForm/FormItemCheckbox";
import {useFilter} from "../../../../components/Grid/filter";
import Grid from "../../../../components/Grid/Grid";
import onRowChange from "../../../../components/Grid/onRowChange";
import TextGridInput from "../../../../components/Grid/TextGridInput";
import {errorAlert, infoAlert} from "../../../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../../../components/Overlay/Loading";
import Spinner from "../../../../components/Spinner/Spinner";
import XlnzDialog from "../../../../components/XlnzDialog/XlnzDialog";
import useMountEffect from "../../../../hooks/useMountEffect";
import usePreload from "../../../../hooks/usePreload";
import {i18n} from "../../../../I18n/I18n";
import {findById} from "../../../../utils/ArrayUtil";
import {addDays, getDayName} from "../../../../utils/DateUtils";
import {formatCurrency, formatHours, formatNumber} from "../../../../utils/NumberUtils";
import DaylockService from "../../DaylockService";
import DayplanningService from "../DayplanningService";


const NUM_OF_DAYS = 30

const DayplanningCopyDialog = props => {
    const filter = useFilter('DayplanningCopyDialog', [
        {name: 'copyHours', defaultValue: true},
        {name: 'copyArticles', defaultValue: true}
    ])

    const {dayplanning} = props

    const [list, setList] = useState()
    const [daylockList, setDaylockList] = useState()
    const [selection, setSelection] = useState([])

    const preload = usePreload()

    const isDayLocked = date => daylockList?.find(item => item.date === date) !== undefined

    const getEmployeeName = id => findById(preload.getEmployeeList(), id)?.name || ' '
    const getSalarytypeName = id => findById(preload.getTimeregsalarytypeList(), id)?.name || ''
    const getDebittypeName = id => findById(preload.getTimeregdebittypeList(), id)?.name || ''

    const defaultValues = {
        copyHours: filter.copyHours,
        copyArticles: filter.copyArticles
    }

    const form = {...useForm({defaultValues: defaultValues})}
    const {handleSubmit, formState: {isSubmitting}, getValues, watch} = form

    const watchCopyHours = watch('copyHours')
    const watchCopyArticles = watch('copyArticles')

    useMountEffect(async () => {
        showLoading()
        const _list = []
        for (let i = 1; i <= NUM_OF_DAYS; i++) {
            _list.push({
                id: i,
                date: addDays(dayplanning.date, i),
                workDone: '',
                note: ''
            })
        }
        setList(_list)

        setDaylockList(await DaylockService.findByDateInterval(_list[0].date, _list[_list.length - 1].date))

        hideLoading()
    }, [])


    const fireChange = (_list) => {
        setList([..._list])
    }

    async function onSubmit() {
        const data = getValues();
        filter.update('copyHours', data.copyHours)
        filter.update('copyArticles', data.copyArticles)

        if (selection.length === 0) {
            errorAlert(i18n('error_values_missing'))
            return
        }
        showLoading()
        await DayplanningService.copy(dayplanning.id, {
            copyHours: data.copyHours,
            copyArticles: data.copyArticles,
            rowList: selection
        })
        hideLoading()
        infoAlert(i18n('finished'))
        props.onHide()
    }

    const columns = [
        {
            field: 'date', headerI18n: 'date',
            body: rowData => <span>{rowData.date + ' - ' + getDayName(rowData.date)}</span>,
            width: 150
        },
        {
            field: 'workDone', headerI18n: 'work_done',
            editor: e => isDayLocked(e.rowData.date) ? undefined : <TextGridInput editorEvent={e}
                                                                                  maxLength={512}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange)
        },
        {
            field: 'note', headerI18n: 'note',
            editor: e => isDayLocked(e.rowData.date) ? undefined : <TextGridInput editorEvent={e}
                                                                                  maxLength={512}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange)
        },
        {field: 'selection', selectionMode: 'multiple', width: 40},
    ]

    if (!list) return <Spinner/>
    return <XlnzDialog headerI18n='copy_dayplanning'
                       onHide={props.onHide}
                       leftButtons={() => [
                           <FormItemCheckbox key='copyHours' name='copyHours' labelI18n='copy_worked_time' {...form}/>,
                           <FormItemCheckbox key='copyArticles' name='copyArticles'
                                             labelI18n='copy_articles' {...form}/>
                       ]}
                       buttons={() => [
                           <SaveButton key='save' onClick={e => handleSubmit(onSubmit)()}
                                       disabled={isSubmitting || (!watchCopyHours && !watchCopyArticles)}/>,
                           <CancelButton key='cancel' onClick={e => props.onHide()}/>
                       ]}
    >
        {/*<div className="EditForm">*/}
        <div style={{
            width: '700px',
            maxHeight: 'calc(100vh - 350px)',
            overflow: "auto"
        }}>
            <h2 style={{marginTop: '0'}}>{dayplanning.projectName}</h2>
            <div>
                {
                    watchCopyHours &&
                    <>
                        <h3>{i18n('worked_time')}</h3>
                        <table className='info-table'>
                            <thead>
                            <tr>
                                <th>{i18n('employee')}</th>
                                <th className='alignRight'>{i18n('fixed_time')}</th>
                                <th className='alignRight'>{i18n('costplus_time')}</th>
                                <th>{i18n('salary_type')}</th>
                                <th className='alignRight'>{i18n('debit_time')}</th>
                                <th>{i18n('debit_type')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                dayplanning.timeregList.map(item => <tr key={item.id}>
                                    <td>{getEmployeeName(item.employee)}</td>
                                    <td className='alignRight'>{formatHours(item.fixedHours)}</td>
                                    <td className='alignRight'>{formatHours(item.costplusHours)}</td>
                                    <td>{getSalarytypeName(item.salarytype)}</td>
                                    <td className='alignRight'>{formatHours(item.debitHours)}</td>
                                    <td>{getDebittypeName(item.debittype)}</td>
                                </tr>)
                            }
                            </tbody>
                        </table>
                    </>
                }
                {
                    watchCopyArticles &&
                    <>
                        <h3>{i18n('articles')}</h3>
                        <table className='info-table' style={{width: '670px'}}>
                            <thead>
                            <tr>
                                <th>{i18n('employee')}</th>
                                {/*<th>{i18n('article')}</th>*/}
                                <th>{i18n('description')}</th>
                                {/*<th>{i18n('unit')}</th>*/}
                                <th className='alignRight'>{i18n('quantity')}</th>
                                {/*<th className='alignRight'>{i18n('aprice')}</th>*/}
                                {/*<th className='alignRight'>{i18n('discount')}</th>*/}
                                <th className='alignRight'>{i18n('sum')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                dayplanning.timeregarticleList.map(item => <tr key={item.id}>
                                    <td>{getEmployeeName(item.employee)}</td>
                                    {/*<td>{item.article?.name}</td>*/}
                                    <td>{item.name}</td>
                                    {/*<td>{Unit.findById(item.unit)?.name}</td>*/}
                                    <td className='alignRight'>{formatNumber(item.quantity, 2)}</td>
                                    {/*<td className='alignRight'>{formatCurrency(item.aprice, 2)}</td>*/}
                                    {/*<td className='alignRight'>{formatPercent(item.discount / 100)}</td>*/}
                                    <td className='alignRight'>{formatCurrency(item.price, 2)}</td>
                                </tr>)
                            }
                            </tbody>
                        </table>
                    </>
                }
            </div>

            <h3>{i18n('select_days_to_copy_to')}</h3>
            <Grid xlnzGrid={true}
                  columns={columns}
                  value={list}
                  footerColumnGroup={<div/>}
                // heightOffset={dayplanning.timeregList.length * 30 + 530}
                  sortable={false}
                  scrollable={false}
                  onRowSelect={() => {
                  }}
                  selection={selection}
                  onSelectionChange={e => setSelection(e.value)}
            />
        </div>
        {/*<div className="button-row" style={{marginTop: '15px'}}>*/}
        {/*    <div className='left-buttons'>*/}
        {/*        <FormItemCheckbox name='copyHours' labelI18n='copy_worked_time' {...form}/>*/}
        {/*        <FormItemCheckbox name='copyArticles' labelI18n='copy_articles' {...form}/>*/}

        {/*    </div>*/}
        {/*    <div className='divider'></div>*/}
        {/*    <div className='right-buttons'>*/}
        {/*        <SaveButton onClick={e => handleSubmit(onSubmit)()}*/}
        {/*                    disabled={isSubmitting || (!watchCopyHours && !watchCopyArticles)}/>*/}
        {/*        <CancelButton onClick={e => props.onHide()}/>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*</div>*/}
    </XlnzDialog>
}

export default DayplanningCopyDialog;
