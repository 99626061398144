import {faPen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import Country from "../../enums/Country";
import {i18n} from "../../I18n/I18n";
import ValidateUtils from "../../utils/ValidateUtils";
import Address from "../Address/Address";
import {CancelButton, SaveButton} from "../Buttons/Button";
import XlnzDialog from "../XlnzDialog/XlnzDialog";
import FormItemDropdown from "./FormItemDropdown";
import FormItemText from "./FormItemText";
import FormItemTextArea from "./FormItemTextArea";

const FormItemAddress = props => {
    const {
        control,
        disabled,
        name,
        label,
        labelI18n,

        required,

        width,
        onBlur
    } = props

    const [showAddressEditFormDialog, setShowAddressEditFormDialog] = useState()

    const validation = {}
    if (required) {
        validation.required = ValidateUtils.required()
    }

    const style = {}
    if (width) {
        style.width = width + 'px'
    }

    return <div className="formItem">
        <label htmlFor={name}>
            {label ? label : i18n(labelI18n)}

            {
                !disabled &&
                <div className='edit'>
                    <FontAwesomeIcon icon={faPen} className='faIcon' onClick={e => setShowAddressEditFormDialog(true)}/>
                </div>
            }
        </label>
        <Controller name={name}
                    control={control}
                    render={({field: {ref, value, onChange}}) =>
                        <div>
                            {
                                !showAddressEditFormDialog &&
                                <Address inputRef={ref} value={value} onChange={onChange} onBlur={onBlur}
                                         disabled={disabled}/>
                            }
                            {
                                showAddressEditFormDialog &&
                                <div>
                                    <Address inputRef={ref} value={value} onChange={onChange} onBlur={onBlur}
                                             disabled={disabled}/>

                                    <AddressEditFormDialog
                                        inputRef={ref} value={value} onChange={onChange} disabled={disabled}
                                        onHide={() => setShowAddressEditFormDialog(false)}
                                    />
                                </div>
                            }
                        </div>
                    }/>
    </div>
}
export default FormItemAddress;


const AddressEditFormDialog = props => {
    const {
        value,
        onChange,
        onHide
    } = props


    const form = {...useForm({defaultValues: value})}
    const {formState, handleSubmit} = form

    const onSubmit = data => {
        onChange(data)
        onHide()
    }

    return <XlnzDialog headerI18n='address'
                       onHide={onHide}
                       buttons={() => [
                           <SaveButton key='save' disabled={formState.isSubmitting} onClick={handleSubmit(onSubmit)}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >
        <div>
            <div className="AddressDialog">
                <FormItemTextArea name='address' labelI18n='address' {...form}/>
                <FormItemText name='zip' number labelI18n='zip' {...form} width={60} min={0} max={999999}/>
                <FormItemText name='city' labelI18n='city' {...form} />
                <FormItemDropdown name='country'
                                  labelI18n='country'
                                  options={Country.values()}
                                  {...form} />
            </div>
        </div>
    </XlnzDialog>
}