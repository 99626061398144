import {groupBy} from "lodash";
import React, {useContext, useState} from "react";
import AppContext from "../../../../AppContext";
import {FetchButton} from "../../../../components/Buttons/Button";
import {errorAlert} from "../../../../components/Overlay/Alert";
import renderOverlay from "../../../../components/Overlay/renderOverlay";
import Companyprop from "../../../../enums/Companyprop";
import RentType from "../../../../enums/RentType";
import Unit from "../../../../enums/Unit";
import Vat from "../../../../enums/Vat";
import calculatePrice from "../../../../functions/calculatePrice";
import useDepsEffect from "../../../../hooks/useEffect";
import {i18n} from "../../../../I18n/I18n";
import ArrayUtil, {arraySum} from "../../../../utils/ArrayUtil";
import {getDiscountFactor, NumberUtils, round} from "../../../../utils/NumberUtils";
import {deepCopy} from "../../../../utils/ObjectUtils";
import ProjectService from "../../../Projects/ProjectService";
import {addTitle} from "../FetchHelper";
import FetchProjectrentsDialog from "./FetchProjectrentsDialog";

export const FetchProjectrentsButton = props => {
    const {
        project, rowList, accountList, onAddRows, onLeftToInvoiceReport
    } = props
    const context = useContext(AppContext);

    const [uninvoicePrice, setUninvoicePrice] = useState(0)
    const [allProjectrentList, setAllProjectrentList] = useState(0)
    const [projectrentList, setProjectrentList] = useState(0)

    // When project is changed
    useDepsEffect(() => {
        if (project && project.id > 0) {
            ProjectService.findRentsByProject(project.id).then(_projectrentList => {
                _projectrentList = filterProjectrentsInOtherInvoices(_projectrentList)
                setAllProjectrentList(_projectrentList)
            })
        }
    }, [project?.id])

    // When invoice rows are changed
    useDepsEffect(() => {
        if (allProjectrentList && project && project.id > 0) {
            let _projectrentList = deepCopy(allProjectrentList)

            // decreaseQuantityOfAlreadyInvoicedRows(_projectrentList)
            decreaseQuantityOfUnsavedRowsInThisInvoice(_projectrentList)
            calculateLeftToInvoice(_projectrentList)

            _projectrentList = _projectrentList.filter(item => item.leftToInvoice !== 0)

            setProjectrentList(_projectrentList)
            const leftToInvoice = round(ArrayUtil.sum(_projectrentList, 'leftToInvoice'), 2)
            setUninvoicePrice(leftToInvoice)
            onLeftToInvoiceReport(leftToInvoice)
        }
    }, [allProjectrentList, rowList])

    const filterProjectrentsInOtherInvoices = projectrentList => {
        projectrentList.forEach(tr => {
            tr.leftToInvoiceQuantity = tr.quantity

            const proposalIrtrList = tr.invoicerowprojectrentList
            proposalIrtrList.forEach(proposalIrtr => {
                if (proposalIrtr.invoicerow > 0 && !isInThisInvoice(proposalIrtr)) {
                    tr.leftToInvoiceQuantity -= proposalIrtr.quantity
                }
            })
        })

        return projectrentList
    }

    function isInThisInvoice(proposalIrtr) {
        return getInvoiceIrtrList().filter(invoiceIrtr => proposalIrtr.invoicerow === invoiceIrtr.invoicerow && proposalIrtr.projectrent === invoiceIrtr.projectrent).length > 0
    }

    function getInvoiceIrtrList() {
        return rowList
            .flatMap(r => r.projectrentList)
            .filter(invoiceIrtr => invoiceIrtr !== undefined)
    }

    function decreaseQuantityOfUnsavedRowsInThisInvoice(_projectrentList) {
        getInvoiceIrtrList().forEach(invoiceIrtr => {

            const _tr = ArrayUtil.findById(_projectrentList, invoiceIrtr.projectrent)
            if (_tr) _tr.leftToInvoiceQuantity -= invoiceIrtr.quantity
        })
    }

    function calculateLeftToInvoice(projectrentList) {
        projectrentList.forEach(_tr => _tr.leftToInvoice = _tr.leftToInvoiceQuantity * _tr.aprice * getDiscountFactor(_tr.discount))
        projectrentList.forEach(_tr => _tr.quantityToInvoice = 0)
    }

    const onSave = (projectrentList, config) => {
        // const invoiceRows = []
        // addTitle(rowList, config, invoiceRows, i18n('rent'))
        //
        // // DAY
        // const dayProjectrentList = projectrentList.filter(item => item.type === RentType.DAY.id)
        // if (config.separateDates) {
        //     invoiceRows.push(...getOneInvoicerowPerProjectrentrow(dayProjectrentList))
        // } else {
        //     invoiceRows.push(...getOneInvoicerowWithMultipleDates(dayProjectrentList))
        // }
        //
        // // BUILD DAY
        // const buildDayProjectrentList = projectrentList.filter(item => item.type === RentType.BUILD_DAY.id)
        // if (config.separateDates) {
        //     invoiceRows.push(...getOneInvoicerowPerProjectrentrow(buildDayProjectrentList))
        // } else {
        //     invoiceRows.push(...getOneInvoicerowWithMultipleDates(buildDayProjectrentList))
        // }
        //
        // // WEEK
        // invoiceRows.push(...getOneInvoicerowPerProjectrentrow(
        //     projectrentList.filter(item => item.type === RentType.WEEK.id)
        // ))
        //
        // // MONTH
        // invoiceRows.push(...getOneInvoicerowPerProjectrentrow(
        //     projectrentList.filter(item => item.type === RentType.MONTH.id)
        // ))
        //
        // onAddRows(invoiceRows)
        onAddRows(getProjectrentsInvoicerows(context, accountList, project, rowList, uninvoicePrice, projectrentList, config))
    }

    if (uninvoicePrice === 0) return undefined
    return <FetchButton
        label={i18n('rent') + ' (' + NumberUtils.formatCurrency(uninvoicePrice) + ')'}
        onClick={e => renderOverlay(<FetchProjectrentsDialog
            projectrentList={projectrentList}
            onSave={onSave}/>)}
    />
}

export function getProjectrentsInvoicerows(context,
                                           accountList,
                                           project,
                                           rowList,
                                           uninvoicePrice,
                                           projectrentList,
                                           config
) {


    function getAccount() {
        let result = undefined
        const accountNumberRent = context.currentCompany.propList.find(p => p.name === Companyprop.ACCOUNT_NUMBER_RENT.name)
        if (accountNumberRent) {
            result = accountList.find(a => a.number === Number(accountNumberRent.value))
        }
        return result
    }


    function getOneInvoicerowPerProjectrentrow(projectrentList) {
        const result = []
        projectrentList.forEach(tr => {
            const invoicerowprojectrentList = []
            invoicerowprojectrentList.push({
                invoicerow: 0, projectrent: tr.id, quantity: tr.quantityToInvoice
            })

            result.push({
                headline: tr.headline,
                onlyTotal: tr.onlyTotal,
                name: tr.name,
                unit: tr.unit,
                quantity: tr.quantityToInvoice,
                aprice: tr.aprice,
                discount: tr.discount,
                price: calculatePrice(tr.quantityToInvoice, tr.aprice, tr.discount),
                vat: Vat.VAT25.id,
                account: getAccount()?.id,
                costcenter: project.costcenter,
                projectrentList: invoicerowprojectrentList
            })
        })
        return result
    }


    function getOneInvoicerowWithMultipleDates(projectrentList) {
        const result = []

        const groupedTimeregList = groupBy(projectrentList, item =>
            (item.tenderrent || item.projectrentbasis) + '-' + item.price)

        for (const key in groupedTimeregList) {
            const quantity = groupedTimeregList[key].length
            // console.log("groupedTimeregList", key, quantity, groupedTimeregList)

            let aprice = 0
            let name = ''

            let minDate = undefined
            let maxDate = undefined

            const invoicerowtimeregList = []
            groupedTimeregList[key].forEach(tr => {
                if (!minDate || minDate > tr.date) minDate = tr.date
                if (!maxDate || maxDate < tr.date) maxDate = tr.date

                name = tr.name.replace('Hyra ' + tr.date + ': ', '')
                aprice = tr.price

                invoicerowtimeregList.push({
                    invoicerow: 0, projectrent: tr.id, quantity: tr.quantityToInvoice
                })
            })

            name = 'Hyra ' + minDate + " - " + maxDate + ': ' + name

            result.push({
                name: name,
                unit: Unit.DAY.id,
                quantity: quantity,
                aprice: aprice,
                discount: 0,
                price: calculatePrice(quantity, aprice, 0),
                vat: Vat.VAT25.id,
                account: getAccount()?.id,
                costcenter: project.costcenter,
                projectrentList: invoicerowtimeregList
            })
        }

        // Extra security that we are not invoicing more than possible.
        const invoicePrice = round(arraySum(result, 'price'), 2)
        if (uninvoicePrice > 0 && invoicePrice > uninvoicePrice) {
            errorAlert('Invoice Price (' + invoicePrice +
                ') > Possible Price (' + uninvoicePrice + ')')
            return
        }
        return result;
    }


    const invoiceRows = []
    addTitle(rowList, config, invoiceRows, i18n('rent'))

    // DAY
    const dayProjectrentList = projectrentList.filter(item => item.type === RentType.DAY.id)
    if (config.separateDates) {
        invoiceRows.push(...getOneInvoicerowPerProjectrentrow(dayProjectrentList))
    } else {
        invoiceRows.push(...getOneInvoicerowWithMultipleDates(dayProjectrentList))
    }

    // BUILD DAY
    const buildDayProjectrentList = projectrentList.filter(item => item.type === RentType.BUILD_DAY.id)
    if (config.separateDates) {
        invoiceRows.push(...getOneInvoicerowPerProjectrentrow(buildDayProjectrentList))
    } else {
        invoiceRows.push(...getOneInvoicerowWithMultipleDates(buildDayProjectrentList))
    }

    // WEEK
    invoiceRows.push(...getOneInvoicerowPerProjectrentrow(
        projectrentList.filter(item => item.type === RentType.WEEK.id)
    ))

    // MONTH
    invoiceRows.push(...getOneInvoicerowPerProjectrentrow(
        projectrentList.filter(item => item.type === RentType.MONTH.id)
    ))

    return invoiceRows
}
