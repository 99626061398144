import I18n from '@I18n';
import React, {useId} from 'react';
import {useNavigate} from "react-router-dom";
import Grid from "../../../../components/Grid/Grid";
import usePreload from "../../../../hooks/usePreload";
import './AllowanceGrid.scss'
import EmployeeAllowanceInfoGridTemplete from "./EmployeeAllowanceInfoGridTemplete";

const AllowanceGrid = props => {
    const preload = usePreload()
    const id = useId()

    const employeeList = preload.getEmployeeList()
    const timeregallowancetypeList = preload.getTimeregallowancetypeList()

    const date = props.date
    const timeregallowanceList = props.timeregallowanceList

    const navigate = useNavigate()

    const onRowSelect = () => navigate('/timeregallowance/' + date)

    const columns = [
        {
            field: 'name',
            body: item => <div>
                <div style={{fontWeight: 'bold'}}>{item.name}</div>
            </div>,
            width: 250,
            mobile: true
        },
        {
            field: 'employeeName', headerI18n: 'employee',
            body: () => <EmployeeAllowanceInfoGridTemplete
                employeeList={employeeList}
                timeregallowanceList={timeregallowanceList}
                timeregallowancetypeList={timeregallowancetypeList}
            />,
            mobile: true
        },
    ];

    return <div className='AllowanceGrid'>
        <Grid columns={columns}
              xlnzGrid={true}
              scrollable={false}
              hideHeader={true}
              hideFooter={true}
              value={[{
                  id: id,
                  name: I18n.t('allowances'),
                  timeregallowanceList: timeregallowanceList
              }]}
              rowSelectParams={'/' + date}
              heightOffset={310}
              onRowSelect={e => onRowSelect(e.data)}
              footerColumnGroup={<div/>}
        />
    </div>
}

export default AllowanceGrid;
