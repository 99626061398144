import React from "react";
import usePreload, {PreloadType} from "../../hooks/usePreload";
import FormItemDropdown from "./FormItemDropdown";

const FormItemTimeregdebittype = props => {
    const preload = usePreload()
    const {
        getValues
    } = props

    const value = getValues('debittype')

    const list = preload.get(PreloadType.TIMEREGDEBITTYPE_LIST)
        .filter(item => item.active || item.id === value)

    if (list.length === 0) return

    return <FormItemDropdown name='debittype' labelI18n='debit_type'
                             options={list}
                             optionLabel='name'
                             {...props} />
}
export default FormItemTimeregdebittype