import React, {useState} from "react";
import {CancelButton, MainButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemAppendixGrid from "../../../components/EditForm/FormItemAppendixGrid";
import Grid from "../../../components/Grid/Grid";
import PdfColumn from "../../../components/Grid/PdfColumn";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import Icon from "../../../enums/Icon";
import {GridTemplates} from "../../../GridTemplates";
import usePreload from "../../../hooks/usePreload";
import {i18n} from "../../../I18n/I18n";
import {findById, sortByField} from "../../../utils/ArrayUtil";
import SupplierinvoiceService from "../../Supplierinvoices/SupplierinvoiceService";


export default function InvoiceAppendixGrid(props) {
    const {
        form,
        save
    } = props


    function getCustomHeaderItems(fileUploadProps) {
        const {
            trigger,
            getValues
        } = fileUploadProps

        const supplierinvoicerowList = getValues('rowList')
            .flatMap(item => item?.supplierinvoicerowList)
            .map(item => item?.supplierinvoicerow)
        if (supplierinvoicerowList.length === 0) return

        return <MainButton faicon={Icon.ADD} labelI18n='supplier_invoices' onClick={() => {
            trigger().then(async isOk => {
                if (isOk) {
                    showLoading()
                    const _model = await save(form.getValues())

                    const alreadyAddedSupplierinvoiceIdList = _model.appendixList.map(item => item.supplierinvoice)

                    form.reset(_model)
                    const supplierinvoiceList = (await SupplierinvoiceService.findInvoice(_model.id))
                        .filter(item => item.fileSize > 0)
                        .filter(item => !alreadyAddedSupplierinvoiceIdList.includes(item.id))
                    hideLoading()

                    renderOverlay(<SupplierinvoiceSelectDialog
                        supplierinvoiceList={supplierinvoiceList}
                        onSave={_supplierinvoiceList => {
                            if (_supplierinvoiceList.length === 0) return
                            showLoading()
                            const data = getValues()

                            _supplierinvoiceList.forEach(item => {
                                data.appendixList.push({
                                    supplierinvoice: item.id,
                                    name: item.supplierName + ' - ' + item.number + '.pdf',
                                    fileSize: item.fileSize
                                })
                            })

                            save(data).then(_model => {
                                console.log("_MODEL", _model)
                                form.reset(_model)
                                hideLoading()
                            })
                        }}/>)
                }
            })
        }}/>;
    }

    return <FormItemAppendixGrid name='appendixList' save={save}
                                 getCustomHeaderItems={getCustomHeaderItems}
                                 {...form}/>

}


function SupplierinvoiceSelectDialog(props) {
    const {
        supplierinvoiceList,
        onSave,
        onHide,
    } = props

    const [selection, setSelection] = useState([])

    const preload = usePreload()
    const supplierList = preload.getSupplierList()

    supplierinvoiceList.forEach(item => {
        item.supplierName = findById(supplierList, item.supplier)?.name
    })

    const onSubmit = async () => {
        onSave(selection)
        onHide()
    }

    const columns = [
        PdfColumn(rowData => rowData.fileSize > 0,
            rowData => rowData.number + ' - ' + rowData.supplierName,
            async rowData => (await SupplierinvoiceService.getImageUrl(rowData.id)).url
        ),
        {field: 'supplierName', headerI18n: 'supplier'},
        {field: 'number', headerI18n: 'invoice_number', width: 130},
        {field: 'fileSize', headerI18n: 'size', body: GridTemplates.formatBytes, width: 100, align: 'right'},
        {field: 'selection', selectionMode: 'multiple', width: 40},
    ]

    return <XlnzDialog header={i18n('documents')} onHide={onHide}
                       buttons={() => [
                           <SaveButton key='save' onClick={onSubmit}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >
        <div className="EditForm" style={{width: '600px', margin: 0}}>
            <Grid xlnzGrid={true}
                  columns={columns}
                  value={sortByField(supplierinvoiceList, 'supplierName')}
                  scrollable={true}
                  hideFooter={true}
                  heightOffset={370}
                  footerColumnGroup={<div/>}

                  selectionMode={'multiple'}
                  selection={selection}
                  onSelectionChange={e => setSelection(e.value)}
            />
        </div>

    </XlnzDialog>
}