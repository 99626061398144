import {faChartLine} from "@fortawesome/free-solid-svg-icons";
import Role from "../../enums/Role";
import FortnoxView from "./FortnoxView";

const FortnoxIndex = {
    routeProps: {
        path: '/fortnox',
        exact: true,
        element: <FortnoxView/>,
    },
    name: 'Fortnox',
    icon: faChartLine,
    roles: [Role.ADMIN]
};
export default FortnoxIndex;