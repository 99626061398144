import React from 'react';
import Checkbox from "../EditForm/Checkbox";

const CheckboxEditor = (editorEvent, fireChange, disabled = false) => {
    if (!editorEvent) throw new Error("editorEvent parameter missing")
    if (!fireChange) throw new Error("fireChange parameter missing")

    const {
        field,
        rowIndex,
        rowData
    } = editorEvent
    const value = rowData[field]

    return <Checkbox checked={value}
                     disabled={disabled}
                     onChange={e2 => {
                         const _rowList = [...editorEvent.props.value]
                         _rowList[rowIndex][field] = e2.target.checked
                         _rowList[rowIndex].dirty = true
                         fireChange(_rowList)
                     }}
                     style={{
                         verticalAlign: 'middle',
                     }}
    />
}
export default CheckboxEditor;