import React from 'react';
import AvatarWithHours from "../shared/AvatarWithHours";
import AbsenceAvatarTooltip from "./AbsenceAvatarTooltip";
import './EmployeeAbsenceInfoGridTemplete.scss'

const EmployeeAbsenceInfoGridTemplete = props => {
    const {
        employeeList,
        timeregabsenceList,
        timeregabsencetypeList,
    } = props

    const hasHours = type => timeregabsenceList.find(tr => tr.type === (!type ? null : type.id))

    return <div className='EmployeeInfo'>
        {
            timeregabsencetypeList
                .filter(type => hasHours(type))
                .map(type => <TypeGroup key={type.id} employeeList={employeeList}
                                        timeregabsenceList={timeregabsenceList}
                                        type={type}/>)
        }
    </div>
}

export default EmployeeAbsenceInfoGridTemplete;

function TypeGroup({employeeList, timeregabsenceList, type}) {
    const getEmployee = employeeId => employeeList.find(employee => employee.id === employeeId)

    return <div className='type'>
        <div className='type-label'>{type.name}</div>
        <div className='employeeList'>
            {
                timeregabsenceList
                    .filter(tr => tr.type === (!type ? null : type.id))
                    .map((tr, index) => {
                        const employee = getEmployee(tr.employee)
                        return <AvatarWithHours
                            key={index}
                            label={employee?.alias || 'ERROR'}
                            leftHours={tr.hours}
                            notarized={tr.notarized}
                            tooltipContent={<AbsenceAvatarTooltip employee={employee} timeregabsence={tr}/>}
                        />
                    })
            }
        </div>
    </div>;
}