import {faClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useRef} from "react";
import {i18n} from "../../I18n/I18n";
import './XlnzDialog.scss'

export default function XlnzDialog(props) {
    const {
        onHide,
        header,
        headerI18n,
        children,
        leftButtons,
        buttons
    } = props
    const dialogRef = useRef()
    const offsetXRef = useRef(0)
    const offsetYRef = useRef(0)

    function mooove(event) {
        const newLeft = event.clientX - offsetXRef.current;
        const newTop = event.clientY - offsetYRef.current;

        dialogRef.current.style.left = newLeft + 'px';
        dialogRef.current.style.top = newTop + 'px';
    }

    return <div id='XlnzDialog'
                className={'XlnzDialog'}
                ref={dialogRef}>

        <div className='dialog-header'
             onMouseDown={event => {
                 const draggableElement = dialogRef.current
                 offsetXRef.current = event.clientX - draggableElement.offsetLeft;
                 offsetYRef.current = event.clientY - draggableElement.offsetTop;

                 // console.log("dialogRef", dialogRef.current)

                 document.addEventListener('mousemove', mooove)
             }}
             onMouseUp={() => document.removeEventListener('mousemove', mooove)}
        >
            <h2>{headerI18n ? i18n(headerI18n) : header}</h2>
            <div onClick={onHide}>
                <FontAwesomeIcon icon={faClose} className='faIcon'/>
            </div>
        </div>
        <div className='dialog-content'>
            {children}
        </div>
        <div className='dialog-buttons'>
            <div className='left-buttons'>{leftButtons && leftButtons(props)}</div>
            <div className='divider'></div>
            <div className='right-buttons'>{buttons && buttons(props)}</div>
        </div>
    </div>
}