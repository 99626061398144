import React, {useState} from "react";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Spinner from "../../components/Spinner/Spinner";
import useMountEffect from "../../hooks/useMountEffect";
import {initI18n} from "../../I18n/I18n";
import NetUtils from "../../utils/NetUtils";

export const Unsubscribe = props => {
    const token = props.token
    const [model, setModel] = useState(null);

    useMountEffect(async () => {
        showLoading()
        await initI18n()

        const _model = await NetUtils.doPost('/api/external/unsubscribe/' + token)
        setModel(_model)
        hideLoading()
    })

    if (!model) return <Spinner/>
    return <div id='App'>
        <div style={{
            position: 'fixed',
            msTransform: 'translate(-50%)',
            transform: 'translate(-50%)',
            top: '30%',
            left: '50%',

            width: '80%',

            textAlign: 'center',
            color: '#fff'

        }}>
            Du har nu avregisterat e-postadressen <b>{model.value}</b>.
        </div>
    </div>
}
