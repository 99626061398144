import NetUtils from "../../utils/NetUtils";

const TimeregService = {
    findByProject: projectId => NetUtils.doGet('/api/timeregs', {project: projectId}),

    findById: id => NetUtils.doGet('/api/timeregs/' + id),
    update: model => NetUtils.doPost('/api/timeregs', model),
    delete: model => NetUtils.doDelete('/api/timeregs', model),

    notarizeIdList: modelIdList => NetUtils.doPost('/api/timeregs/notarize-id-list', modelIdList),
};
export default TimeregService;