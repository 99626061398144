import I18n from '@I18n';
import React from 'react';
import FormItemInteger from "../../components/EditForm/FormItemInteger";
import FormItemText from "../../components/EditForm/FormItemText";
import FormItemTextArea from "../../components/EditForm/FormItemTextArea";
import SimpleEditForm from "../../components/EditForm/SimpleEditForm";
import Role from "../../enums/Role";
import usePreload, {PreloadType} from "../../hooks/usePreload";
import TendertermService from "./TendertermService";

const TendertermEditForm = () => {
    const preload = usePreload()

    const timeregdebittypeList = preload.get(PreloadType.TIMEREGDEBITTYPE_LIST).filter(c => c.active)

    async function loadFunction(id) {
        return await TendertermService.findById(id)
    }

    return (
        <SimpleEditForm
            writeRole={Role.ADMIN}
            headlineI18n='tender_terms'
            defaultValues={{
                active: true
            }}
            loadFunction={loadFunction}
            saveFunction={TendertermService.update}
            removeFunction={TendertermService.delete}
            initLeftFields={form => [
                <FormItemText key='name' name='name' labelI18n='name' maxLength={250} required {...form}/>,
                <FormItemTextArea key='text' name='text' labelI18n='text' maxLength={2000} required {...form}/>,
                <div key='text-info'>
                    <h2>{I18n.t('variables')}</h2>
                    <table style={{fontSize: '12px'}}>
                        <tbody>
                        {
                            timeregdebittypeList && timeregdebittypeList.map(dt =>
                                <tr key={dt.id}>
                                    <td>#{dt.name}#</td>
                                </tr>
                            )
                        }
                        <tr>
                            <td>#{I18n.t('purchase_percentage_markup')}#</td>
                        </tr>
                        <tr>
                            <td>#{I18n.t('validity_time')}#</td>
                        </tr>
                        <tr>
                            <td>#{I18n.t('payment_terms')}#</td>
                        </tr>
                        </tbody>
                    </table>
                </div>,
                <FormItemInteger key='listOrder' name='listOrder' labelI18n='list_order' required {...form}/>,
            ]}
        />
    );
}

export default TendertermEditForm;
