import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import Tooltip from "../../../../components/Overlay/Tooltip";
import useUserPersmissions from "../../../../hooks/useUserPersmissions";
import {i18n} from "../../../../I18n/I18n";
import {formatCurrency} from "../../../../utils/NumberUtils";
import './EmployeeInfoGridTemplete.scss'

const RentInfoGridTemplete = props => {
    const {
        dayplanning
    } = props

    const userPersmissions = useUserPersmissions()

    const rentList = [...dayplanning.tenderrentList, ...dayplanning.projectrentbasisList]

    function getRentRows(_list) {
        return _list.map(item => {
            return <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.startDate}</td>
                <td>{item.endDate}</td>
                {
                    !userPersmissions.isHideArticlePrices() &&
                    <td className='alignRight'>{formatCurrency(item.price, 2)}</td>
                }
            </tr>
        })
    }

    const rentListTrs = getRentRows(rentList)

    const tooltipContent = <table className='xlnz-table'>
        <thead>
        <tr>
            <th>{i18n('name')}</th>
            <th>{i18n('start_date')}</th>
            <th>{i18n('end_date')}</th>
            {!userPersmissions.isHideArticlePrices() && <th className='alignRight'>{i18n('price')}</th>}
        </tr>
        </thead>
        <tbody>
        {rentListTrs}
        </tbody>
    </table>

    const isActive = (item, date) => {
        const {startDate, endDate} = item;

        if (!startDate) return false;

        const start = new Date(startDate);
        const end = endDate ? new Date(endDate) : undefined;
        const checkDate = new Date(date);

        // Om endDate är undefined, kontrollera om date är samma som eller efter startDate
        if (!end) return checkDate >= start;

        // Om både startDate och endDate är definierade, kontrollera om date är inom intervallet
        return checkDate >= start && checkDate <= end;
    };


    function getIconColor() {
        const activeRentItem = rentList.find(item => isActive(item, dayplanning.date))
        return activeRentItem ? 'green' : 'orange'
    }

    function hasRents() {
        return rentList.length > 0
    }


    if (!hasRents()) return
    return <div className='RentInfoGridTemplete'>
        <Tooltip value={tooltipContent}>
            <FontAwesomeIcon icon={faCircle} style={{
                color: getIconColor()
            }}/>
        </Tooltip>

    </div>
}

export default RentInfoGridTemplete;
