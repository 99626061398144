import React, {useState} from "react";
import {FetchButton} from "../../../../components/Buttons/Button";
import renderOverlay from "../../../../components/Overlay/renderOverlay";
import Vat from "../../../../enums/Vat";
import useDepsEffect from "../../../../hooks/useEffect";
import {i18n} from "../../../../I18n/I18n";
import ArrayUtil, {findById} from "../../../../utils/ArrayUtil";
import {NumberUtils} from "../../../../utils/NumberUtils";
import {deepCopy} from "../../../../utils/ObjectUtils";
import PaymentplanService from "../../../Projects/edit/paymentplan/PaymentplanService";
import {addEmptyRow, addTitle} from "../FetchHelper";
import FetchPaymentplanrowsDialog from "./FetchPaymentplanrowsDialog";

export const RETAINED_FUNDS_INDEX = 1_000_000;

export const FetchPaymentplanrowsButton = props => {
    const {
        project,
        rowList,
        onAddRows,
        onLeftToInvoiceReport
    } = props

    const [uninvoicePrice, setUninvoicePrice] = useState(0)
    const [allPaymentplandrawList, setAllPaymentplandrawList] = useState(0)
    const [paymentplanrowList, setPaymentplanrowList] = useState(0)

    // When project is changed
    useDepsEffect(() => {
        if (project && project.id > 0) {
            PaymentplanService.findDrawsByProject(project.id).then(_paymentplandrawList => {
                _paymentplandrawList = filterPaymentplandrawsInOtherInvoices(_paymentplandrawList)
                setAllPaymentplandrawList(_paymentplandrawList)
            })
        }
    }, [project?.id])

    // When invoice rows are changed
    useDepsEffect(() => {
        if (allPaymentplandrawList && project && project.id > 0) {
            let _paymentplandrawList = deepCopy(allPaymentplandrawList)

            decreaseQuantityOfUnsavedRowsInThisInvoice(_paymentplandrawList)

            setPaymentplanrowList(_paymentplandrawList)
            const _leftToInvoicePrice = ArrayUtil.sum(_paymentplandrawList, 'leftToInvoicePrice');
            setUninvoicePrice(_leftToInvoicePrice)

            onLeftToInvoiceReport(_leftToInvoicePrice)
        }
    }, [allPaymentplandrawList, rowList])

    const filterPaymentplandrawsInOtherInvoices = paymentplandrawList => {
        paymentplandrawList.forEach(drawItem => {
            drawItem.leftToInvoicePrice = isRetainedFunds(drawItem)
                ? drawItem.price
                : drawItem.price - drawItem.retainedFunds

            const irPrList = drawItem.invoicerowpaymentplanrowList
            irPrList.forEach(irpr => {
                if (irpr.invoicerow > 0 && !isInThisInvoice(irpr)) {
                    const pr = findById(drawItem.rowList, irpr.paymentplanrow)
                    // drawItem.leftToInvoicePrice -= pr.drawPriceList[irpr.drawIndex]
                    drawItem.leftToInvoicePrice -= isRetainedFunds(drawItem)
                        ? pr.drawPriceList[0]
                        : pr.drawPriceList[irpr.drawIndex]
                }
            })
        })

        return paymentplandrawList
    }

    function isInThisInvoice(irpr) {
        return getInvoiceIrprList().filter(invoiceIrpr =>
            irpr.invoicerow === invoiceIrpr.invoicerow &&
            irpr.paymentplanrow === invoiceIrpr.paymentplanrow).length > 0
    }

    function getInvoiceIrprList() {
        return rowList
            .flatMap(r => r.paymentplanrowList)
            .filter(invoiceIrtr => invoiceIrtr !== undefined)
    }

    function isRetainedFunds(drawItem) {
        return drawItem.drawIndex === RETAINED_FUNDS_INDEX
    }

    function decreaseQuantityOfUnsavedRowsInThisInvoice(_paymentplandrawList) {
        // console.log("getInvoiceIrprList()", getInvoiceIrprList())
        // console.log("_paymentplandrawList", _paymentplandrawList)
        getInvoiceIrprList().forEach(invoiceIrtr => {
            _paymentplandrawList.forEach(drawItem => {
                if (drawItem.drawIndex === invoiceIrtr.drawIndex) {
                    const pr = findById(drawItem.rowList, invoiceIrtr.paymentplanrow)
                    if (pr) {
                        drawItem.leftToInvoicePrice -= isRetainedFunds(drawItem)
                            ? pr.drawPriceList[0]
                            : pr.drawPriceList[invoiceIrtr.drawIndex]
                    }
                }
            })
        })
    }

    const onSave = (paymentplandrawList, config) => {
        const invoiceRows = []

        paymentplandrawList.forEach((drawItem, index) => {
            const i = drawItem.drawIndex

            if (index > 0) addEmptyRow(invoiceRows)
            addTitle(rowList, config, invoiceRows, drawItem.name)

            drawItem.rowList.forEach(row => {
                const _price = row.drawPriceList[isRetainedFunds(drawItem) ? 0 : i]

                const irpr = getInvoiceIrprList().find(irpr => irpr.paymentplanrow === row.id && irpr.drawIndex === i)

                const irprOld = drawItem.invoicerowpaymentplanrowList.find(invoiceIrpr => invoiceIrpr.paymentplanrow === row.id && invoiceIrpr.drawIndex === i)


                if (_price !== 0 && !irpr && !irprOld) {
                    const invoicerowpaymentplanrowList = [{
                        invoicerow: 0,
                        paymentplanrow: row.id,
                        drawIndex: i
                    }]

                    invoiceRows.push({
                        headline: false,
                        onlyTotal: true,
                        housework: false,
                        name: row.retainedFunds ? i18n('retained_funds') : row.name,
                        quantity: 1,
                        aprice: _price,
                        discount: 0,
                        price: _price,
                        vat: Vat.VAT25.id,
                        // account: drawItem.accountId,
                        costcenter: project.costcenter,
                        paymentplanrowList: invoicerowpaymentplanrowList
                    })
                }
            })
        })


        onAddRows(invoiceRows)
        // setShowFetchHoursDialog(false)
    }

    if (uninvoicePrice === 0) return undefined
    return <FetchButton
        label={i18n('payment_plan') + ' (' + NumberUtils.formatCurrency(uninvoicePrice) + ')'}
        onClick={e => {
            paymentplanrowList.forEach(item => item.priceToInvoice = 0)
            renderOverlay(<FetchPaymentplanrowsDialog
                paymentplanrowList={paymentplanrowList}
                onSave={onSave}
            />)
        }}
    />
}
