import React from 'react';
import FormItemCheckbox from "../../components/EditForm/FormItemCheckbox";
import FormItemText from "../../components/EditForm/FormItemText";
import SimpleEditForm from "../../components/EditForm/SimpleEditForm";
import Role from "../../enums/Role";
import {PreloadType} from "../../hooks/usePreload";
import {allModules} from "../modules";
import SelectRestrictPaths from "./SelectRestrictPaths";
import SelectRoles from "./SelectRoles";
import UsergroupService from "./UsergroupService";

const ROLE_FIELD_PREFIX = "ROLE_"
const PATH_PREFIX = "PATH_"

export default function UsergroupEditForm() {
    const postLoadFunction = _model => {
        _model.roleSet.forEach(roleName => _model[ROLE_FIELD_PREFIX + roleName] = true)
        _model.restrictPathSet.forEach(path => _model[PATH_PREFIX + path] = true)
    }

    const preSaveFunction = data => {
        data.roleSet = Role.values()
            .filter(role => data[ROLE_FIELD_PREFIX + role.name] === true)
            .map(role => role.name)

        data.restrictPathSet = []
        allModules.forEach(item => {
            if (data['PATH_' + item.routeProps.path]) {
                data.restrictPathSet.push(item.routeProps.path)
            }
            if (item.routeProps.items) {
                item.routeProps.items
                    .forEach(subItem => {
                        if (data[PATH_PREFIX + subItem.path]) {
                            data.restrictPathSet.push(subItem.path)
                        }
                    })
            }
        })
    }

    return <SimpleEditForm
        legalCompany={true}
        writeRole={Role.USERGROUP_WRITE}
        headlineI18n='user_group'
        defaultValues={{
            id: 0,
            name: '',
            roleSet: [],
            restrictPathSet: []
        }}
        loadFunction={UsergroupService.findById}
        postLoadFunction={postLoadFunction}
        preSaveFunction={preSaveFunction}
        saveFunction={UsergroupService.update}
        removeFunction={UsergroupService.delete}
        initLeftFields={form => [
            <FormItemText key='name' name='name' label='Namn' maxLength={128} required {...form}/>,
            <SelectRoles key='SelectRoles' form={form}/>
        ]}
        initCenterFields={form => [
            <SelectRestrictPaths key='SelectRestrictPaths' form={form}/>
        ]}
        initRightFields={form => [
            <FormItemCheckbox key='hideArticlePrices' name='hideArticlePrices'
                              labelI18n='hide_article_prices' {...form}/>
        ]}
        preloadType={PreloadType.USERGROUP_LIST}
    />
}


