import React from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {CancelButton, SaveButton} from "../../../../../components/Buttons/Button";
import FormItemProject from "../../../../../components/EditForm/FormItemProject";
import {hideLoading, showLoading} from "../../../../../components/Overlay/Loading";
import XlnzDialog from "../../../../../components/XlnzDialog/XlnzDialog";
import RouterUtils from "../../../../../utils/RouterUtils";
import DayplanningService from "../../DayplanningService";

export default function DayplanningMoveToOtherProjectDialog(props) {
    const {
        onHide,
        projectList,
        dayplanning
    } = props

    const navigate = useNavigate()

    const form = {...useForm({defaultValues: {}})}
    const {handleSubmit, formState: {isSubmitting}, getValues} = form

    const _projectList = projectList.filter(item => item.id !== dayplanning.project)

    async function onSubmit() {
        showLoading()
        const data = getValues()
        const savedModel = await DayplanningService.moveToOtherProject(dayplanning.id, data.project)
        if (savedModel) {
            onHide()
            RouterUtils.goBack(navigate)
        }
        hideLoading()
    }

    return <XlnzDialog headerI18n='move_to_other_project'
                       buttons={props => [
                           <SaveButton key='save' onClick={e => handleSubmit(onSubmit)()}
                                       disabled={isSubmitting}/>,
                           <CancelButton key='cancel' onClick={e => props.onHide()}/>
                       ]}
                       onHide={onHide}>
        <div className="EditForm" style={{width: '400px', margin: 0}}>

            <FormItemProject options={_projectList}
                             required
                             showSelect {...form} />
        </div>
    </XlnzDialog>
}