import I18n from '@I18n';
import React from 'react';
import {Controller} from "react-hook-form";
import DatePicker from "../Date/DatePicker";
import ErrorText from "./ErrorText";

const FormItemDate = props => {
    const {
        control,
        disabled,
        formState: {errors},
        name,
        label,
        labelI18n,

        minDate,
    } = props

    return <div className="formItem">
        <label htmlFor={name}>{labelI18n ? I18n.t(labelI18n) : label}</label>
        <Controller name={name}
                    control={control}
                    render={({field: {ref, value, onChange}}) =>
                        <DatePicker
                            inputRef={ref} value={value} onChange={e => {
                            onChange(e)
                            if (props.onChange) props.onChange(e)
                        }}
                            minDate={minDate}
                            disabled={disabled}
                        />
                    }/>
        <ErrorText errors={errors} name={name}/>
    </div>
}
export default FormItemDate;