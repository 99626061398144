import React, {useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import ActiveFilter from "../../components/Grid/ActiveFilter";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Role from "../../enums/Role";
import {matchActive, matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useMountEffect from "../../hooks/useMountEffect";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import {isNumeric, round} from "../../utils/NumberUtils";
import ArticleListService from "./ArticleListService";

const ArticleGrid = props => {
    const roles = useRoles()

    const filter = useFilter('ArticleGrid', [
        {name: 'active', defaultValue: true},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);
    const filteredList = list.filter(c => matchActive(filter, c) && matchSearch(filter, c))

    const updatePercentageMarkup = article => {
        article.percentageMarkup = round((article.price / article.purchasePrice - 1) * 100, 2)
        if (!isNumeric(article.percentageMarkup)) article.percentageMarkup = 0
    }

    useMountEffect(async () => {
        showLoading()
        const _list = await ArticleListService.findAll()

        _list.forEach(item => {
            updatePercentageMarkup(item)
        })

        setList(_list)
        hideLoading()
    }, [])

    const leftFilters = [
        roles.hasRole(Role.ADMIN) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <label className="label">{i18n('status')}</label>
            <ActiveFilter value={filter.active} onChange={e => filter.update('active', e.value)}/>
        </div>
    ]

    const columns = [
        {field: 'name', headerI18n: 'name'},
        {field: 'number', headerI18n: 'number', mobile: true},
        {field: 'unit', headerI18n: 'unit', width: 120, body: GridTemplates.unit},
        {
            field: 'purchasePrice', headerI18n: 'purchase_price',
            body: GridTemplates.currencyTwoDecimals,
            width: 100,
            align: 'right'
        },
        {
            field: 'percentageMarkup', headerI18n: 'percentage_markup',
            body: GridTemplates.percentTimes100,
            width: 80,
            align: 'right',
        },
        {
            field: 'price',
            headerI18n: 'price',
            body: GridTemplates.currencyTwoDecimals,
            width: 100,
            align: 'right'
        },
        {field: 'accountName', headerI18n: 'account'},
        {field: 'employeeExposed', headerI18n: 'show_to_employees', width: 120, body: GridTemplates.boolean},
        {field: 'active', headerI18n: 'active', width: 120, body: GridTemplates.boolean},
    ];

    return <Grid
        xlnzGrid={true}
        id='ArticleGrid'
        labelI18n='articles'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        onRowSelect={roles.hasRole(Role.ARTICLE_WRITE) ? undefined : () => {
        }}
    />
}

export default ArticleGrid;
