import React from "react";
import {GenerateButton} from "../../../components/Buttons/Button";
import {errorAlert} from "../../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Currency from "../../../enums/Currency";
import usePreload from "../../../hooks/usePreload";
import I18n from "../../../I18n/I18n";
import {findById} from "../../../utils/ArrayUtil";
import {NumberUtils} from "../../../utils/NumberUtils";
import SupplierinvoiceService from "../SupplierinvoiceService";

const SupplierinvoiceRowGridFooter = props => {
    const {
        id,
        supplier,
        priceInclVat,
        currency,
        vatPrice,
        difference,
        onRowListChange
    } = props

    const preload = usePreload()
    const accountList = preload.getAccountList()
    const costcenterList = preload.getCostcenterList()

    const getAccount = accountId => findById(accountList, accountId);
    const getCostcenter = costcenterId => findById(costcenterList, costcenterId);

    const currencySuffix = Currency.findById(currency).suffix;

    return <div className='footer'>
        <div className='left'>
            <GenerateButton
                labelI18n='auto_code_accounts'
                onClick={async () => {
                    if (!supplier) {
                        errorAlert(I18n.t('supplier_missing'))
                        return
                    }
                    if (!priceInclVat) {
                        errorAlert(I18n.t('price_missing'))
                        return
                    }

                    showLoading()

                    const _priceInclVat = priceInclVat || 0
                    const _vatPrice = vatPrice || 0

                    const _resp = await SupplierinvoiceService.getAutoCodeAccounts(id, supplier, _priceInclVat, _vatPrice)

                    onRowListChange(_resp.map(item => ({
                        name: '',
                        price: item.price,
                        toInvoice: 0,
                        account: item.account ? getAccount(item.account)?.id : undefined,
                        costcenter: item.costcenter ? getCostcenter(item.costcenter)?.id : undefined
                    })))
                    hideLoading()
                }}
            />
        </div>
        <div className='middle'></div>
        <div className='right'>
            <table>
                <tbody>
                <tr className='to-pay'>
                    <td>{I18n.t('difference')}</td>
                    <td>{NumberUtils.format(difference, 2, true)} {currencySuffix}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
}
export default SupplierinvoiceRowGridFooter