import React from "react";
import {i18n} from "../../../../I18n/I18n";
import {NumberUtils} from "../../../../utils/NumberUtils";

const AvatarTooltip = props => {
    const {
        employee,
        dayplanning,
        timereg,
    } = props
    return <table>
        <tbody>
        {
            !timereg.notarized && timereg.salaryHours > 0 &&
            <tr>
                <td colSpan={2} style={{paddingBottom: 15, color: 'lightpink'}}><b>{i18n('must_be_notarized')}</b></td>
            </tr>
        }

        <tr>
            <td><b>{i18n('employee')}:</b></td>
            <td>
                <b>{employee?.name || '?'}</b>
            </td>
        </tr>
        {
            timereg.fixedHours !== 0 &&
            < tr>
                <td> {i18n('fixed_time')}:</td>
                <td>{NumberUtils.hours(timereg.fixedHours)}</td>
            </tr>
        }
        {
            timereg.costplusHours !== 0 &&
            < tr>
                <td> {i18n('costplus_time')}:</td>
                <td>
                    {NumberUtils.hours(timereg.costplusHours)}

                    {
                        timereg.debitHours !== timereg.costplusHours &&
                        <>
                            &nbsp;(
                            {i18n('debit_time').toLowerCase()}:&nbsp;{NumberUtils.hours(timereg.debitHours)}
                            )
                        </>
                    }
                </td>
            </tr>
        }

        {
            timereg.salaryHours !== 0 &&
            < tr>
                <td><b>{i18n('sum')}:</b></td>
                <td><b>{NumberUtils.hours(timereg.salaryHours)}</b></td>
            </tr>
        }
        {
            timereg.note.length > 0 &&
            < tr>
                <td>{i18n('note')}:</td>
                <td>{timereg.note}</td>
            </tr>
        }

        < tr>
            <td colSpan={2}>&nbsp;</td>
        </tr>

        < tr>
            <td><b>{i18n('project')}:</b></td>
            <td><b>{dayplanning.projectName}</b></td>
        </tr>
        {
            dayplanning.fixedHours !== 0 &&
            < tr>
                <td> {i18n('fixed_time')}:</td>
                <td>{NumberUtils.hours(dayplanning.fixedHours)}</td>
            </tr>
        }
        {
            dayplanning.costplusHours !== 0 &&
            < tr>
                <td> {i18n('costplus_time')}:</td>
                <td>{NumberUtils.hours(dayplanning.costplusHours)}</td>
            </tr>
        }
        {
            dayplanning.salaryHours !== 0 &&
            < tr>
                <td><b>{i18n('sum')}:</b></td>
                <td><b>{NumberUtils.hours(dayplanning.salaryHours)}</b></td>
            </tr>
        }
        </tbody>
    </table>
}
export default AvatarTooltip;