import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import TenderEditForm from "./edit/TenderEditForm";
import TenderGrid from "./TenderGrid";
import TenderSentGrid from "./TenderSentGrid";

const InvoicesIndex = {
    routeProps: {
        path: '/tenders',
        exact: true,
        items: [
            {
                nameKey: 'all_tenders',
                path: '/tenders/list',
                exact: true,
                element: <TenderGrid/>,
                onRowSelectPath: '/tenders'
            },
            {
                nameKey: 'all_sent_tenders',
                path: '/tenders/list-sent',
                exact: true,
                element: <TenderSentGrid/>,
                onRowSelectPath: '/tenders'
            }
        ]
    },
    nameKey: 'tenders',
    icon: Icon.TENDER,
    showHeadline: true,
    subModules: [{
        routeProps: {
            path: '/tenders/:id',
            exact: true,
            element: <TenderEditForm/>,
        }
    }],
    roles: [Role.TENDER_READ]
};
export default InvoicesIndex;