import Role from "../../enums/Role";
import TimeregdebittypeEditForm from "./TimeregdebittypeEditForm";
import TimeregdebittypeGrid from "./TimeregdebittypeGrid";

const TimeregdebittypeIndex = {
    routeProps: {
        path: '/timeregdebittypes',
        exact: true,
        element: <TimeregdebittypeGrid/>,
    },
    nameKey: 'debit_types',
    icon: 'pi pi-fw pi-folder',
    subModules: [{
        routeProps: {
            path: '/timeregdebittypes/:id',
            exact: true,
            element: <TimeregdebittypeEditForm/>,
        }
    }],
    roles: [Role.ADMIN]
};
export default TimeregdebittypeIndex;