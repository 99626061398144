import {i18n} from "../../I18n/I18n";

export default class SupplierinvoiceStatus {
    static get DRAFT() {
        return Object.freeze({id: 'DRAFT', name: i18n('draft'), namePlural: i18n('drafts')});
    }

    static get UNDER_ATTESTATION() {
        return Object.freeze({id: 'UNDER_ATTESTATION', name: i18n('under_attestation'), namePlural: i18n('under_attestation')});
    }

    static get APPROVED() {
        return Object.freeze({id: 'APPROVED', name: i18n('approved'), namePlural: i18n('approved_plur')});
    }

    static get BOOKKEEPED() {
        return Object.freeze({id: 'BOOKKEEPED', name: i18n('bookkeeped'), namePlural: i18n('bookkeeped_plur')});
    }

    static get NOT_APPROVED() {
        return Object.freeze({id: 'NOT_APPROVED', name: i18n('not_approved'), namePlural: i18n('not_approved_plur')});
    }

    static get CANCELLED() {
        return Object.freeze({id: 'CANCELLED', name: i18n('cancelled'), namePlural: i18n('cancelled_plur')});
    }

    static values() {
        return [
            this.DRAFT,
            this.UNDER_ATTESTATION,
            this.APPROVED,
            this.BOOKKEEPED,
            this.NOT_APPROVED,
            this.CANCELLED
        ];
    }

    static getName(id) {
        return this.values().filter(is => is.id === id)[0].name
    }
}