export default class InvoiceStatus {
    static get DRAFT() {
        return Object.freeze({id: 'DRAFT', name: 'Utkast', namePlural: 'Utkast', userDefined: true});
    }

    static get READY_TO_SEND() {
        return Object.freeze({id: 'READY_TO_SEND', name: 'Klar att skickas', namePlural: 'Klara att skickas', userDefined: true});
    }

    static get SENT() {
        return Object.freeze({id: 'SENT', name: 'Skickad', namePlural: 'Skickade', userDefined: true});
    }

    static get OVERDUE() {
        return Object.freeze({id: 'OVERDUE', name: 'Förfallen', namePlural: 'Förfallna', userDefined: false});
    }

    static get PAID() {
        return Object.freeze({id: 'PAID', name: 'Betald', namePlural: 'Betalade', userDefined: false});
    }

    static get CANCELLED() {
        return Object.freeze({id: 'CANCELLED', name: 'Makulerad', namePlural: 'Makulerade', userDefined: true});
    }

    static values() {
        return [
            this.DRAFT,
            this.READY_TO_SEND,
            this.SENT,
            this.OVERDUE,
            this.PAID,
            this.CANCELLED
        ];
    }

    static getName(id) {
        return this.values().filter(is => is.id === id)[0].name
    }
}