const HyrletOrderStatuses = {
    WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
    PROCESSING: 'PROCESSING',
    DELIVERED: 'DELIVERED',
    CANCELLED: 'CANCELLED'
}

HyrletOrderStatuses.LIST = [{
    value: HyrletOrderStatuses.WAITING_FOR_CONFIRMATION,
    label: 'Väntar på bekräftelse',
    labelMultiple: 'Väntar på bekräftelse'
}, {
    value: HyrletOrderStatuses.PROCESSING,
    label: "Behandlas",
    labelMultiple: 'Behandlas'
}, {
    value: HyrletOrderStatuses.DELIVERED,
    label: "Levererad",
    labelMultiple: 'Levererade'
}, {
    value: HyrletOrderStatuses.CANCELLED,
    label: "Makulerad",
    labelMultiple: 'Makulerade'
}];

HyrletOrderStatuses.getName = id => {
    const res = HyrletOrderStatuses.LIST.find(os => os.value === id);
    return res ? res.label : '';
}

export default HyrletOrderStatuses
