import React, {useContext} from "react";
import {Controller, useWatch} from "react-hook-form";
import AppContext from "../../AppContext";
import Button from "../../components/Buttons/Button";
import Checkbox from "../../components/EditForm/Checkbox";
import Company from "../../enums/Company";
import Companyprop from "../../enums/Companyprop";
import Role from "../../enums/Role";
import useCompanyProp from "../../hooks/useCompanyProp";
import useForceUpdate from "../../hooks/useForceUpdate";
import useRoles from "../../hooks/useRoles";
import {contains, notContains} from "../../utils/ArrayUtil";

const ROLE_FIELD_PREFIX = "ROLE_"

export default function SelectRoles(props) {
    const context = useContext(AppContext)
    const roles = useRoles()

    const {
        form
    } = props

    const superadmin = roles.hasRole(Role.SUPERADMIN)
    const forceUpdate = useForceUpdate()
    const HYRLET_CUSTOMER_ID = useCompanyProp(Companyprop.HYRLET_CUSTOMER_ID, 0)

    const {control} = form
    const watchRoleSet = useWatch({control, name: 'roleSet'})
    console.log("watchRoleSet", watchRoleSet, typeof watchRoleSet)

    const XLNZ = Company.XLNZ_TECH === context.currentCompany.id

    const EXCLUDED_XLNZ_ROLES = XLNZ ? [] :
        [
            Role.AGREEMENT_READ.name,
            Role.AGREEMENT_WRITE.name,
            Role.CRM_READ.name,
            Role.CRM_WRITE.name
        ]

    const roleList = Role.values()
        .filter(r => r.name !== 'SUPERADMIN' || superadmin)
        .filter(role => !role.superadmin || superadmin)
        .filter(role => role !== Role.HYRLET || HYRLET_CUSTOMER_ID > 0)
        .filter(role => {
            console.log("", EXCLUDED_XLNZ_ROLES, role.name, contains(EXCLUDED_XLNZ_ROLES, role.name)
            )
            return XLNZ || notContains(EXCLUDED_XLNZ_ROLES, role.name)
        })


    const hasDependencies = (form, dependencies) => {
        if (!dependencies || dependencies.length === 0) return true;

        const model = form.getValues()
        const numOfDependenciesChecked = dependencies.reduce((sum, d) => {
            let value = form.getValues(ROLE_FIELD_PREFIX + d.name)
            if (value === undefined) {
                value = model.roleSet.find(r => r === d) !== undefined
            }
            sum += value ? 1 : 0;
            return sum;
        }, 0);
        return dependencies.length === numOfDependenciesChecked;
    }

    return <div className="formItem" key='roles'>
        <label htmlFor='roles'>Roles</label>

        {
            !form.disabled &&
            <div style={{display: 'flex', gap: '10px', margin: '10px 0'}}>
                <Button label='Standard'
                        onClick={e => {
                            form.setValue(ROLE_FIELD_PREFIX + Role.ADMIN.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.DASHBOARD_READ.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.CUSTOMER_READ.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.CUSTOMER_WRITE.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.DOCUMENT_READ.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.DOCUMENT_WRITE.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.ARTICLE_READ.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.ARTICLE_WRITE.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.EMPLOYEE_READ.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.EMPLOYEE_WRITE.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.REPORT_READ.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.TENDER_READ.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.TENDER_WRITE.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.PROJECT_READ.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.PROJECT_WRITE.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.TIMEREG_READ.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.TIMEREG_WRITE.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.TIMEREG_UNLOCK_DAY.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.INVOICE_READ.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.INVOICE_WRITE.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.SUPPLIER_READ.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.SUPPLIER_WRITE.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.SUPPLIERINVOICE_READ.name, true)
                            form.setValue(ROLE_FIELD_PREFIX + Role.SUPPLIERINVOICE_WRITE.name, true)
                            forceUpdate()
                        }}/>
                <Button labelI18n='clear'
                        onClick={e => {
                            roleList.forEach(item => {
                                form.setValue(ROLE_FIELD_PREFIX + item.name, false)
                            })
                        }}/>
            </div>
        }

        {
            roleList.map(r => {
                const disabled = !hasDependencies(form, r.dependencies)
                return <div key={r.name} style={{marginBottom: '5px'}}>
                    <Controller
                        name={ROLE_FIELD_PREFIX + r.name}
                        control={form.control}
                        render={({field: {ref, value, onChange}}) =>
                            <Checkbox
                                checked={value}
                                onChange={e => {
                                    onChange(e)
                                    forceUpdate()
                                }}
                                disabled={disabled || form.disabled}
                                label={
                                    roles.getRoleName(r) +
                                    (r.dependencies.length > 0 ? ' (' + r.dependencies.map(d => roles.getRoleName(d)).join(', ') + ')' : '')
                                }
                                labelStyle={{
                                    color: disabled ? '#aaa' : undefined
                                }}
                            />
                        }/>
                </div>
            })
        }
    </div>
}

