import React, {useState} from "react";
import Button from "../../components/Buttons/Button";
import Spinner from "../../components/Spinner/Spinner";
import XlnzCard from "../../components/XlnzCard/XlnzCard";
import useMountEffect from "../../hooks/useMountEffect";
import NetUtils from "../../utils/NetUtils";
import './FortnoxIntegrationRedirect.scss'

export const FortnoxIntegrationRedirect = props => {
    const authorizationCode = props.code
    const authState = props.authState

    const [loading, setLoading] = useState(true)

    async function authorizeToServer() {
        await NetUtils.doGet('/api/fortnox/authorize', {
            authorizationCode: authorizationCode,
            authState: authState
        })
        setLoading(false)
    }

    useMountEffect(() => {
        authorizeToServer()
    })

    if (loading) return <Spinner/>
    return <div id='App'>
        <div className='FortnoxIntegrationRedirect'>
            <XlnzCard title='Fortnox'>
                <div className='activated'>
                    Koppling till Fortnox är nu genomförd!<br/>
                </div>
                <Button label='Stäng' onClick={() => window.close()}/>
            </XlnzCard>
        </div>
    </div>
}
