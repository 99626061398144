import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {CancelButton, EmailButton, PdfButton, SaveButton} from "../../../components/Buttons/Button";
import SendByEmailDialog from "../../../components/Dialog/SendByEmailDialog";
import FormItemDate from "../../../components/EditForm/FormItemDate";
import {errorAlert} from "../../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import Role from "../../../enums/Role";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import RouterUtils from "../../../utils/RouterUtils";
import InvoiceService from "../InvoiceService";

const ReminderEditFormDialog = props => {
    const roles = useRoles()
    const navigate = useNavigate();

    const {
        model,
        invoiceModel,
        save,
        getCustomerEmail,
        onHide
    } = props
    const [showSendReminderByEmail, setShowSendReminderByEmail] = useState(false);

    const disabled = !roles.hasRole(Role.INVOICE_WRITE) || model.sentTime

    const form = {
        ...useForm({
            defaultValues: model
        }), disabled: disabled
    }
    const {handleSubmit, getValues, reset} = form

    const saveReminder = async data => {
        if (invoiceModel.reminderList.find(m => m.date === data.date && m.id !== data.id)) {
            errorAlert(i18n('duplicates_not_allowed'))
            return false;
        }

        showLoading()
        let _invoiceModel

        if (!data.id) {
            invoiceModel.reminderList.push(data)
            _invoiceModel = await save(invoiceModel, undefined, true)
        } else {
            const oldReminderModel = invoiceModel.reminderList.find(m => m.id === data.id)
            oldReminderModel.date = data.date
            _invoiceModel = await save(invoiceModel, undefined, true)
        }
        hideLoading()
        const _reminderModel = _invoiceModel.reminderList.find(item => item.date === data.date)
        reset(_reminderModel)
        return _reminderModel
    }
    const onSubmit = async data => {
        if (await saveReminder(data)) onHide()
    }

    const onDownloadReminderPdf = async () => {
        showLoading();
        let data = getValues()
        if (!disabled) {
            data = await saveReminder(data)
        }
        if (data) await InvoiceService.downloadReminderPdf(invoiceModel.id, data.id)
        hideLoading()
    }

    const onSendReminderByEmail = () => {
        setShowSendReminderByEmail(true)
    }

    // const onRemove = async () => {
    //     const data = getValues()
    //     removeByValue(invoiceModel.reminderList, invoiceModel.reminderList.find(m => m.id === data.id))
    //     await save(invoiceModel, undefined, true)
    //     props.onHide()
    // }

    return <div>
        <XlnzDialog headerI18n='reminder'
                    onHide={props.onHide}
                    buttons={() => [
                        <PdfButton key='pdf' labelI18n='preview' onClick={handleSubmit(onDownloadReminderPdf)}/>,
                        <EmailButton key='send' onClick={handleSubmit(onSendReminderByEmail)}/>,
                        <SaveButton key='save' onClick={handleSubmit(onSubmit)} disabled={disabled}/>,
                        // <RemoveButton key='remove' onClick={e => onRemove()} disabled={disabled || model.id > 0}/>,
                        <CancelButton key='cancel' onClick={e => props.onHide()}/>
                    ]}
        >
            <div className='EditForm' style={{width: '400px', margin: 0}}>
                <form>
                    <FormItemDate name='date' labelI18n='date' {...form} />
                </form>
            </div>

        </XlnzDialog>
        {
            showSendReminderByEmail &&
            <SendByEmailDialog
                email={getCustomerEmail()}
                onSend={async toEmail => {
                    showLoading();
                    let data = getValues()
                    if (!disabled) {
                        data = await saveReminder(data)
                    }
                    if (data) {
                        await InvoiceService.sendReminderByEmail(invoiceModel.id, data.id, toEmail)
                        props.onHide()
                        RouterUtils.goBack(navigate) //Needs to be here to make sure invoice is reloaded.
                    }
                    setShowSendReminderByEmail(false)
                    hideLoading()
                }}
                onHide={() => setShowSendReminderByEmail(false)}/>
        }
    </div>
}

export default ReminderEditFormDialog;
