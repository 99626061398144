export const addFreeRows = (rowList, freeRows, newValue, isNotFreeRowFunc) => {
    const _rowList = [...rowList]
    const rowsToAdd = freeRows - numOfFreeRows(_rowList, isNotFreeRowFunc)
    for (let i = 0; i < rowsToAdd; i++) {
        _rowList.push({...newValue})
    }
    return _rowList
}

export const numOfFreeRows = (list, isNotFreeRowFunc) => {
    let result = 0;
    for (let i = list.length - 1; i >= 0; i--) {
        const item = list[i]
        if (
            item?.name?.length > 0
            || item?.alias?.length > 0
            || item?.id > 0
            || typeof item?.id === 'string'
            || item?.user !== undefined
            // || item?.date !== undefined //No no, mess up dayplanning
            || item?.employee !== undefined
            || item?.employeeName !== undefined
            || item?.price > 0 || item?.price < 0
            || item?.project !== undefined
            || item?.projectId > 0
            || item?.key?.length > 0
            || (isNotFreeRowFunc && isNotFreeRowFunc(item))
        ) {
            // if (item?.name?.length > 0 || item?.id > 0 || item?.user > 0) {
            return result
        } else {
            result++
        }
    }
    return result;
}

export const isFreeRow = (list, item) => {
    if (!list) {
        console.log('Invalid list:', list, item)
        throw new Error('Invalid list: ' + list)
    }
    const freeRowsList = list.slice(list.length - numOfFreeRows(list), list.length)
    return freeRowsList.indexOf(item) !== -1
}