import ArrayUtil from "../utils/ArrayUtil";

export default class Userprop {
    static get HIDE_ARTICLE_PRICES() {
        return Object.freeze({name: 'hide-article-prices', label: 'Hide article prices', type: 'boolean'});
    }


    static values() {
        return ArrayUtil.sortOnField([
            this.HIDE_ARTICLE_PRICES,
        ], 'label');
    }

    static findById(name) {
        return this.values().filter(item => item.name === name)[0]
    }
}