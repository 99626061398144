import ArrayUtil from "@utils/ArrayUtil";
import {i18n} from "../I18n/I18n";

export default class Language {
    static get ENGLISH() {
        return Object.freeze({id: 'en', name: i18n('english')});
    }

    static get SWEDISH() {
        return Object.freeze({id: 'sv', name: i18n('swedish')});
    }

    static values() {
        const result = [this.ENGLISH, this.SWEDISH];
        ArrayUtil.sortOnField(result, 'name')
        return result
    }

    static findById(id) {
        switch (id) {
            case this.ENGLISH.id:
                return this.ENGLISH;
            case this.SWEDISH.id:
                return this.SWEDISH;
            default:
                throw new Error("Invalid language id: " + id)
        }
    }
}