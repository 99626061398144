import React from "react";
import {i18n} from "../../I18n/I18n";

const MobileGrid = props => {
    const {
        value,
        columns,
        onRowSelect
    } = props

    function onClick(e, rowData) {
        onRowSelect({
            data: rowData
        })
    }

    return <div className='MobileGrid'>
        {
            value.map((rowData, index) => <div key={rowData.id + '-' + index} className='mobile-row'
                                               onClick={e => onClick(e, rowData)}>
                {
                    columns
                        .filter(column => isMainField(column) || column.mobile || column.mobileWithHeader)
                        .filter(column => rowData[column.field] || column.body)
                        .map(column => <div key={column.field} className='column'>
                                <MobileGridItem rowData={rowData} columns={columns} column={column}/>
                            </div>
                        )
                }
            </div>)

        }
    </div>
}
export default MobileGrid

const MobileGridItem = ({rowData, columns, column}) => {
    const pdfColumn = columns.find(c => c.field === 'pdf')

    function addHeader(column, rowData) {
        if (column.mobileWithHeader && rowData[column.field]) {
            const header = column.headerI18n ? i18n(column.headerI18n) : column.header
            return header + ": "
        } else {
            return ''
        }
    }

    if (isMainField(column)) {
        return <div className='name-column'>
            {pdfColumn && <span className='pdf'>{pdfColumn.body(rowData, column)}</span>}
            {rowData[column.field]}
        </div>
    } else if (column.mobileBold) {
        return <div className='name-column'>
            {rowData[column.field]}
        </div>
    } else if (column.body) {
        return <span>
            {column.mobileWithHeader
                && (column.headerI18n
                    ? i18n(column.headerI18n)
                    : column.header)
                + ': '}
            {column.body(rowData, column)}
        </span>
    } else {
        return addHeader(column, rowData) + rowData[column.field]
    }
}

function isMainField(column) {
    return ['name', 'label'].indexOf(column.field) >= 0
}
