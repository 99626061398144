import React from "react";
import {NBSP} from "../../../App";
import Tooltip from "../../../components/Overlay/Tooltip";
import {i18n} from "../../../I18n/I18n";
import './getDayHeader.scss'

const getDayBody = (item, i, projectInactive) => {
    const q = item.dayList[i].quantity;

    let className = undefined
    let tooltip = undefined
    if (projectInactive) {
        className = 'inactive-project'
        tooltip = i18n('project_is_not_ongoing')
    }

    return <Tooltip value={tooltip}>
        <div className={className}>
            {q !== 0 ? q : NBSP}
        </div>
    </Tooltip>


}
export default getDayBody