const DRAFT = 'DRAFT';
const UNANSWERED = 'UNANSWERED';
const ACCEPTED = 'ACCEPTED';
const NOT_ACCEPTED = 'NOT_ACCEPTED';
const CANCELLED = 'CANCELLED';

const LIST = [{
    value: DRAFT,
    label: 'Utkast',
    labelMultiple: 'Utkast'
}, {
    value: UNANSWERED,
    label: "Obesvarad",
    labelMultiple: 'Obesvarade'
}, {
    value: ACCEPTED,
    label: "Accepterad",
    labelMultiple: 'Accepterade'
}, {
    value: NOT_ACCEPTED,
    label: "Ej accepterad",
    labelMultiple: 'Ej accepterade'
}, {
    value: CANCELLED,
    label: "Makulerad",
    labelMultiple: 'Makulerade'
}]

const TenderStatus = {
    DRAFT,
    UNANSWERED,
    ACCEPTED,
    NOT_ACCEPTED,
    CANCELLED,

    LIST,

    getName: id => {
        const res = LIST.find(os => os.value === id);
        return res ? res.label : '';
    },
    getLabel: id => {
        const res = LIST.find(os => os.value === id);
        return res ? res.label : '';
    },

    getLabelMultiple: id => {
        const res = LIST.find(os => os.value === id);
        return res ? res.labelMultiple : '';
    }
}
export default TenderStatus;