import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import AppforleaveEditForm from "./Appforleaves/AppforleaveEditForm";
import AppforleaveGrid from "./Appforleaves/AppforleaveGrid";
import TimeregabsenceEditForm from "./Dayplannings/absencegrid/edit/TimeregabsenceEditForm";
import TimeregallowanceEditForm from "./Dayplannings/allowancegrid/edit/TimeregallowanceEditForm";
import DayplanningEditForm from "./Dayplannings/dayplanninggrid/edit/DayplanningEditForm";
import DayplanningView from "./Dayplannings/DayplanningView";
import MonthoverviewView from "./Monthoverview/MonthoverviewView";
import NotarizeView from "./Notarize/NotarizeView";
import TimeRegistrationEmployeePerWeekGrid from "./TimeregEmployeePerWeek/TimeregEmployeePerWeek";
import WeekplanningView from "./Weekplannings/WeekplanningView";

const Timereg = {
    routeProps: {
        path: '/timereg',
        exact: true,
        items: [
            {
                nameKey: 'day_planning',
                path: '/timereg/dayplanning',
                exact: true,
                element: <DayplanningView/>,
            },
            {
                nameKey: 'week_planning',
                path: '/timereg/weekplanning',
                exact: true,
                element: <WeekplanningView/>,
            },
            {
                nameKey: 'notarize',
                path: '/timereg/notarize',
                exact: true,
                element: <NotarizeView/>,
            },
            {
                nameKey: 'time_registration_employee_per_week',
                path: '/timereg/time-registration-employee-per-week',
                exact: true,
                element: <TimeRegistrationEmployeePerWeekGrid/>,
            },
            {
                nameKey: 'applications_for_leave',
                path: '/timereg/appforleaves',
                exact: true,
                element: <AppforleaveGrid/>,
            },
            {
                nameKey: 'month_overview',
                path: '/timereg/monthoverview',
                exact: true,
                element: <MonthoverviewView/>,
            }
        ]
    },
    nameKey: 'time_registration',
    icon: Icon.TIMEREG,
    subModules: [
        {
            routeProps: {
                path: '/timereg/dayplanning/:id/:date',
                exact: true,
                element: <DayplanningEditForm/>,
            }
        },
        {
            routeProps: {
                path: '/timeregabsence/:date',
                exact: true,
                element: <TimeregabsenceEditForm/>,
            }
        },
        {
            routeProps: {
                path: '/timeregallowance/:date',
                exact: true,
                element: <TimeregallowanceEditForm/>,
            }
        },
        {
            routeProps: {
                path: '/timereg/appforleaves/:id',
                exact: true,
                element: <AppforleaveEditForm/>,
            }
        }
    ],
    roles: [Role.TIMEREG_READ]
};
export default Timereg;