import {faComment, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import {i18n} from "../../I18n/I18n";
import Button from "../Buttons/Button";
import './Alert.scss';
import renderOverlay from "./renderOverlay";

const Dialog = props => {
    const {
        onHide,
        header,
        message,
        faicon
    } = props
    const closeButtonText = props.closeButtonText ? props.closeButtonText : i18n('close');

    return <div className='Alert'>
        <h1 className='header'>
            {faicon && <FontAwesomeIcon icon={faicon} className='faIcon'/>}
            <span>{header}</span>
        </h1>
        <div className='message'>
            {
                message.split('\n').map((item, key) => {
                    return <span key={key}>{item}<br/></span>
                })
            }
        </div>
        <div className='footer'>
            <Button label={closeButtonText} onClick={onHide}/>
        </div>
    </div>
}

export const alert = (header, msg, faIcon) => renderOverlay(<Dialog
    header={header}
    message={msg}
    faicon={faIcon}/>
)

export const errorAlert = msg => renderOverlay(<Dialog header={i18n('error_message')} message={msg}
                                                       faicon={faExclamationCircle}/>)

export const infoAlert = msg => renderOverlay(<Dialog
    header={i18n('message')}
    message={msg}
    faicon={faComment}/>
)
