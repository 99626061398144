import NetUtils from "../../utils/NetUtils";

const EmployeeService = {
    findAll: () => NetUtils.doGet('/api/employees'),
    findByActive: async () => NetUtils.doGet('/api/employees').then(customerList => customerList.filter(c => c.active)),
    findById: id => NetUtils.doGet('/api/employees/' + id),
    downloadEmploymentContract: async employeeId => NetUtils.downloadFile('/api/employees/' + employeeId + '/employment-contract-pdf'),
    update: model => NetUtils.doPost('/api/employees', model),
    sendMessageToEmployees: (subject, message, emailList) => NetUtils.doPost('/api/employees/send-message-to-employees', {
        subject: subject,
        message: message,
        emailList: emailList
    }),
}
export default EmployeeService;