import React from 'react';
import {i18n} from "../../I18n/I18n";
import TextareaInput from "./TextareaInput";

const FormItemTextArea = props => {
    const {
        name,
        label,
        labelI18n,
    } = props

    return <div className="formItem">
        <label htmlFor={'textarea-' + name}>{labelI18n ? i18n(labelI18n) : label}</label>
        <TextareaInput {...props}/>
    </div>
}
export default FormItemTextArea;