import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useCallback, useEffect, useState} from "react";
import ContextMenuItem from "../../../components/ContextMenu/ContextMenuItem";
import DateStepper from "../../../components/Date/DateStepper";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import {errorAlert} from "../../../components/Overlay/Alert";
import {confirmYes} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Tooltip from "../../../components/Overlay/Tooltip";
import Spinner from "../../../components/Spinner/Spinner";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import Unit from "../../../enums/Unit";
import {matchSearch} from "../../../functions/match";
import {GridTemplates} from "../../../GridTemplates";
import usePreload from "../../../hooks/usePreload";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import {arraySum, findById, pushArray} from "../../../utils/ArrayUtil";
import {
    addDays,
    getDayInMonth,
    getDayNameShort,
    getFirstDateOfWeek,
    getHolidayName,
    getLastDateOfWeek,
    getMonthNameShort,
    isHoliday,
    isWeekend
} from "../../../utils/DateUtils";
import {formatHours, formatNumber} from "../../../utils/NumberUtils";
import DaylockService from "../DaylockService";
import DayplanningService from "../Dayplannings/DayplanningService";
import './TimeregEmployeePerWeek.scss'
import LockButton from "../Shared/LockButton";
import TimeregabsenceService from "../TimeregabsenceService";
import TimeregallowanceService from "../TimeregallowanceService";
import TimeregService from "../TimeregService";

export default function TimeregEmployeePerWeek() {
    const roles = useRoles()
    const filter = useFilter('TimeregEmployeePerWeek', [
        {name: 'date', defaultValue: getFirstDateOfWeek()},
        {name: 'search', defaultValue: ''},
    ])
    const [rowList, setRowList] = useState()
    const [daylockSet, setDaylockSet] = useState()

    const allDaysLocked = daylockSet?.size === 7

    const preload = usePreload()
    const timeregsalarytypeList = preload.getTimeregsalarytypeList()
    const timeregabsencetypeList = preload.getTimeregabsencetypeList()
    const timeregallowancetypeList = preload.getTimeregallowancetypeList()

    const disabled = !roles.hasRole(Role.TIMEREG_WRITE);

    const date = getFirstDateOfWeek(filter.date)

    // console.log("rowList", rowList)
    function getTimeregInfo(tr) {
        const salarytypeName = findById(timeregsalarytypeList, tr.salarytype)?.name

        return <div key={tr.id}>
            {formatHours(tr.salaryHours)}
            {salarytypeName ? ' ' + salarytypeName : ''}:&nbsp;
            {tr.projectLabel}
            {tr.employeeComment.length > 0 && ' (' + tr.employeeComment + ')'}
        </div>
    }

    function getTimeregabsenceInfo(tra) {
        const absencetypeName = findById(timeregabsencetypeList, tra.type)?.name

        return <div key={tra.id}>
            {formatHours(tra.hours)}
            {absencetypeName ? ' ' + absencetypeName : ''}
        </div>
    }

    function getTimeregallowanceInfo(tra) {
        const allowancetype = findById(timeregallowancetypeList, tra.type)
        const unitSymbol = Unit.findById(allowancetype.unit)?.symbol
        return <div key={tra.id}>
            {formatNumber(tra.quantity)} {unitSymbol} {allowancetype.name}
        </div>
    }

    const filteredList = rowList?.filter(c => matchSearch(filter, c))
    if (filteredList) {
        filteredList.forEach(item => {
            for (let i = 0; i < 7; i++) {
                const dateCounter = addDays(date, i)
                item['salaryHours' + i] = 0
                const infoList = []

                const timeregList = item.dateTimeregList[dateCounter]
                let timeregsNotarized = true
                if (timeregList) {
                    item['salaryHours' + i] += arraySum(timeregList, 'salaryHours')
                    timeregsNotarized = timeregList.length === 0 || timeregList.find(item => !item.notarized) === undefined
                    pushArray(infoList, timeregList.map(tr => getTimeregInfo(tr)))
                }

                const timeregabsenceList = item.dateTimeregabsenceList[dateCounter]
                let timeregabsencesNotarized = true
                if (timeregabsenceList) {
                    item['salaryHours' + i] += arraySum(timeregabsenceList, 'hours')
                    timeregabsencesNotarized = timeregabsenceList.length === 0 || timeregabsenceList.find(item => !item.notarized) === undefined
                    pushArray(infoList, timeregabsenceList.map(tra => getTimeregabsenceInfo(tra)))
                }

                const timeregallowanceList = item.dateTimeregallowanceList[dateCounter]
                let hasAllowances = false
                let timeregallowancesNotarized = true
                if (timeregallowanceList) {
                    hasAllowances = timeregallowanceList.length > 0
                    // item['salaryHours' + i] += arraySum(timeregallowanceList, 'hours')
                    timeregallowancesNotarized = timeregallowanceList.length === 0 || timeregallowanceList.find(item => !item.notarized) === undefined
                    pushArray(infoList, timeregallowanceList.map(tra => getTimeregallowanceInfo(tra)))
                }

                item['unnotarized' + i] = !timeregsNotarized || !timeregabsencesNotarized || !timeregallowancesNotarized

                item['hasAllowances' + i] = hasAllowances

                item['info' + i] = <div>
                    {
                        item['unnotarized' + i] &&
                        <div style={{
                            marginBottom: '10px',
                            color: 'lightpink'
                        }}>
                            {i18n('must_be_notarized')}
                        </div>
                    }
                    {infoList}
                </div>

            }
        })
    }

    const loadData = useCallback(() => {
        showLoading()
        setRowList(undefined)
        const _date = filter.date

        const startDate = getFirstDateOfWeek(_date)
        const endDate = getLastDateOfWeek(_date)
        console.log("startDate", startDate, endDate)

        Promise.all([
            DayplanningService.listTimeregEmployeePerWeek(filter.date),
            DaylockService.findByDateInterval(startDate, endDate)
        ]).then(([_rowList, _daylockList]) => {
            setRowList(_rowList)
            setDaylockSet(new Set(_daylockList.map(item => item.date)))
            hideLoading()
        })

    }, [filter.date])

    useEffect(() => {
        loadData()
    }, [loadData])

    function getHeaderName(_date) {
        return getDayNameShort(_date) + ' ' +
            getDayInMonth(_date) + ' ' +
            getMonthNameShort(_date).toLowerCase()
    }

    function DayHeader({date}) {
        const holidayName = getHolidayName(date, true)

        const locked = daylockSet && daylockSet.has(date)

        const classNames = ['dayHeader']
        if (locked) classNames.push('locked')

        const headerName = getHeaderName(date)

        return <div className={classNames.join(' ')}>
            <Tooltip value={
                (holidayName || locked) &&
                <div>
                    {holidayName}
                    {locked ? <div>{i18n('day_locked')}</div> : ''}
                </div>
            }>
                <span>{headerName}</span>
            </Tooltip>
        </div>
    }

    const columns = [{field: 'employeeName', headerI18n: 'employee', minWidth: 150}]
    for (let i = 0; i < 7; i++) {
        const dateCounter = addDays(date, i)

        const classNames = ['dayColumn']
        if (isHoliday(dateCounter, true)) classNames.push('holiday')
        if (isWeekend(dateCounter)) classNames.push('weekend')

        columns.push({
            field: 'salaryHours' + i,
            header: <DayHeader date={dateCounter}/>,
            excelHeader: getHeaderName(dateCounter),
            body: rowData => <DayBody i={i} rowData={rowData}/>,
            excelBody: rowData => rowData['salaryHours' + i],
            footer: FooterAggregate.SUM_HOURS,
            className: classNames.join(' '),
            align: 'right', minWidth: 150
        })
    }

    columns.push({
        field: 'totalHours',
        headerI18n: 'total',
        body: GridTemplates.hours,
        footer: FooterAggregate.SUM_HOURS,
        align: 'right', minWidth: 150,
        className: 'bold'
    })

    function getTimeregList() {
        return rowList.flatMap(item => Object.values(item.dateTimeregList).flat());
    }

    function getTimeregabsenceList() {
        return rowList.flatMap(item => Object.values(item.dateTimeregabsenceList).flat());
    }

    function getTimeregallowanceList() {
        return rowList.flatMap(item => Object.values(item.dateTimeregallowanceList).flat());
    }

    function validateLock() {
        if (getTimeregList().find(tr => !tr.notarized) !== undefined) {
            errorAlert(i18n('error_time_to_notarize'))
            return false
        }

        if (getTimeregabsenceList().find(tr => !tr.notarized) !== undefined) {
            errorAlert(i18n('error_time_to_notarize'))
            return false
        }

        if (getTimeregallowanceList().find(tr => !tr.notarized) !== undefined) {
            errorAlert(i18n('error_time_to_notarize'))
            return false
        }
        return true
    }

    const leftFilters = [
        <div>
            <label className="label">{i18n('week')}</label>
            <DateStepper value={filter.date} weekPicker={true} onChange={date => {
                filter.update('date', date)
            }}/>
        </div>,
        <div style={{marginLeft: '50px'}}>
            {daylockSet &&
                <>
                    <label className="label">&nbsp;</label>
                    <LockButton
                        labelI18n={allDaysLocked ? 'unlock_week' : 'lock_week'}
                        locked={allDaysLocked}
                        disabled={(!roles.hasRole(Role.TIMEREG_WRITE))}
                        onClick={async e => {
                            if (validateLock()) {
                                confirmYes(async () => {
                                    showLoading()
                                    for (let i = 0; i < 7; i++) {
                                        const dateCounter = addDays(date, i)
                                        if (allDaysLocked) {
                                            await DaylockService.unlock(dateCounter)
                                        } else {
                                            if (!daylockSet.has(dateCounter)) await DaylockService.lock(dateCounter)
                                        }
                                    }
                                    await loadData()
                                    hideLoading()
                                })
                            }
                        }}/>
                </>
            }
        </div>

    ]

    function notarize(rowData) {
        confirmYes(async () => {
            showLoading()
            console.log("item", rowData)

            const unnotarizedTimeregList = []
            const unnotarizedTimeregabsenceList = []
            const unnotarizedTimeregallowanceList = []

            for (let i = 0; i < 7; i++) {
                const dateCounter = addDays(date, i)

                pushArray(unnotarizedTimeregList, rowData.dateTimeregList[dateCounter].filter(item => !item.notarized))
                pushArray(unnotarizedTimeregabsenceList, rowData.dateTimeregabsenceList[dateCounter].filter(item => !item.notarized))
                pushArray(unnotarizedTimeregallowanceList, rowData.dateTimeregallowanceList[dateCounter].filter(item => !item.notarized))
            }

            if (unnotarizedTimeregList.length > 0) {
                await TimeregService.notarizeIdList(unnotarizedTimeregList.map(item => item.id))
            }
            if (unnotarizedTimeregabsenceList.length > 0) {
                await TimeregabsenceService.notarizeIdList(unnotarizedTimeregabsenceList.map(item => item.id))
            }
            if (unnotarizedTimeregallowanceList.length > 0) {
                await TimeregallowanceService.notarizeIdList(unnotarizedTimeregallowanceList.map(item => item.id))
            }
            await loadData()
            hideLoading()
        })
    }

    const contextMenu = [
        ContextMenuItem({
            i18n: 'notarize', icon: Icon.NOTARIZE,
            command: notarize,
            hidden: row => !roles.hasRole(Role.TIMEREG_WRITE)
        }),
    ]

    if (!rowList) return <Spinner/>
    return <div className='TimeregEmployeePerWeek'>
        <Grid
            xlnzGrid={true}
            forceDesktop={true}
            disabled={disabled}
            labelI18n='time_registration_employee_per_week'
            icon={Icon.TIMEREG}
            leftFilters={leftFilters}
            filter={filter}
            scrollable={true}
            columns={columns}
            value={filteredList}
            sortable={true}
            onRowSelect={() => {
            }}
            contextMenu={contextMenu}
        />
    </div>
}

function DayBody({i, rowData}) {
    const salaryHours = rowData['salaryHours' + i]
    const unnotarized = rowData['unnotarized' + i]
    const hasAllowances = rowData['hasAllowances' + i]

    return <Tooltip value={rowData['info' + i]}>
        <div className='DayGridTemplate'>
            {
                unnotarized &&
                <div className='unnotarized-warning'>
                    <FontAwesomeIcon icon={Icon.UNNOTARIZE}
                                     className='faIcon'
                                     style={{
                                         color: 'red',
                                         fontSize: '12'
                                     }}
                    />
                </div>
            }
            {
                salaryHours !== 0
                    ? formatHours(salaryHours)
                    : (hasAllowances ? '-' : '')
            }
        </div>
    </Tooltip>
}