import React, {useMemo, useState} from 'react';
import Checkbox from "../../components/EditForm/Checkbox";
import FormItemText from "../../components/EditForm/FormItemText";
import Label from "../../components/EditForm/Label";
import SimpleEditForm from "../../components/EditForm/SimpleEditForm";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import TabPanel from "../../components/TabView/TabPanel";
import XlnzDropdown from "../../components/XlnzDropdown/XlnzDropdown";
import Role from "../../enums/Role";
import {match, matchOnlyEmployees, matchSearch} from "../../functions/match";
import useEmployees from "../../hooks/useEmployees";
import usePreload from "../../hooks/usePreload";
import useUserPersmissions from "../../hooks/useUserPersmissions";
import {i18n} from "../../I18n/I18n";
import {findById} from "../../utils/ArrayUtil";
import {deepCopy} from "../../utils/ObjectUtils";
import WorkcrewService from "./WorkcrewService";

export default function WorkcrewEditForm() {
    const userPersmissions = useUserPersmissions()

    const filter = useFilter('WorkcrewEditForm', [
        {name: 'onlyEmployees', defaultValue: false},
        {name: 'subcontractor', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])

    // const userPersmissions = useUserPersmissions()
    const employees = useEmployees()
    const preload = usePreload()

    const employeeList = useMemo(() => deepCopy(employees.activeUsable), [employees])

    const subcontractorList = preload.getSupplierList().filter(item => item.subcontractor)

    const [selection, setSelection] = useState([])

    const filteredList = employeeList.filter(e =>
        matchOnlyEmployees(e, filter.onlyEmployees) &&
        match(filter, e, 'subcontractor') &&
        matchSearch(filter, e)
    )

    const postLoadFunction = _model => {
        const _selection = _model.employeeIdList.map(eId => findById(employeeList, eId))
        console.log("_selection", _selection)
        setSelection(_selection)
    }

    const preSaveFunction = data => {
        data.employeeIdList = selection.map(item => item.id)
    }

    const leftFilters = [
        <div>
            <Label valueI18n='only_employees'/>
            <Checkbox checked={filter.onlyEmployees} onChange={e => filter.update('onlyEmployees', e.target.checked)}
                      style={{marginTop: '5px'}}/>
        </div>,
        <div>
            <Label valueI18n='subcontractor'/>
            <XlnzDropdown
                value={filter.subcontractor}
                options={subcontractorList}
                showClear={true}
                placeholder={i18n('select') + '...'}
                optionValue='id'
                optionLabel='name'
                onChange={e => filter.update('subcontractor', e.value)}
                style={{width: '250px'}}/>
        </div>
    ]

    const columns = [
        {field: 'name', headerI18n: 'name'},
        {field: 'number', headerI18n: 'number', width: 80, mobileWithHeader: true},
        {field: 'subcontractorName', headerI18n: 'subcontractor'},
        {field: 'selection', selectionMode: 'multiple', width: 40},
    ]


    return <SimpleEditForm
        writeRole={Role.EMPLOYEE_WRITE}
        headlineI18n='work_crews'
        defaultValues={{
            id: 0,
            name: '',
            employeeIdList: []
        }}
        loadFunction={WorkcrewService.findById}
        postLoadFunction={postLoadFunction}
        preSaveFunction={preSaveFunction}
        saveFunction={WorkcrewService.update}
        removeFunction={WorkcrewService.delete}
        initLeftFields={form => [
            <FormItemText key='name' name='name' label='Namn' maxLength={128} required {...form}/>,
        ]}
        initCenterFields={form => [
            <div key='selectedEmployees' className='formItem'>
                <Label valueI18n='selected'/>
                {
                    selection.map(item => <div key={item.id} style={{lineHeight: '20px'}}>
                        {item.name + (item.subcontractor ? ' (' + item.subcontractorName + ')' : '')}
                    </div>)
                }
            </div>

        ]}
        initTabs={form => [
            <TabPanel key='employees' headerI18n='employees'>
                <div>
                    <Grid disabled={!userPersmissions.hasRole(Role.EMPLOYEE_WRITE)}
                          leftFilters={leftFilters}
                          filter={filter}
                          updateFilter={filter.update}
                        // menuItems={menuItems}
                          columns={columns}
                          value={filteredList}
                          selection={selection}
                          onSelectionChange={e => setSelection(e.value)}
                          heightOffset={400}
                          hideExtraMenu={true}
                    />
                </div>
            </TabPanel>
        ]}
    />
}


