import React from "react";
import usePreload, {PreloadType} from "../../hooks/usePreload";
import FormItemDropdown from "./FormItemDropdown";

const FormItemCostcenter = props => {
    const preload = usePreload()
    const {
        getValues
    } = props

    const value = getValues('account')

    const list = preload.get(PreloadType.ACCOUNT_LIST)
        .filter(item => item.active || item.id === value)

    if (list.length === 0) return

    return <FormItemDropdown name='account' labelI18n='account'
                             options={list}
                             optionLabel='label'
                             showSelect
                             {...props} />
}
export default FormItemCostcenter