import {faChartLine} from "@fortawesome/free-solid-svg-icons";
import Role from "../../enums/Role";
import KmaView from "./KmaView";

const KmaIndex = {
    routeProps: {
        path: '/kma',
        exact: true,
        element: <KmaView/>,
    },
    name: 'KMA-plan',
    icon: faChartLine,
    roles: [Role.ADMIN]
};
export default KmaIndex;