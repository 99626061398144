import React from 'react';
import Language from "../../enums/Language";
import {i18n} from "../../I18n/I18n";
import UserService from "../../modules/Users/UserService";
import {MainButton} from "../Buttons/Button";
import Grid from "../Grid/Grid";
import {showLoading} from "../Overlay/Loading";
import XlnzDialog from "../XlnzDialog/XlnzDialog";

export default function ChangeLanguage(props) {

    const switchLanguage = async language => {
        showLoading()
        console.log("language", language)
        await UserService.changeLanguage(language.id)
        window.location = '/'
    }

    return <XlnzDialog header={i18n('change_language')}
                       onHide={props.onHide}
                       buttons={() => [<MainButton key='close' labelI18n='close' onClick={e => props.onHide()}/>]}
    >
        {
            <div style={{width: '400px'}}>
                <Grid xlnzGrid={true}
                      columns={[
                          {
                              headerI18n: 'language',
                              field: 'name'
                          }
                      ]}
                      value={Language.values()}
                      onRowSelect={e => {
                          switchLanguage(e.data)
                      }}
                />
            </div>
        }
    </XlnzDialog>
}