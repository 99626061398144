import React from 'react';
import {Controller} from "react-hook-form";
import FormItemCheckbox from "../../../components/EditForm/FormItemCheckbox";
import FormItemText from "../../../components/EditForm/FormItemText";
import SimpleEditForm from "../../../components/EditForm/SimpleEditForm";
import TabPanel from "../../../components/TabView/TabPanel";
import Role from "../../../enums/Role";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import {i18n} from "../../../I18n/I18n";
import {filterActive} from "../../../utils/ArrayUtil";
import ProjecttimeregdebittypepriceGrid from "../../Projects/edit/ProjecttimeregdebittypepriceGrid";
import PricelistService from "../PricelistService";
import PricelistarticlepriceGrid from "./PricelistarticlepriceGrid";

const PricelistEditForm = () => {
    const preload = usePreload()
    const timeregdebittypeList = filterActive(preload.getTimeregdebittypeList())
    const articleList = filterActive(preload.getArticleList())


    return <SimpleEditForm
        writeRole={Role.INVOICE_WRITE}
        headlineI18n='pricelist'
        defaultValues={{
            active: true,
            timeregdebittypepriceList: [],
            articlepriceList: []
        }}
        loadFunction={PricelistService.findById}
        saveFunction={PricelistService.update}
        initLeftFields={form => [
            <FormItemText key='name' name='name' labelI18n='name' maxLength={128} required {...form}/>,
            <FormItemCheckbox key='active' name='active' labelI18n='active' {...form}/>
        ]}
        initTabs={form => [
            <TabPanel key='debit_time' header={i18n('debit_time')}>
                <div className='columnContainer'>
                    <div className='column'>
                        <div className="formItem">
                            <Controller name='timeregdebittypepriceList'
                                        control={form.control}
                                        render={({field: {ref, value, onChange}}) =>
                                            <ProjecttimeregdebittypepriceGrid inputRef={ref} value={value}
                                                                              onChange={onChange}
                                                                              timeregdebittypeList={timeregdebittypeList}
                                            />
                                        }/>
                        </div>
                    </div>
                </div>
            </TabPanel>,
            <TabPanel key='articles' header={i18n('articles')}>
                <div style={{width: '700px'}}>
                    <div className="formItem">
                        <Controller name='articlepriceList'
                                    control={form.control}
                                    render={({field: {ref, value, onChange}}) =>
                                        <PricelistarticlepriceGrid inputRef={ref} value={value}
                                                                   onChange={onChange}
                                                                   articleList={articleList}
                                        />
                                    }/>
                    </div>
                </div>
            </TabPanel>

        ]}
        preloadType={PreloadType.PRICELIST_LIST}
    />
}

export default PricelistEditForm;
