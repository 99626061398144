import Role from "../../enums/Role";
import TimeregsalarytypeEditForm from "./TimeregsalarytypeEditForm";
import TimeregsalarytypeGrid from "./TimeregsalarytypeGrid";

const TimeregsalarytypeIndex = {
    routeProps: {
        path: '/timeregsalarytypes',
        exact: true,
        element: <TimeregsalarytypeGrid/>,
    },
    nameKey: 'salary_types',
    icon: 'pi pi-fw pi-folder',
    subModules: [{
        routeProps: {
            path: '/timeregsalarytypes/:id',
            exact: true,
            element: <TimeregsalarytypeEditForm/>,
        }
    }],
    roles: [Role.ADMIN]
};
export default TimeregsalarytypeIndex;