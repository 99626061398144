import React from 'react';
import FormItemText from "./FormItemText";

const FormItemNumber = props => {
    const {
        maxWidth = 160
    } = props

    return <FormItemText maxWidth={maxWidth} type='number' step="any" {...props}/>
}
export default FormItemNumber;