import React, {useState} from 'react';
import MonthStepper from "../../../components/Date/MonthStepper";
import XlnzDropdown from "../../../components/XlnzDropdown/XlnzDropdown";
import Checkbox from "../../../components/EditForm/Checkbox";
import Label from "../../../components/EditForm/Label";
import ExtraMenu from "../../../components/Grid/ExtraMenu";
import {useFilter} from "../../../components/Grid/filter";
import GridLabel from "../../../components/Grid/GridLabel";
import {confirmYes} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import {matchOnlyEmployees} from "../../../functions/match";
import useEmployees from "../../../hooks/useEmployees";
import useMountEffect from "../../../hooks/useMountEffect";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import {getDayInMonth, getFirstDateOfMonth, getLastDateOfMonth, getYearMonth} from "../../../utils/DateUtils";
import DaylockService from "../DaylockService";
import MonthlockService from "../MonthlockService";
import LockButton from "../Shared/LockButton";
import MonthoverviewAbsenceGrid from "./absencegrid/MonthoverviewAbsenceGrid";
import MonthoverviewAllowanceGrid from "./allowancegrid/MonthoverviewAllowanceGrid";
import CreateSalasyBaseDialog from "./CreateSalasyBaseDialog";
import MonthoverviewService from "./MonthoverviewService";
import './MonthoverviewView.scss'
import MonthoverviewTotalGrid from "./totalgrid/MonthoverviewTotalGrid";
import MonthoverviewWorkedtimeGrid from "./workedtime/MonthoverviewWorkedtimeGrid";

export const MONTHOVERVIEW_VIEW_DAY_WIDTH = 43

const MonthoverviewView = () => {
    const roles = useRoles()
    const preload = usePreload()
    const filter = useFilter('MonthoverviewView', [
        {name: 'date', defaultValue: getFirstDateOfMonth()},
        {name: 'employeeId', defaultValue: undefined},
        {name: 'onlyEmployees', defaultValue: true},
    ])

    const employeeList = preload.get(PreloadType.EMPLOYEE_LIST).filter(item => item.active)
    const employees = useEmployees()

    const [model, setModel] = useState()
    const [daylockList, setDaylockList] = useState()
    const [timeregabsenceList, setTimeregabsenceList] = useState()
    const [timeregallowanceList, setTimeregallowanceList] = useState()

    const [locked, setLocked] = useState();

    const [showCreateSalasyBaseDialog, setShowCreateSalasyBaseDialog] = useState(false);

    const disabled = locked || !roles.hasRole(Role.TIMEREG_WRITE);

    const startDate = getFirstDateOfMonth(filter.date)
    const numOfDays = getDayInMonth(getLastDateOfMonth(filter.date))
    const endDate = getLastDateOfMonth(startDate)

    const filteredEmployeeList = employees.activeUsable?.filter(e => matchOnlyEmployees(e, filter.onlyEmployees))

    const loadData = async (_startDate, employeeId) => {
        const _endDate = getLastDateOfMonth(_startDate)

        if (!employeeId) {
            setModel(undefined)
            return
        }

        showLoading()

        const filter = {
            date: _startDate,
            employeeId: employeeId
        }

        const [
            _locked,
            _model,
            _daylockList,
            // _timeregabsenceList,
            // _timeregallowanceList,
        ] = await Promise.all([
            MonthlockService.isLocked(getYearMonth(_startDate)),
            MonthoverviewService.findByFilter(filter),
            DaylockService.findByDateInterval(_startDate, _endDate),
        ])
        setLocked(_locked)
        setModel(_model)
        setDaylockList(_daylockList)
        setTimeregabsenceList(_model.absenceList)
        setTimeregallowanceList(_model.allowanceList)

        hideLoading()
    }
    useMountEffect(async () => {
        loadData(filter.date, filter.employeeId)
    })

    function isDayLocked(date) {
        return daylockList?.find(dl => dl.date === date) !== undefined
    }

    const extraMenu = [{
        label: i18n('create_salary_base') + '...',
        faicon: Icon.PDF,
        command: () => setShowCreateSalasyBaseDialog(true)
    }]

    return <div className='MonthoverviewView'>
        <div className='Grid'>
            <GridLabel icon={Icon.TIMEREG} labelI18n='month_overview'/>
            <div className="filter">
                <div>
                    <label className="label">{i18n('month')}</label>
                    <MonthStepper value={filter.date} onChange={date => {
                        filter.update('date', date)
                        loadData(date, filter.employeeId)
                    }}/>
                </div>

                <div>
                    <label className="label">{i18n('employee')}</label>
                    <XlnzDropdown
                        value={filter.employeeId}
                        options={filteredEmployeeList}
                        optionLabel='name'
                        optionValue='id'
                        showClear={true}
                        placeholder={i18n('select') + '...'}
                        onChange={e => {
                            filter.update('employeeId', e.value)
                            loadData(filter.date, e.value)
                        }}
                        filter={filteredEmployeeList.length > 10}
                        filterBy='name'
                        style={{width: '200px'}}/>
                </div>

                <div>
                    <Label valueI18n='only_employees'/>
                    <Checkbox checked={filter.onlyEmployees}
                              onChange={e => filter.update('onlyEmployees', e.target.checked)}/>
                </div>

                <div style={{marginLeft: '50px'}}>
                    <label className="label">&nbsp;</label>
                    <LockButton locked={locked}
                                disabled={(locked && !roles.hasRole(Role.TIMEREG_UNLOCK_MONTH))}
                                onClick={async e => {
                                    if (locked) {
                                        await MonthlockService.unlock(getYearMonth(filter.date))
                                        loadData(filter.date, filter.employeeId)
                                    } else {
                                        confirmYes(async () => {
                                            showLoading()
                                            await MonthlockService.lock(getYearMonth(filter.date))
                                            hideLoading()
                                            loadData(filter.date, filter.employeeId)
                                        })
                                    }
                                }}/>
                </div>

                <div style={{flexGrow: 1, padding: 0}}></div>

                <div style={{paddingRight: 0}}>
                    <label className="label">&nbsp;</label>
                    <ExtraMenu itemList={extraMenu}/>
                </div>
            </div>

            {
                model &&
                <MonthoverviewWorkedtimeGrid
                    disabled={disabled}
                    employeeId={filter.employeeId}
                    startDate={startDate}
                    isDayLocked={isDayLocked}
                    model={model}
                />
            }
            {
                timeregabsenceList &&
                <MonthoverviewAbsenceGrid
                    disabled={disabled}
                    employeeId={filter.employeeId}
                    startDate={startDate}
                    numOfDays={numOfDays}
                    isDayLocked={isDayLocked}
                    timeregabsenceList={timeregabsenceList}
                    onChange={_timeregabsenceList => setTimeregabsenceList(_timeregabsenceList)}
                />
            }
            {
                model && timeregabsenceList &&
                <MonthoverviewTotalGrid
                    startDate={startDate}
                    numOfDays={numOfDays}
                    model={model}
                    timeregabsenceList={timeregabsenceList}
                />
            }
            {
                timeregallowanceList &&
                <MonthoverviewAllowanceGrid
                    disabled={disabled}
                    employeeId={filter.employeeId}
                    startDate={startDate}
                    numOfDays={numOfDays}
                    isDayLocked={isDayLocked}
                    timeregallowanceList={timeregallowanceList}
                    onChange={_timeregallowanceList => setTimeregallowanceList(_timeregallowanceList)}
                />
            }
        </div>

        {
            showCreateSalasyBaseDialog &&
            <CreateSalasyBaseDialog employeeList={employeeList}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onHide={() => setShowCreateSalasyBaseDialog(false)}/>
        }
    </div>
}

export default MonthoverviewView;
