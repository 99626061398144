import React, {useState} from "react";


export default function NumberGridInput(props) {
    const {
        editorEvent,
        integer = false,
        min,
        max,
        align = 'right'
    } = props

    if (!editorEvent) throw new Error("editorEvent parameter missing")

    //
    // const field = editorEvent.field
    // const value = editorEvent.rowData[field]

    const field = editorEvent.field;
    const initialValue = editorEvent.rowData[field];
    const [inputValue, setInputValue] = useState(initialValue);

    const handleChange = (e) => {
        const {value} = e.target;
        setInputValue(value);

        let numericValue = Number(value
            .replace(',', '.') // Replace comma with dot for decimal conversion
            .replace(/[^-\d.]/g, '') // Remove all non-numeric characters except for the minus sign and dot
            .replace(/(?!^)-/g, '') // Remove all minus signs that are not at the start
        );
        if (isNaN(numericValue)) {
            numericValue = 0; // Default value if input is not a number
        }

        if (integer) {
            numericValue = Math.round(numericValue);
        }
        if (min !== undefined && numericValue < min) {
            numericValue = min;
        }
        if (max !== undefined && numericValue > max) {
            numericValue = max;
        }

        editorEvent.editorCallback(numericValue);
    };

    return <input
        // type='number'
        autoComplete='off'
        // step='any'

        // value={value}
        value={inputValue}
        onChange={handleChange}
        // onChange={e => {
        // let value = Number(e.target.value) || 0
        // if (integer) {
        //     value = round(value, 0)
        // }
        // if (min !== undefined && value < min) {
        //     value = min
        // }
        // if (max !== undefined && value > max) {
        //     value = max
        // }

        // editorEvent.editorCallback(value)
        // }}
        autoFocus
        onFocus={e => e.target.select()}
        style={{
            width: '100%',
            height: '100%',
            paddingRight: '5px',
            textAlign: align,
            fontSize: '12px'
        }}
    />
}