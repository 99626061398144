import Role from "../../enums/Role";
import CostcenterEditForm from "./CostcenterEditForm";
import CostcenterGrid from "./CostcenterGrid";

const CostcentersIndex = {
    routeProps: {
        path: '/costcenters',
        exact: true,
        element: <CostcenterGrid/>,
    },
    nameKey: 'costcenters',
    icon: 'pi pi-fw pi-folder',
    subModules: [{
        routeProps: {
            path: '/costcenters/:id',
            exact: true,
            element: <CostcenterEditForm/>,
        }
    }],
    roles: [Role.INVOICE_READ]
};
export default CostcentersIndex;