import NetUtils from "../../../utils/NetUtils";

const DayplanningService = {
    findByFilter: filter => NetUtils.doGet('/api/dayplannings', filter),
    findById: id => NetUtils.doGet('/api/dayplannings/' + id),

    listTimeregEmployeePerWeek: date => NetUtils.doGet('/api/dayplannings/timereg-employee-per-week/' + date),

    findInvoicerowtimeregsByDayplanning: dayplanningId => NetUtils.doGet('/api/dayplannings/' + dayplanningId + '/invoicerowtimeregs'),
    findInvoicerowtimeregarticlesByDayplanning: dayplanningId => NetUtils.doGet('/api/dayplannings/' + dayplanningId + '/invoicerowtimeregarticles'),

    findByProject: projectId => NetUtils.doGet('/api/dayplannings/project/' + projectId),
    update: model => NetUtils.doPost('/api/dayplannings', model),
    delete: id => NetUtils.doDelete('/api/dayplannings/' + id),

    moveToOtherProject: (dayplanningId, projectId) => NetUtils.doGet('/api/dayplannings/' + dayplanningId + '/move-to-other-project/' + projectId),

    downloadWorkorderPdf: async dayplanningId => NetUtils.downloadFile('/api/dayplannings/' + dayplanningId + '/workorder-pdf'),
    copy: (dayplanningId, copyDayplanningRowList) => NetUtils.doPost('/api/dayplannings/' + dayplanningId + '/copy', copyDayplanningRowList),
};
export default DayplanningService;