import React from 'react';
import {i18n} from "../../I18n/I18n";
import XlnzDropdown from "../XlnzDropdown/XlnzDropdown";

const ActiveFilter = props => {
    const statusList = [{
        id: true,
        label: i18n('actives')
    }, {
        id: false,
        label: i18n('inactives')
    }];

    return (
        <XlnzDropdown
            value={props.value}
            options={statusList}
            showClear={true}
            placeholder={i18n('all')}
            onChange={props.onChange}
            required={true}
            style={{minWidth: '120px'}}
        />
    );

}
export default ActiveFilter