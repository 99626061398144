import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import ProjectEditForm from "./edit/ProjectEditForm";
import ProjectGrid from "./ProjectGrid";
import ProjectInvoiceOverviewGrid from "./ProjectInvoiceOverviewGrid";
import ProjectResultOverviewGrid from "./ProjectResultOverviewGrid";

const InvoicesIndex = {
    routeProps: {
        path: '/projects',
        exact: true,
        element: <ProjectGrid/>,
        onRowSelectPath: '/projects',
        items: [
            {
                nameKey: 'all_projects',
                path: '/projects/list',
                exact: true,
                element: <ProjectGrid/>,
                onRowSelectPath: '/projects'
            },
            {
                nameKey: 'invoice_overview',
                path: '/projects/invoice-overview',
                exact: true,
                element: <ProjectInvoiceOverviewGrid/>,
                onRowSelectPath: '/projects'
            },
            {
                nameKey: 'result_overview',
                path: '/projects/result-overview',
                exact: true,
                element: <ProjectResultOverviewGrid/>,
                onRowSelectPath: '/projects'
            }
        ]
    },
    nameKey: 'projects',
    icon: Icon.PROJECT,
    subModules: [{
        routeProps: {
            path: '/projects/:id',
            exact: true,
            element: <ProjectEditForm/>,
        }
    }],
    roles: [Role.PROJECT_READ]
};
export default InvoicesIndex;