import Role from "../../enums/Role";
import SupplierEditForm from "./SupplierEditForm";
import SupplierGrid from "./SupplierGrid";

const SupplierIndex = {
    routeProps: {
        path: '/suppliers',
        exact: true,
        element: <SupplierGrid/>,
    },
    nameKey: 'suppliers',
    icon: 'pi pi-fw pi-folder',
    subModules: [{
        routeProps: {
            path: '/suppliers/:id',
            exact: true,
            element: <SupplierEditForm/>,
        }
    }],
    roles: [Role.SUPPLIER_READ]
};
export default SupplierIndex;