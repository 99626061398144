import React from "react";
import Button from "../../../../components/Buttons/Button";
import {hideLoading, showLoading} from "../../../../components/Overlay/Loading";
import Icon from "../../../../enums/Icon";
import useDialog from "../../../../hooks/useDialog";
import PaymentplanDialog from "./PaymentplanDialog";

export const PaymentplanButton = props => {
    const {
        tender,
        handleSubmit,
        saveProject,
        getValues
    } = props

    const dialog = useDialog()

    const showDialog = data => {
        showLoading();
        saveProject(data).then(_project => {
            if (_project) {
                dialog.show(<PaymentplanDialog
                    tender={tender}
                    project={_project}
                    getValues={getValues}
                    saveProject={saveProject}
                />)

                hideLoading();
            } else {
                hideLoading();
            }
        })
    }

    return <div>
        <Button labelI18n='payment_plan'
                faicon={Icon.PAYMENT_PLAN}
                onClick={handleSubmit(showDialog)}
        />
        {dialog.render()}
    </div>
}
