import React, {useEffect, useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Icon from "../../enums/Icon";
import {GridTemplates} from "../../GridTemplates";
import AgreementService from "./AgreementService";

const AgreementGrid = () => {
    const filter = useFilter('AgreementGrid', [
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);

    useEffect(() => {
        showLoading()
        const params = {
            status: filter.status,
            search: filter.search
        }
        AgreementService.findAll(params).then(_list => {
            setList(_list);
            hideLoading()
        });
    }, [filter.status, filter.search])

    const columns = [
        {field: 'customerName', headerI18n: 'customer', minWidth: 200},
        {field: 'customercontactName', headerI18n: 'contact_person', minWidth: 100},
        {field: 'startDate', headerI18n: 'start_date', width: 100},
        {field: 'endDate', headerI18n: 'end_date', width: 100},
        {field: 'note', headerI18n: 'note', body: GridTemplates.stringLimit100, minWidth: 100},
    ];

    const leftFilters = [
        <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div>,
    ]

    return <Grid
        forceDesktop={true}
        id='AgreementGrid'
        labelI18n='all_agreements'
        icon={Icon.AGREEMENT}
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={list}
    />
}

export default AgreementGrid;
