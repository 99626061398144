import React from 'react';
import {i18n} from "../../I18n/I18n";
import CheckboxInput from "./CheckboxInput";
import Label from "./Label";

const FormItemCheckbox = props => {
    const {
        name,
        label,
        labelI18n,
    } = props

    const _props = {...props}
    delete _props.label


    return <div className="formItem">
        <Label htmlFor={name}>{labelI18n ? i18n(labelI18n) : label}</Label>
        <div style={{height: '3px'}}></div>
        <CheckboxInput {..._props} />
    </div>
}
export default FormItemCheckbox;