import ArrayUtil from "../utils/ArrayUtil";

export const getCustomerEmailByIds = (customerList, customerId, customercontactId) => {
    let result = ''
    const c = ArrayUtil.findById(customerList, customerId)
    if (c) {
        if (customercontactId > 0) {
            const cc = ArrayUtil.findById(c.contactList, customercontactId)
            result = cc.email
        }
        if (result.length === 0) {
            result = c.email
        }
    }
    return result
}