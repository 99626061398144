import React from "react";
import {i18n} from "../../I18n/I18n";
import ValidateUtils from "../../utils/ValidateUtils";
import ErrorText from "./ErrorText";

export default function TextInput(props) {
    const {
        register,
        formState: {errors},
        disabled,
        forceDisabled,
        name,
        // label,
        // labelI18n,
        placeholder,
        placeholderI18n,
        title,
        onKeyUp,
        value,

        type,
        autoComplete,
        required,
        maxLength,
        number,
        integer,
        min,
        max,
        step,
        email,

        maxWidth,
        width,

        onBlur,
        hidden,
        capitalize = true,
        validation = {},
        style
    } = props

    if (!name) throw new Error('Property "name" is missing.')

    if (hidden) {
        return
    }

    if (required) {
        validation.required = ValidateUtils.required()
    }
    if (maxLength) {
        validation.maxLength = ValidateUtils.maxLength(maxLength)
    }
    if (number) {
        validation.pattern = ValidateUtils.numberPattern()
    }
    if (integer) {
        validation.pattern = ValidateUtils.integerPattern()
    }
    if (min !== undefined) {
        validation.min = ValidateUtils.min(min)
    }
    if (max !== undefined) {
        validation.max = ValidateUtils.max(max)
    }
    if (email) {
        validation.validate = ValidateUtils.email
    }

    const _style = style || {}
    if (maxWidth) {
        _style.maxWidth = maxWidth + 'px'
    }
    if (width) {
        _style.width = width + 'px'
    } else {
        _style.width = '100%'
    }

    function keyUpHandler(e) {
        if (capitalize && !type) {
            const str = e.target.value
            e.target.value = str.charAt(0).toUpperCase() + str.slice(1)
        }
        if (onKeyUp) onKeyUp(e)
    }

    return <>
        <input
            id={'input-' + name}
            type={type ? type : undefined}
            title={title}
            autoComplete={autoComplete ? autoComplete : 'nope'}
            onFocus={(event) => {
                event.target.setAttribute('autocomplete', 'off');
            }}

            {...register(name, validation)}
            disabled={disabled || forceDisabled}
            style={_style}
            placeholder={placeholderI18n ? i18n(placeholderI18n) : placeholder}
            onKeyUp={keyUpHandler}
            value={value}
            step={step}
            maxLength={maxLength}
            onBlur={onBlur}
        />
        <ErrorText errors={errors} name={name}/>
    </>
}