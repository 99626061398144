import React from "react";
import usePreload, {PreloadType} from "../../hooks/usePreload";
import {deepCopy} from "../../utils/ObjectUtils";
import FormItemDropdown from "./FormItemDropdown";

const FormItemSupplier = props => {
    const preload = usePreload()
    const {
        getValues,
        name
    } = props

    const value = getValues(name)

    const list = deepCopy(preload.get(PreloadType.SUPPLIER_LIST)
        .filter(item => item.active || item.id === value))

    list.forEach(item => {
        item.filterBy = [item.regNo, item.number, item.name, item.bankgiro, item.plusgiro].join(';')
    })

    function getSubInfo(item) {
        let result = ''
        if (item.bankgiro) {
            result += 'BG: ' + item.bankgiro
        }
        if (item.plusgiro) {
            if (result.length > 0) result += ', '
            result += 'PG: ' + item.plusgiro
        }
        return result
    }


    return <FormItemDropdown options={list}
                             optionLabel='name'
                             filterBy='filterBy'
                             itemTemplate={item => <div>
                                 <div>{item.name}</div>
                                 <div style={{opacity: '0.7'}}>{getSubInfo(item)}</div>
                             </div>}
                             {...props} />
}
export default FormItemSupplier