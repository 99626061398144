import {useContext} from "react";
import {useWatch} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import AppContext from "../../../AppContext";
import {EmailButton} from "../../../components/Buttons/Button";
import SendByEmailDialog from "../../../components/Dialog/SendByEmailDialog";
import {errorAlert} from "../../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import InvoiceSendMethod from "../../../enums/InvoiceSendMethod";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import {i18n} from "../../../I18n/I18n";
import {findById} from "../../../utils/ArrayUtil";
import RouterUtils from "../../../utils/RouterUtils";
import InvoiceService from "../InvoiceService";

export const SendInvoiceButton = props => {
    const context = useContext(AppContext)

    const {
        dialog,
        handleSubmit,
        save,
        reset,
        form
    } = props

    const preload = usePreload()

    const {control} = form
    const watchCustomer = Number(useWatch({control, name: 'customer'}))

    const customer = findById(preload.get(PreloadType.CUSTOMER_LIST), watchCustomer)
    const invoiceSendMethod = customer ? InvoiceSendMethod.findById(customer.invoiceSendMethod) : undefined

    const style = {}
    if (invoiceSendMethod?.id !== InvoiceSendMethod.EMAIL.id) {
        style.color = '#F00'
    }
    const info = <div style={style}>
        Förvald faktureringsmetod på kundkortet: <b>{invoiceSendMethod?.name}</b>
    </div>

    const navigate = useNavigate();

    // const [showSendInvoiceByEmail, setShowSendInvoiceByEmail] = useState(false);

    function validate(data) {
        if (context.currentCompany.usingFortnox) {
            if (!(data.number > 0)) {
                errorAlert(i18n('error_invoice_number_missing'))
                return false
            }
        }
        return true
    }

    const onSendByEmail = data => {
        showLoading();

        if (validate(data)) {
            save(data).then(_model => {
                if (_model) {
                    reset(_model);
                    hideLoading();
                    // setShowSendInvoiceByEmail(true);
                    dialog.show(<SendByEmailDialog
                        info={info}
                        email={customer.invoiceEmail}
                        onSend={toEmail => {
                            showLoading();
                            InvoiceService.sendByEmail(_model.id, toEmail).then(_model => {
                                if (_model) {
                                    // setShowSendInvoiceByEmail(false)
                                    RouterUtils.goBack(navigate)
                                }
                                hideLoading();
                            });
                        }}/>)
                } else {
                    hideLoading();
                }
            })
        } else {
            hideLoading()
        }
    }

    return <EmailButton onClick={handleSubmit(onSendByEmail)}/>
}
