import Role from "../../enums/Role";
import UsergroupEditForm from "./UsergroupEditForm";
import UsergroupGrid from "./UsergroupGrid";

const UsergroupIndex = {
    routeProps: {
        path: '/usergroups',
        exact: true,
        element: <UsergroupGrid/>,
    },
    nameKey: 'user_groups',
    icon: 'pi pi-fw pi-user',
    subModules: [{
        routeProps: {
            path: '/usergroups/:id',
            exact: true,
            element: <UsergroupEditForm/>
        }
    }],
    roles: [Role.USERGROUP_READ]
};
export default UsergroupIndex