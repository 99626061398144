let apiBuildTime
let newVersionAvailable = false

const useApiVersion = () => {

    return {
        setApiBuildTime: value => {
            if (value) {
                if (!apiBuildTime) {
                    apiBuildTime = value
                    // console.log("Setting API version time", value)
                } else if (apiBuildTime !== value && !newVersionAvailable) {
                    newVersionAvailable = true;
                    // console.log("Setting new API version available", newVersionAvailable)
                }
            }
        },
        isNewVersionAvailable: () => newVersionAvailable
    }
}
export default useApiVersion

