import React from 'react';
import {i18n} from "../../I18n/I18n";
import Label from "./Label";

export default function FormItemConstant(props) {
    const {
        label,
        labelI18n,
        value,
        hidden
    } = props

    if (hidden) return undefined
    return <div className="formItem">
        <Label>{labelI18n ? i18n(labelI18n) : label}</Label>
        <div style={styles}>
            {value}
        </div>
    </div>
}

const styles = {
    padding: '7px',
    border: '1px solid #cccccc',
    borderRadius: '3px',
    fontSize: '12px',

    cursor: 'default',
    backgroundColor: 'light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3))',
    color: 'light-dark(rgb(84, 84, 84), rgb(170, 170, 170))',
}