export function deepFreezeObject(o) {
    Object.freeze(o);
    if (o === undefined) {
        return o;
    }

    Object.getOwnPropertyNames(o).forEach(function (prop) {
        if (o[prop] !== null
            && (typeof o[prop] === "object" || typeof o[prop] === "function")
            && !Object.isFrozen(o[prop])) {
            deepFreezeObject(o[prop]);
        }
    });

    return o;
};

// export const deepCopy = obj => {
//     return obj ? JSON.parse(JSON.stringify(obj)) : undefined
// }

export function deepCopy(value) {
    // If the value is a primitive type, simply return it.
    if (typeof value !== 'object' || value === null) {
        return value;
    }

    // If the value is an array, create a deep copy of it.
    if (Array.isArray(value)) {
        const newArray = [];
        for (const element of value) {
            newArray.push(deepCopy(element));
        }
        return newArray;
    }

    // Otherwise, the value is an object. Create a new empty object to store the deep copy.
    const newObj = {};
    for (const prop in value) {
        newObj[prop] = deepCopy(value[prop]);
    }
    return newObj;
}

export const getValueByField = function (obj, field, defaultValue = undefined) {
    field = field.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    field = field.replace(/^\./, '');           // strip a leading dot
    var a = field.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in obj) {
            obj = obj[k];
        } else {
            return defaultValue
        }
    }
    return obj;
}
