import React, {useContext} from 'react';
import AppContext from "../../AppContext";
import {getYear, todayYear} from "../../utils/DateUtils";
import XlnzDropdown from "../XlnzDropdown/XlnzDropdown";

const YearFilter = props => {
    const context = useContext(AppContext);
    const startYear = Number(props?.startYear) || getYear(context.currentCompany.ctime)

    const yearList = Array.from(Array(todayYear() - startYear + 2).keys())
        .map(value => ({
            id: value + startYear,
            label: value + startYear
        }))

    return (
        <XlnzDropdown
            value={props.value}
            options={yearList.reverse()}
            onChange={e => props.onChange(e)}
            required={true}
        />
    );
}

export default YearFilter;