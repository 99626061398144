import Role from "../../enums/Role";
import CompanyEditForm from "./CompanyEditForm";
import CompanyGrid from "./CompanyGrid";

const CompaniesIndex = {
    routeProps: {
        path: '/companies',
        exact: true,
        element: <CompanyGrid/>,
    },
    nameKey: 'companies',
    icon: 'pi pi-fw pi-folder',
    subModules: [{
        routeProps: {
            path: '/companies/:id',
            exact: true,
            element: <CompanyEditForm/>,
        }
    }],
    roles: [Role.SUPERADMIN]
};
export default CompaniesIndex;