import {useContext} from "react";
import AppContext from "../../AppContext";
import {findByField} from "../../utils/ArrayUtil";

export default function useCustomercompanypricelist() {
    const context = useContext(AppContext)

    function getCustomercompanypricelist(_companypricelistList) {
        return findByField(_companypricelistList, 'company', context.currentCompany.id)
    }

    return {
        getCustomercompanypricelist: getCustomercompanypricelist,
        getPricelist: _companypricelistList => getCustomercompanypricelist(_companypricelistList)?.pricelist
    }
}