export default class VatType {
    static get SEVAT() {
        return Object.freeze({id: 'SEVAT', name: 'SE'});
    }

    static get SEREVERSEDVAT() {
        return Object.freeze({id: 'SEREVERSEDVAT', name: 'SE omvänd skattskyldighet'});
    }

    static get EUREVERSEDVAT() {
        return Object.freeze({id: 'EUREVERSEDVAT', name: 'EU omvänd skattskyldighet'});
    }

    static values() {
        return [
            {id: this.SEVAT.id, name: this.SEVAT.name},
            {id: this.SEREVERSEDVAT.id, name: this.SEREVERSEDVAT.name},
            {id: this.EUREVERSEDVAT.id, name: this.EUREVERSEDVAT.name}
        ];
    }
}