import {useMemo, useState} from "react";
import {filterActive, findById} from "../utils/ArrayUtil";
import {deepCopy} from "../utils/ObjectUtils";
import usePreload from "./usePreload";

export default function useCustomers() {
    const preload = usePreload()
    const [forceUpdateCounter, setForceUpdateCounter] = useState(0)

    const list = useMemo(() => deepCopy(preload.getCustomerList()),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [preload, forceUpdateCounter])

    const allActive = useMemo(() => filterActive(list))


    function getContactName(customerId, customercontactId) {
        const customer = findById(list, customerId)
        return findById(customer.contactList, customercontactId)?.name
    }

    const result = useMemo(() => ({
        all: list,
        allActive: allActive,

        findById: id => findById(list, id),

        getContactName: getContactName,

        forceUpdate: () => setForceUpdateCounter(forceUpdateCounter + 1)
    }), [list, allActive, forceUpdateCounter])

    return result
}