import {i18n} from "../../I18n/I18n";
import {findByField} from "../../utils/ArrayUtil";

const COMING = 'COMING';
const ONGOING = 'ONGOING';
const COMPLETED = 'COMPLETED';
const CANCELLED = 'CANCELLED';

export default class ProjectStatus {

    static get COMING() {
        return Object.freeze({
            id: COMING,
            label: i18n('upcoming'),
            labelMultiple: i18n('upcoming')
        })
    }

    static get ONGOING() {
        return Object.freeze({
            id: ONGOING,
            label: i18n('ongoing'),
            labelMultiple: i18n('ongoing')
        })
    }

    static get COMPLETED() {
        return Object.freeze({
            id: COMPLETED,
            label: i18n('completed'),
            labelMultiple: i18n('completed_plur')
        })
    }

    static get CANCELLED() {
        return Object.freeze({
            id: CANCELLED,
            label: i18n('cancelled'),
            labelMultiple: i18n('cancelled_plur')
        })
    }

    static values() {
        return [
            this.COMING,
            this.ONGOING,
            this.COMPLETED,
            this.CANCELLED,
        ];
    }

    static findById(id) {
        return findByField(this.values(), 'id', id)
    }
}