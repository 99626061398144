import I18n from '@I18n';
import React, {useState} from 'react';
import {Controller, useForm, useWatch} from "react-hook-form";
import {matchPath, useLocation, useNavigate} from "react-router-dom";
import Button, {CancelButton, SaveButton} from "../../../../../components/Buttons/Button";
import {errorAlert} from "../../../../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../../../../components/Overlay/Loading";
import Spinner from "../../../../../components/Spinner/Spinner";
import Role from "../../../../../enums/Role";
import useMountEffect from "../../../../../hooks/useMountEffect";
import useRoles from "../../../../../hooks/useRoles";
import {i18n} from "../../../../../I18n/I18n";
import RouterUtils from "../../../../../utils/RouterUtils";
import DaylockService from "../../../DaylockService";
import TimeregabsenceService from "../../../TimeregabsenceService";
import TimeregabsenceGrid from "./TimeregabsenceGrid";

const TimeregabsenceEditForm = props => {
    const roles = useRoles()
    const [model, setModel] = useState();
    const [locked, setLocked] = useState();

    const location = useLocation()
    const navigate = useNavigate()

    const disabled = !roles.hasRole(Role.TIMEREG_WRITE) || locked;

    // const getId = () => matchPath(location.pathname, {
    //     path: '/timereg/:id/:date', exact: true, strict: true
    // }).params.id

    const date = matchPath({
        path: '/timeregabsence/:date', exact: true, strict: true
    }, location.pathname).params.date

    const defaultValues = {
        timeregabsenceList: []
    }

    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, getValues, setValue, control} = form

    const watchTimeregabsenceList = useWatch({control, name: 'timeregabsenceList'})

    const validate = (data) => {
        for (let index in data.timeregabsenceList) {
            const tra = data.timeregabsenceList[index]
            if (!tra.employee || tra.hours <= 0 || !tra.type) {
                errorAlert(I18n.t('error_values_missing'))
                return false
            }
        }
        return true;
    }

    const onSubmit = () => {
        const data = getValues();
        if (validate(data)) {
            Object.assign(model, data);

            const _model = {
                date: date,
                timeregabsenceList: model.timeregabsenceList
            }

            return TimeregabsenceService.updateList(_model).then(savedModel => RouterUtils.goBack(navigate))
        }
    };

    useMountEffect(async () => {
        showLoading()

        const [
            _timeregabsenceList,
            _locked
        ] = await Promise.all([
            TimeregabsenceService.findByDate(date),
            DaylockService.isLocked(date)
        ])
        setLocked(_locked)

        const _model = {
            timeregabsenceList: _timeregabsenceList
        }

        setModel(_model);
        reset(_model);
        hideLoading()
    }, [props, reset])

    function isAttestNeeded() {
        if (watchTimeregabsenceList.find(tr => !tr.notarized) !== undefined) {
            return true
        }
    }

    async function attest() {
        showLoading()
        const _timeregabsenceList = [...watchTimeregabsenceList]
        _timeregabsenceList.forEach(item => item.notarized = true)
        setValue('timeregabsenceList', _timeregabsenceList)
        await handleSubmit(onSubmit)()
        hideLoading()
    }

    if (!model) return <Spinner/>
    return (
        <div className="EditForm">
            <div className="headline">
                <h1>
                    {I18n.t('absence')} {date}
                </h1>
            </div>
            <form>
                <div className="button-row">
                    <div className='left-buttons'>
                        {!disabled && <SaveButton onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>}
                        <CancelButton onClick={e => RouterUtils.goBack(navigate)}/>
                        {
                            isAttestNeeded() &&
                            <div style={{marginLeft: '40px'}}>
                                <Button label={i18n('notarize')} onClick={e => attest()}/>
                            </div>
                        }
                    </div>
                </div>

                <div className="formItem">
                    <Controller name="timeregabsenceList"
                                control={control}
                                render={({field: {ref, value, onChange}}) =>
                                    <TimeregabsenceGrid
                                        inputRef={ref} value={value} onChange={onChange}
                                        disabled={disabled}
                                        date={date}
                                    />
                                }/>
                </div>
            </form>
        </div>
    );
}

export default TimeregabsenceEditForm;
