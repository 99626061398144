import React from 'react';
import XlnzChart from "../../components/XlnzChart/XlnzChart";
import I18n from "../../I18n/I18n";
import {NumberUtils} from "../../utils/NumberUtils";
import {deepCopy} from "../../utils/ObjectUtils";
import TenderStatus from "../Tenders/TenderStatus";
import './Dashboard.scss'

const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;

const PREDEF_COLORS = ['#699b2c', '#000066', '#4d9fe2', '#bb0000', '#2C9B96', '#CA3935', '#82DE21', '#21DEDB', '#8231CE']

const getColor = index => {
    return index < PREDEF_COLORS.length ? PREDEF_COLORS[index] : randomRGB()
}
const TenderChart = props => {
    const turnoverChart = deepCopy(props.data.tenderChart);

    for (let i = 0; i < turnoverChart.datasets.length; i++) {
        const ds = turnoverChart.datasets[i]
        ds.label = TenderStatus.getLabelMultiple(ds.label)
        ds.backgroundColor = getColor(i);
    }

    let options = {
        plugins: {
            legend: {
                display: true
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const index = tooltipItem.dataIndex

                        const val = turnoverChart.datasets.reduce((sum, ds) => {
                            //console.log("DATAAA", ds.data)
                            return sum + ds.data[index]
                        }, 0)

                        //console.log('tooltipItem', tooltipItem, val)

                        return [
                            tooltipItem.dataset.label + ': ' + NumberUtils.formatCurrency(tooltipItem.raw),
                            I18n.t('total') + ': ' + NumberUtils.formatCurrency(val)
                        ]
                    }
                }
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            }
        }
    }
    return <div style={{width: '800px', height: '400px'}}>
        <XlnzChart type="bar" data={turnoverChart} options={options}/>
    </div>
}

export default TenderChart;
