import React, {useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import ActiveFilter from "../../components/Grid/ActiveFilter";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {infoAlert} from "../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Companyprop from "../../enums/Companyprop";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import {matchActive, matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useCompanyProp from "../../hooks/useCompanyProp";
import useMountEffect from "../../hooks/useMountEffect";
import usePreload, {PreloadType} from "../../hooks/usePreload";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import NetUtils from "../../utils/NetUtils";
import CostcenterService from "./CostcenterService";

const CostcenterGrid = () => {
    const roles = useRoles()
    const preload = usePreload()
    const pyramid = useCompanyProp(Companyprop.PYRAMID)

    const filter = useFilter('CostcenterGrid', [
        {name: 'active', defaultValue: true},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);
    const filteredList = list.filter(c => matchActive(filter, c) && matchSearch(filter, c))

    async function load() {
        showLoading()
        setList(await CostcenterService.findAll())
        hideLoading()
    }

    useMountEffect(load, [])

    const leftFilters = [
        roles.hasRole(Role.ADMIN) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <label className="label">{i18n('status')}</label>
            <ActiveFilter value={filter.active} onChange={e => filter.update('active', e.value)}/>
        </div>
    ]

    const menuItems = []
    if (pyramid) {
        menuItems.push({
            label: i18n('fetch_from_pyramid'),
            faicon: Icon.PLUGIN,
            command: async item => {
                showLoading()
                const _resp = await NetUtils.doGet('/api/pyramid/sync-costcenters')
                await preload.load(PreloadType.COSTCENTER_LIST)
                await load()
                infoAlert(_resp.value)
                hideLoading()
            }
        })
    }

    const columns = [
        {field: 'number', headerI18n: 'number', width: 100, mobile: true},
        {field: 'name', headerI18n: 'name'},
        {field: 'active', headerI18n: 'active', body: GridTemplates.boolean, style: {maxWidth: '100px'}},
    ];

    return <Grid
        xlnzGrid={true}
        labelI18n='costcenters'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        menuItems={menuItems}
    />
}

export default CostcenterGrid;
