const isProd = () => process.env.NODE_ENV === "production"

const Env = {
    isProd: () => isProd(),
    isDev: () => !isProd(),
    isMobile: () => window.innerWidth > 0 && window.innerWidth < 1000,
    isTouchDevice: () => {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    }
}
export default Env;


