import React, {useEffect, useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import MonthDropdown from "../../components/Date/MonthDropdown";
import XlnzDropdown from "../../components/XlnzDropdown/XlnzDropdown";
import YearFilter from "../../components/filters/YearFilter";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import PdfColumn from "../../components/Grid/PdfColumn";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import getEmailStatusName from "../../functions/getEmailStatusName";
import {GridTemplates} from "../../GridTemplates";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import {formatTime, todayYear} from "../../utils/DateUtils";
import TenderSentStatus from "./TenderSentStatus";
import TenderService from "./TenderService";

const TenderSentGrid = () => {
    const roles = useRoles()

    const filter = useFilter('TenderSentGrid', [
        {name: 'status', defaultValue: TenderSentStatus.UNANSWERED},
        {name: 'year', defaultValue: todayYear()},
        {name: 'month', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);

    useEffect(() => {
        showLoading()
        const params = {
            status: filter.status,
            year: filter.year,
            month: filter.month,
            search: filter.search
        }
        TenderService.findAllSent(params).then(_list => {

            _list.forEach(item => {
                if (item.answerTime) {
                    const answerStr = i18n(item.accepted ? 'accepted' : 'denied')
                    item.answer = answerStr + ' ' + formatTime(item.answerTime)
                }
            })

            setList(_list);
            hideLoading()
        });
    }, [filter.status, filter.year, filter.month, filter.search])

    const columns = [
        PdfColumn(() => true,
            rowData => rowData.number + ' - ' + rowData.name,
            async rowData => (await TenderService.downloadTendersendlogPdf(rowData.id, rowData.sentlogId)).url
        ),
        {field: 'name', headerI18n: 'name'},
        {field: 'number', headerI18n: 'number', width: 100, mobile: true},
        {field: 'ourReferenceName', headerI18n: 'our_reference', mobile: true},
        {field: 'toEmail', headerI18n: 'email', mobile: true},
        {field: 'sentTime', headerI18n: 'sent', body: GridTemplates.time, width: 140, mobile: true},
        {
            field: 'status',
            header: i18n('email_status'),
            body: rowData => getEmailStatusName(rowData.status),
            width: 100,
            mobile: true
        },
        {field: 'visitTime', headerI18n: 'last_opened', body: GridTemplates.time, width: 140},
        {field: 'answer', headerI18n: 'answer'},
    ];

    const leftFilters = [
        roles.hasRole(Role.TENDER_WRITE) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <label className="label">{i18n('status')}</label>
            <XlnzDropdown
                value={filter.status}
                options={TenderSentStatus.LIST}
                optionLabel='labelMultiple'
                optionValue='value'
                showClear={true}
                placeholder={i18n('select') + '...'}
                onChange={e => filter.update('status', e.value)}
                style={{width: '160px'}}/>
        </div>,
        <div>
            <label className="label">{i18n('year')}</label>
            <YearFilter value={filter.year} onChange={e => filter.update('year', e.value)}/>
        </div>,
        <div>
            <label className="label">{i18n('month')}</label>
            <MonthDropdown value={filter.month} onChange={e => {
                filter.update('month', e.value)
            }}/>
        </div>
    ]

    return <Grid
        xlnzGrid={true}
        id='TenderSentGrid'
        labelI18n='all_sent_tenders'
        icon={Icon.TENDER}
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={list}
    />
}

export default TenderSentGrid;
