import React from 'react';
import DatePicker from "../Date/DatePicker";

const DateEditor = editorEvent => {
    if (!editorEvent) throw new Error("editorEvent parameter missing")

    const {
        field,
        rowData
    } = editorEvent
    const value = rowData[field]

    return <DatePicker
        value={value}
        onChange={editorEvent.editorCallback}
        showIcon={false}
        openOnFocus={true}
    />
}
export default DateEditor;