import I18n from '@I18n';
import React from 'react';
import {NBSP} from "../../../../App";
import Grid from "../../../../components/Grid/Grid";
import {addDays} from "../../../../utils/DateUtils";
import {NumberUtils} from "../../../../utils/NumberUtils";
import {MONTHOVERVIEW_VIEW_DAY_WIDTH} from "../MonthoverviewView";
import getDayStyle from "../workedtime/getDayStyle";
import './MonthoverviewTotalGrid.scss'

const MonthoverviewTotalGrid = props => {
    const startDate = props.startDate
    const numOfDays = props.numOfDays
    const model = props.model
    const timeregabsenceList = props.timeregabsenceList

    const getTotalHoursByDate = (i, date) => {
        let q = 0

        model.workedHours.forEach(wh => {
            if (!wh.breakdown) {
                q += wh.dayList[i]
            }
        })

        q += timeregabsenceList
            .filter(ta => ta.date === date)
            .map(ta => ta.hours)
            .reduce((sum, hours) => sum + hours, 0)

        return q
    }

    const totalRow = {name: I18n.t('total')}
    let totalHours = 0
    for (let i = 0; i < numOfDays; i++) {
        const date = addDays(startDate, i)
        totalRow['day_' + i] = getTotalHoursByDate(i, date)
        totalHours += totalRow['day_' + i]
    }
    totalRow.totalHours = totalHours

    const emptyRow = {name: NBSP}


    const columns = [{
        field: 'name',
        style: {fontWeight: 'bold'}
    }]

    for (let i = 0; i < numOfDays; i++) {
        const date = addDays(startDate, i)

        columns.push({
            field: 'day_' + i,
            body: item => {
                const result = NumberUtils.formatTwoDecimalsIfNeeded(item['day_' + i])
                return result !== 'NaN' ? result : ''
            },
            width: MONTHOVERVIEW_VIEW_DAY_WIDTH,
            align: 'center',
            className: 'dayColumn',
            style: {...getDayStyle(date), fontWeight: 'bold'},
        })
    }

    columns.push({
        field: 'totalHours',
        body: item => {
            const result = NumberUtils.formatTwoDecimalsIfNeeded(item.totalHours)
            return result !== 'NaN' ? result : ''
        },
        width: MONTHOVERVIEW_VIEW_DAY_WIDTH,
        align: 'center',
        className: 'dayColumn',
        style: {fontWeight: 'bold'},
    })

    return <div className='MonthoverviewTotalGrid'>
        <Grid xlnzGrid={true}
              forceDesktop={true}
              scrollable={false}
              columns={columns}
              value={[totalRow, emptyRow]}
              hideHeader={true}
              hideFooter={true}
              sortable={false}
              onRowSelect={() => {
              }}
        >
        </Grid>
    </div>
}

export default MonthoverviewTotalGrid;
