import {i18n} from "../I18n/I18n";

const getEmailStatusName = status => {
    if (!status) return ''

    let result = status
    switch (status) {
        case 'Initiate':
            result = i18n('initiated')
            break;
        case 'Send':
            result = i18n('sending') + '...'
            break;
        case 'Delivery':
            result = i18n('delivered')
            break;
        default:
            break;
    }
    return result
}
export default getEmailStatusName