const filterTimeregallowancetypes = (timeregallowancetypeList, timeregallowanceList, disabled) => {

    const hasTimeregallowances = type => timeregallowanceList.find(ta => ta.type === type.id) !== undefined

    if (disabled) {
        return timeregallowancetypeList.filter(type => hasTimeregallowances(type))
    } else {
        return timeregallowancetypeList.filter(type => type.active || hasTimeregallowances(type))
    }
}
export default filterTimeregallowancetypes