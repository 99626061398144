import React, {useContext} from "react";
import AppContext from "../../AppContext";
import CheckboxInput from "../../components/EditForm/CheckboxInput";
import Label from "../../components/EditForm/Label";
import Tooltip from "../../components/Overlay/Tooltip";
import Role from "../../enums/Role";
import {i18n} from "../../I18n/I18n";
import {findByField, sortByField} from "../../utils/ArrayUtil";
import {deepCopy} from "../../utils/ObjectUtils";
import {modules as _modules, registerModules as _registerModules, settingModules as _settingModules} from "../modules";


export default function SelectRestrictPaths(props) {
    const {
        form
    } = props

    function updateLabel(item) {
        item.label = item.nameKey ? i18n(item.nameKey) : item.name
    }

    const modules = deepCopy(_modules)
    modules.forEach(updateLabel)

    const registerModules = deepCopy(_registerModules)
    registerModules.forEach(updateLabel)
    sortByField(registerModules, 'label')

    const settingModules = deepCopy(_settingModules)
    settingModules.forEach(updateLabel)
    sortByField(settingModules, 'label')

    return <div className='formItem'>
        <Label value='Begränsa flikar'/>
        <div key='modules'>
            {modules.map(item => <Module key={item.routeProps.path} item={item} form={form}/>)}
        </div>
        <div key='registerModules' style={{paddingTop: '20px'}}>
            <Label valueI18n='register'/>
            {registerModules.map(item => <Module key={item.routeProps.path} item={item} form={form}/>)}
        </div>
        <div key='settingModules' style={{paddingTop: '20px'}}>
            <Label valueI18n='settings'/>
            {settingModules.map(item => <Module key={item.routeProps.path} item={item} form={form}/>)}
        </div>
    </div>

}


function Module(props) {
    const context = useContext(AppContext)
    const {
        form,
        item
    } = props

    function isAccessable(item) {
        const isSuperadmin = findByField(item?.roles, 'name', Role.SUPERADMIN.name) !== undefined
        if (isSuperadmin) return false
        return !item.isAccessable || item.isAccessable(context)
    }

    if (!isAccessable(item)) return undefined
    return <div>
        <PathItem form={form}
                  name={item.label}
                  path={item.routeProps.path}
                  bold={true}/>

        {
            item.routeProps.items &&
            item.routeProps.items
                .filter(isAccessable)
                .map(subItem => <PathItem
                    key={subItem.path}
                    form={form}
                    name={subItem.nameKey
                        ? i18n(subItem.nameKey)
                        : subItem.name}
                    path={subItem.path}/>)
        }
    </div>
}

function PathItem({form, name, path, bold}) {


    return <div style={{marginBottom: '5px', marginLeft: bold ? undefined : '30px'}}>
        <Tooltip value={path}>
            <CheckboxInput name={'PATH_' + path}
                           control={form.control}
                           disabled={form.disabled}
                           label={name}
                           labelStyle={{
                               fontWeight: bold ? 'bold' : ''
                           }}
            />
        </Tooltip>
    </div>
}