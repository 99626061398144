import {useMemo} from "react";
import {useLocation} from "react-router-dom";

/*
USAGE:
const query = useQuery();
query.get('project')
*/

export default function useQuery() {
    const {search} = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}