export const FooterAggregate = Object.freeze({
    'SUM_DECIMALS_IF_NEEDED': 1,
    'SUM_INTEGER': 2,
    'SUM_HOURS': 3,
    'SUM_CURRENCY': 4,
    'SUM_CURRENCY_TWO_DECIMALS': 5,
    'SUM_CURRENCY_FOUR_DECIMALS': 6,
    'SUM_WEIGHT': 7,
    'SUM_DURATION': 8,

    'MEAN': 9,
})

//
// const getFooterGroup = (columns, list, showRowReorder = false) => {
//     const hasPdf = () => columns[0].field === 'pdf'
//     const count = list.length - numOfFreeRows(list)
//
//     return <ColumnGroup>
//         <Row>
//             {showRowReorder && <Column key={0} footer={''}/>}
//             {
//                 columns.map((c, index) => {
//                     const style = c.style || {}
//                     style.display = 'table-cell'
//                     if (c.align) style.justifyContent = c.align
//                     if (c.align) style.textAlign = c.align
//                     if (c.width) style.width = c.width + 'px'
//
//                     let value
//                     if ((!hasPdf() && index === 0) || (hasPdf() && index === 1)) {
//                         value = I18n.t('total') + ' (' + count + ')'
//                     } else {
//                         switch (c.footer) {
//                             case FooterAggregate.SUM_DECIMALS_IF_NEEDED:
//                                 value = NumberUtils.formatTwoDecimalsIfNeeded(ArrayUtil.sum(list, c.field), 0)
//                                 break;
//                             case FooterAggregate.SUM_INTEGER:
//                                 value = NumberUtils.format(ArrayUtil.sum(list, c.field), 0)
//                                 break;
//                             case FooterAggregate.SUM_HOURS:
//                                 value = NumberUtils.hours(ArrayUtil.sum(list, c.field), 0)
//                                 break;
//                             case FooterAggregate.SUM_CURRENCY:
//                                 value = NumberUtils.formatCurrency(ArrayUtil.sum(list, c.field), 0)
//                                 break;
//                             case FooterAggregate.SUM_CURRENCY_TWO_DECIMALS:
//                                 value = NumberUtils.formatCurrency(ArrayUtil.sum(list, c.field), 2)
//                                 break;
//                             case FooterAggregate.SUM_CURRENCY_FOUR_DECIMALS:
//                                 value = NumberUtils.formatCurrency(ArrayUtil.sum(list, c.field), 4)
//                                 break;
//                             case FooterAggregate.SUM_WEIGHT:
//                                 value = NumberUtils.formatWeight(ArrayUtil.sum(list, c.field), 0)
//                                 break;
//                             case FooterAggregate.SUM_DURATION:
//                                 value = StringUtils.formatDuration(ArrayUtil.sum(list, c.field))
//                                 break;
//                             case FooterAggregate.MEAN:
//                                 const sum = ArrayUtil.sum(list, c.field)
//                                 value = '\u0078\u0304 = ' + formatNumber(sum / count, 0)
//                                 break;
//                             default:
//                                 value = ''
//                                 break;
//                         }
//                     }
//
//                     return <Column
//                         key={index}
//                         footer={value}
//                         style={style}
//                     />
//                 })
//             }
//         </Row>
//     </ColumnGroup>
// }
// export default getFooterGroup;