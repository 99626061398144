import {cloneElement} from "react";
import {createRoot} from "react-dom/client";
import Spinner from "../Spinner/Spinner";
import './Loading.scss';

let referenceCounter = 0;

export function initLoader() {
    const renderContainer = document.createElement('div');
    document.body.appendChild(renderContainer);
    const root = createRoot(renderContainer);
    root.render(cloneElement(<Spinner id='global-spinner' style={{display: 'none'}}/>));
}

export function showLoading() {
    referenceCounter += 1
    if (referenceCounter === 1) {
        document.getElementById('global-spinner').style.display = 'flex'
    }
}

export function hideLoading() {
    referenceCounter -= 1
    if (referenceCounter === 0) {
        document.getElementById('global-spinner').style.display = 'none'
    }
}