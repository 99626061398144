import React, {useId} from 'react';
import {useNavigate} from "react-router-dom";
import Grid from "../../../../components/Grid/Grid";
import usePreload from "../../../../hooks/usePreload";
import {i18n} from "../../../../I18n/I18n";
import './AbsenceGrid.scss'
import EmployeeAbsenceInfoGridTemplete from "./EmployeeAbsenceInfoGridTemplete";

const AbsenceGrid = props => {
    const preload = usePreload()
    const id = useId()

    const employeeList = preload.getEmployeeList()
    const timeregabsencetypeList = preload.getTimeregabsencetypeList()

    const date = props.date
    const timeregabsenceList = props.timeregabsenceList

    const navigate = useNavigate()

    const onRowSelect = () => navigate('/timeregabsence/' + date)

    const columns = [
        {
            field: 'name',
            body: item => <div>
                <div style={{fontWeight: 'bold'}}>{item.name}</div>
            </div>,
            width: 250,
            mobile: true
        },
        {
            field: 'employeeName', headerI18n: 'employee',
            body: () => <EmployeeAbsenceInfoGridTemplete
                employeeList={employeeList}
                timeregabsenceList={timeregabsenceList}
                timeregabsencetypeList={timeregabsencetypeList}
            />,
            mobile: true
        },
    ];

    return <div className='AbsenceGrid'>
        <Grid columns={columns}
              xlnzGrid={true}
              scrollable={false}
              hideHeader={true}
              hideFooter={true}
              value={[{
                  id: id,
                  name: i18n('absence'),
                  timeregabsenceList: timeregabsenceList
              }]} rowSelectParams={'/' + date}
              heightOffset={310}
              onRowSelect={e => onRowSelect(e.data)}
              footerColumnGroup={<div/>}
        />
    </div>
}

export default AbsenceGrid;
