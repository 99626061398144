import React from 'react';
import {useForm} from "react-hook-form";
import {CancelButton, RemoveButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemDate from "../../../components/EditForm/FormItemDate";
import FormItemTextArea from "../../../components/EditForm/FormItemTextArea";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import Role from "../../../enums/Role";
import useRoles from "../../../hooks/useRoles";

const LeadlogEditFormDialog = props => {
    const {
        model,
        onHide
    } = props

    const roles = useRoles()
    const disabled = !roles.hasRole(Role.CRM_WRITE)

    const form = {...useForm({defaultValues: model}), disabled: disabled}
    const {handleSubmit, formState: {isSubmitting}} = form

    const onSubmit = data => {
        Object.assign(model, data);
        props.onSave(model);
        props.onHide()
    }

    const onRemove = async () => {
        props.onRemove(model);
        props.onHide()
    }

    return <XlnzDialog headerI18n='log'
                       onHide={onHide}
                       buttons={() => [
                           disabled
                               ? undefined
                               : <SaveButton onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>,
                           disabled || model.newPrice
                               ? undefined
                               : <RemoveButton onClick={e => onRemove()}/>,
                           <CancelButton onClick={e => props.onHide()}/>
                       ]}
    >

        <div className="EditForm" style={{width: '800px'}}>
            <form>
                <FormItemDate name='date' labelI18n='date' required {...form}/>
                <FormItemTextArea name='value' labelI18n='value' maxLength={5000} required {...form}/>
            </form>
        </div>

    </XlnzDialog>
}

export default LeadlogEditFormDialog;
