import React from "react";
import {formatCurrency} from "../../../../utils/NumberUtils";
import {i18n} from "../../../../I18n/I18n";

export default function PaymentplanRowGridFooter(props) {
    const {
        difference,
    } = props

    if (difference === 0) return undefined
    return <div className='grid-footer'>
        <div className='left'></div>
        <div className='middle'></div>
        <div className='right'>
            <table>
                <tbody>
                <tr className='to-pay'>
                    <td>{i18n('difference')}:</td>
                    <td>{formatCurrency(difference, 0)}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
}