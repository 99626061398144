import React from 'react';
import Unit from "../../../../enums/Unit";
import {i18n} from "../../../../I18n/I18n";
import {formatCurrency, formatTwoDecimalsIfNeeded} from "../../../../utils/NumberUtils";
import AvatarWithHours from "../shared/AvatarWithHours";
import AvatarTooltip from "./AvatarTooltip";
import './EmployeeInfoGridTemplete.scss'

const EmployeeInfoGridTemplete = props => {
    const {
        dayplanning,
        employeeList,
        timeregsalarytypeList,
    } = props

    const hasHours = salarytype => dayplanning.timeregList.find(tr => tr.salarytype === (!salarytype ? null : salarytype.id))

    return <div className='EmployeeInfo'>
        {
            hasHours(undefined) &&
            <TypeGroup employeeList={employeeList} dayplanning={dayplanning} type={undefined}/>
        }

        {
            timeregsalarytypeList
                .filter(type => hasHours(type))
                .map(type => <TypeGroup key={type.id} employeeList={employeeList} dayplanning={dayplanning}
                                        type={type}/>)
        }

        {
            <TypeArticleGroup employeeList={employeeList} dayplanning={dayplanning}/>
        }

    </div>
}

export default EmployeeInfoGridTemplete;

export function TypeGroup({employeeList, dayplanning, type}) {
    const getEmployee = employeeId => employeeList.find(employee => employee.id === employeeId)


    function getRightSide(tr) {
        let result = ''
        if (tr.costplusHours > 0 || tr.debitHours > 0) {
            result += formatTwoDecimalsIfNeeded(tr.costplusHours, true)
            if (tr.costplusHours !== tr.debitHours) {
                result += ' (' + formatTwoDecimalsIfNeeded(tr.debitHours, true) + ')'
            }

        } else {
            result = ''
        }
        // tr.costplusHours+'DE'+tr.debitHours
        return result
    }

    return <div className='type'>
        <div className='type-label'>{type?.name || i18n('to_assign')}</div>
        <div className='employeeList'>
            {
                dayplanning.timeregList
                    .filter(tr => tr.salarytype === (!type ? null : type.id))
                    .map((tr, index) => {
                        const employee = getEmployee(tr.employee)

                        return <AvatarWithHours
                            key={index}
                            label={employee?.alias}
                            leftHours={formatTwoDecimalsIfNeeded(tr.fixedHours)}
                            rightHours={getRightSide(tr)}
                            notarized={tr.notarized}
                            tooltipContent={<AvatarTooltip employee={employee} dayplanning={dayplanning} timereg={tr}/>}
                        />
                    })
            }
        </div>
    </div>;
}

function TypeArticleGroup({employeeList, dayplanning}) {
    if (dayplanning.timeregarticleList.length === 0) return

    function formatQuantityAndUnit(tr) {
        let result = tr.quantity
        if (tr.unit) {
            result += ' ' + Unit.findById(tr.unit).symbol
        }
        return result
    }

    function formatPrice(tr) {
        if (tr.price !== 0) return formatCurrency(tr.price)
        else return ''
    }

    return <div className='type'>
        <div className='type-label'>{i18n('articles')}</div>
        <div className='employeeList'>
            {
                dayplanning.timeregarticleList
                    .map((tr, index) => {
                        return <AvatarWithHours
                            key={index}
                            // employee={employee}
                            label={tr.name}
                            leftHours={formatQuantityAndUnit(tr)}
                            rightHours={formatPrice(tr)}
                            notarized={tr.notarized}
                            // tooltipContent={<AvatarTooltip employee={employee} dayplanning={dayplanning} timereg={tr}/>}
                        />
                    })
            }
        </div>
    </div>;
}