import {findByField, sortByField} from "../../../utils/ArrayUtil";

export default class Formrowtype {
    static get ROW() {
        return Object.freeze({id: 'ROW', name: 'Rad'});
    }

    static get NEW_LINE() {
        return Object.freeze({id: 'NEW_LINE', name: 'Ny rad'});
    }

    static get HEADER1() {
        return Object.freeze({id: 'HEADER1', name: 'Header 1'});
    }

    static get HEADER2() {
        return Object.freeze({id: 'HEADER2', name: 'Header 2'});
    }

    static get INFO() {
        return Object.freeze({id: 'INFO', name: 'Info'});
    }

    static get TEXT() {
        return Object.freeze({id: 'TEXT', name: 'Text'});
    }

    static get TEXTAREA() {
        return Object.freeze({id: 'TEXTAREA', name: 'Textarea'});
    }

    static get NUMBER() {
        return Object.freeze({id: 'NUMBER', name: 'Number'});
    }

    static get CHECK() {
        return Object.freeze({id: 'CHECK', name: 'Checkbox'});
    }

    static get RADIO() {
        return Object.freeze({id: 'RADIO', name: 'Radiobox'});
    }

    static get SELECT() {
        return Object.freeze({id: 'SELECT', name: 'Select'});
    }

    static values() {
        const result = [
            this.ROW,
            this.NEW_LINE,
            this.HEADER1,
            this.HEADER2,
            this.INFO,
            this.TEXT,
            this.TEXTAREA,
            this.NUMBER,
            this.CHECK,
            this.RADIO,
            this.SELECT,
        ]
        sortByField(result, 'name')
        return result
    }

    static findById(id) {
        return findByField(this.values(), 'id', id)
    }
}