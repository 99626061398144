import Role from "../../enums/Role";
import ArticleGrid from "./ArticleGrid";
import ArticleEditForm from "./edit/ArticleEditForm";

const CustomerIndex = {
    routeProps: {
        path: '/articles',
        exact: true,
        element: <ArticleGrid/>
    },
    nameKey: 'articles',
    icon: 'pi pi-fw pi-list',
    subModules: [{
        routeProps: {
            path: '/articles/:id',
            exact: true,
            element: <ArticleEditForm/>,
        },
        name: 'Redigera artikel',
    }],
    roles: [Role.ARTICLE_READ]
}
export default CustomerIndex;