import {createRef, useEffect, useRef, useState} from "react";
import useMountEffect from "../../hooks/useMountEffect";
import './AutoComplete.scss'

const AutoComplete = props => {
    const {
        options,
        optionLabel,
        optionValue,
        onChange,
        filterBy,
        itemTemplate,
    } = props

    const [filteredOptions, setFilteredOptions] = useState([]);
    const [activeOptionIndex, setActiveOptionIndex] = useState(-1);
    const [showSuggestions, setShowSuggestions] = useState(true);
    const [input, setInput] = useState('');
    const [inputWidth, setInputWidth] = useState('');

    const inputRef = useRef()

    useMountEffect(() => {
        search()
        setInputWidth(inputRef.current.offsetWidth)
    })

    const _onChange = (e) => {
        const userInput = e.target.value;
        search(userInput)
    };

    function match(item, q, propName) {
        // const label = item[propName]

        const label = item[filterBy || propName] + ''

        if (!label) return false
        return ("" + label).toLowerCase().indexOf(q.toLowerCase()) > -1
    }

    const search = (userInput = '') => {
        let _filteredOptions
        if (userInput.length === 0) {
            _filteredOptions = [...options]
        } else {
            _filteredOptions = options.filter(item => {
                return match(item, userInput, optionLabel) ||
                    match(item, userInput, 'number')

            })
        }
        setInput(userInput);
        setFilteredOptions(_filteredOptions);
        setShowSuggestions(true)
    }

    const fireChange = option => {
        if (!option) return

        setShowSuggestions(false);
        if (option.id === 'NONE') {
            onChange()
        } else {
            setInput(option[optionLabel]);
            const fireValue = optionValue ? option[optionValue] : option
            onChange({value: fireValue})
        }
    };

    const onClick = option => {
        fireChange(option)
        inputRef.current.focus()
    }

    const onKeyDown = e => {
        switch (e.keyCode) {
            case 9: // TAB
            case 13: // ENTER
                let _activeOptionIndex = activeOptionIndex
                if (_activeOptionIndex === -1 && input?.length > 0 && showSuggestions) {
                    _activeOptionIndex = 0
                }
                fireChange(filteredOptions[_activeOptionIndex])
                break
            case 38: // UP ARROW
                if (activeOptionIndex > 0) setActiveOptionIndex(activeOptionIndex - 1)
                break
            case 40: // DOWN ARROW
                if (activeOptionIndex < filteredOptions.length - 1) setActiveOptionIndex(activeOptionIndex + 1)
                break
            default:
                break
        }
    };

    // console.log("inputRef?.current?.offsetTop", inputRef?.current?.offsetTop)

    return (
        <div className='AutoComplete'>
            <input ref={inputRef}
                   type="text"
                   onChange={_onChange}
                   onKeyDown={onKeyDown}
                   value={input}
                   autoFocus
            />
            {
                showSuggestions &&
                <SuggestionsListComponent
                    filteredSuggestions={filteredOptions}
                    activeOptionIndex={activeOptionIndex}
                    onClick={onClick}
                    optionLabel={optionLabel}
                    style={{minWidth: inputWidth + 80}}
                    offsetTop={inputRef?.current?.offsetTop}
                    itemTemplate={itemTemplate}
                />
            }
        </div>
    );
};

const SuggestionsListComponent = props => {
    const {
        filteredSuggestions,
        activeOptionIndex,
        onClick,
        optionLabel,
        style,
        itemTemplate
    } = props

    const refs = filteredSuggestions.reduce((acc, value) => {
        acc[value.id] = createRef();
        return acc;
    }, {});

    useEffect(() => {

        filteredSuggestions.forEach((option, index) => {
            if (index === activeOptionIndex && refs[option.id].current) {
                refs[option.id].current.scrollIntoView({block: 'center',});
            }
        })

    }, [refs, filteredSuggestions, activeOptionIndex])

    return filteredSuggestions.length
        ? <div className='suggestions-container'>
            <ul className="suggestions" style={style}>
                {filteredSuggestions.map((option, index) => {
                    // Flag the active suggestion with a class
                    const className = index === activeOptionIndex ? 'suggestion-active' : '';

                    return (
                        <li className={className}
                            key={index}
                            ref={refs[option.id]}
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                onClick(option)
                            }}
                        >
                            {/*{option[optionLabel]}*/}
                            {itemTemplate ? itemTemplate(option) : option[optionLabel]}
                        </li>
                    );
                })}
            </ul>
        </div>
        : <></>

};

export default AutoComplete