import NetUtils from "../../utils/NetUtils";


const SuperadminService = {
    getServerStartTime: () => NetUtils.doGet('/api/superadmin/server-start-time'),
    // getRequestCounters: () => NetUtils.doGet('/api/superadmin/request-counters'),
    getCurrentlyOnlineUsers: () => NetUtils.doGet('/api/superadmin/currently-online-users'),
    getCurrentlyOnlineEmployees: () => NetUtils.doGet('/api/superadmin/currently-online-employees'),
    getErrorLogs: () => NetUtils.doGet('/api/superadmin/error-logs'),
    deleteErrorLogs: () => NetUtils.doDelete('/api/superadmin/error-logs'),
    listUsersActivities: (startDate, endDate) => NetUtils.doGet('/api/superadmin/user-activities', {
        startDate: startDate,
        endDate: endDate,
    }),
    listEmployeeActivities: (startDate, endDate) => NetUtils.doGet('/api/superadmin/employee-activities', {
        startDate: startDate,
        endDate: endDate,
    }),

    getLatestCronLogs: () => NetUtils.doGet('/api/superadmin/latest-cron-logs'),
    getCaches: () => NetUtils.doGet('/api/superadmin/caches'),


    byggletSyncAccounts: () => NetUtils.doGet('/api/superadmin/bygglet/sync-accounts'),
    byggletSyncTimeregdebittypes: () => NetUtils.doGet('/api/superadmin/bygglet/sync-timeregdebittypes'),
    byggletSyncTimeregsalarytypes: () => NetUtils.doGet('/api/superadmin/bygglet/sync-timeregsalarytypes'),
    byggletSyncArticles: () => NetUtils.doGet('/api/superadmin/bygglet/sync-articles'),
    byggletSyncEmployees: () => NetUtils.doGet('/api/superadmin/bygglet/sync-employees'),
    byggletSyncCustomers: () => NetUtils.doGet('/api/superadmin/bygglet/sync-customers'),
    byggletSyncTenderhowtotemplates: () => NetUtils.doGet('/api/superadmin/bygglet/sync-tenderhowtotemplates'),
    byggletSyncSuppliers: () => NetUtils.doGet('/api/superadmin/bygglet/sync-suppliers'),
    byggletSyncProjects: () => NetUtils.doGet('/api/superadmin/bygglet/sync-projects'),
    byggletSyncAssignments: () => NetUtils.doGet('/api/superadmin/bygglet/sync-assignments'),
    byggletSyncSupplierinvoices: () => NetUtils.doGet('/api/superadmin/bygglet/sync-supplierinvoices'),
    byggletSyncSupplierinvoiceImages: () => NetUtils.doGet('/api/superadmin/bygglet/sync-supplierinvoice-images'),
    byggletSyncInvoices: () => NetUtils.doGet('/api/superadmin/bygglet/sync-invoices'),
    byggletSyncInvoiceAppendices: () => NetUtils.doGet('/api/superadmin/bygglet/sync-invoice-appendices'),


    fortnoxGetAccessToken: () => NetUtils.doGet('/api/superadmin/fortnox/access-token'),
    fortnoxSyncInvoiceAccounts: () => NetUtils.doGet('/api/superadmin/fortnox/sync-invoice-accounts'),
    fortnoxSyncSupplierinvoiceAccounts: () => NetUtils.doGet('/api/superadmin/fortnox/sync-supplierinvoice-accounts'),
    fortnoxSyncCustomers: () => NetUtils.doGet('/api/superadmin/fortnox/sync-customers'),
    fortnoxSyncSuppliers: () => NetUtils.doGet('/api/superadmin/fortnox/sync-suppliers'),
    fortnoxSyncEmployees: () => NetUtils.doGet('/api/superadmin/fortnox/sync-employees'),

    pyramidSyncArticles: () => NetUtils.doGet('/api/superadmin/pyramid/sync-articles'),
    pyramidSyncSkipped: () => NetUtils.doGet('/api/superadmin/pyramid/sync-skipped'),

    syncI18n: () => NetUtils.doGet('/api/superadmin/sync-i18n'),
    sendSupplierInvoiceReminders: () => NetUtils.doGet('/api/superadmin/send-supplier-invoice-reminders'),

    getScanningStats: (startDate, endDate) => NetUtils.doGet('/api/superadmin/scanning-stats', {
        startDate: startDate,
        endDate: endDate,
    }),

    // moveCostcenter: () => NetUtils.doGet('/api/superadmin/move-costcenter'),
    validateSsn: () => NetUtils.doGet('/api/superadmin/validate-ssn'),
}
export default SuperadminService;