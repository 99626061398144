import {isHoliday, isWeekend} from "../../../../utils/DateUtils";

const getDayStyle = date => {
    const result = {}

    if (isHoliday(date, true)) {
        result.backgroundColor = 'rgba(128, 0, 0, 0.3)'
    } else if (isWeekend(date)) {
        result.backgroundColor = 'rgba(128, 0, 0, 0.1)'
    }
    return result
};
export default getDayStyle

