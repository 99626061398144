import I18n from '@I18n';
import React, {useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import ActiveFilter from "../../components/Grid/ActiveFilter";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Role from "../../enums/Role";
import {matchActive, matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useMountEffect from "../../hooks/useMountEffect";
import useRoles from "../../hooks/useRoles";
import TimeregabsencetypeService from "./TimeregabsencetypeService";

const TimeregabsencetypeGrid = () => {
    const roles = useRoles()

    const filter = useFilter('TimeregabsencetypeGrid', [
        {name: 'active', defaultValue: true},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);
    const filteredList = list.filter(c => matchActive(filter, c) && matchSearch(filter, c))

    useMountEffect(async () => {
        showLoading()
        setList(await TimeregabsencetypeService.findAll())
        hideLoading()
    }, [])

    const leftFilters = [
        roles.hasRole(Role.ADMIN) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <label className="label">{I18n.t('status')}</label>
            <ActiveFilter value={filter.active} onChange={e => filter.update('active', e.value)}/>
        </div>
    ]

    const columns = [
        {field: 'name', headerI18n: 'name'},
        {field: 'salaryCode', headerI18n: 'salary_code'},
        {field: 'listOrder', headerI18n: 'list_order', align: 'right'},
        {field: 'active', headerI18n: 'active', body: GridTemplates.boolean, style: {maxWidth: '100px'}},
    ];

    return <Grid
        xlnzGrid={true}
        labelI18n='absence_types'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
    />
}

export default TimeregabsencetypeGrid;
