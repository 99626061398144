import React from 'react';
import './Dashboard.scss'
import XlnzChart from "../../components/XlnzChart/XlnzChart";
import {i18n} from "../../I18n/I18n";
import {NumberUtils} from "../../utils/NumberUtils";

const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;

const PREDEF_COLORS = ['#699b2c', '#5E2C9B', '#2C699B', '#C9368A', '#2C9B96', '#CA3935', '#82DE21', '#21DEDB', '#8231CE']

const getColor = index => {
    return index < PREDEF_COLORS.length ? PREDEF_COLORS[index] : randomRGB()
}
const TurnoverChart = props => {
    const turnoverChart = props.data.turnoverChart;
    // turnoverChart.datasets[0].backgroundColor = '#699b2c'
    //turnoverChart.datasets[0].backgroundColor = randomRGB()


    for (let i = 0; i < turnoverChart.datasets.length; i++) {
        const ds = turnoverChart.datasets[i];
        ds.backgroundColor = getColor(i);
    }

    let options = {
        plugins: {
            legend: {
                display: true
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const index = tooltipItem.dataIndex

                        const val = turnoverChart.datasets.reduce((sum, ds) => {
                            //console.log("DATAAA", ds.data)
                            return sum + ds.data[index]
                        }, 0)

                        //console.log('tooltipItem', tooltipItem, val)

                        return [
                            tooltipItem.dataset.label + ': ' + NumberUtils.formatCurrency(tooltipItem.raw),
                            i18n('total') + ': ' + NumberUtils.formatCurrency(val)
                        ]
                    }
                }
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            }
        }
    }

    return <div style={{width: '800px', height: '400px'}}>
        <XlnzChart type="bar" data={turnoverChart} options={options}/>
    </div>
}

export default TurnoverChart;
