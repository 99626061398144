import NetUtils from "../../utils/NetUtils";

const NewsletterService = {
    findAll: () => NetUtils.doGet('/api/newsletters'),
    findById: id => NetUtils.doGet('/api/newsletters/' + id),
    update: (model) => NetUtils.doPost('/api/newsletters', model),
    sendByEmail: (id, recipient) => NetUtils.doGet('/api/newsletters/' + id + '/send-by-email', {recipient: recipient}),

    findAllUnsubscribes: () => NetUtils.doGet('/api/newsletters/unsubscribes'),
    findUnsubscribeById: id => NetUtils.doGet('/api/newsletters/unsubscribes/' + id),
    findUnsubscribeByToken: token => NetUtils.doGet('/api/newsletters/unsubscribes/token/' + token),
    updateUnsubscribe: model => NetUtils.doPost('/api/newsletters/unsubscribes', model),
    deleteUnsubscribe: model => NetUtils.doDelete('/api/newsletters/unsubscribes', model),

        findAllUnsubscribeCtrls: () => NetUtils.doGet('/api/newsletters/unsubscribes-ctrls'),
}
export default NewsletterService;