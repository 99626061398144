import {useContext, useMemo} from "react";
import AppContext from "../AppContext";
import Companyprop from "../enums/Companyprop";

export default function useCompanyProp(prop, defaultValue = '') {
    if (!prop) throw new Error('Invalid company prop: ' + prop)

    const context = useContext(AppContext);

    const propType = Companyprop.findById(prop.name)

    let value = context.currentCompany.propList.find(p => p.name === prop.name)?.value || defaultValue
    if (propType?.type === 'boolean') {
        value = value === '1'
    }

    if (typeof defaultValue === 'number')
        value = Number(value)

    const result = useMemo(() => value, [value])
    return result
}