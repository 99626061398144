import NetUtils from "../../utils/NetUtils";

const HyrletService = {
    getDashboard: (customerId, date) => NetUtils.doGet('/api/hyrlet/portal.dashboard', {
        date: date,
        customer: customerId
    }).then(resp => {
        return resp
    }, () => {
        console.error("Unable to fetch dashboard info from Hyrlet")
        return undefined
    }),


    getLoginInfo: async () => {
        return NetUtils.doGet('/api/hyrlet/users.loginInfo').then(resp => {
            return resp
        }, () => {
            console.error("Unable to fetch login info from Hyrlet")
            return undefined
        })
    },
    findOrderById: (customerId, id) => NetUtils.doGet('/api/hyrlet/portal.orders.' + id, {
        customer: customerId
    }),
    findOrders: (customerId, first, rows, status, year, search) => NetUtils.doGet('/api/hyrlet/portal.orders', {
        customer: customerId,
        first: first,
        rows: rows,
        status: status,
        year: year,
        search: search,
        includeSubCustomers: false
    }),
    update: model => NetUtils.doPost('/api/hyrlet/portal.orders', model),

    listArticlegroups: customerId => NetUtils.doGet('/api/hyrlet/portal.articlegroups', {
        customer: customerId
    }),

    articlesToRent: () => NetUtils.doGet('/api/hyrlet/portal.articles.list.articles-to-rent'),

    customerPrices: (customerId, time) => NetUtils.doGet('/api/hyrlet/portal.articles.list.customer-prices', {
        customer: customerId,
        time: time,
    }),

    listAvailableArticles: customerId => NetUtils.doGet('/api/hyrlet/portal.articles.list.available', {
        customer: customerId
    }),
    listArticlesByOrder: (customerId, orderId) => NetUtils.doGet('/api/hyrlet/portal.articles.list.by-order.' + orderId, {
        customer: customerId
    }),
    stockQuantities: (customerId, time) => NetUtils.doGet('/api/hyrlet/portal.orders.stockQuantities', {
        customer: customerId,
        time: time
    }),

    listRentedArticles: customerId => NetUtils.doGet('/api/hyrlet/portal.articles.list.rented', {
        customer: customerId
    }),
    customerStockQuantities: (customerId, time) => NetUtils.doGet('/api/hyrlet/portal.orders.stockQuantities.customer', {
        customer: customerId,
        time: time
    }),


    // update: model => NetUtils.doPost('/api/accounts', model)
}
export default HyrletService;