import React from 'react';
import FormItemText from "./FormItemText";

const FormItemEmail = props => {
    return <FormItemText email maxLength={128} type='email' capitalize={false}
                         onBlur={e => {
                             const str = e.target.value
                             props.setValue(props.name, str.trim())
                         }}
                         {...props}/>
}
export default FormItemEmail;