import {useMemo} from "react";
import Role from "../enums/Role";
import {findByField} from "../utils/ArrayUtil";
import {getRoleName} from "./useUserPersmissions";

let ROLES

export default function useRoles(isApp) {
    // if (!isApp) console.warn("DEPRECATED: useRoles, use useUserPersmissions instead.")

    const result = useMemo(() => ({
        getAll: () => Array.from(ROLES),
        set: roles => {
            ROLES = new Set(roles)
        },
        hasRole: role => {
            return ROLES.has(role.name)
        },
        hasRoles: roles => roles &&
            roles.filter(role => {
                    if (!role) throw new Error("Invalid role:; " + role)
                    return ROLES.has(role.name)
                }
            ).length === roles.length,
        getRoleName: getRoleName,
        findByName: _name => findByField(Role.values(), 'name', _name)
    }), [])

    return result
}