import React from 'react';
import {i18n} from "../../I18n/I18n";
import {getMonthName, getYear} from "../../utils/DateUtils";
import {formatCurrency} from "../../utils/NumberUtils";
import './TurnoverInfo.scss'

const TurnoverInfo = props => {
    const {
        date
    } = props
    const turnoverInfp = props.data.turnoverInfo;

    return <div className='TurnoverInfo'>
        <div className='item'>
            <div>{getMonthName(date)}</div>
            <div>{formatCurrency(turnoverInfp.month, 0, true)}</div>
        </div>
        <div className='item'>
            <div>{i18n('year') + ' ' + getYear(date)}            </div>
            <div>{formatCurrency(turnoverInfp.year, 0, true)}</div>
        </div>
        <div className='item'>
            <div>{i18n('rolling12')}</div>
            <div>{formatCurrency(turnoverInfp.rolling12, 0, true)}</div>
        </div>
    </div>
}

export default TurnoverInfo;
