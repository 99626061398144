import {groupBy} from "lodash";
import React from "react";
import Checkbox from "../../../components/EditForm/Checkbox";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import Tooltip from "../../../components/Overlay/Tooltip";
import {GridTemplates} from "../../../GridTemplates";
import useEmployees from "../../../hooks/useEmployees";
import usePreload from "../../../hooks/usePreload";
import {i18n} from "../../../I18n/I18n";
import {arraySum, findById} from "../../../utils/ArrayUtil";
import {formatHours} from "../../../utils/NumberUtils";
import {truncate} from "../../../utils/StringUtil";

export default function DayplanningSideBar(props) {
    const {
        dayplanningList,
        timeregabsenceList
    } = props

    const filterSideBar = useFilter('DayplanningSideBar', [
        {name: 'onlyEmployees', defaultValue: true},
    ])

    const preload = usePreload()
    const employees = useEmployees()
    const salarytypeList = preload.getTimeregsalarytypeList()
    const absencetypeList = preload.getTimeregabsencetypeList()

    const timeregList = dayplanningList.flatMap(dp => dp.timeregList)
        .filter(tr => tr.salarytype > 0)
        .filter(tr => tr.salaryHours > 0)

    const timeregListBySalarytype = groupBy(timeregList, tr => tr.salarytype)
    const timeregabsenceListByAbsencetype = groupBy(timeregabsenceList, tr => tr.type)

    const value = employees.activeUsable
        .filter(employee => filterSideBar.onlyEmployees ? !employee.hired : true)
        .map(employee => {
            const result = {
                alias: employee.alias,
                active: employee.active
            }
            let totalSalaryHours = 0

            // Worked time
            for (let salarytypeId in timeregListBySalarytype) {
                // const salarytype = findById(salarytypeList, salarytypeId)
                const timeregListByEmployee = groupBy(timeregListBySalarytype[salarytypeId], item => item.employee)

                const timeregByEmployeeAndSalaryType = timeregListByEmployee[employee.id]
                if (timeregByEmployeeAndSalaryType) {
                    const salaryHours = arraySum(timeregByEmployeeAndSalaryType, 'salaryHours')
                    totalSalaryHours += salaryHours
                    result['salarytype-' + salarytypeId] = salaryHours

                    if (salaryHours > 0) {
                        const foo = timeregByEmployeeAndSalaryType
                            .map(item => findById(dayplanningList, item.dayplanning))
                            .map(dp => dp.projectName)

                        result['salarytype-' + salarytypeId + '-info'] = foo
                        // console.log("foo", foo)
                    }
                }
            }

            //Absence time
            for (let absencetypeId in timeregabsenceListByAbsencetype) {
                // const salarytype = findById(salarytypeList, salarytypeId)
                const timeregabsenceListByEmployee = groupBy(timeregabsenceListByAbsencetype[absencetypeId], item => item.employee)

                const timeregByEmployeeAndAbsenceype = timeregabsenceListByEmployee[employee.id]
                if (timeregByEmployeeAndAbsenceype) {
                    const hours = arraySum(timeregByEmployeeAndAbsenceype, 'hours')
                    totalSalaryHours += hours
                    result['absencetype-' + absencetypeId] = hours
                }
            }

            result['totalSalaryHours'] = totalSalaryHours
            return result
        })
        .filter(item => item.totalSalaryHours !== 0 || item.active)

    const columns = [{field: 'alias', headerI18n: 'employee', width: 150}]

    salarytypeList
        .filter(item => timeregListBySalarytype[item.id] !== undefined
            && arraySum(value, 'salarytype-' + item.id) !== 0)
        .forEach(item => {
            columns.push({
                field: 'salarytype-' + item.id, header: truncate(item.name, 15),
                // body: GridTemplates.hours,
                body: rowData => {
                    const info = <div>
                        {
                            rowData['salarytype-' + item.id + '-info'] &&
                            rowData['salarytype-' + item.id + '-info'].map((info, infoIndex) => <div key={infoIndex}>
                                - {info}
                            </div>)
                        }
                    </div>

                    return <Tooltip value={info}>
                        <div>
                            {formatHours(rowData['salarytype-' + item.id])}
                        </div>
                    </Tooltip>

                },
                footer: FooterAggregate.SUM_HOURS,
                width: 100,
                align: 'right'
            })
        })

    absencetypeList
        .filter(item => timeregabsenceListByAbsencetype[item.id] !== undefined
            && arraySum(value, 'absencetype-' + item.id) !== 0)
        .forEach(item => {
            columns.push({
                field: 'absencetype-' + item.id, header: truncate(item.name, 15),
                body: GridTemplates.hours,
                footer: FooterAggregate.SUM_HOURS,
                width: 100,
                align: 'right'
            })
        })

    const leftFilters = [<div>
        <label className="label">{i18n('only_employees')}</label>
        <Checkbox checked={filterSideBar.onlyEmployees}
                  onChange={e => filterSideBar.update('onlyEmployees', e.target.checked)}/>
    </div>]

    return <Grid
        xlnzGrid={true}
        leftFilters={leftFilters}
        hideRightFilters={true}
        filter={filterSideBar}
        columns={columns}
        width={250}
        scrollable={true}
        forceDesktop={true}
        hideFooter={false}
        value={value}
        heightOffset={450}
        onRowSelect={e => {
        }}
        // footerColumnGroup={<div/>}
    />
}