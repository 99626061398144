import NetUtils from "@utils/NetUtils";
import el from "date-fns/locale/sv";
import {addLocale, locale} from "primereact/api";
import {registerLocale} from "react-datepicker";
import Language from "../enums/Language";

const SV = Object.freeze({
    are_you_sure: 'Är du säker?',
    message: 'Meddelande',
    close: 'Stäng',
    back: 'Tillbaka',
    email: 'E-post',
    error_message: 'Felmeddelande',
    error_user_is_inactive: 'Användaren är inaktiv',
    error_wrong_username_or_password: 'Fel användarnamn eller lösenord',
    error_multiple_user_usage: 'Ditt konto används på en annan enhet så du har loggats ut.',
    forgot_password: 'Glömt lösenordet?',
    log_in: 'Logga in',
    new_password_sent_to_email: 'Ett nytt lösenord har skickats till din e-postadress',
    password: 'Lösenord',
    send_new_password: 'Skicka nytt lösenord',

    approve: 'Godkänn',
    accept: 'Acceptera',
    question: 'Fråga',
    yes: 'Ja',
    download: 'Ladda ner',
    deny: 'Neka',
    cancel: 'Avbryt',
})

let translationList = SV

export const i18n = (key, ...params) => {
    let result = translationList[key] ? translationList[key] : '### ' + key + ' ###'
    for (let i = 0; i < params.length; i++) {
        result = result.replace("$" + i, params[i])
    }
    return result
}

const I18n = {
    t: (key) => {
        return translationList[key] ? translationList[key] : '### ' + key + ' ###'
    },
}
export default I18n

export const initI18n = async (user) => {
    const locale = user?.language || Language.SWEDISH.id

    if (user) {
        translationList = Object.freeze(await NetUtils.doGet('/api/languages/' + locale))
    }
    if (!translationList) translationList = SV
    initPrimeLocale(locale)

    registerLocale("el", el); // register it with the name you want
}

export const DAY_NAMES_I18N = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

const initPrimeLocale = i18nLocale => {

    addLocale(i18nLocale, {
        accept: i18n('yes'),
        reject: i18n('no'),
        emptyMessage: '-',

        firstDayOfWeek: 1,
        dateFormat: 'yy-mm-dd',
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['Sö', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö'],
        weekHeader: i18n('week'),
        monthNames: [
            i18n('january'),
            i18n('february'),
            i18n('march'),
            i18n('april'),
            i18n('may'),
            i18n('june'),
            i18n('july'),
            i18n('august'),
            i18n('september'),
            i18n('october'),
            i18n('november'),
            i18n('december'),
        ],
        monthNamesShort: [
            i18n('jan'),
            i18n('feb'),
            i18n('mar'),
            i18n('apr'),
            i18n('may'),
            i18n('jun'),
            i18n('jul'),
            i18n('aug'),
            i18n('sep'),
            i18n('oct'),
            i18n('nov'),
            i18n('dec'),
        ],

        today: i18n('today'),
        clear: i18n('clear'),

    });
    locale(i18nLocale);
}