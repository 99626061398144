import React from 'react';
import FormItemInteger from "../../components/EditForm/FormItemInteger";
import FormItemText from "../../components/EditForm/FormItemText";
import SimpleEditForm from "../../components/EditForm/SimpleEditForm";
import Role from "../../enums/Role";
import IncrementService from "./IncrementService";
import FormItemDropdown from "../../components/EditForm/FormItemDropdown";

const IncrementEditForm = () => {
    return <SimpleEditForm
        writeRole={Role.SUPERADMIN}
        headline='Increment'
        defaultValues={{
            type: 'CUSTOMER',
            prefix: 'C',
            value: 10000
        }}
        loadFunction={IncrementService.findById}
        saveFunction={IncrementService.update}
        initLeftFields={form => [
            <FormItemDropdown key='type' name='type' labelI18n='type'
                              options={[
                                  {id: 'LEAD', name: 'LEAD'},
                                  {id: 'CUSTOMER', name: 'CUSTOMER'},
                                  {id: 'INVOICE', name: 'INVOICE'},
                                  {id: 'PROJECT', name: 'PROJECT'},
                                  {id: 'TENDER', name: 'TENDER'},
                              ]}
                              required {...form} />,
            <FormItemText key='prefix' name='prefix' label='Prefix' maxLength={1} {...form}/>,
            <FormItemInteger key='value' name='value' label='Value (Last used number)' required {...form}/>,
        ]}
    />
}
export default IncrementEditForm;
