import React from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {CancelButton, EmailButton, SendButton} from "../../../components/Buttons/Button";
import {infoAlert} from "../../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import InvoiceSendMethod from "../../../enums/InvoiceSendMethod";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import {i18n} from "../../../I18n/I18n";
import {findById} from "../../../utils/ArrayUtil";
import RouterUtils from "../../../utils/RouterUtils";
import NewsletterService from "../NewsletterService";

export const SendNewsletterButton = props => {
    const {
        model,
        handleSubmit,
        save,
        reset,
        recipient
    } = props

    const preload = usePreload()
    const customer = findById(preload.get(PreloadType.CUSTOMER_LIST), model.customer)
    const invoiceSendMethod = customer ? InvoiceSendMethod.findById(customer.invoiceSendMethod) : undefined

    const style = {}
    if (invoiceSendMethod?.id !== InvoiceSendMethod.EMAIL.id) {
        style.color = '#F00'
    }

    const navigate = useNavigate();

    const onSendByEmail = data => {
        showLoading();

        save(data).then(_model => {
            if (_model) {
                reset(_model);
                hideLoading();
                renderOverlay(<SendNewsletterByEmailDialog
                    recipient={recipient}
                    onSend={({recipient}) => {
                        showLoading();
                        NewsletterService.sendByEmail(model.id, recipient).then(_model => {
                            if (_model) {
                                infoAlert(
                                    _model.numSent + '/' + _model.numAddresses + ' ' + i18n('sent').toLowerCase()
                                    + '\nRedan skickade: ' + _model.numAlreadySent
                                    + '\n' + i18n('unsubscribed') + ': ' + _model.numUnsubscribed
                                )
                                RouterUtils.goBack(navigate)
                            }
                            hideLoading();
                        });
                    }}
                />)
            } else {
                hideLoading();
            }
        })
    }

    return <div>
        <EmailButton
            label={i18n('send') + '...'}
            onClick={handleSubmit(onSendByEmail)}
        />
    </div>
}

const SendNewsletterByEmailDialog = ({recipient, onSend, onHide}) => {
    const defaultValues = {recipient: 'TEST'}

    const form = {...useForm({defaultValues: defaultValues})}
    const {register, handleSubmit} = form

    const onSubmit = data => {
        onSend(data)
        onHide()
    }

    const getRecipientName = () => {
        if (recipient === 'USERS') return i18n('users')
        if (recipient === 'LEADS') return i18n('leads')
    }

    return <XlnzDialog headerI18n='send_by_email' onHide={onHide}>
        <div className="EditForm">
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="formItem">
                    <label>{i18n('recipient')}</label>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px 0',
                    }}>
                        <input type="radio" id="test" name="recipient" value="TEST"
                               {...register("recipient")}
                        />
                        <label htmlFor="test" style={{fontSize: '12px', paddingRight: '15px'}}>Test</label>

                        <input type="radio" id="recipient" name="recipient" value={recipient}
                               {...register("recipient")}
                        />
                        <label htmlFor="recipient" style={{fontSize: '12px'}}>{getRecipientName()}</label>
                    </div>
                </div>

                <div className="button-row">
                    <div className='left-buttons'></div>
                    <div className='divider'></div>

                    <div className='right-buttons'>
                        <SendButton labelI18n='send' onClick={handleSubmit(onSubmit)}/>
                        <CancelButton onClick={e => onHide()}/>
                    </div>
                </div>
            </form>
        </div>
    </XlnzDialog>

    // return
    // <Dialog className='dialog'
    //         header={i18n('send_by_email')}
    //         visible={true}
    //         onHide={onHide}
    //         style={{width: '500px', zIndex: '100'}}>
    //
    //
    // </Dialog>
}
