import './ContextMenu.scss'
import {useEffect, useRef} from "react";

export default function ContextMenu(props) {
    const {
        onHide,
        options,
        top,
        left,
        row
    } = props

    const ref = useRef()

    useEffect(() => {
        ref.current.style.setProperty('--mouse-x', left + 'px');
        ref.current.style.setProperty('--mouse-y', top + 'px');
    }, [ref, top, left]);


    return <div ref={ref} className='ContextMenu'>
        {options.map(item => {
            return <div key={item.label} onClick={() => onHide()}>
                {item.originalTemplate(undefined, item, row)}
            </div>

        })}
    </div>
}