import Grid from "@components/Grid/Grid";
import React from 'react';
import {GridTemplates} from "../../GridTemplates";

const LatestCronLogs = props => {
    const {
        value,
    } = props

    const columns = [
        {
            field: 'ctime', header: 'Time',
            body: GridTemplates.time,
            width: 140,
        },
        {field: 'name', header: 'Name', width: 250},
        {field: 'comment', header: 'Comment'},
    ]


    return <div>
        <Grid
            xlnzGrid={true}
            scrollable={false}
            columns={columns}
            value={value}
            onRowSelect={() => {
            }}
            forceDesktop={true}
        />
    </div>
}
export default LatestCronLogs;