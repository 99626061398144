import React, {useCallback, useState} from 'react';
import {MainButton} from "../../../components/Buttons/Button";
import YearFilter from "../../../components/filters/YearFilter";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import XlnzDropdown from "../../../components/XlnzDropdown/XlnzDropdown";
import Icon from "../../../enums/Icon";
import {matchSearch} from "../../../functions/match";
import {GridTemplates} from "../../../GridTemplates";
import useEmployees from "../../../hooks/useEmployees";
import {i18n} from "../../../I18n/I18n";
import {getYear, MONTHS_I18N_KEYS} from "../../../utils/DateUtils";
import NetUtils from "../../../utils/NetUtils";

const MonthlyTurnoverPerAccount = () => {
    const filter = useFilter('MonthlyTurnoverPerAccount', [
        {name: 'year', defaultValue: getYear()},
        {name: 'projectLeader', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])
    const [list, setList] = useState([])
    const filteredList = list.filter(item => matchSearch(filter, item))

    const employees = useEmployees()
    const projectLeaderList = employees.activeUsable

    const loadData = useCallback(() => {
        showLoading()
        NetUtils.doGet('/api/reports/monthly-turnover-per-account', {
            year: filter.year,
            projectLeader: filter.projectLeader,
        }).then(res => {
            setList(res)
            hideLoading()
        });
    }, [filter.year, filter.projectLeader]);

    const columns = [{field: 'name', headerI18n: 'account', minWidth: 150}]

    MONTHS_I18N_KEYS.forEach(monthI18nKey => {
        columns.push({
            field: monthI18nKey.substring(0, 3),
            headerI18n: monthI18nKey,
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: 100, align: 'right'
        })
    })

    columns.push({
        field: 'tot',
        headerI18n: 'total',
        body: GridTemplates.currency,
        footer: FooterAggregate.SUM_CURRENCY,
        width: 100, align: 'right'
    })

    const leftFilters = [
        <div>
            <label className="label">{i18n('year')}</label>
            <YearFilter value={filter.year} onChange={e => filter.update('year', e.value)}/>
        </div>,
        <div>
            <label className="label">{i18n('project_leader')}</label>
            <XlnzDropdown
                value={filter.projectLeader}
                options={projectLeaderList}
                optionLabel='name'
                showClear={true}
                placeholder={i18n('select') + '...'}
                optionValue='id'
                filter={true}
                onChange={e => filter.update('projectLeader', e.value)}
                style={{width: '300px'}}/>
        </div>
    ]

    leftFilters.push(<div>
        <label className="label">&nbsp;</label>
        <MainButton labelI18n='create_report' onClick={() => loadData()}/>
    </div>)

    return <div className="Grid" style={{padding: '10px 0'}}>
        <Grid
            xlnzGrid={true}
            id='MonthlyTurnoverPerAccount'
            forceDesktop={true}
            labelI18n='monthly_turnover_per_account'
            icon={Icon.REPORT}
            leftFilters={leftFilters}
            filter={filter}
            updateFilter={filter.update}
            columns={columns}
            value={filteredList}
            onRowSelect={e => {
            }}
        />
    </div>
}

export default MonthlyTurnoverPerAccount;