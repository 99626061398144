import {faMagnifyingGlassMinus, faMagnifyingGlassPlus} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from 'react';
import {Document, Page} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import NetUtils from "../../utils/NetUtils";
import {CloseButton, DownloadButton, MainButton} from "../Buttons/Button";
import {hideLoading, showLoading} from "../Overlay/Loading";
import './PdfViewer.scss'
import 'react-pdf/dist/Page/TextLayer.css';

// Not working. In  production it's trying to fetch local files from c:. /JW 2024-06-13
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.mjs',
//     import.meta.url,
// ).toString();

const PdfViewer = props => {
    const {
        url,
        enableZoom = false,
        landscape = false
    } = props

    const [blob, setBlob] = useState()
    const [pages, setPages] = useState()

    useEffect(() => {
        if (url) {
            NetUtils.downloadBlob(url).then(_blob => setBlob(_blob))
        } else {
            setBlob(undefined)
        }
    }, [url])

    const Control = ({zoomIn, zoomOut, resetTransform}) => (
        <div className='control'>
            <MainButton faicon={faMagnifyingGlassPlus} onClick={() => zoomIn()}/>
            <MainButton faicon={faMagnifyingGlassMinus} onClick={() => zoomOut()}/>
            {/*<Button onClick={() => resetTransform()}/>*/}
        </div>
    )

    const pdfComponent = <Document
        file={blob}
        loading="Laddar bild..."
        onLoadSuccess={e => {
            let _pages = [];
            for (let i = 0; i < e.numPages; i++) {
                _pages.push(
                    <div key={'page_' + i}
                         className={'pageContainer' + (landscape ? ' landscape' : ' portrait')}>
                        <Page pageNumber={i + 1}
                            // Somehow '2.0' does note work on Chrome IOS. '1.9' is good though.
                              scale={1.9}
                        />
                    </div>
                );
            }
            setPages(_pages)
        }}
        onLoadError={console.error}>
        {pages}

    </Document>

    return <div className="PdfViewer">
        {
            blob &&
            <>
                {
                    enableZoom &&
                    <TransformWrapper
                        initialScale={1}
                        // initialPositionX={200}
                        // initialPositionY={100}
                    >
                        {(utils) => (
                            <>
                                <Control {...utils}/>
                                <TransformComponent>{pdfComponent}</TransformComponent>
                            </>
                        )}
                    </TransformWrapper>
                }
                {
                    !enableZoom &&
                    <>{pdfComponent}</>
                }
            </>
        }
    </div>
}

export default PdfViewer;


export const PdfViewerModal = props => {
    const {
        url,
        label = '',
        landscape = false
    } = props

    return <div className='PdfViewerModal'>
        <div className='PdfViewerModal-container'>
            <div className='button-bar'>
                <div className='left'>
                    <h3>{label}</h3>
                </div>
                <div className='center'>
                </div>
                <div className='right'>
                    <DownloadButton labelI18n='download' onClick={() => {
                        showLoading()
                        NetUtils.downloadFile(url).then(() => hideLoading())
                    }}/>
                    <CloseButton onClick={props.onHide}/>
                </div>
            </div>
            <div className='content'>
                <PdfViewer url={url} landscape={landscape}/>
            </div>
        </div>
    </div>
}