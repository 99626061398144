import React from 'react';
import DateEditor from "../../../components/Grid/DateEditor";
import {addFreeRows, numOfFreeRows} from "../../../components/Grid/freeRows";
import Grid from "../../../components/Grid/Grid";
import onRowChange from "../../../components/Grid/onRowChange";
import OptionalDateEditor from "../../../components/Grid/OptionalDateEditor";
import RemoveColumn from "../../../components/Grid/RemoveColumn";
import SelectEditor from "../../../components/Grid/SelectEditor";
import {todayDate} from "../../../utils/DateUtils";

const NUM_OF_FREE_ROWS = 1

const AgreementrowGrid = props => {
    const userList = props.userList;

    const rowList = addFreeRows(props.value, NUM_OF_FREE_ROWS, {
        id: 0, name: '', startDate: todayDate()
    });
    rowList.forEach(row => {
        const u = userList.find(item => item.id === row.user)
        row.userName = u?.name
        row.userEmail = u?.email
    })

    const getSlicedList = list => list.slice(0, list.length - numOfFreeRows(list))

    const fireChange = (list) => {
        console.log("getSlicedList(list)", getSlicedList(list))
        props.onChange(getSlicedList(list));
    }

    const columns = [
        {
            field: 'user',
            headerI18n: 'user',
            body: row => <div className='nowrap' style={{width: '250px'}}>{row.userName}</div>,
            editor: e => <SelectEditor event={e} options={userList} optionValue='id' optionLabel='name'
                                       onChange={e2 => e.editorCallback(e2?.value)}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange)
        },
        {field: 'userEmail', headerI18n: 'email'},
        {
            field: 'startDate', headerI18n: 'start_date',
            editor: DateEditor,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 130
        },
        {
            field: 'endDate', headerI18n: 'end_date',
            editor: e => <OptionalDateEditor editorEvent={e}/>,
            onCellEditComplete: e => {
                console.log("EEEEEEE", e)
                onRowChange(e, e.newValue, fireChange)
            },
            width: 130
        },
        RemoveColumn(row => fireChange(rowList.filter(r => r !== row)))
    ]


    return <div className='AgreementrowGrid' style={{width: '900px'}}>
        <Grid
            xlnzGrid={true}
            scrollable={false}
            columns={columns}
            value={rowList}
            // footerColumnGroup={<div/>}
            onRowReorder={e => fireChange([...e.value])}
            sortable={false}
        />
    </div>
}
export default AgreementrowGrid;