import React from 'react';
import FormItemText from "../../components/EditForm/FormItemText";
import FormItemTextArea from "../../components/EditForm/FormItemTextArea";
import SimpleEditForm from "../../components/EditForm/SimpleEditForm";
import Role from "../../enums/Role";
import I18nService from "./I18nService";

const I18nEditForm = () => {

    async function loadFunction(id) {
        const i18nEn = await I18nService.findById('en_' + id).then(_res => _res, () => undefined)
        const i18nSv = await I18nService.findById('sv_' + id).then(_res => _res, () => undefined)

        return {
            id: id,
            valueEn: i18nEn?.value,
            valueSv: i18nSv?.value,
        }
    }

    async function saveFunction(data) {
        if (data.valueEn) {
            await I18nService.update({id: 'en_' + data.id, value: data.valueEn})
        }
        if (data.valueSv) {
            await I18nService.update({id: 'sv_' + data.id, value: data.valueSv})
        }

        return {
            id: data.id,
            valueEn: data.valueEn,
            valueSv: data.valueSv,
        }
    }

    async function removeFunction(data) {
        await I18nService.delete({id: 'en_' + data.id, value: data.valueEn})
        await I18nService.delete({id: 'sv_' + data.id, value: data.valueSv})
    }

    return <SimpleEditForm
        headline='I18n'
        writeRole={Role.SUPERADMIN}
        defaultValues={{}}
        loadFunction={loadFunction}
        saveFunction={saveFunction}
        removeFunction={removeFunction}
        initLeftFields={form => [
            <FormItemText key='id' name='id' label='ID' maxLength={64} required capitalize={false} {...form}/>,
            <FormItemTextArea key='valueEn' name='valueEn' label='English' maxLength={5000}
                              capitalize={false} {...form}/>,
            <FormItemTextArea key='valueSv' name='valueSv' label='Swedish' maxLength={5000}
                              capitalize={false} {...form}/>,
        ]}
    />
}

export default I18nEditForm;
