import {faQuestion} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import Tooltip from "../../../../components/Overlay/Tooltip";
import './AvatarWithHours.scss'
import Icon from "../../../../enums/Icon";

const AvatarWithHours = props => {
    const {
        label, employee, leftHours, rightHours, notarized, tooltipContent
    } = props

    const hours = leftHours + rightHours


    let initials = label || employee?.firstName
    if (!initials) {
        initials = <FontAwesomeIcon icon={faQuestion}
                                    className='faIcon'
                                    style={{
                                        // color: 'red',
                                        // fontSize: '16'
                                    }}
        />
    }

    const classNames = ['name']
    if (!notarized) {
        classNames.push('name-notarized')
    }
    // console.log("hours", label, hours, Number(hours) === Number(0))
    return <Tooltip value={tooltipContent}>
        <div className='AvatarWithHours'>

            {
                leftHours &&
                <div className='leftHours'>
                    <span>{leftHours}</span>
                </div>
            }

            <div className={classNames.join(' ')} style={{
                borderTopLeftRadius: !leftHours ? '10px' : undefined,
                borderBottomLeftRadius: !leftHours ? '10px' : undefined,
                borderTopRightRadius: !rightHours ? '10px' : undefined,
                borderBottomRightRadius: !rightHours ? '10px' : undefined,

            }}>
                {initials}
            </div>
            {
                rightHours &&
                <div className='rightHours'>
                    <span>{rightHours}</span>
                </div>
            }

            {
                !notarized && Number(hours) !== Number(0) &&
                <div className='unNotarized'>
                    <FontAwesomeIcon icon={Icon.UNNOTARIZE}
                                     className='faIcon'
                                     style={{
                                         color: 'red',
                                         fontSize: '12'
                                     }}
                    />
                </div>
            }
        </div>
    </Tooltip>
}

export default AvatarWithHours;
