import {Children, cloneElement, isValidElement} from "react";

export default function TabPanel(props) {
    const {
        active,
        children,
        updateQuantity
    } = props

    const style = {}
    if (active) {
        // style.display = 'block' //Fungerar inte med autosize!
        // style.visibility = 'visible'
        // style.opacity = '1'
    } else {
        // style.display = 'none'
        style.visibility = 'hidden'
        // style.opacity = '0'
        style.position = 'absolute'
        style.left = '-9999px';
        // style.width = '100%'

    }

    const childrenWithProps = Children.map(children, child => {
        if (isValidElement(child) && typeof child.type !== 'string') {
            return cloneElement(child, {updateQuantity: updateQuantity});
        }
        return child;
    })

    // return <div style={{position: 'relative', width: '100%'}}>
    //     <div style={style}>
    //         {children}
    //     </div>
    // </div>

    return <div style={style}>
        {childrenWithProps}
    </div>
}