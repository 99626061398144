import React from 'react';
import {Controller} from "react-hook-form";
import ReactQuill, {Quill} from "react-quill";
import {i18n} from "../../I18n/I18n";

const Block = Quill.import("blots/block");

Block.tagName = "DIV";
Quill.register(Block, true);

const FormItemRichText = props => {
    const {
        control,
        name,
        label,
        labelI18n,
        hidden
    } = props

    if (hidden) return undefined
    return <div className="formItem">
        <label htmlFor={name}>{labelI18n ? i18n(labelI18n) : label}</label>
        <Controller name="message"
                    control={control}
                    render={({field: {ref, value, onChange}}) =>
                        <ReactQuill theme="snow" value={value} onChange={onChange} modules={mods}/>
                    }/>
    </div>
}
export default FormItemRichText;


const mods = {
    clipboard: {
        matchVisual: false
    },
    keyboard: {
        bindings: {
            linebreak: {
                key: 13,
                handler: function (range, _context) {
                    this.quill.clipboard.dangerouslyPasteHTML(
                        range.index,
                        "<p><br/><br/></p>"
                    );
                }
            }
        }
    }
}
