import React from "react";
import {getValueByField} from "../../utils/ObjectUtils";
import Tooltip from "../Overlay/Tooltip";

export default function XlnzGridContentCell({col, row, data, cellClassName}) {
    const _cellClassName = !cellClassName ? undefined : cellClassName(row, {
        column: {
            props: {
                field: col.field
            }
        },
        props: {
            value: data
        },
        rowIndex: data.indexOf(row)
    })

    const classNames = getClassStringFromObject(_cellClassName, row)

    const result = <span
        style={{
            width: 'inherit',
            display: 'flex',
            justifyContent: 'inherit',
            alignItems: 'center'
        }}
        className={(classNames?.length > 0 ? classNames : '')}>

        {col.body && col.body(row, {
            column: col,
            field: col.field,
            rowIndex: data.indexOf(row),
            props: {
                value: data
            }
        })}

        {!col.body && getValueByField(row, col.field)}

    </span>

    if (col.tooltip) {
        return <Tooltip value={col.tooltip(row)} style={{
            width: '100%',
            height: '100%'
        }}>
            {result}
        </Tooltip>
    } else {
        return result
    }
}

function getClassStringFromObject(cellClassName, row) {
    if (!cellClassName) return ''
    return Object.keys(cellClassName)
        .filter(key => cellClassName[key] === true)
        .join(' ')
}