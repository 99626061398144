const isMatching = (model, gf) => {
    if (gf && gf.length >= 3) {
        gf = gf.toLowerCase();
        return Object.keys(model).find(key => {
                const value = model[key];

                switch (typeof value) {
                    case "string":
                        return value.toLowerCase().indexOf(gf) !== -1
                    case "number":
                        return Number(gf) === value
                    default:
                        return false
                }
            }
            /*            typeof model[key] === 'string' &&
                        model[key].toLowerCase().indexOf(gf) !== -1*/
        )
    }
    return true;
}

const SearchUtils = {
    isMatching
}

export default SearchUtils;

