import React from "react";
import FormItemText from "../../../../components/EditForm/FormItemText";
import {FORMROW_KEY_PREFIX} from "../FormanswerEditForm";

export default function FormtypeText(props) {
    const model = props.model
    return <FormItemText label={model.name}
                         name={FORMROW_KEY_PREFIX + model.id}
                         placeholder={model.example}
                         {...props}
    />
}