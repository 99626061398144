const UNANSWERED = 'UNANSWERED';
const ACCEPTED = 'ACCEPTED';
const NOT_ACCEPTED = 'NOT_ACCEPTED';

const LIST = [
    {
        value: UNANSWERED,
        label: "Obesvarad",
        labelMultiple: 'Obesvarade'
    },
    {
        value: ACCEPTED,
        label: "Accepterad",
        labelMultiple: 'Accepterade'
    }, {
        value: NOT_ACCEPTED,
        label: "Ej accepterad",
        labelMultiple: 'Ej accepterade'
    }
]

const TenderSentStatus = {
    UNANSWERED,
    ACCEPTED,
    NOT_ACCEPTED,

    LIST,

    getName: id => {
        const res = LIST.find(os => os.value === id);
        return res ? res.label : '';
    }
}
export default TenderSentStatus;