import I18n from '@I18n';
import React from "react";
import Tooltip from "../../../components/Overlay/Tooltip";
import {getDayInMonth, getHolidayName} from "../../../utils/DateUtils";
import './getDayHeader.scss'

export default function getDayHeader(date, i, locked) {
    const day = getDayInMonth(date);
    const holidayName = getHolidayName(date, true)

    const classNames = ['dayHeader']
    if (locked) classNames.push('locked')

    return <div className={classNames.join(' ')}>
        <Tooltip value={
            (holidayName || locked) &&
            <div>
                {holidayName}
                {locked ? <div>{I18n.t('day_locked')}</div> : ''}
            </div>
        }>
            <span>{day}</span>
        </Tooltip>
    </div>
}