import React, {useState} from "react";
import {useForm} from "react-hook-form";
import AppendixViewer from "../../../components/Appendix/AppendixViewer";
import Button, {CancelButton, MainButton} from "../../../components/Buttons/Button";
import {Thumbnail} from "../../../components/EditForm/FormItemAppendixGrid";
import FormItemEmail from "../../../components/EditForm/FormItemEmail";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import Spinner from "../../../components/Spinner/Spinner";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import DocumentType from "../../../enums/DocumentType";
import {GridTemplates} from "../../../GridTemplates";
import useDialog from "../../../hooks/useDialog";
import useMountEffect from "../../../hooks/useMountEffect";
import {i18n} from "../../../I18n/I18n";
import {pushArray} from "../../../utils/ArrayUtil";
import FormanswerService from "../../Formanswers/FormanswerService";
import ProjectService from "../ProjectService";
import ProjectdocumentEmailLogDialog from "./ProjectdocumentEmailLogDialog";

export const SendDocumentsDialog = props => {
    const {
        project,
        tender,
        toEmail,
        onHide,
        onSent
    } = props

    const defaultValues = {
        toEmail: toEmail
    }

    const form = {...useForm({defaultValues: defaultValues})}
    const {handleSubmit, formState: {isSubmitting}} = form
    const [selection, setSelection] = useState([])
    const dialog = useDialog()

    function prepareList(_list) {
        _list.push(...[
            {
                id: 0,
                type: 'KMA',
                name: DocumentType.KMA.name + '.pdf',
                fileSize: 0,
                pdfUrl: '/api/projects/' + project.id + '/kma-pdf',
                selected: false,
            },
        ].filter(c => !c.hide))

        pushArray(_list, project.appendixList.map(appendix => ({
            id: appendix.id,
            type: 'PROJECTAPPENDIX',
            name: appendix.name,
            fileSize: appendix.fileSize,
            pdfUrl: '/api/files/' + appendix.token,
            token: appendix.token,
            selected: false,
        })))

        if (tender?.appendixList?.length > 0) {
            pushArray(_list, tender.appendixList.map(appendix => ({
                id: appendix.id,
                type: 'TENDERAPPENDIX',
                name: appendix.name,
                fileSize: appendix.fileSize,
                pdfUrl: '/api/files/' + appendix.token,
                token: appendix.token,
                selected: false,
            })))
        }
    }

    useMountEffect(async () => {
        const _list = []

        const _formanswerList = await FormanswerService.findByProject(project.id)

        pushArray(_list, _formanswerList.map(item => ({
            id: item.id,
            type: 'FORMANSWER',
            name: item.name + '.pdf',
            // name: item.form.name + ' - ' + formatTime(item.ctime),
            fileSize: 0,
            pdfUrl: '/api/formanswers/' + item.id + '/pdf',
            selected: false,
        })))

        prepareList(_list)

        setList(_list)
    })

    const [list, setList] = useState()

    const onSubmit = async data => {
        const _documentList = selection
            .map(item => ({
                id: item.id,
                type: item.type,
            }))

        if (_documentList.length === 0) {
            onHide()
            return
        }

        showLoading()
        await ProjectService.sendDocumentsToCustomer(project.id, data.toEmail, _documentList)
        await onSent()
        onHide()
        hideLoading()
    }

    const fireChange = (list) => {
        setList(list);
    }

    const columns = [
        {
            field: 'thumbnail',
            width: 130,
            body: row => <Thumbnail appendix={row} onClick={e => {
                e.stopPropagation()
                renderOverlay(<AppendixViewer
                    imageList={list}
                    index={list.indexOf(row)}
                    onReload={fireChange}
                />)
            }}/>
        },
        {field: 'name', headerI18n: 'name'},
        {field: 'fileSize', headerI18n: 'size', body: GridTemplates.formatBytes, width: 100},
        {field: 'selection', selectionMode: 'multiple', width: 40},
    ]


    if (!list) return <Spinner/>
    return <>
        <XlnzDialog header={i18n('send_documents_to_customer')} onHide={onHide}
                    buttons={() => [
                        <Button key='email_log' labelI18n='email_log'
                                onClick={e => {
                                    dialog.show(<ProjectdocumentEmailLogDialog project={project}/>)
                                }}/>,
                        <MainButton key='send' labelI18n='send' onClick={e => handleSubmit(onSubmit)()}
                                    disabled={isSubmitting}/>,
                        <CancelButton key='cancel' onClick={e => onHide()}/>
                    ]}
        >
            <div className="EditForm" style={{width: '700px', margin: 0}}>
                <Grid xlnzGrid={true}
                      columns={columns}
                      value={list}
                      scrollable={true}
                      hideFooter={true}
                      heightOffset={370}
                      selection={selection}
                      onSelectionChange={e => setSelection(e.value)}
                      footerColumnGroup={<div/>}
                />
                <FormItemEmail name='toEmail' labelI18n='email' required {...form}/>
            </div>

        </XlnzDialog>
        {dialog.render()}
    </>
}
