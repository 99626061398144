import {faMapPin} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from 'react';
import Country from "../../enums/Country";
import RouterUtils from "../../utils/RouterUtils";
import StringUtils from "../../utils/StringUtil";
import {hideLoading, showLoading} from "../Overlay/Loading";
import './Address.scss';
import XlnzAutoComplete from "../XlnzAutoComplete/XlnzAutoComplete";
import AddressService from "./AddressService";

const Address = props => {
    const {
        value,
        disabled,
        onBlur
    } = props

    const DEFAULT_ADDRESS = {
        address: '',
        zip: 0,
        city: '',
        country: Country.SE.id
    }

    const _value = {...value}
    _value.label = StringUtils.formatAddress(_value)

    const [addressList, setAddressList] = useState([])

    async function searchAddressHandler(_addressQuery) {

        if (!_addressQuery || _addressQuery.length <= 4) return

        showLoading()
        const _addressList = await AddressService.search(_addressQuery)
        _addressList.forEach(item => item.label = StringUtils.formatAddress(item))
        setAddressList(_addressList)
        hideLoading()
    }

    function onChange(e) {
        const a = e.target.value

        if (!a) {
            props.onChange(DEFAULT_ADDRESS)
            return
        }

        const newValue = {..._value}
        newValue.address = a.address || DEFAULT_ADDRESS.address
        newValue.zip = a.zip || DEFAULT_ADDRESS.zip
        newValue.city = a.city || DEFAULT_ADDRESS.city
        newValue.country = a.country || DEFAULT_ADDRESS.country
        newValue.latLng = a.latLng
        newValue.label = StringUtils.formatAddress(newValue)

        setAddressList([])
        props.onChange(newValue)
    }

    return (
        <div className="AddressQuery">
            <XlnzAutoComplete
                field='label'
                disabled={disabled}
                className='addressSearch'
                delay={600}
                value={_value.label}
                suggestions={addressList}
                completeMethod={searchAddressHandler}
                onChange={onChange}
                onBlur={onBlur}
            />

            <div className='map-marker' onClick={e => {
                const q = StringUtils.formatAddress(value)
                const url = 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(q)
                RouterUtils.openTab(url)
            }}>
                <FontAwesomeIcon icon={faMapPin} className='faIcon'/>
            </div>
        </div>
    )
}
export default Address;