import React, {useContext, useState} from 'react';
import AppContext from "../../AppContext";
import {MainButton} from "../../components/Buttons/Button";
import {errorAlert, infoAlert} from "../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import XlnzCard from "../../components/XlnzCard/XlnzCard";
import Role from "../../enums/Role";
import useRoles from "../../hooks/useRoles";
import FortnoxAuthorizeButton from "./FortnoxAuthorizeButton";
import FortnoxService from "./FortnoxService";
import './FortnoxView.scss'

const FortnoxView = () => {
    const context = useContext(AppContext)
    const roles = useRoles()

    // const [authorizationCode, setAuthorizationCode] = useState('')
    const [accessToken, setAccessToken] = useState('')

    return <div className="FortnoxView">

        {
            roles.hasRole(Role.SUPERADMIN) &&
            <>
                {/*<div>*/}
                {/*    <XlnzCard title='Fortnox API-kod' subTitle='[SUPERADMIN]'>*/}
                {/*        <p>Det äldre sättet att koppla ihop.</p>*/}
                {/*        {*/}
                {/*            context.currentCompany.usingFortnox &&*/}
                {/*            <div className='activated'>*/}
                {/*                Det finns en aktiv koppling till Fortnox!*/}
                {/*            </div>*/}
                {/*        }*/}

                {/*        <InputText value={authorizationCode} onChange={e => setAuthorizationCode(e.target.value)}*/}
                {/*                   placeholder='Authorization Code'*/}
                {/*                   style={{width: '90%', textAlign: 'center'}}*/}
                {/*        />*/}
                {/*        <div>&nbsp;</div>*/}

                {/*        <MainButton label='Koppla ihop!' onClick={async () => {*/}
                {/*            if (authorizationCode.length === 0) {*/}
                {/*                errorAlert("API-koden kan inte vara tom")*/}
                {/*                return*/}
                {/*            }*/}
                {/*            showLoading()*/}
                {/*            const _resp = await FortnoxService.obtainAccessToken(authorizationCode);*/}
                {/*            if (_resp) {*/}
                {/*                infoAlert("Kopplingen genomförd!")*/}
                {/*            }*/}
                {/*            hideLoading()*/}
                {/*        }}*/}
                {/*        />*/}
                {/*    </XlnzCard>*/}

                {/*    <div>&nbsp;</div>*/}
                {/*</div>*/}
                <div>
                    <XlnzCard title='Fortnox Access-Token' subTitle='[SUPERADMIN]'>
                        <input value={accessToken} onChange={e => setAccessToken(e.target.value)}
                               placeholder='Access Token'
                               style={{width: '90%', textAlign: 'center'}}
                        />
                        <div>&nbsp;</div>

                        <MainButton label='Spara' onClick={async () => {
                            if (accessToken.length === 0) {
                                errorAlert("Fältet kan inte vara tomt.")
                                return
                            }
                            showLoading()
                            const _resp = await FortnoxService.manuallySetAccessToken(accessToken);
                            if (_resp) {
                                infoAlert("Access-Token uppdaterad!")
                            }
                            hideLoading()
                        }}
                        />
                    </XlnzCard>

                    <div>&nbsp;</div>
                </div>
            </>
        }

        <XlnzCard title='Fortnox-koppling'>
            {
                context.currentCompany.usingFortnox &&
                <div className='activated'>
                    Det finns en aktiv koppling till Fortnox!
                </div>
            }
            <FortnoxAuthorizeButton/>
        </XlnzCard>

    </div>
}

export default FortnoxView;
