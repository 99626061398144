import {Chart} from 'chart.js/auto';
import React, {useEffect, useRef} from 'react';

const XlnzChart = ({type, data, options, style}) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    useEffect(() => {
        if (chartRef.current) {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }

            chartInstance.current = new Chart(chartRef.current, {
                type: type,
                data: data,
                options: options,
            });
        }

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [type, data, options]);

    return (
        <canvas
            ref={chartRef}
            style={style}
        />
    );
};

export default XlnzChart;
