import React from "react";
import {i18n} from "../../I18n/I18n";
import ArrayUtil, {sum} from "../../utils/ArrayUtil";
import {formatNumber, NumberUtils} from "../../utils/NumberUtils";
import StringUtils from "../../utils/StringUtil";
import {numOfFreeRows} from "../Grid/freeRows";
import {FooterAggregate} from "../Grid/getFooterGroup";

export default function XlnzGridFooter({
                                           editable,
                                           columns,
                                           data,
                                           getColumnWidth
                                       }) {

    const getFooterColumns = (columns, list) => {


        return <>
            {
                columns.map((col, index) => {
                    const style = {...col.style} || {}
                    const colWidth = getColumnWidth(col)
                    style.minWidth = col.minWidth > 0 ? col.minWidth : colWidth
                    style.width = colWidth
                    style.maxWidth = colWidth
                    style.textAlign = col.align


                    return <div key={col.field} className='footer-cell' style={style}>
                        {getXlnzGridFooterValue(columns, list, col, index)}
                    </div>


                })
            }
        </>
    }

    return <div className='footer'>
        {getFooterColumns(columns, data)}
    </div>
}

export function getXlnzGridFooterValue(columns, list, col, index, format = true) {
    const hasPdf = () => columns[0].field === 'pdf'

    const subFromCount = numOfFreeRows(list)
    const count = list.length - (subFromCount !== list.length ? subFromCount : 0)

    let value
    if ((!hasPdf() && index === 0) || (hasPdf() && index === 1)) {
        value = i18n('total') + ' (' + count + ')'
    } else {
        switch (col.footer) {
            case FooterAggregate.SUM_DECIMALS_IF_NEEDED:
                if (format) value = NumberUtils.formatTwoDecimalsIfNeeded(ArrayUtil.sum(list, col.field), 0)
                else value = ArrayUtil.sum(list, col.field)
                break;
            case FooterAggregate.SUM_INTEGER:
                if (format) value = NumberUtils.format(ArrayUtil.sum(list, col.field), 0)
                else value = ArrayUtil.sum(list, col.field)
                break;
            case FooterAggregate.SUM_HOURS:
                if (format) value = NumberUtils.hours(ArrayUtil.sum(list, col.field), 0)
                else value = ArrayUtil.sum(list, col.field)
                break;
            case FooterAggregate.SUM_CURRENCY:
                if (format) value = NumberUtils.formatCurrency(ArrayUtil.sum(list, col.field), 0)
                else value = ArrayUtil.sum(list, col.field)
                break;
            case FooterAggregate.SUM_CURRENCY_TWO_DECIMALS:
                if (format) value = NumberUtils.formatCurrency(ArrayUtil.sum(list, col.field), 2)
                else value = ArrayUtil.sum(list, col.field)
                break;
            case FooterAggregate.SUM_CURRENCY_FOUR_DECIMALS:
                if (format) value = NumberUtils.formatCurrency(ArrayUtil.sum(list, col.field), 4)
                else value = ArrayUtil.sum(list, col.field)
                break;
            case FooterAggregate.SUM_WEIGHT:
                if (format) value = NumberUtils.formatWeight(ArrayUtil.sum(list, col.field), 0)
                else value = ArrayUtil.sum(list, col.field)
                break;
            case FooterAggregate.SUM_DURATION:
                if (format) value = StringUtils.formatDuration(ArrayUtil.sum(list, col.field))
                else value = ArrayUtil.sum(list, col.field)
                break;
            case FooterAggregate.MEAN:
                const val = sum(list, col.field)
                value = '\u0078\u0304 = ' + formatNumber(val / count, 0)
                break;
            default:
                value = ''
                break;
        }
    }
    return value
}