import React from 'react';
import {Controller} from "react-hook-form";
import {i18n} from "../../I18n/I18n";
import OptionalDatePicker from "../Date/OptionalDatePicker";
import Label from "./Label";

const FormItemOptionalDate = props => {
    const {
        control,
        disabled,
        name,
        label,
        labelI18n,
        onChange: customOnChange
    } = props

    return <div className="formItem">
        <Label htmlFor={name}>{labelI18n ? i18n(labelI18n) : label}</Label>
        <div style={{display: 'flex'}}>
            <Controller name={name}
                        control={control}
                        render={({field: {ref, value, onChange}}) => {
                            return <OptionalDatePicker value={value} onChange={_date => {
                                onChange(_date)
                                customOnChange && customOnChange(_date)
                            }} disabled={disabled}/>
                        }}/>
        </div>
    </div>
}
export default FormItemOptionalDate;