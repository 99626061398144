import {addDays, getWeek} from "../../../utils/DateUtils";
import './getDayHeader.scss'
import {WEEKPLANNING_NUM_OF_DAYS} from "./WeekplanningView";

const getWeekUberHeaders = (date) => {
    const weekColumns = []

    let weekCounter = getWeek(date);
    let numOfDaysCounter = 0;

    const weekArray = []

    for (let i = 0; i < WEEKPLANNING_NUM_OF_DAYS; i++) {
        const dateCounter = addDays(date, i)
        const week = getWeek(dateCounter)

        if (week !== weekCounter) {
            weekArray.push({
                startColumnField: addDays(dateCounter, -numOfDaysCounter),
                week: weekCounter,
                numOfDays: numOfDaysCounter
            })

            weekCounter = week
            numOfDaysCounter = 0
        }
        numOfDaysCounter++
    }
    weekArray.push({
        startColumnField: addDays(date, WEEKPLANNING_NUM_OF_DAYS - numOfDaysCounter),
        week: weekCounter,
        numOfDays: numOfDaysCounter
    })

    weekArray.forEach((item, index) => {
        weekColumns.push({
            header: 'v.' + item.week,
            style: {backgroundColor: index % 2 === 0 ? 'rgba(184, 207, 105, 0.2)' : 'rgba(184, 207, 105, 0.4)'},
            startColumnField: item.startColumnField,
            colSpan: item.numOfDays
        })
    })

    return weekColumns
}
export default getWeekUberHeaders