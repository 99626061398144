import Role from "../../enums/Role";
import TimeregallowancetypeEditForm from "./TimeregallowancetypeEditForm";
import TimeregallowancetypeGrid from "./TimeregallowancetypeGrid";

const TimeregallowancetypeIndex = {
    routeProps: {
        path: '/timeregallowancetypes',
        exact: true,
        element: <TimeregallowancetypeGrid/>,
    },
    nameKey: 'allowance_types',
    icon: 'pi pi-fw pi-folder',
    subModules: [{
        routeProps: {
            path: '/timeregallowancetypes/:id',
            exact: true,
            element: <TimeregallowancetypeEditForm/>,
        }
    }],
    roles: [Role.ADMIN]
};
export default TimeregallowancetypeIndex;