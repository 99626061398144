import {i18n} from "../I18n/I18n";
import {findByField, sortByFields} from "../utils/ArrayUtil";

export default class RentType {
    static get DAY() {
        return Object.freeze({id: 'DAY', name: i18n('calendar_day')});
    }

    static get BUILD_DAY() {
        return Object.freeze({id: 'BUILD_DAY', name: i18n('build_day')});
    }

    static get WEEK() {
        return Object.freeze({id: 'WEEK', name: i18n('week')});
    }

    static get MONTH() {
        return Object.freeze({id: 'MONTH', name: i18n('month')});
    }

    static values() {
        const result = [
            {id: this.DAY.id, name: this.DAY.name},
            {id: this.BUILD_DAY.id, name: this.BUILD_DAY.name},
            {id: this.WEEK.id, name: this.WEEK.name},
            {id: this.MONTH.id, name: this.MONTH.name}
        ]
        sortByFields(result, 'name')
        return result
    }

    static getName(id) {
        return findByField(this.values(), 'id', id).name
    }
}