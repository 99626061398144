import NetUtils from "../../utils/NetUtils";

const DocumentService = {
    findAll: () => NetUtils.doGet('/api/documents'),
    findById: id => NetUtils.doGet('/api/documents/' + id),
    update: (model, files) => NetUtils.doPostWithFiles('/api/documents', model, files),

    deletable: id => NetUtils.doGet('/api/documents/' + id + '/deletable'),
    delete: id => NetUtils.doDelete('/api/documents/' + id),

    getImageUrl: id => NetUtils.doGet('/api/documents/' + id + '/image-url'),
}
export default DocumentService;