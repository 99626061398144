import NetUtils from "../../utils/NetUtils";

const LeadService = {
    findAll: () => NetUtils.doGet('/api/leads'),

    findByActive: async () => NetUtils.doGet('/api/leads').then(customerList => customerList.filter(c => c.active)),

    findById: id => NetUtils.doGet('/api/leads/' + id),
    update: (model, files) => NetUtils.doPostWithFiles('/api/leads', model, files),

    exists: regNo => NetUtils.doGet('/api/leads/exists/' + regNo),
}
export default LeadService;