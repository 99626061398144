import React from 'react';
import FormItemCheckbox from "../../components/EditForm/FormItemCheckbox";
import FormItemText from "../../components/EditForm/FormItemText";
import SimpleEditForm from "../../components/EditForm/SimpleEditForm";
import Role from "../../enums/Role";
import {PreloadType} from "../../hooks/usePreload";
import CostcenterService from "./CostcenterService";

const CostcenterEditForm = () => {
    return <SimpleEditForm
        writeRole={Role.ADMIN}
        headlineI18n='costcenter'
        defaultValues={{
            active: true
        }}
        loadFunction={CostcenterService.findById}
        saveFunction={CostcenterService.update}
        initLeftFields={form => [
            <FormItemText key='number' name='number' labelI18n='number' maxLength={32} required {...form}/>,
            <FormItemText key='name' name='name' labelI18n='name' maxLength={128} required {...form}/>,
            <FormItemCheckbox key='active' name='active' labelI18n='active' {...form}/>
        ]}
        preloadType={PreloadType.COSTCENTER_LIST}
    />
}

export default CostcenterEditForm;
