import {findByField} from "../utils/ArrayUtil";
import {i18n} from "../I18n/I18n";

export default class BillingType {
    static get FIXED() {
        return Object.freeze({id: 'FIXED', name: i18n('fixed')});
    }

    static get COSTPLUS() {
        return Object.freeze({id: 'COSTPLUS', name: i18n('costplus')});
    }

    static get BOTH() {
        return Object.freeze({
            id: 'BOTH',
            name: i18n('fixed') + ' & ' + i18n('costplus').toLowerCase()
        })
    }

    static values() {
        const result = [
            this.FIXED,
            this.COSTPLUS,
            this.BOTH,
        ];
        return result
    }

    static findById(id) {
        return findByField(this.values(), 'id', id)
    }

    static getName(id) {
        if (!id) return ''
        return findByField(this.values(), 'id', id)?.name
    }
}