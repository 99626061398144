import TaxReductionType from "../../../enums/TaxReductionType";
import VatType from "../../../enums/VatType";
import ArrayUtil, {findByField, sortByField} from "../../../utils/ArrayUtil";
import {NumberUtils} from "../../../utils/NumberUtils";

export default class InvoiceEntity {
    vatType
    rowList
    houseworkList
    taxReductionType
    houseworkType

    constructor(vatType, rowList, houseworkList, taxReductionType, houseworkType) {
        this.vatType = vatType ? vatType : VatType.SEVAT.id
        this.rowList = rowList
        this.houseworkList = houseworkList
        this.taxReductionType = taxReductionType
        this.houseworkType = houseworkType
    }

    // static ofObject(obj) {
    //     const result = new Order()
    //     Object.assign(result, obj)
    //
    //     for (let i = 0; i < result.rowList.length; i++) {
    //         const objRow = result.rowList[i];
    //         objRow.article = Object.assign(new Article(), objRow.article)
    //         result.rowList[i] = Object.assign(new Orderrow(), objRow)
    //     }
    //     return result
    // }

    get priceExclVat() {
        return ArrayUtil.sum(this.rowList, 'price')
    }

    get vatPrice() {
        switch (this.vatType) {
            case VatType.SEVAT.id:
                return this.vatPrices.reduce((sum, item) => sum + item.price, 0)
            default:
                return 0
        }
    }

    get vatPrices() {
        switch (this.vatType) {
            case VatType.SEVAT.id:
                const result = []

                this.rowList.forEach(row => {
                    const vat = row.vat !== undefined ? row.vat : 25

                    const vatPrice = NumberUtils.round(row.price * (vat / 100), 2)

                    if (!isNaN(vatPrice) && vatPrice !== 0) {
                        const existingItem = findByField(result, 'vat', vat)
                        if (existingItem) {
                            existingItem.price += vatPrice
                        } else {
                            result.push({
                                vat: vat,
                                price: vatPrice
                            })
                        }
                    }
                })

                sortByField(result, 'vat')
                return result
            default:
                return []
        }
    }

    get taxReductionPercentage() {
        const taxReductionTypeObj = TaxReductionType.findById(this.taxReductionType)
        if (!taxReductionTypeObj) return 0
        const houseworkTypeObj = findByField(taxReductionTypeObj.houseworkTypeList, 'id', this.houseworkType)
        return houseworkTypeObj?.reductionPercentage || taxReductionTypeObj.reductionPercentage
    }

    get houseworkPrice() {
        const houseRowsRows = this.rowList.filter(item => item.housework)
        return Math.floor(ArrayUtil.sum(houseRowsRows, 'price') * 1.25 * this.taxReductionPercentage)
    }

    get houseworkClaimedPrice() {
        if (this.houseworkList) {
            return ArrayUtil.sum(this.houseworkList, 'amount')
        } else {
            return this.houseworkPrice
        }
    }

    get priceInclVat() {
        return NumberUtils.round(this.priceExclVat + this.vatPrice, 2)
    }

    get pennyRoundingPrice() {
        return NumberUtils.round(this.payPrice - this.priceExclVat - this.vatPrice + this.houseworkClaimedPrice, 2)
    }

    get payPrice() {
        return NumberUtils.round(this.priceExclVat + this.vatPrice - this.houseworkClaimedPrice, 0)
    }

    // get toPay() {
    //     if (this.hasArticlesWithQuantity) {
    //         const price = ArrayUtils.sum(this.rowList, 'price')
    //         let result = price
    //         result += this.vat12Price
    //         result += this.vat25Price
    //         return NumberUtils.round(result, 0)
    //     } else {
    //         return 0
    //     }
    // }
    //
    // toDto() {
    //     return {
    //         warehouseDelivery: this.warehouseDelivery,
    //         customerNote: this.customerNote,
    //         rowList: this.rowList
    //             .filter(or => or.quantity > 0)
    //             .map((or, index) => (or.toDto(index)))
    //     }
    // }
}