import React, {useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Language from "../../enums/Language";
import Role from "../../enums/Role";
import Userprop from "../../enums/Userprop";
import {matchSearch} from "../../functions/match";
import useMountEffect from "../../hooks/useMountEffect";
import usePreload from "../../hooks/usePreload";
import useUserPersmissions from "../../hooks/useUserPersmissions";
import {i18n} from "../../I18n/I18n";
import {findByField, findById} from "../../utils/ArrayUtil";
import UserService from "./UserService";

const UserGrid = () => {
    const userPersmissions = useUserPersmissions()
    const preload = usePreload()
    const usergroupList = preload.getUsergroupList()

    const filter = useFilter('UserGrid', [
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);
    const filteredList = list.filter(c => matchSearch(filter, c))

    filteredList.forEach(item => {
        item.roleSetString = item.roleSet
            .map(roleName => userPersmissions.getRoleName(userPersmissions.findRoleByName(roleName)))
            .sort()
            .join(', ')

    })

    useMountEffect(async () => {
        showLoading()
        const _list = await UserService.findAll()
        _list.forEach(item => {
            item.languageName = Language.findById(item.language)?.name
            item.usergroupLabel = findById(usergroupList, item.usergroup)?.name
        })
        setList(_list)
        hideLoading()
    })

    const leftFilters = [
        userPersmissions.hasRole(Role.USER_WRITE) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
    ]

    const columns = [
        {field: 'name', headerI18n: 'name', width: 150},
        {field: 'email', headerI18n: 'email', width: 250, mobile: true},
        {field: 'languageName', headerI18n: 'language', width: 70, mobile: true},
        {field: 'usergroupLabel', headerI18n: 'user_group', width: 150, mobile: true},
        {
            field: 'hideArticlePrices', headerI18n: 'hide_article_prices',
            body: row => {
                const hideArticlePrices = findByField(row.propList, 'name', Userprop.HIDE_ARTICLE_PRICES.name)
                return hideArticlePrices?.value === '1' ? i18n('yes') : ''
            },
            width: 120
        },
        {
            field: 'roleSetString', headerI18n: 'roles',
            style: {flexDirection: 'column', alignItems: 'flex-start'}
        }
    ];

    return <Grid
        xlnzGrid={true}
        labelI18n='users'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
    />
}

export default UserGrid;
