import React, {useState} from "react";
import {useForm, useWatch} from "react-hook-form";
import {CancelButton, MainButton} from "../../../../components/Buttons/Button";
import FormItemCheckbox from "../../../../components/EditForm/FormItemCheckbox";
import {useFilter} from "../../../../components/Grid/filter";
import {FooterAggregate} from "../../../../components/Grid/getFooterGroup";
import Grid from "../../../../components/Grid/Grid";
import XlnzDialog from "../../../../components/XlnzDialog/XlnzDialog";
import {GridTemplates} from "../../../../GridTemplates";
import {i18n} from "../../../../I18n/I18n";
import {sortByField} from "../../../../utils/ArrayUtil";

const FetchCostplusWorkDialog = props => {
    const filter = useFilter('FetchCostplusWorkDialog', [
        {name: 'showTitle', defaultValue: true},
        {name: 'separateDates', defaultValue: true},
        {name: 'separateEmployees', defaultValue: false},
        {name: 'includeEmployeeComments', defaultValue: false},
    ])

    const {
        timeregList,
        timeregarticleList,
        onSave
    } = props
    const [selection, setSelection] = useState([])
    const _selection = []

    const joinedList = []
    timeregList.forEach(item => {
        const _item = {...item}
        _item.type = "timereg"
        _item.typeName = item.debittypeName
        _item.quantity = item.debitHours
        _item.aprice = item.timeregdebittypeprice
        joinedList.push(_item)
    })
    timeregarticleList.forEach(item => {
        const _item = {...item}
        _item.type = "timeregarticle"
        _item.typeName = item.name
        joinedList.push(_item)
    })
    sortByField(joinedList, 'date')

    joinedList.forEach(_item => {
        if (selection.find(sel => sel.id === _item.id && sel.type === _item.type)) {
            _selection.push(_item)
        }
    })


    const defaultValues = {
        showTitle: filter.showTitle,
        separateDates: filter.separateDates,
        separateEmployees: filter.separateEmployees,
        includeEmployeeComments: filter.includeEmployeeComments,
    }

    const form = {...useForm({defaultValues: defaultValues})}
    const {handleSubmit, formState: {isSubmitting}, getValues, control} = form

    const watchSeparateDates = useWatch({control, name: 'separateDates'})

    const onSubmit = () => {
        const data = getValues();
        filter.update('showTitle', data.showTitle)
        filter.update('separateDates', data.separateDates)
        filter.update('separateEmployees', data.separateEmployees)
        filter.update('includeEmployeeComments', data.includeEmployeeComments)
        onSave(_selection, {
            showTitle: data.showTitle,
            separateDates: data.separateDates,
            separateEmployees: data.separateEmployees,
            includeEmployeeComments: data.includeEmployeeComments
        })
    }

    const columns = [
        {field: 'date', headerI18n: 'date', width: 90},
        {field: 'employeeName', headerI18n: 'employee'},
        {field: 'typeName', header: i18n('debit_type') + '/' + i18n('article').toLowerCase()},
        {
            field: 'quantity', headerI18n: 'quantity', body: (rowData, column) => {
                switch (rowData.type) {
                    case 'timereg':
                        return GridTemplates.hours(rowData, column)
                    case 'timeregarticle':
                        return GridTemplates.numberTwoDecimalsIfNeeded(rowData, column)
                    default:
                        return ''
                }
            },
            align: 'right',
            width: 80
        },
        {
            field: 'aprice', headerI18n: 'aprice', body: GridTemplates.currency, align: 'right',
            width: 80
        },
        {
            field: 'discount', headerI18n: 'discount', body: GridTemplates.percentTimes100, align: 'right', width: 50
        },
        {
            field: 'price', headerI18n: 'price', body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            align: 'right',
            width: 80
        },
        {field: 'workDone', headerI18n: 'work_done'},
        {field: 'employeeComment', headerI18n: 'comment_from_employee', width: 300},
        {field: 'selection', selectionMode: 'multiple', width: 40},
    ]

    return <XlnzDialog headerI18n='costplus_time'
                       onHide={props.onHide}
                       style={{maxWidth: '100%', width: '1200px'}}
                       leftButtons={() => [
                           <FormItemCheckbox key='showTitle' name='showTitle' labelI18n='show_title' {...form}/>,
                           <FormItemCheckbox key='separateDates' name='separateDates'
                                             labelI18n='separate_dates' {...form}/>,
                           <FormItemCheckbox key='separateEmployees' name='separateEmployees'
                                             labelI18n='separate_employees' {...form}/>,
                           !watchSeparateDates ? undefined :
                               <FormItemCheckbox key='includeEmployeeComments' name='includeEmployeeComments'
                                                 labelI18n='include_employee_comments' {...form}/>,
                       ]}
                       buttons={() => [
                           <MainButton key='do_invoice' labelI18n='do_invoice' onClick={e => handleSubmit(onSubmit)()}
                                       disabled={isSubmitting}/>,
                           <CancelButton key='cancel' onClick={e => props.onHide()}/>
                       ]}
    >

        <div className="EditForm">
            <Grid xlnzGrid={true}
                  forceDesktop={true}
                  columns={columns}
                  value={joinedList}
                  heightOffset={370}
                  selection={_selection}
                  onSelectionChange={e => setSelection(e.value)}
            />
        </div>

    </XlnzDialog>
}
export default FetchCostplusWorkDialog