import {faPlus} from "@fortawesome/free-solid-svg-icons";
import React, {useRef, useState} from 'react';
import Button from "../../../../../components/Buttons/Button";
import CheckboxEditor from "../../../../../components/Grid/CheckboxEditor";
import EmployeeSelectEditor from "../../../../../components/Grid/EmployeeSelectEditor";
import {isFreeRow} from "../../../../../components/Grid/freeRows";
import Grid from "../../../../../components/Grid/Grid";
import NumberGridInput from "../../../../../components/Grid/NumberGridInput";
import onRowChange from "../../../../../components/Grid/onRowChange";
import RemoveColumn from "../../../../../components/Grid/RemoveColumn";
import SelectEditor from "../../../../../components/Grid/SelectEditor";
import TextGridInput from "../../../../../components/Grid/TextGridInput";
import {hideLoading, showLoading} from "../../../../../components/Overlay/Loading";
import XlnzMenu from "../../../../../components/XlnzMenu/XlnzMenu";
import {GridTemplates} from "../../../../../GridTemplates";
import useEmployees from "../../../../../hooks/useEmployees";
import useMountEffect from "../../../../../hooks/useMountEffect";
import usePreload, {PreloadType} from "../../../../../hooks/usePreload";
import ArrayUtil, {filterActive, findById} from "../../../../../utils/ArrayUtil";
import {NumberUtils} from "../../../../../utils/NumberUtils";
import WorkcrewService from "../../../../Workcrews/WorkcrewService";

const TimeregGrid = props => {
    const preload = usePreload()
    const rowList = props.value;
    const disabled = props.disabled;
    const date = props.date;
    const invoicerowtimeregList = props.invoicerowtimeregList;

    const employees = useEmployees()
    const salarytypeList = preload.get(PreloadType.TIMEREGSALARYTYPE_LIST)
    const debittypeList = preload.get(PreloadType.TIMEREGDEBITTYPE_LIST)

    const workcrewMenuRef = useRef()
    const [workcrewList, setWorkcrewList] = useState()

    useMountEffect(async () => {
        showLoading()
        setWorkcrewList(await WorkcrewService.findAll())
        hideLoading()
    })

    const fireChange = props.onChange

    const getEmployee = id => employees.findById(id)
    const getSalarytype = id => findById(salarytypeList, id)
    const getDebittype = id => findById(debittypeList, id)

    const onSalaryHoursChange = (editorEvent, value) => {
        const field = editorEvent.field
        const rowData = editorEvent.rowData
        value = NumberUtils.round(Number(value), 2);

        let salarytype = getSalarytype(rowData.salarytype)
        if (!salarytype) {
            salarytype = filterActive(salarytypeList)[0]
            updateCustomFieldValue(editorEvent, 'salarytype', salarytype.id)
        }

        if (!isInvoiced(rowData) && field === 'costplusHours' && value > 0) {
            updateCustomFieldValue(editorEvent, 'debitHours', value)
            updateCustomFieldValue(editorEvent, 'debittype', getDebittype(salarytype.debittype).id)
        }

        updateCustomFieldValue(editorEvent, 'notarized', true)

        onRowChange(editorEvent, value, fireChange)
    }

    const onSalarytypeChange = (editorEvent, value) => {
        if (!isInvoiced(editorEvent.rowData)) updateDebittype(editorEvent, getSalarytype(value)?.debittype)
        onRowChange(editorEvent, value, fireChange)
    }

    const onDebitHoursChange = (editorEvent, value) => {
        value = NumberUtils.round(Number(value), 2);
        if (!editorEvent.rowData.debittype) {
            updateDebittype(editorEvent, value === -0 ? undefined : debittypeList[0].id)
        }
        onRowChange(editorEvent, value, fireChange)
    }

    const updateDebittype = (editorEvent, debittype) => {
        const salarytypeEditorEvent = Object.assign({}, editorEvent)
        salarytypeEditorEvent.field = 'debittype'
        onRowChange(salarytypeEditorEvent, debittype, fireChange)
    }

    const updateCustomFieldValue = (editorEvent, field, value) => {
        const salarytypeEditorEvent = Object.assign({}, editorEvent)
        salarytypeEditorEvent.field = field
        onRowChange(salarytypeEditorEvent, value, fireChange)
    }

    const columns = [
        {
            field: 'employee', headerI18n: 'employee',
            body: row => GridTemplates.onelineStringByValue(getEmployee(row.employee)?.name, 150),
            editor: e => <EmployeeSelectEditor event={e}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
        },
        {
            field: 'fixedHours', headerI18n: 'fixed_time',
            body: GridTemplates.numberTwoDecimalsIfNeeded,
            editor: e => <NumberGridInput editorEvent={e} min={0} max={999}/>,
            onCellEditComplete: e => onSalaryHoursChange(e, e.newValue),
            width: 80,
            align: 'right'
        },
        {
            field: 'costplusHours', headerI18n: 'costplus_time',
            body: GridTemplates.numberTwoDecimalsIfNeeded,
            editor: e => <NumberGridInput editorEvent={e} min={0} max={999}/>,
            onCellEditComplete: e => onSalaryHoursChange(e, e.newValue),
            width: 80,
            align: 'right'
        },
        {
            field: 'salarytype', headerI18n: 'salary_type',
            body: row => GridTemplates.onelineStringByValue(getSalarytype(row.salarytype)?.name, 100),
            editor: disabled ? undefined : e =>
                <SelectEditor event={e} options={ArrayUtil.active(salarytypeList)} optionValue='id'
                              onChange={e2 => e.editorCallback(e2?.value)}/>,
            onCellEditComplete: e => onSalarytypeChange(e, e.newValue),
            width: 100,
        },
        {
            field: 'debitHours', headerI18n: 'debit_time',
            body: GridTemplates.numberTwoDecimalsIfNeeded,
            editor: e => isInvoiced(e.rowData)
                ? NumberUtils.formatTwoDecimalsIfNeeded(e.rowData.debitHours)
                : <NumberGridInput editorEvent={e} min={0} max={999}/>,
            onCellEditComplete: e => onDebitHoursChange(e, e.newValue),
            width: 100,
            align: 'right'
        },
        {
            field: 'debittype', headerI18n: 'debit_type',
            body: row => GridTemplates.onelineStringByValue(getDebittype(row.debittype)?.name, 100),

            editor: disabled ? undefined :
                e => isInvoiced(e.rowData) ? getDebittype(e.rowData.debittype)?.name :
                    <SelectEditor event={e} options={ArrayUtil.active(debittypeList)} optionValue='id'
                                  onChange={e2 => e.editorCallback(e2?.value)}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 100,
        },
        {
            field: 'note', headerI18n: 'note',
            editor: e => <TextGridInput editorEvent={e} maxLength={500}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 150,
        },
        {
            field: 'notarized', headerI18n: 'notarized',
            body: (rowData, e) => isFreeRow(rowList, rowData)
                ? ''
                : CheckboxEditor(Object.assign(e, {rowData: rowData}), fireChange, disabled || isInvoiced(rowData)),
            rendererIsEditor: true,
            width: 80,
            align: 'center'
        },
        {
            field: 'employeeComment', headerI18n: 'comment_from_employee',
            editor: e => <TextGridInput editorEvent={e} maxLength={500}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 150,
        },
        RemoveColumn(row => props.onChange(rowList.filter(r => r !== row)), 0,
            row => !disabled && !isInvoiced(row))
    ].filter(c => !c.hide);

    function isInvoiced(timereg) {
        return invoicerowtimeregList?.find(item => item.timereg === timereg.id) !== undefined
    }

    return <div className='TimeregGrid'>
        <Grid xlnzGrid={true}
              forceDesktop={true}
              disabled={disabled}
              columns={columns}
              value={rowList}
              sortable={false}
              scrollable={false}
        />
        {!disabled &&
            <>
                <div style={{display: 'flex', gap: '10px', marginTop: '15px'}}>
                    <Button faicon={faPlus} labelI18n='employee' onClick={e => {
                        props.onChange(rowList.concat([{
                            date: date,
                            fixedHours: 0,
                            costplusHours: 0,
                            debitHours: 0,
                            note: '',
                            employeeComment: '',
                        }]));
                    }
                    }/>


                    {
                        workcrewList?.length > 0 &&
                        <>
                            <XlnzMenu model={workcrewList.map(item => ({
                                    label: item.name,
                                    command: () => {
                                        const newList = item.employeeIdList.map(eId => ({
                                            date: date,
                                            employee: eId,
                                            fixedHours: 0,
                                            costplusHours: 0,
                                            debitHours: 0,
                                            note: '',
                                            employeeComment: '',
                                        }))
                                        props.onChange(rowList.concat(newList));
                                    }
                                })
                            )}
                                      ref={el => workcrewMenuRef.current = el}/>
                            <Button faicon={faPlus} labelI18n='work_crew'
                                    onClick={e => workcrewMenuRef.current.toggle(e)}/>
                        </>
                    }

                </div>
            </>
        }
    </div>

}
export default TimeregGrid;