import React, {useState} from 'react';
import Label from "../../components/EditForm/Label";
import ActiveFilter from "../../components/Grid/ActiveFilter";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Language from "../../enums/Language";
import {matchActive, matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useMountEffect from "../../hooks/useMountEffect";
import UserService from "../Users/UserService";

const AllUsersGrid = () => {
    const filter = useFilter('AllUsersGrid', [
        {name: 'active', defaultValue: true},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);
    const filteredList = list.filter(c => matchActive(filter, c) && matchSearch(filter, c))

    useMountEffect(async () => {
        showLoading()
        const _list = await UserService.findAllUsers()
        _list.forEach(item => {
            item.languageName = Language.findById(item.language)?.name
        })
        setList(_list.filter(item => item.id !== 1))
        hideLoading()
    })

    const leftFilters = [
        <div>
            <Label valueI18n='status'/>
            <ActiveFilter value={filter.active} onChange={e => filter.update('active', e.value)}/>
        </div>
    ]

    const columns = [
        {field: 'name', headerI18n: 'name', width: 150},
        {field: 'email', headerI18n: 'email', width: 250},
        {field: 'languageName', headerI18n: 'language', width: 70, mobile: true},
        {field: 'ctime', headerI18n: 'created', body: GridTemplates.time, width: 140},
        {field: 'lastLoginTime', header: 'Senast inloggad', body: GridTemplates.time, width: 140},
        {field: 'agreementCustomerListCsv', headerI18n: 'agreement', body: GridTemplates.csvToColumn, width: 220},
        {field: 'companyListString', headerI18n: 'companies', body: GridTemplates.csvToColumn, minWidth: 250},
    ]


    return <Grid
        forceDesktop={true}
        labelI18n='all_users'
        filter={filter}
        leftFilters={leftFilters}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
    />
}

export default AllUsersGrid;