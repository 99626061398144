import {InputSwitch} from "primereact/inputswitch";
import {Sidebar} from "primereact/sidebar";
import React, {useEffect, useState} from "react";
import {CloseButton, DownloadButton, ImagePreviewButton, RemoveButton} from "../../../components/Buttons/Button";
import FileUpload from "../../../components/FileUpload/FileUpload";
import {confirmYes} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import PdfViewer from "../../../components/Appendix/PdfViewer";
import useWindowSize from "../../../hooks/useWindowSize";
import {i18n} from "../../../I18n/I18n";
import NetUtils from "../../../utils/NetUtils";
import SupplierinvoiceService from "../SupplierinvoiceService";
import './SupplierinvoiceImageSidebar.scss'

const SupplierinvoiceImageSidebar = props => {
    const {
        save,
        form
    } = props

    const [visibleRight, setVisibleRight] = useState(false)
    const [imageUrl, setImageUrl] = useState()
    const [enableZoom, setEnableZoom] = useState()

    const [width] = useWindowSize()
    const isUsingSidebar = width < 1800

    const [
        id,
        fileSize
    ] = form?.getValues(['id', 'fileSize'])

    useEffect(() => {
        if (fileSize > 0) {
            SupplierinvoiceService.getImageUrl(id).then(_resp => {
                setImageUrl(_resp.url)
            })
        }
    }, [fileSize, id])


    const sidebarContent = <>
        <div className='button-row'>
            <div className='left-buttons'>
                {
                    isUsingSidebar &&
                    <CloseButton onClick={() => setVisibleRight(false)}/>
                }
                {
                    !isUsingSidebar &&
                    <div className='enable-zoom'>
                        <label>{i18n('enable_zoom')}</label>
                        <InputSwitch checked={enableZoom} onChange={(e) => setEnableZoom(e.value)}/>
                    </div>
                }

            </div>
            <div className='divider'></div>
            <div className='right-buttons'>

                {
                    // fileSize > 0 &&
                    // <Button labelI18n='open_in_a_new_window'
                    //         onClick={() => {
                    //             setVisibleRight(false)
                    //             const _height = window.innerHeight * 0.95
                    //             const newwindow = window.open('/?pdfurl=' + btoa(imageUrl),
                    //                 'pdf-viewer',
                    //                 'height=' + _height + '' +
                    //                 ',width=950' +
                    //                 ',popup=true' +
                    //                 ',toolbar=0' +
                    //                 ',menubar=0' +
                    //                 ',location=0');
                    //             if (window.focus) {
                    //                 newwindow.focus()
                    //             }
                    //             return false;
                    //         }}
                    // />
                }
                {
                    fileSize > 0 &&
                    <DownloadButton labelI18n='download'
                                    onClick={async () => {
                                        showLoading()
                                        NetUtils.downloadFile(imageUrl).then(() => hideLoading())
                                    }}
                    />
                }
                {
                    fileSize > 0 &&
                    <RemoveButton onClick={e => {
                        confirmYes(() => {
                            const data = form.getValues()

                            showLoading()
                            delete data.fileSize
                            save(data).then(_model => {
                                form.reset(_model);
                                hideLoading()
                            })
                        })
                    }}/>
                }
            </div>
        </div>

        <div className='SupplierinvoiceImageSidebar-container'>
            {
                fileSize > 0 &&
                <div className='pdf-viewer-container'>
                    <PdfViewer url={imageUrl} enableZoom={!isUsingSidebar && enableZoom}/>
                </div>
            }
            {
                !fileSize &&
                <FileUpload multiple={false} save={save} {...form}/>
            }
        </div>
    </>

    return <div className='SupplierinvoiceImageSidebar'>
        {
            !isUsingSidebar &&
            sidebarContent
        }

        {
            isUsingSidebar &&
            <>
                {
                    !visibleRight &&
                    <ImagePreviewButton onClick={e => {
                        e.preventDefault()
                        setVisibleRight(true)
                    }}/>
                }
                <Sidebar visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}
                         modal={false}
                         style={{width: '1000px', zIndex: '999999'}}
                         showCloseIcon={false}
                         dismissable={false}
                         className='SupplierinvoiceImageSidebar'>
                    {sidebarContent}
                </Sidebar>
            </>
        }
    </div>
}
export default SupplierinvoiceImageSidebar