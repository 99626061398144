import React from 'react';
import Button from "../../../../components/Buttons/Button";
import DateEditor from "../../../../components/Grid/DateEditor";
import Grid from "../../../../components/Grid/Grid";
import onRowChange from "../../../../components/Grid/onRowChange";
import RemoveColumn from "../../../../components/Grid/RemoveColumn";
import Icon from "../../../../enums/Icon";
import Role from "../../../../enums/Role";
import useRoles from "../../../../hooks/useRoles";
import {formatDate, todayDate} from "../../../../utils/DateUtils";

const PaymentplanDrawDateGrid = props => {
    const {
        value,
        onChange,
        disabled,
        draws
    } = props
    const roles = useRoles()

    const rowList = value.map((date, index) => ({
        num: index + 1,
        value: date
    }))
    // console.log("rowList", rowList)
    // useEffect(() => {
    //     if (value.length < numOfDraws) {
    //         const _value = deepCopy(value)
    //         const numToAdd = numOfDraws - value.length
    //         for (let i = 0; i < numToAdd; i++) _value.push(todayDate())
    //         onChange(_value)
    //     } else if (value.length > numOfDraws) {
    //         onChange(trimArray(value, numOfDraws))
    //     }
    // }, [value, onChange]);

    const fireChange = (list) => {
        onChange(list.map(row => row.value));
    }

    function onRemove(row) {
        const _rowList = rowList.filter(r => r !== row)
        if (_rowList.length === 0) {
            _rowList.push({
                value: formatDate()
            })
        }
        fireChange(_rowList)
    }

    const columns = [
        {field: 'num', header: '#', width: 30},
        {
            field: 'value', headerI18n: 'invoice_date',
            editor: DateEditor,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
        },
        RemoveColumn(onRemove,
            0,
            rowData => !isRowDataInvoiced(rowData)
        )
    ]


    function isRowDataInvoiced(rowData) {
        const drawIndex = rowData.num - 1
        const invoicerowpaymentplanrowList = draws.flatMap(item => item.invoicerowpaymentplanrowList)
        const irprList = invoicerowpaymentplanrowList.filter(item => item.drawIndex === drawIndex)
        return irprList.length > 0
    }

    return <div style={{width: '180px'}}>
        <Grid
            xlnzGrid={true}
            scrollable={true}
            disabled={disabled}
            heightOffset={500}
            columns={columns}
            value={rowList}
            footerColumnGroup={<div/>}
            sortable={false}
            hideFooter={true}
        />
        {
            roles.hasRole(Role.PROJECT_WRITE) &&
            <div style={{paddingTop: '10px'}}>
                <Button faicon={Icon.ADD} onClick={e => {
                    onChange([...value, todayDate()])
                }}/>
            </div>
        }
    </div>
}
export default PaymentplanDrawDateGrid;
