import {cloneElement} from "react";
import {createRoot} from "react-dom/client";
import '../../App.scss'
import makeId from "../../functions/makeId";

export default function renderOverlay(component) {
    const renderContainer = document.createElement('div')
    const id = makeId()

    renderContainer.setAttribute('id', id)

    document.body.appendChild(renderContainer)
    const root = createRoot(renderContainer)
    const unmountModal = () => {
        root.unmount()
        renderContainer.parentNode.removeChild(renderContainer)
        if (component.props.onClose) component.props.onClose()
    }

    const props = {}
    if (component.type !== 'div') {
        props.onHide = unmountModal
    }

    root.render(cloneElement(component, props))

    return id
}

export function removeOverlay(elementId) {
    const element = document.getElementById(elementId)
    if (element) document.body.removeChild(element)
}
