import {useState} from "react";
import {move} from "../../utils/ArrayUtil";

export default function useXlnxGridDragAndDrop(data, setDraggedDropIndex, onRowReorder) {
    const [draggedRow, setDraggedRow] = useState()

    if (!onRowReorder) return undefined

    const getRowIndex = e => {
        let result = e.target.getAttribute('custom-rowindex')
        if (!result) {
            result = e.target.parentElement.getAttribute('custom-rowindex')
        }
        return Number(result);
    }

    return {
        draggable: true,
        onDragStart: e => {
            const rowIndex = getRowIndex(e)
            setDraggedRow(data[rowIndex])
        },
        onDragOver: e => {
            if (draggedRow) {
                e.stopPropagation()
                e.preventDefault();
                const rowIndex = getRowIndex(e)
                // console.log("draggedRow", rowIndex, draggedRow)
                setDraggedDropIndex(rowIndex)
            }
        },
        onDrop: e => {
            e.preventDefault();
            const rowIndex = getRowIndex(e)
            const droppedOnRow = data[rowIndex]

            if (draggedRow !== droppedOnRow) {
                const _value = [...data]

                const oldIndex = data.indexOf(draggedRow)
                const newIndex = oldIndex > rowIndex ? rowIndex : rowIndex - 1

                move(_value, oldIndex, newIndex)
                onRowReorder({value: _value})
            }

            setDraggedRow(undefined)
            setDraggedDropIndex(undefined)
        },
        onDragEnd: e => {
            setDraggedRow(undefined)
            setDraggedDropIndex(undefined)
        }
    }
}