import I18n from '@I18n';
import React from "react";
import {NumberUtils} from "../../../../utils/NumberUtils";

const AbsenceAvatarTooltip = props => {
    const {
        employee,
        timeregabsence,
    } = props

    return <table>
        <tbody>
        <tr>
            <td><b>{I18n.t('employee')}:</b></td>
            <td><b>{employee?.name || '?'}</b></td>
        </tr>
        < tr>
            <td> {I18n.t('time')}:</td>
            <td>{NumberUtils.hours(timeregabsence.hours)}</td>
        </tr>
        </tbody>
    </table>
}
export default AbsenceAvatarTooltip;