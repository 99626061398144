import React from 'react';
import Grid from "../../components/Grid/Grid";

const OnlineEmployeesGrid = ({value}) => {
    const columns = [
        {field: 'employee.id', header: 'ID', width: 80},
        {field: 'employee.name', header: 'Employee', width: 150},
        {field: 'company.label', header: 'Company'},
        {field: 'ipAddress', header: 'IP-Address', width: 110},
        {field: 'version', header: 'Version', width: 110},
        {field: 'activeTimeString', header: 'Active Time', width: 110, align: 'right'},
        {field: 'idleTimeString', header: 'Idle Time', width: 110, align: 'right'},
    ]

    return <div style={{width: '1010px'}}>
        <Grid
            xlnzGrid={true}
            scrollable={false}
            columns={columns}
            value={value}
            onRowSelect={() => {
            }}
            forceDesktop={true}
        />
    </div>
}
export default OnlineEmployeesGrid;